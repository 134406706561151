import React from "react";
import logo1 from "../assets/homepage/pr1.png";
import logo2 from "../assets/homepage/pr2.png";
import logo3 from "../assets/homepage/pr3.png";
import logo4 from "../assets/homepage/pr4.png";
import logo5 from "../assets/homepage/pr5.png";
import "../styles/homepage.css";

function ClientSection() {
  return (
    <>
      <div className="container-fluid p-0">
        <div className="row g-0">
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4 mb-3">
            <div className="">
              <h2 className="partnership-heading">Our valued partnerships</h2>
            </div>
            <div className="slider">
              <div className="slide-track">
                <div className="slide animate__backOutLeft">
                  <img src={logo1} alt="logo1" />
                </div>
                <div className="slide">
                  <img src={logo2} alt="logo2" />
                </div>
                <div className="slide">
                  <img src={logo3} alt="logo3" />
                </div>
                <div className="slide">
                  <img src={logo4} alt="logo4" />
                </div>
                <div className="slide">
                  <img src={logo5} alt="5" />
                </div>
                <div className="slide animate__backOutLeft">
                  <img src={logo1} alt="logo1" />
                </div>
                <div className="slide">
                  <img src={logo2} alt="logo2" />
                </div>
                <div className="slide">
                  <img src={logo3} alt="logo3" />
                </div>
                <div className="slide">
                  <img src={logo4} alt="logo4" />
                </div>
                <div className="slide">
                  <img src={logo5} alt="5" />
                </div>
                <div className="slide animate__backOutLeft">
                  <img src={logo1} alt="logo1" />
                </div>
                <div className="slide">
                  <img src={logo2} alt="logo2" />
                </div>
                <div className="slide">
                  <img src={logo3} alt="logo3" />
                </div>
                <div className="slide">
                  <img src={logo4} alt="logo4" />
                </div>
                <div className="slide">
                  <img src={logo5} alt="5" />
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientSection;
