import React from "react";
import "../styles/homepageStyles/HomepageAboutStyles.css";
import aboutImage01 from "../assets/homepage-new/about-image-01.webp";
import aboutImage02 from "../assets/homepage-new/about-image-02.webp";
import icon1 from "../assets/homepage-new/icon-1.png";
import icon2 from "../assets/homepage-new/icon-2.png";
import icon3 from "../assets/homepage-new/icon-3.png";
import icon4 from "../assets/homepage-new/icon-4.webp";
import { Link, useNavigate } from "react-router-dom";
import { useApiContext } from "../contextapi/contextApi";
import { useState, useEffect } from "react";

function HomepageHeader(props) {
  const { signInUserData, instance, setAppointmentType } = useApiContext();
  const [hasDoctors, setHasDoctors] = useState(false);
  const navigate = useNavigate();

  const getPatientDoctors = async () => {
    try {
      const response = await instance.get(
        `/patient/get-doctors-list/${signInUserData.user_id}`
      );
      if (response) {
        if (response.data.data.length === 0) {
          setHasDoctors(false);
        } else {
          setHasDoctors(true);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getPatientDoctors();
  }, []);
  return (
    <>
      <div className="homepage-about-container">
        <div className="container">
          <div className="container-inner">
            <h1>Your virtual connection to dental excellence</h1>
            <p>
              Navigate your dentist selection effortlessly and engage in video
              consultations with ease. A harmonious dental experience tailored
              to your convenience awaits.
            </p>
            <div className="images-container">
              <div className="image-wrapper">
                <img src={aboutImage01} alt="about image 01" />
                <div className="image-overlay">
                  <h1>Find your destist</h1>
                  <p>Book an appointment fast and easy</p>
                </div>
              </div>
              <div className="image-wrapper">
                <img src={aboutImage02} alt="about image 02" />
                <div className="image-overlay">
                  <h1>
                    Do your video consultation by connecting with your dentist
                  </h1>
                </div>
              </div>
            </div>
            <div className="buttons-container">
              <button
                onClick={() => {
                  const url = `${
                    signInUserData && signInUserData?.user_type === "Patient"
                      ? hasDoctors
                        ? "/patient-portal"
                        : "/patient-portal/appointment-question"
                      : signInUserData && signInUserData?.user_type === "Doctor"
                      ? "/doctor-portal"
                      : "/patient-sign-up"
                  }`;
                  if (url.includes("appointment")) {
                    setAppointmentType("new");
                  }
                  navigate(url);
                }}
                className="btn-large color-p2"
              >
                {`${
                  signInUserData && signInUserData?.user_type === "Patient"
                    ? "Book An Appointment"
                    : signInUserData && signInUserData?.user_type === "Doctor"
                    ? "Doctor Dashboard"
                    : "Book An Appointment"
                }`}
              </button>
              <Link
                to={`${
                  signInUserData && signInUserData?.user_type === "Patient"
                    ? "/doctor-sign-up"
                    : signInUserData && signInUserData?.user_type === "Doctor"
                    ? "/patient-sign-up"
                    : "/doctor-sign-up"
                }`}
              >
                <button className="btn-large color-p1">
                  {`${
                    signInUserData && signInUserData?.user_type === "Patient"
                      ? "Join As A Doctor"
                      : signInUserData && signInUserData?.user_type === "Doctor"
                      ? "Join As A Patient"
                      : "Join As A doctor"
                  }`}
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="logos-container">
          <div className="item-wrapper">
            <div className="item-container">
              <div className="logo-wrapper">
                <img src={icon4} alt="" />
              </div>
              <h1>Online Consultation</h1>
            </div>
          </div>
          <div className="item-wrapper">
            <div className="item-container">
              <div className="logo-wrapper">
                <img src={icon3} alt="" />
              </div>
              <h1>Examination & Treatment</h1>
            </div>
          </div>
          <div className="item-wrapper">
            <div className="item-container">
              <div className="logo-wrapper">
                <img src={icon2} alt="" />
              </div>
              <h1>Specialist Suggestion</h1>
            </div>
          </div>
          <div className="item-wrapper">
            <div className="item-container">
              <div className="logo-wrapper">
                <img src={icon1} alt="" />
              </div>
              <h1>Medical Support</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomepageHeader;
