import React from "react";
import "../styles/ImprintStyles.css";
import Contact from "../assets/homepage/hosp.jpg";
import Contact2 from "../assets/homepage/image 21.png";
import { useApiContext } from "../contextapi/contextApi";
import NewNavbar from "../components/HomeNavbar";
import { useEffect } from "react";
import ContactUsForm from "./ContactUsForm";
import phone from "../assets/contact-phone.png";
import email from "../assets/contact-email.png";

import ClientSlider from "../components/ClientSection";
import MoreButtons from "../components/HomepageMoreButtons";
import Footer from "../components/HomepageFooter";
import Icons from "../components/HomepageIcons";

function Imprint() {
  const { signInUserData, userDetail } = useApiContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NewNavbar imprint={true} />
      <div className="Imprint-text-container">
        <span>Imprint</span>
        <div className="Imprint_text">
          <div>
            <h3>Biotech & Capital Consulting LLC</h3>
            <p>500 Westover Dr. #16113</p>
            <p>Sanfor, NC 27330</p>
          </div>
          <div>
            <p>CE0: Melynda Rae Tedder</p>
          </div>
          <div>
            <p>
              M:{" "}
              <a href="mailto:contact@bc-conulting-llc.com">
                contact@bc-conulting-llc.com
              </a>{" "}
            </p>
            <p>
              W:{" "}
              <a
                href="http://www.bc-consulting-llc.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.bc-consulting-llc.com
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer imprint={true} />
    </>
  );
}

export default Imprint;
