import React from "react";
import { Link } from "react-router-dom";
import Review from "../../assets/homepage/review.png";

const SubDashFifth = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-12"></div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="text-center mt-5 pt-3">
              <div className="mt-5">
                <img
                  src={Review}
                  alt="review-img"
                  className="img-fluid"
                  style={{ width: "50%" }}
                />
              </div>
              <div>
                <h5 className="fw-bold pt-3 mTc main-text">Congratulations!</h5>
                <p className="mTc po-2">
                  Appointment successfully booked. You will receive a
                  notification and the doctor you selected will contact you
                </p>
              </div>
              <Link to="/patient-portals" style={{ textDecoration: "none" }}>
                <div className="d-flex justify-content-center mt-5">
                  <div className="d-grid col-8 gap-2 mt-3 mb-4">
                    <button
                      className="register-button2 dashboard-p3 rounded"
                      type="button"
                    >
                      Go To Dashboard
                    </button>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12"></div>
        </div>
      </div>
    </>
  );
};

export default SubDashFifth;
