import React, { useEffect, useState } from "react";
import Pat2 from "../../../assets/dashboard/filter1.svg";
import Pat3 from "../../../assets/dashboard/calender.svg";
import Pat4 from "../../../assets/dashboard/video.svg";
import { useApiContext } from "../../../contextapi/contextApi";
import { times } from "lodash";

const History = () => {
  const { apiDoctorData, instance,signInUserData } = useApiContext();
  const [appDates, setAppDates] = useState();

  useEffect(() => {
    getPatientDoctors();
  }, []);

  const formatDate = (dateString) => {
    // Create a new Date object from the input date string
    const date = new Date(dateString);

    // Define an array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the day, month, and year components
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Get the day of the week as a string
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayOfWeek = daysOfWeek[date.getDay()];

    // Format the date string
    const formattedDate = `${dayOfWeek}, ${day} ${month} ${year}`;
    return formattedDate;
  };

  const handleTime = (time) => {
    const dateTimeParts = time.split(" ");

    // The time part is the second element in the array
    const timeString = dateTimeParts[1];

    return timeString;
  };

  const getPatientDoctors = async () => {
    try {
      const response = await instance.get(
        `/patient/get-consult-history/${signInUserData.user_id}/${apiDoctorData.id}`
      );
      if (response) {
        console.log("====================================");
        console.log(response.data.data);
        console.log("====================================");
        setAppDates(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching checkbox options:", error);
    }
  };

  return (
    <>
      <div className="p-1">
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-12"></div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <input
              type="text"
              className="patient-input input-field rounded border border-muted"
              placeholder=" Search"
            />
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12">
            <p className="fw-bold dashboard-p">
              <img src={Pat2} alt="" className="pe-1" width={25} />
              Filter
            </p>
          </div>
        </div>
        {appDates &&
          appDates.map((date) => (
            <div className="card shadow-sm ps-3 pe-3 pt-3 m-2">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="d-flex">
                    <div>
                      <img src={Pat3} className="img-fluid" alt="doctor-img" />
                    </div>
                    <div>
                      <ul className="list-unstyled ms-4">
                        <li className=" text-dark fw-bold dashboard-p3">
                          Date & Time
                        </li>
                        <li className=" text-muted pt-2 dashboard-p3">
                          {formatDate(date.appointment_date_times[0].date_time)}
                        </li>
                        <li className=" text-muted pt-2 dashboard-p3">
                          {/* Monday, 22 July 2022 */}
                          {/* handleTime */}
                          {handleTime(date.appointment_date_times[0].date_time)}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5 mt-2"></div>
                <div className="col-lg-3 col-md-3 col-sm-12 mt-2">
                  <div className="d-grid gap-2 col-12 mx-auto">
                    <button
                      className="patient-button1 dashboard-p3 rounded pt-1 pb-1"
                      type="button"
                    >
                      Complete
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="d-flex">
                    <div>
                      <img src={Pat4} className="img-fluid" alt="doctor-img" />
                    </div>
                    <div>
                      <ul className="list-unstyled ms-4">
                        <li className=" text-dark fw-bold dashboard-p3">
                          Appointment Type{" "}
                        </li>
                        <li className=" text-muted pt-2 dashboard-p3">
                          Online video
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5"></div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default History;
