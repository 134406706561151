import React from "react";
import { Link } from "react-router-dom";

function Button(props) {
  return (
    <>
   <Link to="/doctor-sign-up">
   <button className="main-button dashboard-p3 rounded p-2 ps-4 pe-4" type="submit">
        {props.name}
      </button>
   </Link>
    </>
  );
}

export default Button;
