import axios from "axios";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import Validation from "../../subcomponents/Validation";

function Check() {
  const navigate = useNavigate(); // Initialize useNavigate

  const [agreed, setAgreed] = useState(false);
  const [phone, setPhone] = useState("");

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  function handleInput(event) {
    const newObj = { ...values, [event.target.name]: event.target.value };
    setValues(newObj);
  }

  function handleValidation(event) {
    event.preventDefault();
    setErrors(Validation(values, agreed));

    axios
      .get(`http://192.168.0.13/check-email?email=${values.email}`)
      .then((response) => {
        if (response.data.exists) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "This email is already registered!",
          }));
        } else {
          axios
            .post("http://192.168.0.13/register-doctor", values)
            .then((response) => {
              console.log("Success:", response.data);
              navigate("/");
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  return (
    <>
      <form onSubmit={handleValidation}>
        <div>
          <label for="exampleInputEmail1" className="form-label fw-bold mTc">
            Name
          </label>
          <div className="d-flex">
            <div>
              <input
                type="text"
                placeholder="First Name"
                name="first_name"
                className="form-control me-2 register-input"
                aria-describedby="emailHelp"
                onChange={handleInput}
              />
              {errors.first_name && (
                <p className="text-danger input-p">{errors.first_name}</p>
              )}
            </div>
            <div>
              <input
                type="text"
                placeholder="Last Name"
                name="last_name"
                className="form-control ms-4 register-input"
                aria-describedby="emailHelp"
                onChange={handleInput}
              />
              {errors.last_name && (
                <p className="text-danger ps-4 input-p p-0">
                  {errors.last_name}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="mt-2">
          <label for="exampleInputEmail1" className="form-label fw-bold mTc">
            Email
          </label>
          <div className="d-flex position-relative">
            <input
              type="email"
              placeholder="Email"
              name="email"
              className="form-control register-input"
              aria-describedby="emailHelp"
              onChange={handleInput}
            />
          </div>
          {errors.email && (
            <p className="text-danger input-p">{errors.email}</p>
          )}
        </div>

        <div className="mt-3">
          <label htmlFor="phoneInput" className="form-label fw-bold mTc">
            Phone Number
          </label>
          <div id="survey-form">
            <PhoneInput
              country={"de"}
              className="marginBottom"
              value={phone}
              onChange={(newPhone) => {
                setPhone(newPhone); // Update the phone state
                handleInput({
                  target: { name: "phone_no", value: newPhone },
                }); // Update the values.phone field
              }}
              inputProps={{
                style: {
                  height: "35px",
                  width: "99%",
                },
              }}
            />
            <div>
              {errors.phone_no && (
                <p className="text-danger input-p">{errors.phone_no}</p>
              )}
            </div>
          </div>
        </div>

        <div className="mt-3">
          <label for="exampleInputEmail1" className="form-label fw-bold mTc">
            Password
          </label>
          <div className="d-flex position-relative">
            <input
              type="text"
              placeholder="Password"
              name="password"
              className="form-control register-input"
              aria-describedby="emailHelp"
              onChange={handleInput}
            />
          </div>
          {errors.password && (
            <p className="text-danger input-p">{errors.password}</p>
          )}
        </div>

        <div className="form-check mt-3 mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            checked={agreed}
            onChange={(e) => setAgreed(e.target.checked)}
            id="flexCheckDefault"
          />
          <label className="mTc doc-lab" for="flexCheckDefault">
            By selecting I Agree, I acknowledge that I have the right to
            represent the, Lorem ipsum dolor sit amet, consectetur adipiscing
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
            laboris nisi ut aliquip ex ea commodo consequat.
          </label>
        </div>
        {errors.agreed && (
          <p className="text-danger input-p">{errors.agreed}</p>
        )}

        <div className="d-grid gap-2 pb-4">
          <input
            style={{ textDecoration: "none" }}
            type="submit"
            className="register-button2 dashboard-p3 rounded"
            value="Sign Up"
          />
        </div>
      </form>
    </>
  );
}

export default Check;
