import React, { useState } from "react";
import Facebook from "../assets/homepage/facebook.svg";
import Instagram from "../assets/homepage/instagram.svg";
// import Instagram from "../assets/homepage/twitter.svg";
import { Link, useNavigate } from "react-router-dom";
import "../styles/homepage.css";
import { useApiContext } from "../contextapi/contextApi";
import Logout from "./doctorportal/Logout";

function Footer() {
  const { signInUserData, setAppointmentType } = useApiContext(useApiContext);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();

  const handleLogoutTabClick = () => {
    setShowLogoutModal(true);
  };

  const handleLogoutConfirm = () => {
    localStorage.removeItem("signInUserData");
    localStorage.removeItem("user-profile");
    localStorage.removeItem("answer_ids");
    window.location.href = "/";
  };

  const handleLogoutModalClose = () => {
    setShowLogoutModal(false);
  };

  const isDoctor = signInUserData && signInUserData.user_type === "Doctor";
  const isPatient = signInUserData && signInUserData.user_type === "Patient";
  return (
    <>
      <footer className="text-center text-lg-start bg-white">
        <section className="">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-4 col-lg-4 col-xl-4 col-sm-12 mx-auto mb-4">
                <h3 className=" fw-bold mb-4 footer-h3">Get in Touch</h3>
                <p className="footer-p w-75">
                  Follow us on social media to see posts regarding best
                  experiences of online consultation
                </p>
                <div className="d-flex align-items-baseline">
                  <Link
                    to="https://www.facebook.com/login"
                    className="text-decoration-none"
                  >
                    <img src={Facebook} alt="Facebook-img" width={30} />
                  </Link>
                  <Link
                    to="https://www.instagram.com/"
                    className="text-decoration-none"
                  >
                    <img
                      src={Instagram}
                      alt="Instagram-img"
                      width={30}
                      className="m-2 ms-3"
                    />
                  </Link>
                  {/* <img
                    src={Twitter}
                    alt="twitter-img"
                    width={20}
                    className="ms-2"
                  /> */}
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xl-4 col-sm-12 mx-auto mb-4">
                <h6 className=" fw-bold mb-4 footer-h3">Company info</h6>
                <Link to="/about" className="text-decoration-none">
                  <p className="footer-p">About</p>
                </Link>
                <Link to="/contact" className="text-decoration-none">
                  <p className="footer-p">Contact Us</p>
                </Link>
              </div>
              <div className="col-md-4 col-lg-4 col-xl-4 col-sm-12 mx-auto mb-4">
                <h6
                  className=" fw-bold mb-4 footer-h3"
                  style={{ cursor: "pointer" }}
                >
                  Join Now
                </h6>

                {isDoctor ? (
                  // Render this if the user is a doctor
                  <div className="rounded" style={{ width: "fit-content" }}>
                    <a
                      onClick={handleLogoutTabClick}
                      className="text-decoration-none"
                    >
                      <p className="footer-p" style={{ cursor: "pointer" }}>
                        Logout
                      </p>
                    </a>
                  </div>
                ) : (
                  // Render this if the user is a patient
                  <div className="rounded" style={{ width: "fit-content" }}>
                    <Link to="/doctor-sign-up" className="text-decoration-none">
                      <p className="footer-p" style={{ cursor: "pointer" }}>
                        Join as a Doctor
                      </p>
                    </Link>
                  </div>
                )}

                {isPatient ? (
                  <>
                    <div className="rounded" style={{ width: "fit-content" }}>
                      <Link
                        onClick={() => {
                          setAppointmentType("new");
                          navigate("/patient-portal/appointment-questions");
                        }}
                        className="text-decoration-none"
                      >
                        <p className="footer-p" style={{ cursor: "pointer" }}>
                          Make an Appointment
                        </p>
                      </Link>
                    </div>
                    <div className="rounded" style={{ width: "fit-content" }}>
                      <a
                        onClick={handleLogoutTabClick}
                        className="text-decoration-none"
                      >
                        <p className="footer-p" style={{ cursor: "pointer" }}>
                          Logout
                        </p>
                      </a>
                    </div>
                  </>
                ) : (
                  <div className="rounded" style={{ width: "fit-content" }}>
                    <Link
                      to="/patient-sign-up"
                      className="text-decoration-none"
                    >
                      <p className="footer-p" style={{ cursor: "pointer" }}>
                        Join as a Patient
                      </p>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        <Logout
          showModal={showLogoutModal}
          onClose={handleLogoutModalClose}
          onConfirm={handleLogoutConfirm}
        />
        {/* <div className="footer-p text-center p-4">
          Made With Love By Figmaland All Right Reserved
        </div> */}
      </footer>
    </>
  );
}
export default Footer;
