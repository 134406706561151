import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Time from "./Group 75729.png";
import "./Schedule1.css";

function Schedule1() {
  const [visibleButtons, setVisibleButtons] = useState([1, 2, 3, 4]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 250);
  }, []);

  // Function to handle the close button click
  const handleButtonClick = (buttonId) => {
    setVisibleButtons((prevButtons) =>
      prevButtons.filter((id) => id !== buttonId)
    );
  };

  const [selectedOption, setSelectedOption] = useState("1"); // Default to "1 Person"

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <>
      {isLoading ? (
        <div className="content-spinner">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="container-fluid">
          <div>
            <div className="mt-2">
              <h6 className="fw-bold mo">Add Schedule</h6>

              <div className="row pe-2">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <p className=" pt-3 mo dashboard-p3">Timing Slots Duration</p>
                  <select
                    className="form-select mo"
                    aria-label="Default select example"
                  >
                    <option selected>30 Minutes</option>
                    <option value="1">25 Minutes</option>
                    <option value="2">35 Minutes</option>
                    <option value="3">55 Minutes</option>
                  </select>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <p className=" pt-3 mo dashboard-p3">Capacity / Slots</p>
                  <select
                    className="form-select mo"
                    aria-label="Default select example"
                    onChange={handleOptionChange}
                    value={selectedOption}
                  >
                    <option value="1">1 Person</option>
                    <option value="2">2 Person</option>
                  </select>
                </div>
              </div>

              <div className="me-2 mt-3 mb-2">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <p className=" mo dashboard-p3">Availability</p>
                    <p className="d-flex justify-content-between border rounded p-2">
                      <span className="fw-bold dashboard-p3 mo">
                        Monday-Saturday
                      </span>

                      <span className="fw-bold dashboard-p3">
                        <Link
                          to="/doctor-portal/availability"
                          className="text-decoration-none tim"
                        >
                          Change
                        </Link>
                      </span>
                    </p>
                  </div>

                  <p className="mo dashboard-p3">Time Slots</p>

                  {selectedOption === "1" && (
                    <div
                      className="col-lg-12 col-md-12 col-sm-12"
                      id="personOne"
                    >
                      <div className="border rounded p-2">
                        {visibleButtons.includes(1) && (
                          <button className="button1 border bg-white dashboard-p3 m-2 rounded p-2 ps-2 mo">
                            <img src={Time} alt="" className="me-2" />
                            8:00 pm - 8:30 pm
                            <button
                              type="button"
                              className="btn-close ps-2 ms-4"
                              aria-label="Close"
                              onClick={() => handleButtonClick(1)} // Close button click handler
                            ></button>
                          </button>
                        )}
                      </div>
                    </div>
                  )}

                  {selectedOption === "2" && (
                    <div
                      className="col-lg-12 col-md-12 col-sm-12"
                      id="personTwo"
                    >
                      <div className="border rounded p-2">
                        {visibleButtons.includes(4) && (
                          <>
                            <button className="border bg-white dashboard-p3 m-2 rounded p-2 ps-2 mo">
                              <div>
                                <img src={Time} alt="" className="me-2" />
                                8:00 pm - 8:15 pm
                                <button
                                  type="button"
                                  className="btn-close ps-2 ms-4"
                                  aria-label="Close"
                                  onClick={() => handleButtonClick(4)}
                                ></button>
                              </div>
                              <div className="mt-2 me-5">
                                <img src={Time} alt="" className="me-2" />
                                8:15 pm - 8:30 pm
                              </div>
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Schedule1;
