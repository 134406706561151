export default function Validation(values, agreed) {
  const errors = {};

  const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,6}$/;
  const password_pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
  const phone_pattern = /^[+]?[0-9]{1,3}[-\s.]?[0-9]{1,14}$/;
  const name_pattern = /^[a-zA-ZÀ-ÿñÑçÇÁÉÍÓÚÜáéíñóúüöÖÅÄäØøÆæ€\.\, -]+$/;
  const currentDate = new Date();
  const inputDate = new Date(values.dob);

  console.log("First name: ", {
    first_names: values.first_name,
    validationResult: name_pattern.test(values.first_name),
  });

  if (values.first_name === "") {
    errors.first_name = "First Name is Required!";
  } else if (!name_pattern.test(values.first_name)) {
    errors.first_name =
      "Invalid name! Avoid using special characters & numbers like '@', '#', '$', etc.";
  }

  if (values.last_name === "") {
    errors.last_name = "Last Name is Required!";
  } else if (!name_pattern.test(values.last_name)) {
    errors.last_name =
      "Invalid name! Avoid using special characters & numbers like '@', '#', '$', etc.";
  }

  if (values.phone_no === "") {
    errors.phone_no = "Phone Number is Required!";
  } else if (!phone_pattern.test(values.phone_no)) {
    errors.phone_no = "Invalid Phone Number!";
  }

  if (values.email === "") {
    errors.email = "Email is Required!";
  } else if (!email_pattern.test(values.email)) {
    errors.email = "Invalid Email!";
  }

  if (values.password === "") {
    errors.password = "Password is Required!";
  } else if (!password_pattern.test(values.password)) {
    errors.password =
      "Password atleast 1 digit, 1 lowercase, 1uppercase & at least 8 characters long";
  }

  if (values.dob === "") {
    errors.dob = "DOB is Required!";
  } else if (inputDate > currentDate) {
    errors.dob = "Invalid DOB";
  }

  if (values.subject === "") {
    errors.subject = "Subject is Required!";
  }

  if (!agreed) {
    errors.agreed = "Please agree to the terms!";
  }

  return errors;
}
