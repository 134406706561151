import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Review from "../../assets/homepage/review.png";
import Subscriptions from "../../components/Subscriptions";
import { useApiContext } from "../../contextapi/contextApi";
import Navbar from "../Navbar";
import "./doctorPortalStyles.css";

const defaultImage = require("../../assets/homepage/profile-img.png");

const roundedBorder = {
  padding: "5px",
  width: "100px",
};

function DoctorProfile() {
  const { signInUserData, instance, setUserDetail } =
    useApiContext(useApiContext);
  const navigate = useNavigate();
  const [phone, setPhone] = useState(signInUserData.phone_no);
  const [diseases, setDiseases] = useState([]);
  const [specialistInList, setSpecialistInList] = useState([]);
  const [cities, setCities] = useState([]);
  const [first_name, setFirst_name] = useState(signInUserData.first_name);
  const [last_name, setLast_name] = useState(signInUserData.last_name);
  const [email, setEmail] = useState(signInUserData.email);
  const [about_us, setAbout_us] = useState("");
  const [specialist_in, setSpecialist_in] = useState("");
  const [specialization, setSpecialization] = useState([]);
  const [membership_num, setMembership_num] = useState("");
  const [lnar, setlnar] = useState("");
  const [association, setAssociation] = useState("");
  const [insurance_preference, setInsurance_preference] = useState("");
  const [file, setFile] = useState("");
  const [address1, setAddress1] = useState("");
  const [website, setWebsite] = useState("");
  const [clinicName, setClinicName] = useState("");
  const [address2, setAddress2] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [dieasesIds, setDieasesIds] = useState([[]]);
  const [profile_image, setProfileImage] = useState(defaultImage);
  const [progress, setProgress] = useState(25);
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [placeholder, setPlaceholder] = useState("Select");
  const [clickedOnFifty, setClickedOnFifty] = useState(false);
  const [clickedOnTwentyFive, setClickedOnTwentyFive] = useState(false);
  const [clickedOnSeventyFive, setClickedOnSeventyFive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // profile some inputs listing api call //

  useEffect(() => {
    instance.get(`/doctor/edit/${signInUserData.user_id}`).then((response) => {
      if (response.data.statusCode === 200) {
        setDiseases(response.data.data.disease_list);
        setSpecialistInList(response.data.data.specialist_in_list);
        setCities(response.data.data.cities);
      } else {
        console.log("Api is not working");
      }
    });
  }, []);

  // create profile api call //

  const handleButtonClick = () => {
    if (progress === 25) {
      setClickedOnTwentyFive(true);

      if (
        first_name.trim() !== "" &&
        last_name.trim() !== "" &&
        email.trim() !== ""
      ) {
        setProgress(50);
      }
    } else if (progress === 50) {
      setClickedOnFifty(true);
      if (
        lnar.trim() !== "" &&
        association.trim() !== "" &&
        membership_num.trim() !== "" &&
        insurance_preference.trim() !== "" &&
        tags.length !== 0 &&
        dieasesIds[0].length !== 0 &&
        specialist_in.trim() !== ""
      ) {
        setProgress(75);
      }
    } else if (progress === 75) {
      setClickedOnSeventyFive(true);
      console.log(state);
      if (
        address1.trim() !== "" &&
        clinicName.trim() !== "" &&
        isValidWebsiteURL() &&
        city.trim() !== "" &&
        state.trim() !== "" &&
        zipcode.trim() !== ""
      ) {
        setProgress(100);
      }
    }
  };

  const submitApplication = () => {
    let tempArray = [];
    const formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("about_us", about_us);
    formData.append("specialist_in", specialist_in);
    tempArray = JSON.stringify(tags);
    formData.append("specialization", tempArray);
    formData.append("lnar", lnar);
    formData.append("association", association);
    formData.append("membership_num", membership_num);
    formData.append("insurance_preference", insurance_preference);
    formData.append("address1", address1);
    formData.append("address2", address2);
    formData.append("zipcode", zipcode);
    formData.append("state", state);
    formData.append("clinic_name", clinicName);
    formData.append("website", website);
    formData.append("city", city);
    formData.append("user_id", signInUserData.user_id);
    formData.append("profile_image", file);
    tempArray = JSON.stringify(dieasesIds[0]);
    formData.append("diseases", tempArray);

    setIsLoading(true);
    instance
      .post(`/doctor/create-doctor`, formData, {
        headers: {
          "Content-Type": "multipart/-data",
        },
      })
      .then((response) => {
        setIsLoading(false);
        toast.success("Doctor profile created successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setUserDetail(response.data.data);
        navigate("/doctor-portal");
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Error creating doctor profile. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  // file send handler //

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setProfileImage(reader.result);
    };
    if (file) {
      setFile(file);
      reader.readAsDataURL(file);
    }
  };

  // handel change chnage the value //

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "first_name") setFirst_name(value);
    if (name === "last_name") setLast_name(value);
    if (name === "about_us") setAbout_us(value);
    if (name === "about_us") setAbout_us(value);
    if (name === "state") setState(value);
    if (name === "lnar") setlnar(value);
    if (name === "specialist_in") setSpecialist_in(value);
    if (name === "association") setAssociation(value);
    if (name === "membership_num") setMembership_num(value);
    if (name === "address1") setAddress1(value);
    if (name === "address2") setAddress2(value);
    if (name === "website") setWebsite(value);
    if (name === "zipcode") setZipcode(value);
    if (name === "clinic_name") setClinicName(value);
    if (name === "city") setCity(value);
  };

  // option handler //

  // const handleOptionChange = (event) => {
  //   const selectedOption = event.target.value;
  //   if (!selectedOptions.includes(selectedOption)) {
  //     setDieasesIds([...selectedOptions, selectedOption]);
  //     setSelectedOptions([...selectedOptions, selectedOption]);
  //   }
  // };

  // const handleClearSelection = (selectedOption) => {
  //   setSelectedOptions(
  //     selectedOptions.filter((option) => option !== selectedOption)
  //   );
  // };
  const handleSelect = (selected) => {
    const newDiseaseIds = Object.keys(selected).map((key) => selected[key].id);
    const dataArray = [newDiseaseIds];
    setDieasesIds(dataArray);
    setPlaceholder("");
    setSelectedOptions(selected);
  };

  const handleRemove = (selected) => {
    const newDiseaseIds = Object.keys(selected).map((key) => selected[key].id);
    const dataArray = [newDiseaseIds];
    setDieasesIds(dataArray);
    setPlaceholder("");
    setSelectedOptions(selected);
  };
  // progress bar handler //

  const handleBackButtonClick = () => {
    setProgress(progress - 25);
  };

  // tags //

  const handleChange = (tags) => {
    setTags(tags);
  };

  //  unsed handler //

  const handleInputChange1 = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      // Prevent the default behavior of the Enter key (form submission)
      event.preventDefault();
      // Add the new tag to the tags array
      setTags([...tags, inputValue.trim()]);
      setSpecialization([...tags, inputValue.trim()]);
      // Clear the input field
      setInputValue("");
    }
  };

  const handleTagRemove = (tagToRemove) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
  };

  // Function to handle next button click and progress update
  const handleNextButtonClick = () => {
    if (progress < 100) {
      setProgress(progress + 25);
    }
  };

  // Function to check valid website URL
  const isValidWebsiteURL = (inputValue) => {
    const urlRegex =
      /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;

    // Check if the input value matches the URL pattern
    if (urlRegex.test(website) || website === "") {
      return true;
    } else {
      return false;
    }
  };

  // ------------------------- //

  //react select
  return (
    <>
      <Navbar disp="d-none" />
      <div className="container-fluid">
        <div className="row g-0">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="rounded border text-center p-2 m-3">
              {profile_image && (
                <div>
                  <img
                    src={profile_image}
                    alt="Profile"
                    className="rounded-circle"
                    width="100"
                    height="100"
                  />
                </div>
              )}
              <label
                className="rounded list-button dashboard-p3 col-6 p-1 mb-1 mt-4"
                htmlFor="upload-input"
              >
                Upload
                <input
                  id="upload-input"
                  type="file"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
              </label>
            </div>
            <div className="rounded border text-center p-2 m-3 mono2">
              <div
                className="nav dim-content flex-column mb-5 pb-5 nav-pills"
                id="v-tabs"
                role="tablist"
                aria-orientation="vertical"
                aria-disabled
              >
                <a
                  className="nav-link mt-2 mb-2"
                  id="tab1-tab"
                  data-bs-toggle="pill"
                  href="#tab1"
                  role="tab"
                  aria-controls="tab1"
                  aria-selected="true"
                >
                  Dashboard
                </a>
                <a
                  className="nav-link  mt-4 mb-4"
                  id="tab2-tab"
                  data-bs-toggle="pill"
                  href="#tab2"
                  role="tab"
                  aria-controls="tab2"
                  aria-selected="false"
                >
                  Appointments
                </a>
                <a
                  className="nav-link mt-2 mb-2"
                  id="tab3-tab"
                  data-bs-toggle="pill"
                  href="#tab3"
                  role="tab"
                  aria-controls="tab3"
                  aria-selected="true"
                >
                  My Doctors
                </a>

                <a
                  className="nav-link  mt-4 mb-4"
                  id="tab6-tab"
                  data-bs-toggle="pill"
                  href="#tab6"
                  role="tab"
                  aria-controls="tab6"
                  aria-selected="false"
                >
                  Messages
                </a>
                <a
                  className="nav-link mt-2 mb-2"
                  id="tab7-tab"
                  data-bs-toggle="pill"
                  href="#tab7"
                  role="tab"
                  aria-controls="tab7"
                  aria-selected="true"
                >
                  Profile Settings
                </a>
                <a
                  className="nav-link  mt-4 mb-5"
                  id="tab8-tab"
                  data-bs-toggle="pill"
                  href="#tab8"
                  role="tab"
                  aria-controls="tab8"
                  aria-selected="false"
                >
                  Log Out
                </a>
              </div>
            </div>
          </div>
          {signInUserData.is_approved !== "1" ? (
            <div className="col-lg-9 col-md-9 col-sm-12 mono3">
              <div className="rounded border p-1 mt-3">
                <div className="ms-3">
                  <h6 className="fw-bold pt-2 mTc main-text">Profile Setup</h6>
                  {progress === 100 ? (
                    <>
                      <h6 className="main-text mtc1 fw-bold">
                        Congratulations!!!
                      </h6>
                      <p className=" w-75 mTc mo9">
                        Your profile is complete. Please submit your profile for
                        review. Once approved you will get an email.
                      </p>
                    </>
                  ) : (
                    <p className=" w-75 mTc mo9">
                      Your profile is incomplete. Make sure to add all the
                      information correctly. We’ll send this information for
                      verification
                    </p>
                  )}

                  <div className="row g-0 align-items-baseline">
                    <div className="col-lg-10 col-md-10 col-sm-12">
                      <div className="progress">
                        <div
                          className={`progress-bar ${
                            progress === 100 ? "progress-complete" : ""
                          }`}
                          role="progressbar"
                          style={{ width: `${progress}%` }}
                          aria-valuenow={progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12">
                      <div className="circle-container">
                        <p className=" bg-white fw-bold text-success circle">{`${progress}%`}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-2">
                <div>
                  {progress === 25 && (
                    <>
                      <div className="mb-5 ps-3 pe-3 mt-3">
                        <h6 className="fw-bold mTc main-text">
                          Personal Information
                        </h6>
                        <form novalidate>
                          <div className="mb-2 mt-2">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label
                                  htmlFor="first_name"
                                  for="exampleInputEmail1"
                                  className="form-label fw-bold mTc mo7"
                                  // className={`form-label fw-bold mTc mo7 ${
                                  //   clickedOnTwentyFive && first_name === ""
                                  //     ? "text-danger"
                                  //     : ""
                                  // }`}
                                >
                                  First Name *
                                </label>
                                <input
                                  type="text"
                                  placeholder="First Name"
                                  className={`form-control me-2 mb-2 mt-2 register-input ${
                                    clickedOnTwentyFive && first_name === ""
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  id="first_name"
                                  name="first_name"
                                  value={first_name}
                                  aria-describedby="emailHelp"
                                  onChange={handleInputChange}
                                  required
                                  readOnly
                                />
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label
                                  htmlFor="last_name"
                                  for="exampleInputEmail1"
                                  className={`form-label fw-bold mTc mo7 ${
                                    clickedOnTwentyFive && last_name === ""
                                      ? "text-danger"
                                      : ""
                                  }`}
                                >
                                  Last Name *
                                </label>
                                <input
                                  type="text"
                                  placeholder="Last Name"
                                  className="form-control mt-2 register-input"
                                  // className={`form-control mt-2 register-input ${
                                  //   clickedOnTwentyFive && last_name === ""
                                  //     ? "is-invalid"
                                  //     : ""
                                  // }`}
                                  id="last_name"
                                  name="last_name"
                                  value={last_name}
                                  aria-describedby="emailHelp"
                                  onChange={handleInputChange}
                                  required
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mb-2">
                            <label
                              for="exampleInputEmail1"
                              className={`form-label fw-bold mTc mo7 ${
                                clickedOnTwentyFive && phone === ""
                                  ? "text-danger"
                                  : ""
                              }`}
                            >
                              Phone Number *
                            </label>
                            <div id="survey-form">
                              <PhoneInput
                                country={"us"}
                                className="marginBottom"
                                value={phone}
                                onChange={(phone) => setPhone(phone)}
                                inputProps={{
                                  style: {
                                    height: "35px",
                                    width: "99%",
                                  },
                                }}
                                readOnly
                                disabled
                              />
                            </div>
                          </div>

                          <div className="mb-2">
                            <label
                              htmlFor="email"
                              for="exampleInputEmail1"
                              className="form-label fw-bold mTc mo7"

                              // className={`form-label fw-bold mTc mo7 ${
                              //   clickedOnTwentyFive && email === ""
                              //     ? "text-danger"
                              //     : ""
                              // }`}
                            >
                              Email *
                            </label>
                            <div className="d-flex position-relative">
                              <input
                                type="email"
                                placeholder="Email"
                                className="form-control me-2 mb-2 mt-2 register-input"
                                id="email"
                                name="email"
                                value={email}
                                onChange={handleInputChange}
                                aria-describedby="emailHelp"
                                required
                                readOnly
                              />
                            </div>
                          </div>

                          <div className="mb-2">
                            <label
                              htmlFor="about_us"
                              for="exampleInputEmail1"
                              className="form-label fw-bold mTc mo7"
                            >
                              About Me{" "}
                              <span style={{ fontWeight: "100" }}>
                                {" "}
                                (optional)
                              </span>
                            </label>
                            <div className="d-flex position-relative">
                              <textarea
                                type="text"
                                placeholder="Add a description about yourself"
                                className="form-control me-2 mb-2 mt-2 register-input1"
                                id="about_us"
                                name="about_us"
                                value={about_us}
                                onChange={handleInputChange}
                                aria-describedby="emailHelp"
                                style={{
                                  height: "10rem",
                                  padding: "0.6rem 1.2rem",
                                }}
                                maxLength="300"
                              />
                            </div>
                          </div>
                        </form>

                        <div className="d-grid col-3 gap-2 mt-5 mb-4">
                          <button
                            disabled={isLoading}
                            className="register-button2 dashboard-p3 rounded"
                            type="submit"
                            onClick={handleButtonClick}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </>
                  )}

                  {progress === 50 && (
                    <>
                      <div className="mb-5 ps-3 mt-3 pe-3">
                        <h6 className="fw-bold mTc main-text">
                          Professional Information
                        </h6>
                        <form novalidate>
                          <div className="mb-3 mt-3">
                            <div className="row mt-3">
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label
                                  htmlFor="specialist_in"
                                  for="exampleInputEmail1"
                                  className="form-label fw-bold mTc mo7"

                                  // className={`form-label fw-bold mTc mo7 ${
                                  //   clickedOnFifty && specialist_in === ""
                                  //     ? "text-danger"
                                  //     : ""
                                  // }`}
                                >
                                  Specialist In *
                                </label>
                                <select
                                  onChange={(e) =>
                                    setSpecialist_in(e.target.value)
                                  }
                                  className={`form-select register-input m-1 mTc ${
                                    clickedOnFifty && specialist_in === ""
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  aria-label="Default select example"
                                  value={specialist_in}
                                >
                                  <option
                                    value=""
                                    selected={
                                      specialist_in === "" ? true : false
                                    }
                                    disabled
                                  >
                                    Select One
                                  </option>
                                  {specialistInList &&
                                    specialistInList.map((item) => (
                                      <option key={item.id} value={item.id}>
                                        {item.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label
                                  htmlFor="specialization"
                                  for="exampleInputEmail1"
                                  className="form-label fw-bold mTc mo7"

                                  // className={`form-label fw-bold mTc mo7 ${
                                  //   clickedOnFifty && tags.length === 0
                                  //     ? "text-danger"
                                  //     : ""
                                  // }`}
                                >
                                  Specialization *
                                </label>
                                <div
                                  className={` ${
                                    clickedOnFifty && tags.length === 0
                                      ? "multiselect-outer-styles"
                                      : ""
                                  }`}
                                >
                                  <TagsInput
                                    value={tags}
                                    onChange={handleChange}
                                    inputProps={{
                                      placeholder: "Specialization",
                                      style: roundedBorder, // Apply the rounded border style here
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* <div className="row mt-3">
                              <div
                                className="col-lg-12 col-md-12 col-sm-12"
                                style={{ width: "100%" }}
                              >
                                <label
                                  htmlFor="input"
                                  for="exampleInputEmail1"
                                  className="form-label fw-bold mTc mo7"
                                >
                                  Diseases List *
                                </label>
                                <div className="d-flex">
                                  <Multiselect
                                    options={diseases}
                                    selectedValues={selectedOptions}
                                    onSelect={handleSelect}
                                    displayValue="name"
                                  />
                                </div>
                              </div>
                            </div> */}

                            <div className="row mt-3">
                              <div
                                className="col-lg-12 col-md-12 col-sm-12"
                                style={{ width: "100%" }}
                              >
                                <label
                                  for="exampleInputEmail1"
                                  className="form-label fw-bold mTc mo7 "

                                  // className={`form-label fw-bold mTc mo7 ${
                                  //   clickedOnFifty && dieasesIds[0].length === 0
                                  //     ? "text-danger"
                                  //     : ""
                                  // }`}
                                >
                                  Diseases List *
                                </label>
                                <div
                                  className={`${
                                    clickedOnFifty && dieasesIds[0].length === 0
                                      ? "multiselect-outer-styles"
                                      : ""
                                  }`}
                                >
                                  <Multiselect
                                    options={diseases}
                                    selectedValues={selectedOptions}
                                    onSelect={handleSelect}
                                    onRemove={handleRemove}
                                    displayValue="name"
                                    value={signInUserData?.diseases}
                                    placeholder={placeholder}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label
                                  htmlFor="lnar"
                                  for="exampleInputEmail1"
                                  className="form-label fw-bold mTc mo7"

                                  // className={`form-label fw-bold mTc mo7 ${
                                  //   clickedOnFifty && lnar === ""
                                  //     ? "text-danger"
                                  //     : ""
                                  // }`}
                                >
                                  LNAR *
                                </label>
                                <input
                                  type="number"
                                  placeholder=""
                                  className={`form-control me-2 mb-2 mt-2 register-input ${
                                    clickedOnFifty && lnar === ""
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  id="lnar"
                                  name="lnar"
                                  value={lnar}
                                  aria-describedby="emailHelp"
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label
                                  htmlFor="association"
                                  for="exampleInputEmail1"
                                  className="form-label fw-bold mTc mo7"

                                  // className={`form-label fw-bold mTc mo7 ${
                                  //   clickedOnFifty && association === ""
                                  //     ? "text-danger"
                                  //     : ""
                                  // }`}
                                >
                                  Association Name *
                                </label>
                                <input
                                  type="text"
                                  placeholder=""
                                  className={`form-control mt-2 register-input ${
                                    clickedOnFifty && association === ""
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  id="association"
                                  name="association"
                                  value={association}
                                  aria-describedby="emailHelp"
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label
                                  htmlFor="membership_num"
                                  for="exampleInputEmail1"
                                  className="form-label fw-bold mTc mo7"

                                  // className={`form-label fw-bold mTc mo7 ${
                                  //   clickedOnFifty && membership_num === ""
                                  //     ? "text-danger"
                                  //     : ""
                                  // }`}
                                >
                                  Membership Number *
                                </label>
                                <input
                                  type="text"
                                  placeholder=""
                                  className={`form-control me-2 mb-2 mt-2 register-input ${
                                    clickedOnFifty && membership_num === ""
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  id="membership_num"
                                  name="membership_num"
                                  value={membership_num}
                                  aria-describedby="emailHelp"
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label
                                  htmlFor="insurance_preference"
                                  for="exampleInputEmail1"
                                  className="form-label fw-bold mTc mo7"

                                  // className={`form-label fw-bold mTc mo7 ${
                                  //   clickedOnFifty &&
                                  //   insurance_preference === ""
                                  //     ? "text-danger"
                                  //     : ""
                                  // }`}
                                >
                                  Insurance Preference*
                                </label>
                                <select
                                  className={`form-select register-input m-1 mTc ${
                                    clickedOnFifty &&
                                    insurance_preference === ""
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  aria-label="Default select example"
                                  onChange={(e) =>
                                    setInsurance_preference(e.target.value)
                                  }
                                  value={insurance_preference}
                                >
                                  <option
                                    value=""
                                    selected={
                                      insurance_preference === "" ? true : false
                                    }
                                    disabled
                                  >
                                    Select One
                                  </option>
                                  <option value="Legally">Legally</option>
                                  <option value="Privately">Privately</option>
                                  <option value="Both">Both</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </form>

                        <div className="d-flex">
                          <div className="d-grid col-3 gap-2 mt-2 mb-4">
                            <button
                              className="box-button4 dashboard-p3 rounded"
                              type="button"
                              onClick={handleBackButtonClick}
                            >
                              Back
                            </button>
                          </div>

                          <div className="d-grid col-3 gap-2 mt-2 ms-3 mb-4">
                            <button
                              className="register-button2 dashboard-p3 rounded"
                              type="submit"
                              onClick={handleButtonClick}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {progress === 75 && (
                    <>
                      <div className="mt-3 mb-5 ps-3 pe-3">
                        <h6 className="fw-bold mTc ">Clinic Information</h6>
                        <form novalidate>
                          <div className="row mt-3">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                              <label
                                htmlFor="Clinic Name"
                                for="exampleInputEmail1"
                                className="form-label fw-bold mTc mo7"

                                // className={`form-label fw-bold mTc mo7 ${
                                //   clickedOnSeventyFive && clinicName === ""
                                //     ? "text-danger"
                                //     : ""
                                // }`}
                              >
                                Clinic Name*
                              </label>
                              <input
                                type="text"
                                placeholder="Clinic Name"
                                className={`form-control mt-2 register-input ${
                                  clickedOnSeventyFive && clinicName === ""
                                    ? "is-invalid"
                                    : ""
                                }`}
                                id="clinic_name"
                                name="clinic_name"
                                value={clinicName}
                                // aria-describedby="emailHelp"
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                              <label
                                htmlFor="websitek"
                                for="exampleInputEmail1"
                                className="form-label fw-bold mTc mo7"
                              >
                                Website{" "}
                                <span style={{ fontWeight: "100" }}>
                                  {" "}
                                  (optional)
                                </span>
                              </label>
                              <input
                                type="text"
                                placeholder="http://www.domain_name.com"
                                className={`form-control mt-2 register-input ${
                                  clickedOnSeventyFive &&
                                  (isValidWebsiteURL() ? "" : "is-invalid")
                                }`}
                                id="website"
                                name="website"
                                title="Please enter a valid url"
                                value={website}
                                // aria-describedby="emailHelp"
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>

                          <div className="mb-3">
                            <div className="row mt-3">
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <label
                                  htmlFor="address1"
                                  for="exampleInputEmail1"
                                  className="form-label fw-bold mTc mo7"

                                  // className={`form-label fw-bold mTc mo7 ${
                                  //   clickedOnSeventyFive && address1 === ""
                                  //     ? "text-danger"
                                  //     : ""
                                  // }`}
                                >
                                  Address 1*
                                </label>
                                <input
                                  type="text"
                                  placeholder=""
                                  className={`form-control mt-2 register-input ${
                                    clickedOnSeventyFive && address1 === ""
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  id="address1"
                                  name="address1"
                                  value={address1}
                                  aria-describedby="emailHelp"
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label
                                  htmlFor="address2"
                                  for="exampleInputEmail1"
                                  className="form-label fw-bold mTc mo7"
                                >
                                  Address 2{" "}
                                  <span style={{ fontWeight: "100" }}>
                                    {" "}
                                    (optional)
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  placeholder=""
                                  className="form-control me-2 mb-2 mt-2 register-input"
                                  id="address2"
                                  name="address2"
                                  value={address2}
                                  aria-describedby="emailHelp"
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label
                                  htmlFor="association"
                                  for="exampleInputEmail1"
                                  className="form-label fw-bold mTc mo7"
                                >
                                  City*
                                </label>
                                <input
                                  type="text"
                                  placeholder=""
                                  className={`form-control me-2 mb-2 mt-2 register-input ${
                                    clickedOnSeventyFive && city === ""
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  id="city"
                                  name="city"
                                  value={city}
                                  // aria-describedby="emailHelp"
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label
                                  htmlFor="membership_num"
                                  for="exampleInputEmail1"
                                  className="form-label fw-bold mTc mo7"
                                >
                                  State*
                                </label>
                                <input
                                  type="text"
                                  placeholder=""
                                  className={`form-control me-2 mb-2 mt-2 register-input ${
                                    clickedOnSeventyFive && state === ""
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  id="state"
                                  name="state"
                                  value={state}
                                  // aria-describedby="emailHelp"
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label
                                  htmlFor="zipcode"
                                  for="exampleInputEmail1"
                                  className="form-label fw-bold mTc mo7"
                                >
                                  Zipcode*
                                </label>
                                <input
                                  type="text"
                                  placeholder=""
                                  className={`form-control mt-2 register-input ${
                                    clickedOnSeventyFive && zipcode === ""
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  id="zipcode"
                                  name="zipcode"
                                  value={zipcode}
                                  aria-describedby="emailHelp"
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </form>

                        <div className="d-flex">
                          <div className="d-grid col-3 gap-2 mt-2 mb-4">
                            <button
                              className="box-button4 dashboard-p3 rounded"
                              type="button"
                              onClick={handleBackButtonClick}
                            >
                              Back
                            </button>
                          </div>

                          <div className="d-grid col-3 gap-2 mt-2 ms-3 mb-4">
                            <button
                              className="register-button2 dashboard-p3 rounded"
                              type="button"
                              onClick={handleButtonClick}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {progress < 100 && <></>}

                  {progress === 100 && (
                    <>
                      <div className="text-center mt-2">
                        <div className="pt-4 pb-4">
                          <img
                            src={Review}
                            alt="review-img"
                            className="img-fluid"
                            style={{ width: "20%" }}
                          />
                        </div>
                        <div className="pb-3">
                          <h5 className="fw-bold pt-3 mTc main-text">
                            Please submit your
                            application
                          </h5>
                        </div>
                        <button
                          disabled={isLoading}
                          className="register-button2 dashboard-p3 rounded w-50"
                          type="button"
                          onClick={submitApplication}
                        >
                          {isLoading ? (
                            <AiOutlineLoading3Quarters className="chat_spinner" />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <Subscriptions />
          )}
        </div>
      </div>
    </>
  );
}

export default DoctorProfile;
