import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/register/Logo.svg";
import RegisterNavbar from "../doctor/RegisterNavbar";

function PatientThird() {
  return (
    <>
      <RegisterNavbar />
      <div className="container-fluid mt-3">
        <div className="row g-0">
          <div className="col-lg-3 col-md-3 col-sm-12"></div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="text-center">
              <img src={Logo} alt="logo-img" className="img-fluid" />

              <h6 className="fw-bold pt-5 mt-2 pb-3 mTc">
                How are you Insured?
              </h6>

              <div className="row mt-5">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="border rounded p-4 ms-lg-5 me-lg-5">
                    <h6 className="pt-3 pb-3 mTc">Legally Insured</h6>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="border rounded p-4 ms-lg-5 me-lg-5">
                    <h6 className="pt-3 pb-3 mTc">Privately Insured</h6>
                  </div>
                </div>
              </div>

              <div className="d-grid gap-2 col-6 mt-5 pt-5 mx-auto">
                <button className="register-next rounded p-2" type="button">
                  <Link
                    to="/patient-fourth"
                    className="text-decoration-none text-white"
                  >
                    
                    Continue
                  </Link>
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12"></div>
        </div>
      </div>
    </>
  );
}

export default PatientThird;
