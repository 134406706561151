import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import PresentToAllIcon from "@mui/icons-material/PresentToAll";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import CallEndIcon from "@mui/icons-material/CallEnd";
import ScreenshotMonitorIcon from "@mui/icons-material/ScreenshotMonitor";
import { BsChatLeftTextFill } from "react-icons/bs";
import { BiWebcam } from "react-icons/bi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { useState, useRef, useEffect } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import html2canvas from "html2canvas";
import {
  useAgoraVideoContext,
  useFocusedContext,
  useHostUserContext,
} from "./AgoraContext";
import { useApiContext } from "../../../contextapi/contextApi";
import { useToggleChatContext } from "./AgoraContext";
import { useScreenshot } from "use-react-screenshot";

export default function VideoControls({
  localTracksRef,
  setIsJoined,
  setRemoteUsers,
  videoFeedRef,
  toggleDeviceUser,
  deviceConnected,
  externalVideoRef,
  cameraOptions,
  initialCameraLabel,
}) {
  const [muteMic, setMuteMic] = useState(false);
  const { isVideoMuted, setIsVideoMuted } = useHostUserContext();
  const [showScreenShare, setShowScreenShare] = useState(false);
  const screenSharingTrack = useRef(null);
  const { setFocusedUser, focusedUser } = useFocusedContext();
  const { signInUserData, guestUser } = useApiContext();
  const { showChat, setShowChat } = useToggleChatContext();
  const navigate = useNavigate();
  const { client, deviceClient } = useAgoraVideoContext();
  const [image, takeScreenshot] = useScreenshot();

  const handleScreenSharingEnded = async () => {
    setShowScreenShare((prev) => !prev);
    setRemoteUsers((prevUsers) => {
      return prevUsers?.map((User) => {
        if (User?.host) {
          return { ...User, videoTrack: localTracksRef.current.videoTrack };
        }
        return User;
      });
    });
    await client.unpublish(screenSharingTrack.current);
    await client.publish(localTracksRef.current.videoTrack);
  };

  useEffect(() => {
    if (screenSharingTrack.current) {
      screenSharingTrack.current._originMediaStreamTrack.addEventListener(
        "ended",
        handleScreenSharingEnded
      );
    }
  }, [screenSharingTrack.current]);

  const leaveChannel = async () => {
    await client.leave();
    await deviceClient.leave();
    await localTracksRef.current.audioTrack.close();
    await localTracksRef.current.videoTrack.close();
    await externalVideoRef?.current?.videoTrack?.close();
    setIsJoined(false);
    const newRoute =
      signInUserData?.user_type === "Doctor"
        ? "/doctor-portal"
        : "/patient-portal";
    navigate(newRoute);
  };

  const toggleMic = async () => {
    await localTracksRef.current.audioTrack.setMuted(!muteMic);
    setMuteMic((prev) => !prev);
  };

  const toggleCamera = async () => {
    await localTracksRef.current.videoTrack.setMuted(!isVideoMuted);
    setIsVideoMuted((prev) => !prev);
  };

  const toggleScreenShare = async () => {
    const showScreen = showScreenShare;
    setShowScreenShare((prev) => !prev);

    if (!showScreen) {
      screenSharingTrack.current = await AgoraRTC.createScreenVideoTrack();

      setRemoteUsers((prevUsers) => {
        return prevUsers?.map((User) => {
          if (User?.host) {
            return { ...User, videoTrack: screenSharingTrack.current };
          }
          return User;
        });
      });

      await client.unpublish(localTracksRef.current.videoTrack);
      await client.publish(screenSharingTrack.current);
    } else {
      setRemoteUsers((prevUsers) => {
        return prevUsers?.map((User) => {
          if (User?.host) {
            return { ...User, videoTrack: localTracksRef.current.videoTrack };
          }
          return User;
        });
      });
      await client.unpublish(screenSharingTrack.current);
      await client.publish(localTracksRef.current.videoTrack);

      setFocusedUser(null);
      await localTracksRef.current.videoTrack.setMuted(isVideoMuted);
    }
  };

  const takeScreenShot = (element) => {
    const dataURL = takeScreenshot(element);

    const blob = dataURLtoBlob(image);

    // Copy the screenshot to the clipboard
    navigator.clipboard.write([
      new ClipboardItem({
        "image/png": blob,
      }),
    ]);

    toast.success("Copied screenshot to clip board successfully!");
  };

  function dataURLtoBlob(dataURL) {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  function handleCameraOptionSelect(event) {
    if (event.target.value !== "default") {
      const selectedCamera = event.target.value;
      const selectedCameraObject = cameraOptions.filter(
        (c) => c.deviceId === selectedCamera
      );

      if (selectedCameraObject[0]?.label === initialCameraLabel) {
        toast.warn("Camera Device already in use");
      } else {
        toggleDeviceUser(selectedCamera);
      }
    }
  }

  return (
    <>
      {signInUserData && (
        <button
          onClick={() => {
            setShowChat((prev) => !prev);
          }}
          className={`vc_control_button chat_toggle_button ${
            showChat ? null : "active"
          }`}
        >
          <BsChatLeftTextFill />
        </button>
      )}
      <button
        onClick={toggleMic}
        className={`vc_control_button ${muteMic ? null : "active"}`}
      >
        {!muteMic ? <MicIcon /> : <MicOffIcon />}
      </button>
      <button
        onClick={toggleCamera}
        className={`vc_control_button ${isVideoMuted ? null : "active"}`}
      >
        {!isVideoMuted ? <VideocamIcon /> : <VideocamOffIcon />}
      </button>
      <button
        onClick={toggleScreenShare}
        className={`vc_control_button ${showScreenShare ? "active" : null}`}
      >
        {!showScreenShare ? <PresentToAllIcon /> : <CancelPresentationIcon />}
      </button>
      <button
        onClick={() => takeScreenShot(videoFeedRef.current)}
        className="vc_control_button screenshot"
      >
        <ScreenshotMonitorIcon />
      </button>
      {signInUserData &&
        (deviceConnected ? (
          <button
            onClick={toggleDeviceUser}
            className="vc_control_button active"
          >
            <BiWebcam />
            <span>Remove Device</span>
          </button>
        ) : (
          <label className="vc_control_label">
            <BiWebcam />
            <select onChange={handleCameraOptionSelect}>
              <option value="default">Select a camera</option>
              {cameraOptions?.map((camera) => (
                <option key={camera?.deviceId} value={camera?.deviceId}>
                  {camera?.label}
                </option>
              ))}
            </select>
          </label>
        ))}
      <button
        onClick={leaveChannel}
        className="vc_control_button vc_control_leave"
      >
        <CallEndIcon />
      </button>
    </>
  );
}
