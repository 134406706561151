import React, { useEffect, useState } from "react";
import { addDays, subDays } from "date-fns";
import Portal16 from "../../../assets/dashboard/dashboard-patient8.svg";
import classnames from "classnames";
import Portal17 from "../../../assets/dashboard/dashboard-patient7.svg";
import { format } from "date-fns";
import { useApiContext } from "../../../contextapi/contextApi";
import { useNavigate } from "react-router";
import { getCurrentDateInGermany } from "../../../Utils/Utils";

function Reschedule({ closeModal }) {
  const { appdetail, instance, signInUserData, schdulerequestid } =
    useApiContext();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(getCurrentDateInGermany());
  const [activeDate, setActiveDate] = useState(getCurrentDateInGermany());
  const [dateToday, setDateToday] = useState(getCurrentDateInGermany());
  const [timeSlots, setTimeSlots] = useState([]);
  const [activeSlot, setActiveSlot] = useState("");
  const [time, setTime] = useState("");
  const [disableScollLeftOnCalendar, setDisableScollLeftOnCalendar] =
    useState(true);

  console.log("Reschedule appointment");

  useEffect(() => {
    getTimeSlots(activeDate);
  }, []);

  const getTimeSlots = (date) => {
    setActiveDate(date);
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Get the day of the week (0-6, where 0 is Sunday)
    const dayOfWeek = date.getDay();

    // Get the day name based on the day of the week
    const dayName = dayNames[dayOfWeek];

    instance
      .post("/patient/get-doctor-timings", {
        day: dayName,
        doctor_id: signInUserData?.doctor?.id,
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          setTimeSlots(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching doctor timings:", error);
      });
    // }
    // Define an array of day names
  };

  const rescheduleAppointment = async () => {
    try {
      const response = await instance
        .post("/reschedule-appoinment", {
          appoinment_id: schdulerequestid,
          time: time,
          date: activeDate,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.statusCode === 201) {
            navigate("/doctor-portal");
          }
        });
    } catch (error) {
      console.error("Error fetching checkbox options:", error);
    }
  };

  const handleSlot = (slot) => {
    setActiveSlot(slot);
    setTime(slot);
  };

  const formatDate = (dateString) => {
    const inputDate = new Date(dateString);
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dayOfWeek = daysOfWeek[inputDate.getDay()];
    const month = months[inputDate.getMonth()];
    const dayOfMonth = inputDate.getDate();

    const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}`;
    return formattedDate;
  };

  const getWeekDates = (start) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      dates.push(addDays(start, i));
    }
    return dates;
  };
  const weekDates = getWeekDates(selectedDate);
  const handleNextClick = () => {
    setSelectedDate(addDays(selectedDate, 7));
  };

  const handlePreviousClick = () => {
    if (
      !(format(weekDates[0], "yyyy-MM-dd") === format(dateToday, "yyyy-MM-dd"))
    ) {
      setSelectedDate(subDays(selectedDate, 7));
    }
  };

  useEffect(() => {
    if (
      format(weekDates[0], "yyyy-MM-dd") === format(dateToday, "yyyy-MM-dd")
    ) {
      setDisableScollLeftOnCalendar(true);
    } else {
      setDisableScollLeftOnCalendar(false);
    }
  }, [selectedDate]);

  return (
    <React.Fragment>
      <div class="modal-dialog modal-xl" style={{ marginTop: "-22%" }}>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title fw-bold" id="exampleModalLabel">
              Time Slots
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            ></button>
          </div>
          <div class="modal-body">
            <div className="container doctor-appointment mt-2 ps-5 pe-5 rounded pt-4 mb-5">
              <div className="mb-2 mt-2">
                <div className="row align-items-center">
                  <div
                    style={{ opacity: disableScollLeftOnCalendar ? 0.5 : 1 }}
                    className="col-lg-1 col-md-2 col-sm-12 transition"
                  >
                    <button
                      onClick={handlePreviousClick}
                      className="bg-transparent"
                      style={{ border: "1px soild var(--main-bg-color)" }}
                    >
                      <img src={Portal16} alt="patient-img" />
                    </button>
                  </div>
                  <div className="col-lg-10 col-md-8 col-sm-12">
                    <div className="date-list mt-2">
                      {weekDates.map((date, index) => (
                        <div
                          key={index}
                          className={classnames(
                            "date-item",
                            "custom-cursor",
                            "rounded",
                            "p-2",
                            {
                              "active-date":
                                activeDate &&
                                format(date, "yyyy-MM-dd") ===
                                  format(activeDate, "yyyy-MM-dd"),
                            }
                          )}
                          onClick={() => {
                            getTimeSlots(date);
                            setActiveDate(date);
                          }}
                        >
                          <div className="date">
                            {format(date, "dd")} <br />
                            {format(date, "EE")}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-lg-1 col-md-2 col-sm-12 text-end">
                    <button
                      onClick={handleNextClick}
                      className="bg-transparent"
                      style={{ border: "1px soild var(--main-bg-color)" }}
                    >
                      <img src={Portal17} alt="patient-img" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mt-5 mb-3">
                <h6 className="mTc fw-bold">Available Slots</h6>

                {timeSlots &&
                  timeSlots.map((slot) => (
                    <div
                      className="col-lg-3 col-md-4 col-sm-12 mt-3"
                      key={slot}
                    >
                      <div class="d-grid gap-2 col-12 mx-auto">
                        <button
                          onClick={() => handleSlot(slot)}
                          className={`rounded border p-2 ${
                            slot === activeSlot ? "active-slot" : ""
                          }`}
                          type="button"
                        >
                          {slot}
                        </button>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="d-flex justify-content-center mt-5">
                    <div class="d-grid gap-2 col-12 col-md-4 mt-5 mb-5">
                      <button
                        onClick={() => {
                          rescheduleAppointment();
                          closeModal();
                          navigate("/doctor-portal");
                        }}
                        class="fw-bold rounded patient-button2 p-2"
                        type="button"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Reschedule;
