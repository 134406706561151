import { useState } from "react";

import {
  AgoraChatContextProvider,
  ToggleChatContextProvider,
} from "../livemeeting/components/AgoraContext";
import ChatFeedWrapper from "./ChatFeedWrapper";
import "./Chat.css";

export default function Chat() {
  const [showChatWindows, setShowChatWindows] = useState(false);

  return (
    <AgoraChatContextProvider>
      <ToggleChatContextProvider>
        <ChatFeedWrapper />
      </ToggleChatContextProvider>
    </AgoraChatContextProvider>
  );
}
