import React, { useState, useRef } from "react";
import "./Device.css";
import Webcam from "react-webcam";

function WebcamComponent() {
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [showWebcam, setShowWebcam] = useState(true);
  const webcamRef = useRef(null);

  const handleSwitchDevice = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(
      (device) => device.kind === "videoinput"
    );

    if (videoDevices.length > 1) {
      const nextDeviceId =
        selectedDeviceId === videoDevices[0].deviceId
          ? videoDevices[1].deviceId
          : videoDevices[0].deviceId;

      setSelectedDeviceId(nextDeviceId);
      setShowWebcam(false);

      setTimeout(() => {
        setShowWebcam(true);
      }, 100);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <div>
          <div className="d-flex justify-content-center">
            {showWebcam && (
              <Webcam
                style={{
                  borderRadius: "10px",
                  width: "100%",
                  transform: "scaleX(-1)"
                }}
                audio={false}
                videoConstraints={{
                  deviceId: selectedDeviceId || undefined
                }}
                ref={webcamRef}
              />
            )}
          </div>
          <div className="d-flex justify-content-center mt-2">
            <button onClick={handleSwitchDevice} className="devicebtn">
              Switch Device
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default WebcamComponent;
