import React, { useState } from "react";
import "../../styles/dashboard.css";
import { Link, Outlet } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/homepage/Logo.svg";
import IMO from "../../assets/dashboard/mobile-icon.png";
import className from "classnames";
import Logout from "../doctorportal/Logout";
import { useApiContext } from "../../contextapi/contextApi";
import DefultImg from "../../assets/homepage/profile-img.png";
import { format, differenceInYears, parseISO } from "date-fns";
import Breadcrumbs from "../breadCrumbs";
import UnverfiedPatient from "../UnverfiedPatient";
import { FaUserDoctor } from "react-icons/fa6";
import AppointmentModal from "../AppointmentModal";
import Swal from "sweetalert2";
import useCompleteProfileStore from "../../store/useCompleteProfileStore";

function formatDate(dob) {
  const dobDate = parseISO(dob);
  const formattedDate = format(dobDate, "dd MMM yyyy");
  const age = differenceInYears(new Date(), dobDate);
  return `${formattedDate}, ${age} years`;
}

function PatientPortalRoutes() {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [activeTab, setActiveTab] = useState("/patient-portal");
  const [dashboardName, setDashboardName] = useState("Dashboard");
  const {
    isVerified,
    signInUserData,
    baseImageURL,
    newMessages,
    setAppointmentType,
  } = useApiContext();
  const { notified, setNotified } = useCompleteProfileStore((state) => state);

  const currentUrl = window.location.href;
  const urlSearchParams = new URLSearchParams(currentUrl);

  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const cleanedQueryString = search.startsWith("?") ? search.slice(1) : search;

  const [showApppointmentModal, setShowApppointmentModal] = useState(
    cleanedQueryString === "first=true"
  );

  const handleTabChange = (tabPath, tabName) => {
    setActiveTab(tabPath);
    setDashboardName(tabName);
  };

  const handleLogoutTabClick = () => {
    setShowLogoutModal(true);
  };

  const handleLogoutConfirm = () => {
    localStorage.removeItem("signInUserData");
    localStorage.removeItem("user-profile");
    localStorage.removeItem("answer_ids");
    window.location.href = "/";
  };

  const handleLogoutModalClose = () => {
    setShowLogoutModal(false);
  };

  let location = useLocation();
  let activeLink = location.pathname;

  if (!signInUserData) {
    return (
      <div className="content-spinner">
        <div className="loader"></div>
      </div>
    );
  }

  if (!isVerified) return <UnverfiedPatient />;

  const dob = signInUserData?.dob;
  const formattedDOB = dob ? formatDate(dob) : "";

  if (signInUserData && !dob && !notified) {
    Swal.fire({
      title: "Incomplete Profile",
      text: "Your profile is currently incomplete, which limits some features and benefits. Head over to your profile settings and fill in the missing details to enhance your experience!",
    }).then(() => {
      setNotified(true);
      navigate("/patient-portal/profile-setting");
    });
  }

  return (
    <>
      <AppointmentModal
        show={showApppointmentModal}
        setShowApppointmentModal={setShowApppointmentModal}
        username={signInUserData?.first_name + " " + signInUserData?.last_name}
      />
      <section className="shadow-sm">
        <div className="container-fluid d-none d-sm-block">
          <div
            className={
              pathname === "/patient-portal/my-doctors"
                ? "find_doctor_nav"
                : "row g-0 p-2 ps-4 pe-4"
            }
          >
            <div
              className={
                pathname === "/patient-portal/my-doctors"
                  ? ""
                  : "col-lg-5 col-md-5 col-sm-12"
              }
            >
              <div>
                <Link to="/">
                  <img src={Logo} alt="logo-img" className="img-fluid" />
                </Link>
              </div>
            </div>
            <div
              className={
                pathname === "/patient-portal/my-doctors"
                  ? ""
                  : "col-lg-7 col-md-7 col-sm-12"
              }
            >
              <div className="d-flex justify-content-between align-items-baseline mt-2 ms-lg-5">
                <h5 className="fw-bold mTc main-text48">{dashboardName}</h5>
                <button
                  class="btn rounded d-block d-sm-none"
                  type="button"
                  style={{ backgroundColor: "rgba(4, 156, 213, 1)" }}
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <img src={IMO} alt="" width={20} />
                </button>
              </div>
            </div>
            {pathname === "/patient-portal/my-doctors" ? (
              <div className="find_a_doctor_container">
                <button
                  className="Button find_a_doctor"
                  type="button"
                  onClick={() => {
                    setAppointmentType("news");
                    navigate("/patient-portal/appointment-questions");
                  }}
                >
                  <FaUserDoctor /> Find a New Doctor
                </button>
              </div>
            ) : null}
          </div>
        </div>
        <div className="container-fluid d-sm-none">
          <div className="row g-0 p-2 ps-4 pe-4">
            <div className="col-lg-5 col-md-5 col-sm-12 d-flex justify-content-between align-items-center">
              <div>
                <Link to="/">
                  <img src={Logo} alt="logo-img" className="img-fluid" />
                </Link>
              </div>
              {pathname === "/patient-portal/my-doctors" ? (
                <div className="find_a_doctor_container col-sm-6">
                  <button
                    className="Button find_a_doctor"
                    type="button"
                    onClick={() => {
                      setAppointmentType("new");
                      navigate("/patient-portal/appointment-questions");
                    }}
                  >
                    Find a Doctor
                  </button>
                </div>
              ) : null}
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12">
              <div className="d-flex justify-content-between align-items-baseline mt-2 ms-lg-5">
                <h5 className="fw-bold mTc main-text48">{dashboardName}</h5>
                <button
                  class="btn rounded d-block d-sm-none"
                  type="button"
                  style={{ backgroundColor: "rgba(4, 156, 213, 1)" }}
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <img src={IMO} alt="" width={20} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid">
        <div className="row g-0">
          <div className="col-lg-3 col-md-4 col-sm-12">
            <div className="collapse dont-collapse-sm" id="collapseExample">
              <div className="rounded border text-center p-2 m-3">
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    margin: "auto",
                  }}
                >
                  <img
                    src={
                      signInUserData.profile_image
                        ? `${baseImageURL}${signInUserData.profile_image}`
                        : DefultImg
                    }
                    className="doctor10-img rounded-circle"
                    alt="doctor-img"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </div>

                <h5 className="fw-bold pt-3">
                  {signInUserData.first_name} {signInUserData.last_name}
                </h5>
                <p className="dashboard-p3">{formattedDOB}</p>
              </div>

              <div className="bg-white rounded border ps-2 me-3 pe-2 ms-3 mono2">
                <ul id="" role="" className=" list-unstyled">
                  <li className="mt-4">
                    <Link to="/patient-portal" className="text-decoration-none">
                      <a
                        className={className("nav-style", {
                          "active-css": activeLink === "/patient-portal",
                        })}
                        onClick={() =>
                          handleTabChange("/patient-portal", "Dashboard")
                        }
                      >
                        Dashboard
                      </a>
                    </Link>
                  </li>
                  <li className="mt-4">
                    <Link
                      to="/patient-portal/my-device"
                      className="text-decoration-none"
                    >
                      <a
                        className={className("nav-style", {
                          "active-css":
                            activeLink === "/patient-portal/my-device",
                        })}
                        onClick={() =>
                          handleTabChange("/patient-portal", "My Device")
                        }
                      >
                        My Device
                      </a>
                    </Link>
                  </li>
                  <li className="mt-4">
                    <Link
                      to="/patient-portal/appointment"
                      className="text-decoration-none"
                    >
                      <a
                        className={className("nav-style", {
                          "active-css":
                            activeLink === "/patient-portal/appointment" ||
                            activeLink ===
                              "/patient-portal/appointment/history",
                        })}
                        onClick={() =>
                          handleTabChange("/patient-portal", "Appointment")
                        }
                      >
                        Appointment
                      </a>
                    </Link>
                  </li>
                  <li className="mt-4">
                    <Link
                      to="/patient-portal/my-doctors"
                      className="text-decoration-none"
                    >
                      <a
                        className={className("nav-style", {
                          "active-css":
                            activeLink == "/patient-portal/my-doctors",
                        })}
                        onClick={() =>
                          handleTabChange("/patient-portal", "My Doctors")
                        }
                      >
                        My Doctors
                      </a>
                    </Link>
                  </li>

                  <li className="mt-4">
                    <Link
                      to="/patient-portal/messages"
                      className="text-decoration-none"
                    >
                      <a
                        className={className(
                          "nav-style d-flex align-items-center justify-content-between",
                          {
                            "active-css":
                              activeLink == "/patient-portal/messages",
                          }
                        )}
                        onClick={() =>
                          handleTabChange("/patient-portal", "Messages")
                        }
                      >
                        Messages{" "}
                        {newMessages > 0 ? (
                          <span className="new_appointment_notification">
                            {" "}
                            {newMessages}
                          </span>
                        ) : null}
                      </a>
                    </Link>
                  </li>

                  <li className="mt-4">
                    <Link
                      to="/patient-portal/profile-setting"
                      className="text-decoration-none"
                    >
                      <a
                        className={className("nav-style", {
                          "active-css":
                            activeLink == "/patient-portal/profile-setting",
                        })}
                        onClick={() =>
                          handleTabChange("/patient-portal", "Profile Setting")
                        }
                      >
                        Profile Setting
                      </a>
                    </Link>
                  </li>
                  <li className="mt-4" style={{ cursor: "pointer" }}>
                    <a className="nav-style" onClick={handleLogoutTabClick}>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Logout
            showModal={showLogoutModal}
            onClose={handleLogoutModalClose}
            onConfirm={handleLogoutConfirm}
          />
          <div className="col-lg-9 col-md-8 col-sm-12 mt-3 mono3">
            <div className="me-3">
              <div className="breadcrumb-wrapper-patient">
                <Breadcrumbs />
              </div>

              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PatientPortalRoutes;
