import React, { useState } from "react";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useApiContext } from "../../contextapi/contextApi";
import "../../styles/dashboard.css";

function SignIn() {
  const { setSignInUserData, instance, setUserDetail } = useApiContext();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const loginProcess = async (event) => {
    event.preventDefault(event);
    try {
      setIsLoading(true);
      const response = await instance
        .post("/login", {
          email: email,
          password: password,
        })
        .then((response) => {
          setIsLoading(false);

          console.log("sing in payload: ", response.data);

          if (response?.data?.statusCode === 200) {
            if (response.data.data.user_type === "Patient") {
              if (response.data.data.is_email_verified === 0) {
                toast.error(
                  "Sorry, your email address isn't verified yet. Please verify your email address and try again."
                );
              } else {
                setSignInUserData(response.data.data);
                setUserDetail(response.data.data);
                navigate("/patient-portal?first=true");
              }
            } else if (response.data.data.user_type === "Doctor") {
              setSignInUserData(response.data.data);
              setUserDetail(response.data.data);
              navigate("/doctor-portal");
            }
          }
        });
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.status === 403) {
        const responseData = error?.response?.data?.message;
        const messages = [];
        Object.keys(responseData).forEach((key) => {
          const errorMessage = responseData[key][0];
          switch (key) {
            case "email":
              if (errorMessage === "The selected email is invalid.") {
                messages.push("The selected email is invalid.");
              }
              break;
            case "password":
              if (
                errorMessage ===
                "The password field must be at least 8 characters."
              ) {
                messages.push(
                  "The password field must be at least 8 characters."
                );
              }
              break;
          }
        });
        setErrorMessages(messages);
      } else {
        toast.error("Something went wrong.");
      }
    }
  };

  return (
    <>
      <div className="form-wrapper">
        <h1>LOGIN TO YOUR ACCOUNT</h1>
        <form onSubmit={loginProcess}>
          <div className="mt-5">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Email
            </label>
            <div className="d-flex position-relative">
              <input
                type="email"
                placeholder="Email Address"
                name="email"
                className="form-input"
                aria-describedby="emailHelp"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                required
              />
            </div>
          </div>
          <div className="mt-3">
            <label htmlFor="exampleInputPassword1" className="form-label">
              Password
            </label>
            <div className="d-flex position-relative">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                className="form-input"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                required
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="eye-button"
                style={{
                  border: "none",
                  background: "none",
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                }}
              >
                {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </button>
            </div>
            <div className="mt-2">
              {errorMessages.map((message, index) => (
                <div key={index} className="error-message text-danger input-p">
                  {message}
                </div>
              ))}
            </div>
            <div className="d-grid gap-2 mt-4 pb-4">
              <button
                disabled={isLoading}
                className="btn-small color-gradient"
                type="submit"
              >
                {isLoading ? (
                  <AiOutlineLoading3Quarters className="chat_spinner" />
                ) : (
                  "Sign In"
                )}
              </button>
            </div>
          </div>
        </form>
        <div className="buttons-container">
          <span> Not a member yet?</span>
          <div className="buttons-container-inner">
            <Link to="/doctor-sign-up">
              <button>Join As A Doctor</button>
            </Link>
            <Link to="/patient-sign-up">
              <button>Join As A Patient</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;
