import React, {useState, useEffect} from "react";
import axios from "axios";
import Doc1 from "../../assets/homepage/Arrow5.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const SubDashThird = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/patient-portal/question-02");
  };



  const [checkboxOptions, setCheckboxOptions] = useState([]);
      const [selectedCheckbox, setSelectedCheckbox] = useState(null);
    
      useEffect(() => {
        // Fetch checkbox options from API when component mounts
        fetchCheckboxOptions();
      }, []);
    
      const fetchCheckboxOptions = async () => {
        try {
          const response = await axios.get(
            "https://jsonplaceholder.typicode.com/posts"
          );
          setCheckboxOptions(response.data);
        } catch (error) {
          console.error("Error fetching checkbox options:", error);
        }
      };
    
      const handleCheckboxClick = (option) => {
        if (selectedCheckbox === option) {
          setSelectedCheckbox(null);
        } else {
          setSelectedCheckbox(option);
        }
      };
    
      const handleSubmit = async () => {
        if (selectedCheckbox) {
          try {
            const response = await axios.post(
              "https://jsonplaceholder.typicode.com/posts",
              {
                selectedCheckbox: selectedCheckbox,
              }
            );
    
            console.log("API Response:", response.data);
    
            setSelectedCheckbox(null);
          } catch (error) {
            console.error("Error:", error);
          }
        } else {
          console.log("Please select a checkbox before continuing.");
        }
      };
  return <>
        <>
            <div>
              <section className="ms-2">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="d-flex mt-3 mb-3">
                      <Link to="/patient-portal/appointment-slot">
                        <div>
                          <img
                            src={Doc1}
                            alt="arrow-img"
                            width={30}
                            className="main-img"
                            
                          />
                        </div>
                        </Link>
                        <div className="ps-2 pt-1">
                          <h6 className="main-text mTc fw-bold">
                            Schedule Appointment
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="mt-4">
                        <p className="mTc font-p2">
                          Please state the reason for your consultation
                        </p>

                        {checkboxOptions.map((option) => (
                          <div className="d-flex" key={option.id}>
                            <div
                              className={`circle-checkbox ${
                                selectedCheckbox === option ? "checked" : ""
                              }`}
                              onClick={() => handleCheckboxClick(option)}
                            >
                              <div className="checkbox-inner"></div>
                            </div>
                            <p className="ps-2 font-p1">{option.title}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 mb-5">
                    <div class="d-grid gap-2 col-5 mx-auto">
                      <button
                        class="Button"
                        type="button"
                        // onClick={handleSubmit}
                        onClick={handleClick}
                   
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
  </>;
};

export default SubDashThird;
