import React, { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useApiContext } from "../../contextapi/contextApi";
import "../../styles/dashboard.css";
import Validation from "../../subcomponents/Validation";

function DoctorPortal() {
  const { setSignInUserData, instance } = useApiContext();
  const navigate = useNavigate(); // Initialize useNavigate

  const [agreed, setAgreed] = useState(false);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    password: "",
  });

  function handleInput(event) {
    setErrors({});
    const errorMessagesContainer = document.getElementById("error-messages");
    errorMessagesContainer.innerHTML = "";
    const newObj = { ...values, [event.target.name]: event.target.value };
    setValues(newObj);
  }

  function handleValidation(event) {
    event.preventDefault();
    const tempErrors = Validation(values, agreed);
    setErrors(tempErrors);

    if (
      "email" in tempErrors ||
      "password" in tempErrors ||
      "last_name" in tempErrors ||
      "phone_no" in tempErrors ||
      "first_name" in tempErrors ||
      "agreed" in tempErrors
    ) {
      return;
    } else {
      setIsLoading(true);
      instance
        .post("/doctor/register", values)
        .then((response) => {
          setIsLoading(false);
          setSignInUserData(response.data.data);
          navigate("/doctor-profile");
        })
        .catch((error) => {
          setIsLoading(false);
          if (error?.response?.status === 403) {
            // Change 'statusCode' to 'status'
            const responseData = error?.response?.data?.message;
            const errorMessagesContainer =
              document.getElementById("error-messages");
            errorMessagesContainer.innerHTML = "";
            Object.keys(responseData).forEach((key) => {
              const errorMessage = responseData[key][0];
              switch (key) {
                case "email":
                  if (errorMessage === "The email has already been taken.") {
                    displayErrorMessage(
                      "The email is already taken. Please use a different email."
                    );
                  }
                  break;
                case "phone_no":
                  if (errorMessage === "The phone no has already been taken.") {
                    displayErrorMessage(
                      "The phone number is already exist. Please use a different phone number."
                    );
                  }
                  break;
                default:
                  break;
              }
            });

            function displayErrorMessage(message) {
              const errorMessageElement = document.createElement("div");
              errorMessageElement.className = "error-message";
              errorMessageElement.textContent = message;
              errorMessagesContainer.appendChild(errorMessageElement);
            }
          } else {
            toast.error("Something went wrong. Please try again.");
          }
        });
    }
  }

  return (
    <>
      <div className="form-wrapper">
        <h1>JOIN AS A DOCTOR</h1>
        <form onSubmit={handleValidation}>
          <div>
            <label for="exampleInputEmail1" className="form-label">
              Name
            </label>
            <div className="row">
              <div className="col-6">
                <input
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  className="form-input"
                  aria-describedby="emailHelp"
                  onChange={handleInput}
                />
              </div>
              <div className="col-6">
                <input
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  className="form-input"
                  aria-describedby="emailHelp"
                  onChange={handleInput}
                />
              </div>
            </div>
            {errors.first_name && errors.last_name && (
              <p className="text-danger input-p">
                Both first name and last name are invalid.
              </p>
            )}
            {errors.first_name && !errors.last_name && (
              <p className="text-danger input-p">{errors.first_name}</p>
            )}
            {!errors.first_name && errors.last_name && (
              <p className="text-danger input-p">{errors.last_name}</p>
            )}
          </div>

          <div className="mt-2">
            <label for="exampleInputEmail1" className="form-label">
              Email
            </label>
            <div className="d-flex position-relative">
              <input
                type="email"
                placeholder="Email"
                name="email"
                className="form-input"
                aria-describedby="emailHelp"
                onChange={handleInput}
              />
            </div>
            {errors.email && (
              <p className="text-danger input-p">{errors.email}</p>
            )}
          </div>
          <div className="mt-3">
            <label htmlFor="phoneInput" className="form-label">
              Phone Number
            </label>
            <div id="survey-form">
              <PhoneInput
                country={"de"}
                className="form-input phone-input"
                value={phone}
                onChange={(newPhone) => {
                  setPhone(newPhone); // Update the phone state
                  handleInput({
                    target: { name: "phone_no", value: newPhone },
                  }); // Update the values.phone field
                }}
                inputProps={{
                  style: {
                    height: "35px",
                    width: "99%",
                  },
                }}
              />
            </div>
            {errors.phone_no && (
              <p className="text-danger input-p">{errors.phone_no}</p>
            )}
          </div>

          {/* <div className="mt-3">
                      <label
                        for="exampleInputEmail1"
                        className="form-label fw-bold mTc"
                      >
                        Password
                      </label>
                      <div className="d-flex position-relative">
                        <input
                          type="password"
                          placeholder="Password"
                          name="password"
                          className="form-control register-input"
                          aria-describedby="emailHelp"
                          onChange={handleInput}
                        />
                      </div>
                      <p
                        className="text-danger input-p mt-2"
                        id="error-messages"
                      ></p>
                    </div> */}

          <div className="mt-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <div className="d-flex position-relative">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                id="password"
                className="form-input"
                aria-describedby="emailHelp"
                value={password}
                onChange={(e) => {
                  handleInput(e);
                  setPassword(e.target.value);
                }}
              />
              <span
                className="position-absolute end-0 top-50 translate-middle-y"
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? (
                  <i
                    className="fa fa-eye me-3"
                    style={{ color: "var(--main-button-color)" }}
                  ></i>
                ) : (
                  <i
                    className="fa fa-eye-slash me-3"
                    style={{ color: "var(--main-button-color)" }}
                  ></i>
                )}
              </span>
            </div>

            {errors.password && (
              <p className="text-danger input-p">{errors.password}</p>
            )}
            <p className="text-danger input-p mt-2" id="error-messages"></p>
          </div>

          <div className="form-check mt-3 mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              checked={agreed}
              onChange={(e) => setAgreed(e.target.checked)}
              id="flexCheckDefault"
            />
            <label className="mTc doc-lab" for="flexCheckDefault">
              By selecting I Agree, I acknowledge that I have the right to
              represent the, Lorem ipsum dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod tempor incididunt ut labore et dolore magna
              aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
              laboris nisi ut aliquip ex ea commodo consequat.
            </label>
            {errors.agreed && (
              <p className="text-danger input-p">{errors.agreed}</p>
            )}
          </div>
          <div className="d-grid gap-2 pb-4">
            <button
              disabled={isLoading}
              className="btn-small color-gradient"
              type="submit"
            >
              {isLoading ? (
                <AiOutlineLoading3Quarters className="chat_spinner" />
              ) : (
                "Join As A Doctor"
              )}
            </button>
          </div>
        </form>
        <div className="buttons-container">
          <span>Already a member?</span>
          <Link to="/login">
            <button>Login To Doctor Area</button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default DoctorPortal;
