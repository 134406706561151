import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import Navbar from "../Navbar";
import { useApiContext } from "../../contextapi/contextApi";
import { useNavigate } from "react-router-dom";

import "react-tagsinput/react-tagsinput.css";
import Logout from "./Logout";

const defaultImage = require("../../assets/homepage/profile-img.png");

function DoctorSubscription() {
  const {
    setSignInUserData,
    instance,
    setUserDetail,
    userDetail,
    signInUserData,
  } = useApiContext();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();
  const { baseImageURL } = useApiContext();

  useEffect(() => {
    const doctorDetails = async () => {
      try {
        const response = await instance.get(
          `/user-verification/${signInUserData.user_id}`
        );
        if (response) {
          if (response.data.statusCode == 200) {
            setSignInUserData(response.data.data);
            setUserDetail(response.data.data);
            if (response.data.data.user_type === "Doctor") {
              if (
                response.data.data.is_subscribed == "1" &&
                response.data.data.is_approved == "1"
              ) {
                navigate("/doctor-portal");
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching checkbox options:", error);
      }
    };
    doctorDetails();
  }, []);

  // profile some inputs listing api call //

  const handleLogoutTabClick = () => {
    setShowLogoutModal(true);
  };

  const handleLogoutConfirm = () => {
    localStorage.removeItem("signInUserData");
    localStorage.removeItem("user-profile");
    localStorage.removeItem("answer_ids");
    localStorage.removeItem("agoraToken");
    localStorage.removeItem("checkproduct-detail");
    window.location.href = "/";
  };

  const handleLogoutModalClose = () => {
    setShowLogoutModal(false);
  };

  //react select
  return (
    <>
      <Navbar disp="d-none" />
      <div className="container-fluid">
        <div className="row g-0">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="rounded border text-center p-2 m-3">
              <div>
                {userDetail?.profile_image !== null ? (
                  <img
                    src={`${baseImageURL}${userDetail?.profile_image}`}
                    alt=""
                    className="rounded-circle"
                    width="100"
                    height="100"
                  />
                ) : (
                  <img
                    src={defaultImage}
                    alt=""
                    className="rounded-circle"
                    width="100"
                    height="100"
                  />
                )}
              </div>

              <h6 className="fw-bold pt-3">
                {userDetail && userDetail?.first_name}&nbsp;
                {userDetail && userDetail?.last_name}
              </h6>
            </div>
            <div className="rounded border text-center p-2 m-3 mono2">
              <div
                className="nav dim-content flex-column nav-pills"
                id="v-tabs"
                role="tablist"
                aria-orientation="vertical"
                aria-disabled
              >
                <a
                  className="nav-link mt-2 mb-2"
                  id="tab1-tab"
                  data-bs-toggle="pill"
                  href="#tab1"
                  role="tab"
                  aria-controls="tab1"
                  aria-selected="true"
                >
                  Dashboard
                </a>
                <a
                  className="nav-link  mt-4 mb-4"
                  id="tab2-tab"
                  data-bs-toggle="pill"
                  href="#tab2"
                  role="tab"
                  aria-controls="tab2"
                  aria-selected="false"
                >
                  Appointments
                </a>
                <a
                  className="nav-link mt-2 mb-2"
                  id="tab3-tab"
                  data-bs-toggle="pill"
                  href="#tab3"
                  role="tab"
                  aria-controls="tab3"
                  aria-selected="true"
                >
                  New request
                </a>
                <a
                  className="nav-link  mt-4 mb-4"
                  id="tab6-tab"
                  data-bs-toggle="pill"
                  href="#tab6"
                  role="tab"
                  aria-controls="tab6"
                  aria-selected="false"
                >
                  Avalibility
                </a>
                <a
                  className="nav-link  mt-4 mb-4"
                  id="tab6-tab"
                  data-bs-toggle="pill"
                  href="#tab6"
                  role="tab"
                  aria-controls="tab6"
                  aria-selected="false"
                >
                  Order history
                </a>

                <a
                  className="nav-link  mt-4 mb-4"
                  id="tab6-tab"
                  data-bs-toggle="pill"
                  href="#tab6"
                  role="tab"
                  aria-controls="tab6"
                  aria-selected="false"
                >
                  Messages
                </a>
                <a
                  className="nav-link mt-2 mb-2"
                  id="tab7-tab"
                  data-bs-toggle="pill"
                  href="#tab7"
                  role="tab"
                  aria-controls="tab7"
                  aria-selected="true"
                >
                  Profile Settings
                </a>
                <a
                  className="nav-link  mt-4 mb-5"
                  id="tab8-tab"
                  data-bs-toggle="pill"
                  href="#tab8"
                  role="tab"
                  aria-controls="tab8"
                  aria-selected="false"
                >
                  Log Out
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-1 mono3">
            <div className="rounded border p-1 mt-3">
              <div className="ms-3">
                <h6 className="fw-bold pt-2 mTc main-text">Profile Setup</h6>
                <p className=" w-75 mTc mo9">
                  Your subscription is complete. You will get an E-mail once
                  your subscription is approved
                </p>
              </div>
            </div>

            <div className="p-2">
              <div>
                <>
                  <div className="text-center mt-2">
                    <div>
                      <svg
                        width="300"
                        height="300"
                        viewBox="0 0 300 300"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_1421_1272)">
                          <path
                            d="M150 269C216.274 269 270 215.274 270 149C270 82.7258 216.274 29 150 29C83.7258 29 30 82.7258 30 149C30 215.274 83.7258 269 150 269Z"
                            fill="url(#paint0_linear_1421_1272)"
                          />
                          <g filter="url(#filter0_d_1421_1272)">
                            <rect
                              x="89"
                              y="63.5566"
                              width="124.537"
                              height="155.029"
                              rx="16"
                              fill="white"
                            />
                          </g>
                          <rect
                            x="100"
                            y="83.5566"
                            width="50"
                            height="9.8"
                            rx="4.9"
                            fill="#0E2647"
                          />
                          <rect
                            x="100"
                            y="111.357"
                            width="100"
                            height="9.8"
                            rx="4.9"
                            fill="#D5D5D5"
                          />
                          <rect
                            x="100"
                            y="139.156"
                            width="100"
                            height="9.8"
                            rx="4.9"
                            fill="#D5D5D5"
                          />
                          <rect
                            x="100"
                            y="166.957"
                            width="100"
                            height="9.8"
                            rx="4.9"
                            fill="#D5D5D5"
                          />
                          <rect
                            x="100"
                            y="194.756"
                            width="100"
                            height="9.8"
                            rx="4.9"
                            fill="#D5D5D5"
                          />
                          <g filter="url(#filter1_d_1421_1272)">
                            <path
                              d="M288.15 46.4238H228.069C225.27 46.4238 223 48.8123 223 51.7587V81.4778C223 84.4242 225.27 86.8127 228.069 86.8127H288.15C290.949 86.8127 293.219 84.4242 293.219 81.4778V51.7587C293.219 48.8123 290.949 46.4238 288.15 46.4238Z"
                              fill="white"
                            />
                          </g>
                          <path
                            d="M238 73C241.314 73 244 70.3137 244 67C244 63.6863 241.314 61 238 61C234.686 61 232 63.6863 232 67C232 70.3137 234.686 73 238 73Z"
                            fill="#049CD5"
                          />
                          <rect
                            x="252"
                            y="61"
                            width="32"
                            height="12"
                            rx="6"
                            fill="#D5D5D5"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M185.109 225.563C197.989 225.563 209.959 221.666 219.905 214.989L260.604 253.84L275.364 236.715L236.091 199.225C243.345 189.017 247.609 176.538 247.609 163.063C247.609 128.545 219.627 100.562 185.109 100.562C150.592 100.562 122.609 128.545 122.609 163.063C122.609 197.58 150.592 225.563 185.109 225.563ZM238.418 163.063C238.418 192.286 214.728 215.976 185.505 215.976C156.282 215.976 132.592 192.286 132.592 163.063C132.592 133.839 156.282 110.149 185.505 110.149C214.728 110.149 238.418 133.839 238.418 163.063Z"
                            fill="#049CD5"
                          />
                          <g filter="url(#filter2_b_1421_1272)">
                            <path
                              d="M185.5 217C215.6 217 240 192.823 240 163C240 133.177 215.6 109 185.5 109C155.4 109 131 133.177 131 163C131 192.823 155.4 217 185.5 217Z"
                              fill="white"
                              fill-opacity="0.3"
                            />
                          </g>
                          <path
                            d="M260.602 253.842L275.362 236.717L277.585 238.839C279.742 240.898 281.033 243.774 281.175 246.836C281.317 249.897 280.297 252.892 278.341 255.162C276.384 257.432 273.651 258.791 270.742 258.94C267.833 259.09 264.987 258.017 262.83 255.958L260.607 253.835L260.602 253.842Z"
                            fill="#E1DCEB"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M56.3144 91.1719C56.2956 89.7459 56.1167 88.3076 55.7589 86.8594C54.5042 81.7773 48.9064 78.5146 42.9484 77.7475C36.9927 76.9804 30.7993 78.7261 28.4123 83.1468C27.047 85.6743 26.8587 87.8527 27.4166 89.6893C27.9721 91.5161 29.2903 93.0306 31.0793 94.2108C36.0675 97.4981 44.8034 98.1693 48.7887 96.7949C50.6319 96.1581 52.4327 95.391 54.1864 94.5133C53.1836 100.259 49.4477 105.703 44.4619 110.608C33.6264 121.269 16.8117 129.36 7.65456 132.335C7.16257 132.495 6.88948 133.041 7.04249 133.554C7.19551 134.068 7.71809 134.356 8.21008 134.196C17.5485 131.162 34.6905 122.899 45.7402 112.026C51.4487 106.411 55.5164 100.09 56.2109 93.4437C69.1156 86.2423 79.7134 73.1769 88.774 62.1817C89.1106 61.776 89.0659 61.1589 88.6751 60.8073C88.2844 60.4582 87.6958 60.5024 87.3592 60.9106C78.6681 71.4558 68.5812 84 56.3144 91.1719ZM54.4383 92.2169C54.509 90.6138 54.3582 88.9861 53.951 87.3437C52.8658 82.9452 47.8776 80.3439 42.72 79.6801C39.5585 79.2744 36.3077 79.6088 33.7583 80.7988C32.1552 81.5462 30.8345 82.6305 30.0389 84.1057C28.9937 86.0407 28.7677 87.6954 29.1961 89.0993C29.6245 90.513 30.6887 91.6489 32.0752 92.561C36.6208 95.5582 44.575 96.195 48.2025 94.9435C50.3423 94.2059 52.4186 93.2864 54.4383 92.2169Z"
                            fill="#0E2647"
                          />
                          <circle cx="285" cy="184" r="6" fill="#049CD5" />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M58.3842 225.394C59.455 224.993 60.5818 224.459 61.4507 223.686C62.4827 222.769 62.9024 221.587 63.1843 220.343C63.5461 218.744 63.6907 217.042 64.1295 215.441C64.2919 214.846 64.6046 214.621 64.7387 214.522C65.0778 214.27 65.4206 214.202 65.743 214.228C66.1252 214.257 66.6501 214.409 66.9953 215.083C67.0446 215.18 67.1086 215.327 67.1516 215.528C67.183 215.676 67.2033 216.137 67.2366 216.328C67.3196 216.797 67.3892 217.266 67.4544 217.737C67.6716 219.306 67.7966 220.639 68.4827 222.081C69.4138 224.038 70.3467 225.235 71.612 225.766C72.8354 226.279 74.2981 226.182 76.167 225.78C76.3449 225.735 76.5209 225.696 76.695 225.664C77.519 225.513 78.3067 226.082 78.4686 226.946C78.6304 227.809 78.1067 228.65 77.2901 228.84C77.1197 228.88 76.9516 228.917 76.7855 228.952C74.26 229.61 71.3362 231.958 69.6372 234.014C69.1135 234.648 68.3467 236.421 67.5646 237.551C66.9873 238.386 66.3387 238.935 65.7941 239.13C65.4292 239.261 65.1215 239.24 64.8673 239.174C64.4981 239.079 64.1917 238.868 63.9566 238.533C63.8286 238.35 63.7098 238.104 63.6532 237.791C63.6261 237.64 63.623 237.257 63.6236 237.083C63.4643 236.506 63.2692 235.943 63.127 235.36C62.788 233.971 62.1227 233.092 61.3326 231.93C60.5935 230.843 59.7997 230.159 58.636 229.614C58.4846 229.575 57.263 229.26 56.8317 229.08C56.2015 228.815 55.9012 228.371 55.7923 228.132C55.607 227.727 55.588 227.373 55.6249 227.077C55.6797 226.641 55.8655 226.268 56.1953 225.967C56.3997 225.78 56.7049 225.598 57.1135 225.509C57.4292 225.44 58.2667 225.4 58.3842 225.394ZM65.5504 223.13C65.607 223.263 65.6673 223.396 65.7313 223.531C67.095 226.397 68.6199 227.998 70.4735 228.774L70.5356 228.799C69.2956 229.768 68.1732 230.851 67.3153 231.889C66.9621 232.317 66.4944 233.205 65.9892 234.115C65.5301 232.545 64.7793 231.435 63.8353 230.045C63.1141 228.985 62.3584 228.187 61.4298 227.537C62.1504 227.148 62.8378 226.692 63.4366 226.16C64.4335 225.273 65.0926 224.246 65.5504 223.13Z"
                            fill="#049CD5"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_1421_1272"
                            x="59"
                            y="53.5566"
                            width="184.537"
                            height="215.029"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="20" />
                            <feGaussianBlur stdDeviation="15" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0.570833 0 0 0 0 0.570833 0 0 0 0 0.570833 0 0 0 0.19 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_1421_1272"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_1421_1272"
                              result="shape"
                            />
                          </filter>
                          <filter
                            id="filter1_d_1421_1272"
                            x="216"
                            y="37.4238"
                            width="96.2188"
                            height="66.3887"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dx="6" dy="4" />
                            <feGaussianBlur stdDeviation="6.5" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0.104618 0 0 0 0 0.465612 0 0 0 0 0.545833 0 0 0 0.09 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_1421_1272"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_1421_1272"
                              result="shape"
                            />
                          </filter>
                          <filter
                            id="filter2_b_1421_1272"
                            x="128"
                            y="106"
                            width="115"
                            height="114"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feGaussianBlur
                              in="BackgroundImageFix"
                              stdDeviation="1.5"
                            />
                            <feComposite
                              in2="SourceAlpha"
                              operator="in"
                              result="effect1_backgroundBlur_1421_1272"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_backgroundBlur_1421_1272"
                              result="shape"
                            />
                          </filter>
                          <linearGradient
                            id="paint0_linear_1421_1272"
                            x1="149.075"
                            y1="-10.0749"
                            x2="151.533"
                            y2="411.347"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#049CD5" stop-opacity="0.22" />
                            <stop offset="1" stop-color="#EFEFEF" />
                          </linearGradient>
                          <clipPath id="clip0_1421_1272">
                            <rect width="300" height="300" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div>
                      <h5 className="fw-bold pt-3 mTc main-text">
                        <span>Subscription is in review.</span> <br />
                        <span>
                          You will get an E-mail once your Subscription is
                          approved.
                        </span>
                      </h5>
                      <div class="d-grid gap-2 mt-5 col-6 mx-auto">
                        <button
                          class="btn list-button dashboard-p3 "
                          onClick={handleLogoutTabClick}
                          type="button"
                        >
                          Logout
                        </button>
                      </div>
                      {/* <button
                        className="rounded list-button dashboard-p3  p-1 mb-1 mt-4"
                        
                      >
                        Logout
                      </button> */}
                    </div>
                  </div>
                </>
              </div>
              <Logout
                showModal={showLogoutModal}
                onClose={handleLogoutModalClose}
                onConfirm={handleLogoutConfirm}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoctorSubscription;
