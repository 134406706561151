import Navbar from "./Navbar";

import { IoIosLogOut } from "react-icons/io";
import emailImage from "../assets/register/Verification Email.png";
import "../styles/Subsciption.css";

function Subscriptions() {
 const Logout = () => {
    localStorage.removeItem("signInUserData");
    localStorage.removeItem("user-profile");
    localStorage.removeItem("answer_ids");
    window.location.href = "/";
  };

  return (
    <>
      <Navbar doctor="Home" doctorLink="/" />
      <div className="container-fluid">
        <div className="row g-0">
          <div className="col-lg-9 col-md-9 col-sm-12 mt-4 under_processing">
            <div>
              <img src={emailImage} alt="Profile under_review" />
              <p>
                Your profile is currently undergoing a review process. An
                approval email will be dispatched to you shortly.
              </p>
            </div>
            <button
              onClick={() => Logout()}
              className="btn btn-primary d-inline-block align-items-center gap-2"
            >
              <IoIosLogOut /> Logout
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Subscriptions;
