import React, { useEffect, useState } from "react";
import { IoIosSend } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import Sch from "../../../assets/dashboard/Sch.png";
import Portal9 from "../../../assets/dashboard/clock2.svg";
import defultimag from "../../../assets/homepage/profile-img.png";
import { useApiContext } from "../../../contextapi/contextApi";
import InviteModal from "../../inviteModal";
import { GuestCard } from "../../GuestList";

const formatDate = (dateString) => {
  const inputDate = new Date(dateString); // Replace this with your input date

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayOfWeek = daysOfWeek[inputDate.getDay()];
  const month = months[inputDate.getMonth()];
  const dayOfMonth = inputDate.getDate();

  const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}`;
  return formattedDate;
};

function Scheduled() {
  const { baseImageURL, signInUserData, instance } = useApiContext();
  const [scheduledAppointment, setScheduledAppointment] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 250);
    scheduledApointment();
  }, []);

  // Scheduled appointment list  //
  const scheduledApointment = async () => {
    try {
      const response = await instance.get(
        `/doctor/scheduled-appoinments/${signInUserData.user_id}`
      );
      setScheduledAppointment(response.data.data);
      console.log("data for the appointments ", response.data.data);
      setIsLoading(false);

      // console.log("")

      if (response.data.data.length === 0) {
        setShowNoDataMessage(true);
      }
    } catch (err) {
      setScheduledAppointment(null);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="content-spinner">
          <div className="loader"></div>
        </div>
      ) : (
        <div>
          {showNoDataMessage ? (
            <div className="no-data-message text-center">
              <img src={Sch} alt="" className="img-fluid mt-5" width={500} />
              <h3 className="mt-4">No Scheduled Appointment</h3>
              <p className="mt-4">
                Try checking new request or come back later.
              </p>
            </div>
          ) : (
            <>
              <div>
                <div className="container-fluid">
                  <div className="row">
                    {scheduledAppointment?.map((item) => (
                      <div
                        key={item.id}
                        className="col-lg-6 col-md-6 col-sm-12 mt-2"
                      >
                        <AppointmentCard item={item} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default Scheduled;

export function AppointmentCard({ item }) {
  const {
    baseImageURL,
    instance,
    setAppDetail,
    setApQuestions,
    setSchduleRequestid,
  } = useApiContext();
  const [guestList, setGuestList] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);

  console.log("Refresh variable: ", refresh)

  useEffect(() => {
    async function getGuestList() {
      try {
        const { data } = await instance.get(
          `guest/get-guest-details/${item.id}`
        );
        console.log("Guest List: ", data.data);
        setGuestList(data.data);
      } catch (error) {
        console.log("No guest for this appointment", item.id);
      }
    }

    getGuestList();
  }, [refresh]);

  const getAllAppointmentsDetail = async (id) => {
    try {
      const response = await instance.get(
        `/doctor/get-appoinment-details/${id}`
      );
      setAppDetail(response);
      setSchduleRequestid(id);
      const patientData = response?.data?.data?.questions;
      if (patientData) {
        setApQuestions(patientData);
      }
    } catch (error) {
      console.error("Error fetching checkbox options:", error);
    }
  };

  return (
    <div>
      <div className="border rounded">
        <Link
          className="col-lg-6 col-md-6 col-sm-12 mt-2"
          to="/doctor-portal/appointment-detail"
          onClick={() => getAllAppointmentsDetail(item.id)}
        >
          <div className="card-box1 rounded pt-3 ps-2 pe-2 d-flex justify-content-between align-items-center">
            <p className="text-white dashboard-th1 fw-lighter">
              {formatDate(item?.latest_appoinment?.date)}
            </p>

            <p className="text-white dashboard-th1 fw-lighter">
              <img src={Portal9} className="pe-2 " alt="" />
              {item?.latest_appoinment.start_time}
              <span className="mx-1">-</span>
              {item?.latest_appoinment.end_time}
            </p>
          </div>
          <div className="ps-2 pe-2">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="d-flex">
                  <img
                    className="schdule rounded-circle mt-1"
                    src={
                      `${baseImageURL}${item.patient.user.profile_image}` ||
                      defultimag
                    }
                    alt=""
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                  <p className="ps-2 pt-3 dashboard-p2 dashboard-th">
                    {item?.patient?.user?.first_name}&nbsp;
                    {item?.patient?.user?.last_name}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between pt-1">
              <div>
                <p className="text-muted dashboard-th">Appoinment Reason</p>
              </div>
              <div>
                <p className="dashboard-p2 dashboard-th">
                  {item?.reason?.name}
                </p>
              </div>
            </div>
          </div>
        </Link>
        {guestList && guestList.length ? (
          <div className="border-top bg-white">
            <div className="m-2 mt-4 guest_cards_container">
              <div>
                <p className="text-muted dashboard-th">Guests</p>
              </div>
              <div className="grid_cards_wrapper">
                {guestList.map((guest) => (
                  <GuestCard key={guest.id} guest={guest} />
                ))}
              </div>
            </div>
          </div>
        ) : null}

        <div className="border-top bg-white">
          <div className="d-grid gap-2 m-2 mt-4">
            <button
              className="pt-1 pb-1 card-button dashboard-p3 rounded"
              type="button"
              onClick={() => navigate("/doctor-portal/appointment-detail")}
            >
              <i className="fa fa-calendar me-2"></i>
              Reschedule
            </button>
            <>
              <button
                className="rounded modal_toggler_button p-1"
                type="button"
                onClick={() => setShowModal(true)}
              >
                Invite user <IoIosSend />
              </button>
              <InviteModal
                appointmentId={item.id}
                showModal={showModal}
                setShowModal={setShowModal}
                setRefresh={setRefresh}
              />
            </>
          </div>
        </div>
      </div>
    </div>
  );
}
