import { utcToZonedTime } from "date-fns-tz";
// Functiont to parse specialization of doctor in proper formating

export const parseSpecialization = (inputArray) => {
  if (inputArray) {
    const specilizationArray = JSON.parse(inputArray);

    if (typeof specilizationArray === "string") {
      const parsedArray = specilizationArray.split(",");
      return parsedArray;
    }

    if (Array.isArray(specilizationArray) && specilizationArray.length === 0) {
      return [specilizationArray];
    }

    const parsedArray = specilizationArray.map((item) => {
      // Remove double quotes from the beginning and end of each string
      return item.replace(/^"|"$/g, "");
    });

    return parsedArray;
  } else {
    return [];
  }
};

export const getCurrentDateInGermany = () => {
  const date = new Date();
  const berlinTime = utcToZonedTime(date, "Europe/Berlin");

  return berlinTime;
};

export function timeAgo(timestamp) {
  const currentDate = new Date();
  const inputDate = new Date(timestamp);

  const timeDifference = currentDate - inputDate;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years > 0) {
    return `${years} ${years === 1 ? "year" : "years"} ago`;
  } else if (months > 0) {
    return `${months} ${months === 1 ? "month" : "months"} ago`;
  } else if (days > 0) {
    return `${days} ${days === 1 ? "day" : "days"} ago`;
  } else if (hours > 0) {
    return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
  } else if (minutes > 0) {
    return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
  } else {
    return `${seconds} ${seconds === 1 ? "second" : "seconds"} ago`;
  }
}

export const validateFormData = (formData) => {
  const errors = {};

  // Regex expression for validating email
  // const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,6}$/;

  // Regex expression for validating website url
  const website_url =
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;

  // // Validation Email
  // const email = formData.get("email")
  // if (!email) {
  //   errors.email = "Email is required.";
  // } else {
  //   errors.email = "Invalid email address.";
  // }

  // Validating First Name
  if (!formData.get("first_name")) {
    errors.first_name = "First name is required.";
  }

  // Validating Last Name
  if (!formData.get("last_name")) {
    errors.last_name = "Last name is reqrired.";
  }

  // Validating about us
  if (!formData.get("about_us")) {
    errors.about_us = "About is required.";
  }

  // Specialization validation
  if (!formData.get("specialization")) {
    errors.tags = "Specialization is required.";
  }

  // Primary address validation
  if (!formData.get("address1")) {
    errors.address1 = "Primary address is required";
  }

  // Association is required
  if (!formData.get("association")) {
    errors.association = "Association name is reqruied.";
  }

  // zip code valiation
  if (!formData.get("zipcode")) {
    errors.zipcode = "Zipcode is reqruied.";
  }

  // City valiation
  if (!formData.get("city")) {
    errors.city = "City is reqruied.";
  }

  // State valiation
  if (!formData.get("state")) {
    errors.state = "State is reqruied.";
  }

  // Diseases validation
  if (!formData.get("diseases")) {
    errors.dieasesIds = "Disease feild is required.";
  }

  // Clinic name is required
  if (!formData.get("clinic_name")) {
    errors.clinicName = "Clinic name is required.";
  }

  // Member ship number VALIDATION
  if (!formData.get("membership_num")) {
    errors.membership_num = "Membership no is required.";
  }

  // Website url validation
  const website = formData.get("website");
  if (website === null || website === undefined || website === "") {
    // errors.website = "Website is required.";
  } else if (!website_url.test(formData.get("website"))) {
    errors.website = "Invalid website url.";
  }

  const isValid = Object.keys(errors).length === 0;

  return { isValid, errors };
};

export function checkImageUrl(url, callback) {
  const img = new Image();
  img.onload = function () {
    callback(true);
  };
  img.onerror = function () {
    callback(false);
  };
  img.src = url;
}

export function formatMonthAndDay(date, type) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  switch (type) {
    case "mon":
      return months[date.getMonth()];
    case "day":
      return days[date.getDay()];
      defult: return date;
  }
}
