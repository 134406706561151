import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useApiContext } from "../contextapi/contextApi";

export default function PrivateRoute({ children, type }) {
  const [redirectPath, setRedirectPath] = useState("/login");
  const [loading, setLoading] = useState(true);
  const { signInUserData, setIsVerified } = useApiContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (signInUserData) {
      if (signInUserData?.user_type === "Patient") {
        if (
          signInUserData?.is_email_verified === 0 ||
          !signInUserData?.is_email_verified
        ) {
          setIsVerified(false);
          setLoading(false);
          navigate("/patient-portal");
        } else {
          if (signInUserData?.user_type === type) {
            setLoading(false);
            setRedirectPath(null);
          } else {
            navigate("/");
          }
        }
      } else if (signInUserData?.user_type === "Doctor") {
        if (signInUserData?.is_approved === 0) {
          setLoading(false);
          setIsVerified(false);
        } else {
          if (signInUserData?.user_type === type) {
            setLoading(false);
            setRedirectPath(null);
          } else {
            navigate("/");
          }
        }
      }
    } else {
      navigate(redirectPath);
    }
  }, [signInUserData]);

  return loading ? <>Loading</> : <>{children}</>;
}
