// #0185cf

const reasons = [
  {
    heading: "Consistent Care Always Here:",
    description:
      "Your appointments are with the same dedicated professional, ensuring a seamless and personalized experience.",
  },
  {
    heading: "Cutting-Edge Dental Expertise:",
    description:
      "Advanced training in comprehensive treatments for restorative and cosmetic dentistry needs.",
  },
  {
    heading: "Your Time Matters:",
    description:
      "Punctuality without sacrificing the personalized attention you deserve.",
  },
  {
    heading: "Technology-Driven Convenience:",
    description: "Experience the ease of AI-powered dentist selection.",
  },
];

export default function AboutChooseus() {
  return (
    <section className="about_choose_us">
      <div className="about_inner">
        <div className="about_choose_us_header">
          <span>Why Choose Us</span>
          <h1>Consistent Care, <br /> Cutting-Edge Expertise, Punctuality</h1>
        </div>
        <div className="about_choose_us_reasons">
          {reasons.map(({ heading, description }) => (
            <div className="about_choose_us_item">
              <h3>{heading}</h3>
              <p>{description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
