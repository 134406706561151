import { createContext, useContext, useState } from "react";

const AppointAnswerIdContext = createContext(null);

export function AppointAnswerIdContextProvider({ children }) {
  const [answerIds, setAnswerIds] = useState([]);
  const [inputTypeAnswer, setInputTypeAnswer] = useState(null);

  return (
    <AppointAnswerIdContext.Provider
      value={{ answerIds, setAnswerIds, inputTypeAnswer, setInputTypeAnswer }}
    >
      {children}
    </AppointAnswerIdContext.Provider>
  );
}

export const useAppointmentAsnwerIdContext = () => {
  return useContext(AppointAnswerIdContext);
};