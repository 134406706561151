import { useEffect } from "react";
import Navbar from "../../components/HomeNavbar";
import NewSigninForm from "./NewSigninForm";
import "./NewSigninStyles.css";

import ClientSlider from "../../components/ClientSection";
import Footer from "../../components/HomepageFooter";
import Icons from "../../components/HomepageIcons";
import MoreButtons from "../../components/HomepageMoreButtons";

function DoctorSignUp() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="signin-header">
        <Navbar />
        <div className="form-bg">
          <div className="overlay">
            <div className="form-wrapper-outer">
            <NewSigninForm />

            </div>
          </div>
        </div>
      </div>
      {/* <div className="signin-about">
        <div className="container">
          <h1>EVEN MORE SUCCESS FOR YOUR PRACTICE</h1>
          <h2>FOR ALL DENTAL SERVICE PROVIDER</h2>
          <p>
            With our software for patients and appointment management, you can
            manage your medical practice more efficiently, relieve your practice
            team and attract new patients.
          </p>
          <div className="buttons-container">
            <span>Already a member?</span>
            <button className="btn-small color-gradient">
              Login To Doctor Area
            </button>
          </div>
        </div>
      </div>
      <div className="signin-icons">
        <div className="about-container">
          <div className="about-container-inner">
            <div className="item-container">
              <div className="logo">
                <img src={icon5} alt="" />
              </div>
              <div className="text">
                <h1>+24</h1>
                <h2>Awards</h2>
              </div>
            </div>
            <div className="item-container">
              <div className="logo">
                <img src={icon6} alt="" />
              </div>
              <div className="text">
                <h1>+120</h1>
                <h2>Years of Experience</h2>
              </div>
            </div>
            <div className="item-container">
              <div className="logo">
                <img src={icon7} alt="" />
              </div>
              <div className="text">
                <h1>+72</h1>
                <h2>Partners</h2>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Icons />
      <ClientSlider />
      <MoreButtons />
      <Footer />
    </>
  );
}
export default DoctorSignUp;
