import React, { useState,useEffect } from "react"
import { Link, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Pat1 from "../../assets/dashboard/Doctor7.svg";
import classNames from "classnames";

function PatientRoutes() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 250); 
  }, []);
  let location = useLocation();
  let activeLink = location.pathname;

  return (
    <>
     {isLoading ? (
      <div className="content-spinner">
        <div className="loader"></div>
      </div>
    ) : (
      <div className="container-fluid">
        <div  className="row g-0">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="rounded border p-2 m-3 pb-5 mb-5">
              <div className="text-center">
                <img src={Pat1} className="doctor10-img" alt="doctor-img" />
              </div>

              <h4 className="fw-bold pt-3 text-center dashboard-p3 patient-p patient-p-color">
                Courtney Wilson
              </h4>

              <p className="text-center text-muted patient-p dashboard-p3">
                Patient ID: PT0012
              </p>

              <p className="text-center text-muted patient-p dashboard-p3">
                Pappelallee 18, Geisa, 36416
              </p>
              <hr />

              <p className="fw-bold patient-p1  dashboard-p3">
                Upcoming Appointments
              </p>

              <div className="card shadow-sm p-2">
                <div className="d-flex justify-content-between p-2">
                  <p className=" patient-p patient-p-color fw-bold  dashboard-p3">
                    Online Consultation
                  </p>

                  <a href="#" className=" patient-p text-danger  dashboard-p3">
                    Reschedule
                  </a>
                </div>

                <div className="ps-2 pe-2 pb-2">
                  <p className="patient-p text-muted  dashboard-p3">
                    Monday 12 July, 2023
                  </p>

                  <p className="patient-p text-muted  dashboard-p3">
                    9:45 pm - 10:00 pm
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-12">
            <div>
              <div className="container-fluid g-0">
                <div className="bg-white me-3 pe-2">
                  <ul className="nav-tabs">
                    {/* Apply the 'nav-tabs' class */}
                    <li className="mt-1">
                      <Link
                        to="/patient-detail"
                        className="text-decoration-none"
                      >
                        <a
                          className={classNames("nav-style", {
                            active: activeLink === "/patient-detail",
                          })}
                        >
                          Basic Information
                        </a>
                      </Link>
                    </li>
                    <li className="mt-1">
                      <Link
                        to="/patient-detail/history"
                        className="text-decoration-none"
                      >
                        <a
                          className={classNames("nav-style", {
                            active: activeLink === "/patient-detail/history",
                          })}
                        >
                          Consult History
                        </a>
                      </Link>
                    </li>
                    <li className="mt-1">
                      <Link
                        to="/patient-detail/records"
                        className="text-decoration-none"
                      >
                        <a
                          className={classNames("nav-style", {
                            active: activeLink === "/patient-detail/records",
                          })}
                        >
                          Medical Records
                        </a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
            <Outlet />
          </div>
        </div>
        
      </div>
    )}
    </>
  );
}

export default PatientRoutes;
