import React, { useState } from "react";
import Doc1 from "../../assets/homepage/Arrow5.svg";
import Portal16 from "../../assets/dashboard/dashboard-patient8.svg";
import Portal17 from "../../assets/dashboard/dashboard-patient7.svg";
import { addDays, subDays, format } from "date-fns";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getCurrentDateInGermany } from "../../Utils/Utils";

const SubDashSecond = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/patient-portal/question-01");
  };

  const [selectedDate, setSelectedDate] = useState(getCurrentDateInGermany());

  const handleNextClick = () => {
    setSelectedDate(addDays(selectedDate, 7));
  };

  const handlePreviousClick = () => {
    setSelectedDate(subDays(selectedDate, 7));
  };

  const getWeekDates = (start) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      dates.push(addDays(start, i));
    }
    return dates;
  };

  const weekDates = getWeekDates(selectedDate);
  return (
    <>
      <div>
        <div>
          <section className="ms-2">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="d-flex mt-3 mb-3">
                    <Link to="/patient-portal/my-doctors">
                      <div>
                        <img
                          src={Doc1}
                          alt="arrow-img"
                          width={30}
                          className="main-img"
                        />
                      </div>
                    </Link>
                    <div className="ps-2 pt-1">
                      <h6 className="main-text mTc fw-bold">
                        Schedule Appointment
                      </h6>
                    </div>
                  </div>

                  <div className="d-flex mb-3">
                    <div className="mt-2">
                      <h5 className="fw-bold mTc">Select Available Slots</h5>
                      <p className="mt-lg-3 main-text">
                        Choose Date
                        <input
                          type="date"
                          id="birthday"
                          name="birthday"
                          className="main-input55 rounded ms-3 border border-muted"
                          placeholder="Select your birthday"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container doctor-appointment mt-2 ps-5 pe-5 rounded pt-1 mb-5">
              <div className="mb-2 mt-2">
                <div className="row align-items-center ps-lg-4">
                  <div className="col-lg-1 col-md-1 col-sm-12">
                    <button className="bg-white border border-white p-1">
                      <img src={Portal16} alt="patient-img" />
                    </button>
                  </div>
                  <div className="col-lg-10 col-md-10 col-sm-12">
                    <div className="date-list">
                      {weekDates.map((date, index) => (
                        <div key={index} className="date-item rounded p-2">
                          <div className="date">
                            {format(date, "dd")} <br />
                            {format(date, "EE")}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-lg-1 col-md-1 col-sm-12">
                    <button
                      onClick={handleNextClick}
                      className="bg-white border border-white p-1"
                    >
                      <img src={Portal17} alt="patient-img" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mt-5 mb-3">
                <h6 className="mTc fw-bold">Morning Slots</h6>

                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>
              </div>

              <div className="row mt-3 mb-3">
                <h6 className="mTc fw-bold mt-5">Afternoon Slots</h6>
                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>
              </div>

              <div className="row mt-3 mb-3">
                <h6 className="mTc fw-bold mt-5">Evening Slots</h6>
                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 mt-3">
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button class="rounded border p-2" type="button">
                      09:00 am
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="d-flex justify-content-center mt-5 pt-5">
                    <div class="d-grid gap-2 col-6 mt-2 mb-5">
                      <button
                        class="fw-bold rounded patient-button2 main-text1 p-2"
                        type="button"
                        onClick={handleClick}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default SubDashSecond;
