import React from "react";
import ClientSection from "../components/ClientSection";
import HomepageAbout from "../components/HomepageAbout";
import HomepageApp from "../components/HomepageApp";
import HomepageFooter from "../components/HomepageFooter";
import HomepageForDoctors from "../components/HomepageForDoctors";
import HomepageGallery from "../components/HomepageGallery";
import HomepageHeader from "../components/HomepageHeader";
import HomepageHowTo from "../components/HomepageHowTo";
import HomepageIcons from "../components/HomepageIcons";
import HomepageMoreButtons from "../components/HomepageMoreButtons";

function HomePage() {
  return (
    <>
      {/* <MainNavbar /> */}
      <HomepageHeader />
      {/* <MainSection /> */}
      <HomepageAbout />
      {/* <HelpSection /> */}
      <HomepageGallery />
      {/* <Appointment /> */}
      <HomepageHowTo />

      {/* <Banner /> */}
      <HomepageForDoctors />
      <HomepageIcons />
      <HomepageApp />

      <ClientSection />
      {/* <ContactSection /> */}
      <HomepageMoreButtons />
      {/* <Footer /> */}
      <HomepageFooter />
    </>
  );
}

export default HomePage;
