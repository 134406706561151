import React from "react";
import "../styles/ImprintStyles.css";
import Contact from "../assets/homepage/hosp.jpg";
import Contact2 from "../assets/homepage/image 21.png";
import { useApiContext } from "../contextapi/contextApi";
import NewNavbar from "../components/HomeNavbar";
import { useEffect } from "react";
import ContactUsForm from "./ContactUsForm";
import phone from "../assets/contact-phone.png";
import email from "../assets/contact-email.png";

import ClientSlider from "../components/ClientSection";
import MoreButtons from "../components/HomepageMoreButtons";
import Footer from "../components/HomepageFooter";
import Icons from "../components/HomepageIcons";

function DataPrivacy() {
  const { signInUserData, userDetail } = useApiContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NewNavbar />

      <div className="Imprint-text-container">
        <span>Data Privacy</span>
      </div>
      <Footer />
    </>
  );
}

export default DataPrivacy;
