import { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";

import { useApiContext } from "../../../contextapi/contextApi";
import { useToggleChatContext } from "./AgoraContext";

export default function ChatHeader({ isLoading, peerId, showClose = true }) {
  const { showChat, setShowChat } = useToggleChatContext();
  const [userData, setUserData] = useState(null);
  const { instance, baseImageURL } = useApiContext();
  useEffect(() => {
    if (peerId) getUserById();
  }, [isLoading, peerId]);

  const getUserById = async () => {
    try {
      const respone = await instance.get(`/user-verification/${peerId}`);

      const data = respone?.data?.data;

      setUserData(data);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  return (
    <>
      {!userData ? (
        <AiOutlineLoading3Quarters className="chat_spinner" />
      ) : (
        <>
          <div className="chat_header_image">
            {userData?.profile_image ? (
              <img
                src={`${baseImageURL}${userData?.profile_image}`}
                alt={userData?.first_name || "profile image"}
              />
            ) : (
              <FaUserCircle />
            )}
          </div>
          <h3>{`${userData?.first_name} ${userData?.last_name}`}</h3>
        </>
      )}
      {showClose ? (
        <button
          className="close_chat_button"
          onClick={() => setShowChat((prev) => !prev)}
        >
          Close
        </button>
      ) : null}
    </>
  );
}
