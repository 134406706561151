import { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoCheckbox } from "react-icons/io5";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { TiArrowRight } from "react-icons/ti";
import { toast } from "react-toastify";
import { useApiContext } from "../../contextapi/contextApi";

import { useNavigate } from "react-router-dom";
import {
  AppointAnswerIdContextProvider,
  useAppointmentAsnwerIdContext,
} from "./ApppointmentContext";
import "./style.css";

// modify this enums afterward
const questionareEnums = {
  REASON: "REASON",
  QUESTIONS: "QUESTION",
  END: "END",
};

const noSymptomsReason = [
  "check up",
  "correction of malocclusion",
  "cosmetic correction",
  "tooth broken off",
  "other pains/complaints/questions",
];

// RETURN DIAGNOSE ACCORING TO SYMPTOM
function mapDiagnose(diagnose, appointmentQuestionsArray) {
  switch (diagnose) {
    case "Tooth broken off":
      return "Broken Tooth";
    case "Correction of Malocclusion":
      return "Malocclusion";
    case "Cosmetic correction":
      if (appointmentQuestionsArray?.length === 2) {
        return "Veneers, Aligners";
      }
      return appointmentQuestionsArray.some(
        (questionAnswer) => questionAnswer.answer === "Veneers"
      )
        ? "Veneers"
        : "Aligners";
    case "Other pains/complaints/questions":
      return "Check Up";
    default:
      return "Check Up";
  }
}

function createAnswerArray(questionList, answerIds, inputAnswer) {
  let answerArray = [];

  questionList.map((question) => {
    question.answers.forEach((answer) => {
      if (answerIds.includes(answer.id)) {
        answerArray.push({
          id: answer.id,
          question: question.question_answer,
          answer: answer.question_answer,
          questionImg: question.question_image,
          answerImg: answer.question_image,
        });
      }
      answer?.sub_questions?.forEach((subQuestion) => {
        subQuestion.answers?.forEach((subans) => {
          if (answerIds.includes(subans.id)) {
            answerArray.push({
              id: subans.id,
              question: subQuestion.question_answer,
              answer: subans.question_answer,
              questionImg: subQuestion.question_image,
              answerImg: subans.question_image,
            });
          }
        });
      });
    });
  });

  if (inputAnswer) {
    answerArray.push({
      id: inputAnswer.id,
      question: "Explain you problem.",
      answer: inputAnswer.answer,
    });
  }

  return answerArray;
}

export default function AppointmentQuestions() {
  const {
    instance,
    setDoctorsList,
    signInUserData,
    setAppointmentQuestionsArray,
    setAppointQuestionsInput,
    appointmentQuestionsArray,
    setGptQuestions,
    gptQuestions,
    appointmentType,
    setAppointReason,
    setreasonName,
  } = useApiContext();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true); // loading state when reasons are fetched
  const [isQuestionsLoading, setIsQuestionsLoading] = useState(false); // Loading state when questions are laoding
  const [isPosting, setIsPosting] = useState(false); // Loading state for posting data to backend.

  const [reasonList, setReasonList] = useState([]);
  const [reasonId, setReasonId] = useState(null);
  const [questionsList, setQuestionsList] = useState([]); // This array will store question that are coming from db based on reason selected
  const [allAnswered, setAllAnswered] = useState(false); // Wethere all of the questions are answered or not
  const [answerIds, setAnswerIds] = useState([]);
  const [inputTypeAnswer, setInputTypeAnswer] = useState(null);
  const [askGptQuestion, setAskGptQuestion] = useState(true);

  const [questionareState, setquestionareState] = useState(
    questionareEnums.REASON
  );

  useEffect(() => {
    setAppointmentQuestionsArray(null);
    setGptQuestions(null);
  }, []);

  function handleNextClick() {
    // Logic to transition to the next state
    switch (questionareState) {
      case questionareEnums.REASON:
        if (reasonId) {
          setquestionareState(questionareEnums.QUESTIONS);
        } else {
          toast.error("Please select a reason before proceeding.");
        }
        break;
      case questionareEnums.QUESTIONS:
        setquestionareState(questionareEnums.END);
        break;
      case questionareEnums.END:
        // Logic when all questions have been answered
        break;
      default:
        break;
    }
  }

  async function getReasonList() {
    try {
      const { data } = await instance.get("/patient/reasons-list");
      setReasonList(data.data);
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong. Could not fetch reasons.");
      setIsLoading(false);
    }
  }

  async function getQuestionByReasonId() {
    try {
      setIsQuestionsLoading(true);
      setAppointReason(reasonId);
      setreasonName(reasonList.filter((reason) => reason.id === reasonId));
      const { data } = await instance.get(
        `/patient/questions-list/${reasonId}`
      );

      const selectedReason = reasonList.filter(({ id }) => id === reasonId);

      if (
        selectedReason.length > 0 &&
        noSymptomsReason.includes(selectedReason[0].name.toLowerCase())
      )
        setAskGptQuestion(false);

      if (data.data.length !== 0) {
        setQuestionsList(data.data);
      } else {
        setAllAnswered(true);
      }

      setIsQuestionsLoading(false);
    } catch (error) {
      toast.error("Something went wrong. Could not fetch reasons.");
      setIsQuestionsLoading(false);
    }
  }

  async function postAnswers() {
    try {
      setAppointQuestionsInput(inputTypeAnswer);
      const questions_array = createAnswerArray(
        questionsList,
        answerIds,
        inputTypeAnswer
      );

      setAppointmentQuestionsArray(questions_array);

      if (!askGptQuestion) {
        const selectedReason = reasonList.filter(({ id }) => id === reasonId);
        const diagnose = mapDiagnose(selectedReason[0].name, questions_array);

        setIsPosting(true);
        const { data } = await instance.post(`/patient/doctors-list`, {
          user_id: signInUserData?.user_id,
          reason_id: reasonId,
          diagnosis: diagnose,
        });

        setDoctorsList(data);
        setGptQuestions({
          conversation: questions_array,
          user_id: signInUserData?.user_id,
          reason_id: reasonId,
          diagnosis: diagnose,
        });
      }

      setTimeout(() => {
        console.log("Gpt Questions: ", gptQuestions);
        if (appointmentType === "inside") {
          !askGptQuestion
            ? navigate("/patient-portal/doctor-appointment")
            : navigate("/patient-portal/gpt-questions");
        } else {
          !askGptQuestion
            ? navigate("/patient-portal/doctor-list")
            : navigate("/patient-portal/gpt-questions");
        }
      }, 3000);
      setIsPosting(false);
    } catch (error) {
      toast.error("Something went wrong while posting questions.");
    }
  }

  useEffect(() => {
    getReasonList();
  }, []);

  useEffect(() => {
    // If questionare state is question
    if (questionareState === questionareEnums.QUESTIONS) {
      getQuestionByReasonId();
    }

    if (questionareState === questionareEnums.END) {
      postAnswers();
    }
  }, [questionareState]);

  useEffect(() => {
    if (allAnswered) {
      handleNextClick();
    }
  }, [allAnswered]);

  return isLoading ? (
    <div className="content-spinner">
      <div className="loader"></div>
    </div>
  ) : (
    <>
      <section className="appointment_questionare">
        <div className="reason_question_wrapper">
          <h2>Perfect Match Health Assessment</h2>
          <p>
            Answer a Few Questions to Discover the Specialist Tailored Just for
            You
          </p>
          {questionareState === questionareEnums.REASON ? (
            <div className="questions_container">
              <h5>Please state the reason of your consultation?</h5>
              <RadioInputReason
                setReasonId={setReasonId}
                reasonId={reasonId}
                data={reasonList}
              />
              <button onClick={handleNextClick}>
                Next <TiArrowRight />{" "}
              </button>
            </div>
          ) : questionareState === questionareEnums.QUESTIONS ? (
            isQuestionsLoading ? (
              <div className="appointment_questions_loader">
                <AiOutlineLoading3Quarters className="chat_spinner" />
              </div>
            ) : (
              <AppointAnswerIdContextProvider>
                <QuestionRenderer
                  questionsList={questionsList}
                  setAllAnswered={setAllAnswered}
                  setInputTypeAnswerObj={setInputTypeAnswer}
                  setAnswerIdsArray={setAnswerIds}
                />
              </AppointAnswerIdContextProvider>
            )
          ) : (
            <div className="appointment_questions_loader">
              <AiOutlineLoading3Quarters className="chat_spinner" />
              <p>Processing....</p>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

function RadioInputReason({ setReasonId, reasonId, data }) {
  return data?.map((reason) => (
    <div
      key={reason.id}
      onClick={() => setReasonId(reason.id)}
      className={`single_reason ${reasonId === reason.id ? "selected" : ""}`}
    >
      {reasonId === reason.id ? (
        <IoCheckbox />
      ) : (
        <MdOutlineCheckBoxOutlineBlank />
      )}
      {reason.name}
    </div>
  ));
}

function QuestionRenderer({
  questionsList,
  setAllAnswered,
  setInputTypeAnswerObj,
  setAnswerIdsArray,
}) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const { answerIds, setAnswerIds, inputTypeAnswer, setInputTypeAnswer } =
    useAppointmentAsnwerIdContext();

  useEffect(() => {
    if (
      currentQuestionIndex > questionsList.length - 1 &&
      questionsList.length !== 0
    ) {
      setAnswerIdsArray(answerIds);
      setInputTypeAnswerObj(inputTypeAnswer);
      setAllAnswered(true);
    }
  }, [currentQuestionIndex]);

  return questionsList[currentQuestionIndex]?.is_multi_selection === 3 ? (
    <TextInput
      question={questionsList[currentQuestionIndex]}
      setCurrentQuestionIndex={setCurrentQuestionIndex}
    />
  ) : (
    <SingleQuestion
      question={questionsList[currentQuestionIndex]}
      setCurrentQuestionIndex={setCurrentQuestionIndex}
      currentQuestionIndex={currentQuestionIndex}
    />
  );
}

function TextInput({ question, setCurrentQuestionIndex }) {
  const { setInputTypeAnswer } = useAppointmentAsnwerIdContext();
  const [textInput, setTextInput] = useState("");

  function handleNext() {
    setInputTypeAnswer({ question_id: question?.id, answer: textInput });
    setTextInput("");
    setCurrentQuestionIndex((prev) => prev + 1);
  }

  return (
    <div className="questions_container">
      <h5>{question?.question_answer}</h5>
      {question?.question_image !== null ? (
        <img src={question?.question_image} alt={question?.question_answer} />
      ) : null}
      <input
        type="text"
        className="input"
        placeholder="Describe your problem.."
        name="reason"
        value={textInput}
        onChange={(e) => setTextInput(e.target.value)}
      />
      <button onClick={() => handleNext()}>
        Next <TiArrowRight />
      </button>
    </div>
  );
}

function SingleQuestion({ question, setCurrentQuestionIndex }) {
  const { baseImageURL } = useApiContext();
  const { setAnswerIds } = useAppointmentAsnwerIdContext();
  const [answerId, setAnswerId] = useState(null);
  const [answerIdArray, setAnswerIdArray] = useState([]);

  const [subQuestionId, setSubQuestionId] = useState(null);
  const [subQuestionIdArray, setSubQuestionIdArray] = useState([]);
  const [subQuestions, setSubQuestions] = useState(null);
  const [subQuestionIndex, setSubQuestionIndex] = useState(0);

  function handleAnswerSelect(answer_id, isMultiSelect) {
    if (isMultiSelect === 0 || isMultiSelect === 2) {
      setAnswerId((prev) => (prev === answer_id ? null : answer_id));
    } else {
      setAnswerIdArray((prev) =>
        prev.includes(answer_id)
          ? prev.filter((answer) => answer !== answer_id)
          : [...prev, answer_id]
      );
    }
  }

  function handleSubAnswerSelect(answer_id, isMultiSelect) {
    if (isMultiSelect === 0 || isMultiSelect === 2) {
      setSubQuestionId((prev) => (prev === answer_id ? null : answer_id));
    } else {
      setSubQuestionIdArray((prev) =>
        prev.includes(answer_id)
          ? prev.filter((answer) => answer !== answer_id)
          : [...prev, answer_id]
      );
    }
  }

  function handleNext(type) {
    // check wether selected asnwer has subquestion or not
    const selectedAnswer = question?.answers.filter((answer) => {
      if (answer?.id === answerId && answer?.sub_questions.length !== 0)
        return answer;
    });

    if (type === "answer") {
      if (
        (answerId || answerIdArray.length !== 0) &&
        selectedAnswer.length !== 0 &&
        !subQuestions
      ) {
        setSubQuestions(selectedAnswer[0]?.sub_questions);
        setAnswerIds((prev) => [
          ...prev,
          ...(answerId ? [answerId] : answerIdArray),
        ]);
      } else if (answerId || answerIdArray.length !== 0) {
        setAnswerIds((prev) => [
          ...prev,
          ...(answerId ? [answerId] : answerIdArray),
        ]);
        setSubQuestionId(null);
        setSubQuestions(null);
        setSubQuestionIdArray([]);
        setSubQuestionIndex(0);
        setAnswerId(null);
        setAnswerIdArray([]);
        setCurrentQuestionIndex((prev) => prev + 1);
      } else {
        toast.error("Please select an answer to proceed further.");
      }
    } else {
      if (
        (subQuestionId || subQuestionIdArray.length !== 0) &&
        subQuestionIndex < subQuestions?.length - 1
      ) {
        setAnswerIds((prev) => [
          ...prev,
          ...(subQuestionId ? [subQuestionId] : answerIdArray),
        ]);
        setSubQuestionIndex((prev) => prev + 1);
        setSubQuestionId(null);
      } else if (subQuestionId || subQuestionIdArray.length !== 0) {
        setAnswerIds((prev) => [
          ...prev,
          ...(subQuestionId ? [subQuestionId] : subQuestionIdArray),
        ]);
        setSubQuestionId(null);
        setSubQuestions(null);
        setSubQuestionIdArray([]);
        setSubQuestionIndex(0);
        setAnswerId(null);
        setAnswerIdArray([]);
        setCurrentQuestionIndex((prev) => prev + 1);
      } else {
        toast.error("Please select an answer to proceed further.");
      }
    }
  }

  return !subQuestions ? (
    <div className="questions_container">
      <h5>{question?.question_answer}</h5>
      {question?.question_image !== null ? (
        <img src={`${baseImageURL}${question?.question_image}`} alt="" />
      ) : null}
      <>
        {question?.answers?.map((answer) => (
          <div
            key={answer?.id}
            onClick={() =>
              handleAnswerSelect(answer?.id, answer?.is_multi_selection)
            }
            className={`single_reason ${
              answerId === answer?.id || answerIdArray.includes(answer?.id)
                ? "selected"
                : ""
            }`}
          >
            {answerId === answer?.id || answerIdArray.includes(answer?.id) ? (
              <IoCheckbox />
            ) : (
              <MdOutlineCheckBoxOutlineBlank />
            )}
            <div>
              {answer?.question_image !== null ? (
                <img src={`${baseImageURL}${answer?.question_image}`} alt="" />
              ) : (
                answer?.question_answer
              )}
            </div>
          </div>
        ))}
      </>
      <button onClick={() => handleNext("answer")}>
        Next <TiArrowRight />{" "}
      </button>
    </div>
  ) : (
    <div className="questions_container">
      <h5>{subQuestions[subQuestionIndex]?.question_answer}</h5>
      {subQuestions[subQuestionIndex]?.question_image !== null ? (
        <img
          src={`${baseImageURL}${subQuestions[subQuestionIndex]?.question_image}`}
          alt=""
        />
      ) : null}
      <>
        {subQuestions[subQuestionIndex]?.answers?.map((answer) => (
          <div
            className={`single_reason ${
              subQuestionId === answer?.id ||
              subQuestionIdArray.includes(answer?.id)
                ? "selected"
                : ""
            }`}
            key={answer?.id}
            onClick={() =>
              handleSubAnswerSelect(answer?.id, answer?.is_multi_selection)
            }
          >
            {subQuestionId === answer?.id ||
            subQuestionIdArray.includes(answer?.id) ? (
              <IoCheckbox />
            ) : (
              <MdOutlineCheckBoxOutlineBlank />
            )}
            <div>
              {answer?.question_image !== null ? (
                <img src={`${baseImageURL}${answer?.question_image}`} alt="" />
              ) : (
                answer?.question_answer
              )}
            </div>
          </div>
        ))}
      </>
      <button onClick={() => handleNext("sub_question")}>
        Next <TiArrowRight />
      </button>
    </div>
  );
}
