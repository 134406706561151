import { useEffect, useState } from "react";
import { BsChatRightTextFill } from "react-icons/bs";
import { toast } from "react-toastify";

import { useApiContext } from "../../contextapi/contextApi";
import {
  useAgoraChatContext,
  useToggleChatContext,
} from "../livemeeting/components/AgoraContext";
import ChatFeed from "./ChatFeed";
import ConversationList from "./ConversationList";

export default function ChatFeedWrapper() {
  const {
    instance,
    signInUserData,
    chatPeerId,
    setChatPeerId,
    setNewMessages,
  } = useApiContext();
  const { conn } = useAgoraChatContext();
  const { showChat, setShowChat } = useToggleChatContext();

  const [messages, setMessages] = useState([]);
  const [conversationList, setConversationList] = useState([]);
  const [token, setToken] = useState("");
  const [connected, setConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Loading State for connection
  const [isConversationLoading, setIsConversationLoading] = useState(false); // Loading state for conversation
  const [isChatLoading, setIsChatLoading] = useState(false); // Loading State for Chat
  const [refreshMessages, setRefreshMessages] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showConversationList, setShowConversationList] = useState(true);
  const [firstTimeLoadingComversation, setFirstTimeLoadingComversation] =
    useState(true); // Not a great solution!

  // CHECK FOR SCREEN SIZE AND SHOWS OR HIDE THE Convserations
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1200);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // INITIALIZING CHAT  AND ADDING EVENT FRO NOTIFICATIONS
  useEffect(() => {
    initialize();

    conn?.addEventHandler("connection&message", {
      onConnected: () => {
        setIsLoading(false);
        setConnected(true);
        toast.done("Successfully Logged in");
      },
      onDisconnected: () => {
        setIsLoading(false);
        setConnected(false);
        toast.done("logged out...");
      },
      onTextMessage: (message) => {
        setIsLoading(false);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            from: message?.from,
            msg: message?.msg,
          },
        ]);
        setRefreshMessages((prev) => !prev);
      },
      onTokenExpired: (params) => {
        toast.info("Token has Ended.");
      },
      onError: (error) => {
        setIsLoading(false);
        toast.error("Oops! Something went wrong!");
      },
      onImageMessage: (message) => {
        setMessages((prevMessages) => setMessages([...prevMessages, message]));
        setRefreshMessages((prev) => !prev);
      },
      onFileMessage: function (message) {
        setMessages((prevMessages) => setMessages([...prevMessages, message]));
        setRefreshMessages((prev) => !prev);
      },
    });
  }, [conn]);

  // Function to intialize whole chat process
  const initialize = async () => {
    try {
      setIsLoading(true);

      const res = await instance.get(
        `agora/generate-chat-user-token?user_id=${signInUserData?.user_id}`
      );
      setToken(res?.data?.data);
      handleLogin(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  //   This function will create user connection to agora
  const handleLogin = (chatToken) => {
    conn
      .open({
        user: signInUserData?.user_id.toString(),
        agoraToken: chatToken.toString(),
      })
      .then(() => {
        setConnected(true);
        setRefreshMessages((prev) => !prev);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(
          "Something went wrong while connecting to chat server. Please try refreshing again."
        );
      });
  };

  // If there is a peer Select and then this
  // effect will fetch the messages of that peer
  useEffect(() => {
    if (connected && chatPeerId) {
      if (!messages) {
        setIsChatLoading(true);
      }
      conn
        .getHistoryMessages({
          targetId: chatPeerId.toString(),
          pageSize: 50,
          cursor: -1,
          chatType: "singleChat",
          searchDirection: "up",
        })
        .then((res) => {
          setMessages(res?.messages?.reverse());
          setIsChatLoading(false);
        })
        .catch((error) => {
          toast.error("Error in fetching previous messages!");
          setIsChatLoading(false);
        });
    }

    if (connected) {
      if (conversationList.length === 0) {
        setIsConversationLoading(true);
      }
      setTimeout(() => {
        conn
          .getConversationlist({ pageNum: 1, pageSize: 20 })
          .then((res) => {
            setConversationList(res?.data?.channel_infos);

            let totalUnreadMsgs = 0;
            res?.data?.channel_infos.forEach((item) => {
              totalUnreadMsgs += item?.unread_num || 0;
            });

            setNewMessages(totalUnreadMsgs);
            setIsConversationLoading(false);
          })
          .catch((error) => {
            toast.error(
              "Oops could not get conversations. Please refersh and try again."
            );
            setIsConversationLoading(false);
          });
      }, 1000);
    }
  }, [connected, refreshMessages, chatPeerId]);

  return isLoading ? (
    <div className="content-spinner">
      <div className="loader"></div>
    </div>
  ) : (
    <>
      <div className="profile-message profile_messages_wrapper">
        <ConversationList
          isSmallScreen={isSmallScreen}
          showConversationList={showConversationList}
          setShowConversationList={setShowConversationList}
          conversationList={conversationList}
          isConversationLoading={isConversationLoading}
        />
        {chatPeerId ? (
          <ChatFeed
            isSmallScreen={isSmallScreen}
            peerId={chatPeerId}
            isChatLoading={isChatLoading}
            messages={messages}
            setRefreshMessages={setRefreshMessages}
            setShowConversationList={setShowConversationList}
          />
        ) : (
          <div className="choose_a_chat">
            <BsChatRightTextFill className="icon" />
            <p>Select conversation to start chat</p>
          </div>
        )}
      </div>
    </>
  );
}
