import { useEffect } from "react";
import Navbar from "../../components/HomeNavbar";
import "./NewPatientSignupStyles.css";
import NewPatientSignupForm from "./NewPatientSignupform";

import { Link } from "react-router-dom";
import ClientSlider from "../../components/ClientSection";
import Footer from "../../components/HomepageFooter";
import Icons from "../../components/HomepageIcons";
import MoreButtons from "../../components/HomepageMoreButtons";

function PatientSignUp() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="patient-signup-header">
        <Navbar />
        <div className="form-bg">
          <div className="overlay">
            <div className="form-wrapper-outer ">
              <NewPatientSignupForm />
            </div>
          </div>
        </div>
      </div>
      <div className="patient-signup-about">
        <div className="container">
          <h1>SEAMLESS DENTAL CARE AWAITS:</h1>
          <h1>JOIN US AS A VALUED PATIENT</h1>
          <h2>
            OPT FOR SUPERIOUR DENTAL CARE THAT PRIORITIZES YOUR WELL-BEING,
            INSURING A HEALTHIER AND HAPPIER YOU.
          </h2>
          <p>
            Choose Better Dental Health: Experience Personalized care that
            prioitizes your well-being and ensures a healthier smile awaits you.
          </p>
          <div className="buttons-container">
            <span>Already a member?</span>
            <Link to="/login">
              <button className="btn-small color-gradient">
                Login To Patient Area
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Icons />
      <ClientSlider />
      <MoreButtons />
      <Footer />
    </>
  );
}
export default PatientSignUp;
