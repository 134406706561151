import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/register/Logo.svg";
import Upload from "../../assets/register/Upload.png";
import RegisterNavbar from "../doctor/RegisterNavbar";

function PatientSecond() {
  return (
    <>
      <RegisterNavbar />
      <div className="container-fluid mt-3">
        <div className="row g-0">
          <div className="col-lg-4 col-md-4 col-sm-12"></div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="text-center">
              <img src={Logo} alt="logo-img" className="register-logo" />

              <h4 className="fw-bold pt-5 mt-2 pb-3 mTc">Profile Picture</h4>
              <div className="border rounded p-3 mt-3 ms-lg-5 me-lg-5">
                <div className="ms-lg-5 me-lg-5">
                  <label for="imageInput">
                    <img
                      src={Upload}
                      alt="upload-img"
                      className="pt-2"
                      style={{ cursor: "pointer" }}
                    />
                    <h5 className="fw-bold pt-4 mt-2 mTc">
                      Upload Profile <br /> Picture
                    </h5>
                  </label>
                  <input
                    type="file"
                    id="imageInput"
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                </div>
              </div>

              <div className="d-grid gap-2 col-10 mt-5 pt-2 mx-auto">
                <button className="register-next rounded p-2" type="button">
                <Link to="/patient-third" className="text-decoration-none text-white"> Continue</Link> 
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12"></div>
        </div>
      </div>
    </>
  );
}

export default PatientSecond;
