import { useState, useEffect } from "react";
import { instance, useApiContext } from "../contextapi/contextApi";

export default function GuestList({ appointmentId, refresh }) {
  const [guestList, setGuestList] = useState([]);

  useEffect(() => {
    async function getGuestList() {
      try {
        const { data } = await instance.get(
          `guest/get-guest-details/${appointmentId}`
        );
        setGuestList(data.data);
      } catch (error) {}
    }

    getGuestList();
  }, [appointmentId, refresh]);

  if (guestList && guestList?.length)
    return (
      <div className="border-top">
        <div className="m-2 mt-4 guest_cards_container">
          <div>
            <p className="font-weight-bold dashboard-th">Guests</p>
          </div>
          <div className="grid_cards_wrapper">
            {guestList.map((guest) => (
              <GuestCard key={guest.id} guest={guest} />
            ))}
          </div>
        </div>
      </div>
    );
  else return null;
}

export const GuestCard = ({ guest }) => {
  const { baseImageURL } = useApiContext();

  if (!guest.invited_by) {
    return (
      <div className="guest_card">
        <span>{guest.name}</span>
      </div>
    );
  }
  return (
    <div className="guest_card">
      <span>{guest.name} invited by </span>{" "}
      {guest?.invited_by?.profile_image ? (
        <img
          className="invited_by_img"
          src={`${baseImageURL}${guest.invited_by.profile_image}`}
          alt={guest.invited_by.first_name}
          title={`${guest.invited_by.first_name} ${guest.invited_by.last_name}`}
        />
      ) : (
        <span className="invited_by_img">{guest.invited_by.first_name[0]}</span>
      )}
    </div>
  );
};
