import { useRef, useState, useEffect } from "react";
import AC from "agora-chat";
import { toast } from "react-toastify";
import { RiSendPlane2Fill, RiAttachment2 } from "react-icons/ri";
import { FaImage } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { HiMenu } from "react-icons/hi";

import ChatHeader from "../livemeeting/components/ChatHeader";
import { useAgoraChatContext } from "../livemeeting/components/AgoraContext";
import { timeAgo } from "../../Utils/Utils";

export default function ChatFeed({
  peerId,
  isChatLoading,
  messages,
  setRefreshMessages,
  isSmallScreen,
  setShowConversationList,
}) {
  const { conn } = useAgoraChatContext();
  const [textMessage, setTextMessage] = useState(""); // State to store text message
  const fileRef = useRef(null); // Ref to store file
  const imageRef = useRef(null); // Ref to store image
  const chatBodyRef = useRef(null); // Ref to store body ref that will be used to scroll to the bottom

  const [textLoading, setTextLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);

  // FUNCTION TO SEND TEXT MESSAGE TO THE PEER
  const handleSendPeerMessage = () => {
    if (textMessage.length > 0) {
      const options = {
        chatType: "singleChat",
        type: "txt",
        to: peerId.toString(),
        msg: textMessage,
      };

      const messageToSend = AC.message.create(options);

      if (conn) {
        setTextLoading(true);
        conn
          .send(messageToSend)
          .then((res) => {
            setTextMessage("");
            setRefreshMessages((prev) => !prev);
            setTextLoading(false);
          })
          .catch((error) => {
            toast.error("Unable to send message");
            console.log("Error: ", error);
            setTextLoading(false);
          });
      } else {
        toast.error("Something went wrong. 209");
      }
    }
  };

  // FUNCTION TO SEND IMAGES
  const sendImages = () => {
    if (
      !(
        imageRef?.current &&
        imageRef?.current?.files &&
        imageRef?.current?.files?.length > 0
      )
    ) {
      toast.warn("No image to send.");
      return;
    }

    const file = AC.utils.getFileUrl(imageRef.current);

    // Reset the input feild
    fileRef.current.value = null;

    if (file) {
      setImageLoading(true);
      var allowType = {
        jpg: true,
        gif: true,
        png: true,
        bmp: true,
      };

      if (file?.filetype?.toLowerCase() in allowType) {
        const options = {
          chatType: "singleChat",
          type: "img",
          to: peerId.toString(),
          file: file,
          ext: {
            file_length: file.data.size,
          },
          onFileUploadError: () => toast.error("Unable to upload file."),

          onFileUploadProgress: (e) => console.log("Porgress", e),

          onFileUploadComplete: () => toast.done("File upload complete."),
        };

        try {
          const messageToSend = AC.message.create(options);
          conn.send(messageToSend).then((res) => {
            setImageLoading(false);
            setTimeout(() => setRefreshMessages((prev) => !prev), 1000);
          });
        } catch (error) {
          setImageLoading(false);
          toast.error("Could not send Image");
        }
      } else {
        setImageLoading(false);
        toast.error("Invalid File type");
      }
    } else {
      toast.error("Please select a file.");
    }
  };

  // FUNCTION TO SEND FILES
  const sendFiles = () => {
    if (
      !(
        fileRef?.current &&
        fileRef?.current?.files &&
        fileRef?.current?.files?.length > 0
      )
    ) {
      toast.warn("No File to send.");
      return;
    }

    // Reset the input feild
    const file = AC.utils.getFileUrl(fileRef.current);

    fileRef.current.value = null;

    if (file) {
      setFileLoading(true);
      const allowTypes = {
        zip: true,
        txt: true,
        doc: true,
        docx: true,
        pdf: true,
      };

      if (file?.filetype?.toLowerCase() in allowTypes) {
        const option = {
          type: "file",
          file: file,
          to: peerId.toString(),
          chatType: "singleChat",
          ext: { file_length: file.data.size },

          onFileUploadError: () => toast.error("On load upload file error."),

          onFileUploadProgress: (e) => console.log("Progress:", e),

          onFileUploadComplete: () => toast.done("File upload complete."),
        };

        const msg = AC.message.create(option);

        conn
          .send(msg)
          .then((res) => {
            setFileLoading(false);
            setTimeout(() => setRefreshMessages((prev) => !prev), 1000);
          })
          .catch((e) => {
            setFileLoading(false);
            toast.error("Error! File's not sent.");
          });
      } else {
        setFileLoading(false);
        toast.error("Invalid Format");
      }
    } else {
      toast.error("Please select a file");
    }
  };

  function handleSubmit(e) {
    e.preventDefault();
    handleSendPeerMessage();
    setRefreshMessages((prev) => !prev);
  }

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Function will scroll to very bottom of the chat body
  // Whenever a new message is recieved....
  const scrollToBottom = () => {
    const container = chatBodyRef.current;

    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  return isChatLoading ? (
    <div className="chat_loader_wrapper chat_feed_loader">
      <AiOutlineLoading3Quarters className="chat_spinner" />
    </div>
  ) : (
    <section className="cf_container show">
      <div className="cf_heading">
        <ChatHeader isLoading={isChatLoading} peerId={peerId} showClose={false} />
        {isSmallScreen ? (
          <button
            className="toggle__conversation"
            onClick={() => setShowConversationList(prev => !prev)}
          >
            <HiMenu />
          </button>
        ) : null}
      </div>
      <div ref={chatBodyRef} className="cf_body">
        {messages?.map((message, index) => (
          <>
            <div
              key={message?.id + index}
              className={`${
                message?.from === peerId.toString() ? "" : "active"
              }`}
            >
              {message?.type === "img" ? (
                <img src={message?.url} alt="image" width={200} height={100} />
              ) : message?.type === "file" ? (
                <a href={message?.url} download>
                  <MdOutlineFileDownload />
                  {message?.filename ? (
                    <>{message?.filename.slice(0, 20)}...</>
                  ) : (
                    "Download File"
                  )}
                </a>
              ) : (
                <p>{message?.msg}</p>
              )}
              <span className="chat_time_stamp">{timeAgo(message?.time)}</span>
            </div>
          </>
        ))}
      </div>
      <div className="cf_input">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={textMessage}
            onChange={(e) => setTextMessage(e.target.value)}
            placeholder="Enter your message"
          />
          <label title="Select only one file to send.">
            {imageLoading ? (
              <AiOutlineLoading3Quarters className="chat_spinner" />
            ) : (
              <FaImage />
            )}
            <input
              disabled={imageLoading}
              type="file"
              ref={imageRef}
              onChange={sendImages}
            />
          </label>
          <label title="Select only one file to send.">
            {fileLoading ? (
              <AiOutlineLoading3Quarters className="chat_spinner" />
            ) : (
              <RiAttachment2 />
            )}
            <input
              disabled={fileLoading}
              type="file"
              ref={fileRef}
              onChange={sendFiles}
            />
          </label>
          <button type="submit" disabled={textLoading}>
            {textLoading ? (
              <AiOutlineLoading3Quarters className="chat_spinner" />
            ) : (
              <RiSendPlane2Fill />
            )}
          </button>
        </form>
      </div>
    </section>
  );
}
