import { useEffect } from "react";

import NewNavbar from "../components/HomeNavbar";
import HomepageFooter from "../components/HomepageFooter";
import AboutPageheader from "../components/AboutPage/AboutPageHeader";
import HomepageIcons from "../components/HomepageIcons";
import AboutPageGallery from "../components/AboutPage/AboutPageGallery";
import HomePageApp from "../components/HomepageApp";
import ClientSection from "../components/ClientSection";
import HomePageMoreButtons from "../components/HomepageMoreButtons";
import { useApiContext } from "../contextapi/contextApi";

import "../styles/about.css";
import AboutChooseus from "../components/AboutPage/AboutChooseus";
import AboutHistory from "../components/AboutPage/AboutHistory";
import AboutCallToActions from "../components/AboutPage/AboutCallToActions";

function About() {
  const { signInUserData, userDetail } = useApiContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NewNavbar />
      <AboutPageheader />
      <HomepageIcons />
      <AboutPageGallery />
      <AboutChooseus />
      <AboutHistory />
      <AboutCallToActions />
      <HomePageApp />
      <ClientSection />
      <HomePageMoreButtons />
      <HomepageFooter />
    </>
  );
}

export default About;
