import { useEffect, useState } from "react";
import { useAgoraChatContext } from "../livemeeting/components/AgoraContext";
import { useApiContext } from "../../contextapi/contextApi";
import { toast } from "react-toastify";
import { timeAgo } from "../../Utils/Utils";
import { FaImages, FaFileAlt, FaUser } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import _ from "lodash";

export default function ConversationList({
  conversationList,
  isConversationLoading,
  isSmallScreen,
  showConversationList,
  setShowConversationList,
}) {
  const { signInUserData, instance, baseImageURL, setChatPeerId } =
    useApiContext();
  const [searchData, setSearchData] = useState([]); // This state will store array of MyDoctors or MyPatient depending upon the user role
  const [queryString, setQueryString] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  // Throttle the search function to avoid frequent calls
  const throttleSearch = _.throttle(() => {
    // Check if the length of the search query is greater than 2 characters
    if (queryString.length > 2) {
      // If the signed-in user is of type "Doctor"
      if (signInUserData.user_type === "Doctor") {
        // Filter the data based on the search query for a doctor's patient
        const results = searchData?.filter(
          (item) =>
            // Check if the patient's first name includes the search query
            item?.patient?.user?.first_name
              .toLowerCase()
              .includes(queryString.toLocaleLowerCase()) ||
            // Check if the patient's last name includes the search query
            item?.patient?.user?.last_name
              .toLowerCase()
              .includes(queryString.toLowerCase()) ||
            // Check if the full name (concatenation of first and last name) includes the search query
            `${item?.patient?.user?.first_name} ${item?.patient?.user?.last_name}`
              .toLowerCase()
              .includes(queryString.toLowerCase())
        );

        console.log("Search Results: ", results);

        // Update the search result with the filtered results
        setSearchResult(results);
      } else {
        // If the signed-in user is not a doctor, filter the data based on the search query for patients
        const results = searchData?.filter(
          (item) =>
            // Check if the patient's first name includes the search query
            item?.user?.first_name
              .toLocaleLowerCase()
              .includes(queryString.toLocaleLowerCase()) ||
            // Check if the patient's last name includes the search query
            item?.user?.last_name
              .toLocaleLowerCase()
              .includes(queryString.toLowerCase()) ||
            // Check if the full name (concatenation of first and last name) includes the search query
            `${item?.user?.first_name} ${item?.user?.last_name}`
              .toLowerCase()
              .includes(queryString.toLocaleLowerCase())
        );

        // Update the search result with the filtered results
        setSearchResult(results);
      }
    }
  }, 300);

  useEffect(() => {
    async function getAllDoctors() {
      const response = await instance.get(
        `/patient/get-doctors-list/${signInUserData.user_id}`
      );
      if (response) {
        setSearchData(response?.data?.data);
      }
    }

    async function getAllPatient() {
      const response = await instance.get(
        `/doctor/patients-list/${signInUserData.user_id}`
      );

      if (response) {
        setSearchData(response?.data?.data);
      }
    }

    if (signInUserData?.user_type === "Doctor") {
      getAllPatient();
    } else {
      getAllDoctors();
    }
  }, []);

  function handleInputChange(event) {
    const query = event.target.value;

    setQueryString(query);

    throttleSearch(query);
  }

  return (
    <div
      className={`conversation_list ${
        isSmallScreen ? "conversation_list_small" : ""
      } ${showConversationList ? "show" : ""}`}
    >
      <div className="search__wrapper">
        <div class="group">
          <svg class="icon" aria-hidden="true" viewBox="0 0 24 24">
            <g>
              <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
            </g>
          </svg>
          <input
            placeholder="Type aleast 3 character to search"
            value={queryString}
            onChange={handleInputChange}
            type="search"
            class="input"
          />
        </div>
        <div className="results">
          {queryString.length > 2 &&
            searchResult?.map((item) =>
              signInUserData?.user_type === "Doctor" ? (
                <div
                  className="single_result"
                  onClick={() => {
                    setChatPeerId(item?.patient?.user_id);
                    setQueryString("");
                    setShowConversationList(false);
                  }}
                >
                  {item?.patient?.user?.profile_image ? (
                    <img
                      src={`${baseImageURL}${item?.patient?.user?.profile_image}`}
                      alt={item?.patient?.user?.first_name}
                    />
                  ) : (
                    <span className="fallback_profile_image">
                      <FaUser />
                    </span>
                  )}
                  <h3>
                    {item?.patient?.user?.first_name}{" "}
                    {item?.patient?.user?.last_name}
                  </h3>
                </div>
              ) : (
                <div
                  className="single_result"
                  onClick={() => {
                    setChatPeerId(item?.user_id);
                    setQueryString("");
                    setShowConversationList(false);
                  }}
                >
                  {item?.user?.profile_image ? (
                    <img
                      src={`${baseImageURL}${item?.user?.profile_image}`}
                      alt={item?.user?.first_name}
                    />
                  ) : (
                    <span className="fallback_profile_image">
                      <FaUser />
                    </span>
                  )}
                  <h3>
                    {item?.user?.first_name} {item?.user?.last_name}
                  </h3>
                </div>
              )
            )}
        </div>
      </div>
      {isConversationLoading ? (
        <div className="coversation_skeletion">
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        /* Removed extra curly braces here */
        conversationList?.map((conversation) => (
          <ConversationBox
            key={conversation.id}
            conversation={conversation}
            isSmallScreen={isSmallScreen}
            setShowConversationList={setShowConversationList}
          />
        ))
      )}
    </div>
  );
}

function ConversationBox({
  conversation,
  isSmallScreen,
  setShowConversationList,
}) {
  const { signInUserData, instance, baseImageURL, chatPeerId, setChatPeerId } =
    useApiContext();
  const [peer, setPeer] = useState(null);
  const [peerId, setPeerId] = useState(null);

  async function getUserVerification(id) {
    try {
      const { data } = await instance.get(`/user-verification/${id}`);
      setPeer(data?.data);
    } catch (error) {
      toast.error("Error is getting conversation.");
    }
  }

  useEffect(() => {
    let peerId;
    if (
      signInUserData?.user_id.toString() === conversation?.lastMessage?.from
    ) {
      peerId = conversation?.lastMessage?.to;
      setPeerId(peerId);
    } else {
      peerId = conversation?.lastMessage?.from;
      setPeerId(peerId);
    }

    getUserVerification(peerId);
  }, [peerId]);

  return (
    <div
      className={`conversation_card ${
        chatPeerId?.toString() === peerId ? "active" : ""
      }`}
      onClick={() => {
        setChatPeerId(peerId);
        if (isSmallScreen) {
          setShowConversationList(false);
        }
      }}
    >
      {peer?.profile_image ? (
        <img
          src={`${baseImageURL}${peer?.profile_image}`}
          alt={`${peer?.first_name} ${peer?.last_name}`}
        />
      ) : (
        <span className="fallback_profile_image">
          <FaUser />
        </span>
      )}
      <section className="conversation_info">
        <div>
          <h4>
            {peer?.first_name} {peer?.last_name}
          </h4>
          <span>{timeAgo(conversation?.lastMessage?.time)}</span>
        </div>
        <div className="conversation_card_stats">
          <p>
            {conversation?.lastMessage?.type === "img" ? (
              <>
                <FaImages /> New Message
              </>
            ) : conversation?.lastMessage?.type === "file" ? (
              <>
                <FaFileAlt /> New Message
              </>
            ) : conversation?.lastMessage?.msg?.length > 20 ? (
              conversation?.lastMessage?.msg?.slice(0, 20) + "..."
            ) : (
              conversation?.lastMessage?.msg
            )}
          </p>
          <span className={conversation?.unread_num ? "new_message" : ""}>
            {conversation?.unread_num === 0 ? null : conversation?.unread_num}
          </span>
        </div>
      </section>
    </div>
  );
}
