import React from "react";
import "../styles/homepage.css";

function ContactSection() {
  return (
    <>
      <section className="contact-main">
        <div className="container">
          <div className="row pt-3 pb-3">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="pt-5 pb-5 pe-5">
                <h2 className="contact-h2">Get In Touch</h2>
                <p className="contact-p">
                  The gradual accumulation of information about atomic and
                  small-scale behaviour during the first quarter of the 20th
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="mt-lg-5 pt-lg-5 mt-md-5 pt-md-5 ms-lg-5 me-lg-5">
                <div className="input-group ps-2 pb-2 pt-2">
                  <input
                    type="search"
                    className="form-control rounded border border-white register-input"
                    placeholder="Your Email"
                    aria-label="Search"
                    aria-describedby="search-addon"
                  />
                  <button type="button" className="btn dashboard-p3 contact-button">
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactSection;
