import React, { useState, useEffect, useRef } from "react";
import "../styles/dashboard.css";
import ReactReadMoreReadLess from "react-read-more-read-less";
import Navbar from "../components/Navbar";
import ContactSection from "./ContactSection";
import Footer from "./Footer";
import Doc3 from "../assets/homepage/arrow2.png";
// import Doc2 from "../assets/homepage/doctor10.png";
import { Link, useNavigate } from "react-router-dom";
import { useApiContext } from "../contextapi/contextApi";
import { parseSpecialization } from "../Utils/Utils";
import ParseSpecializationComponent from "./UtilComponent/ParseSpecializationComponent";

function DoctorDetail() {
  const { apiDoctorData, instance, baseImageURL } =
    useApiContext(useApiContext);
  const [doctorDetail, setDoctorDetail] = useState();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const doctorDetails = async () => {
      try {
        const response = await instance.get(
          `/patient/doctors-details/${apiDoctorData.id}`
        );
        if (response) {
          setDoctorDetail(response.data.data);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching checkbox options:", error);
        setIsLoading(false);
      }
    };

    doctorDetails();
  }, [apiDoctorData.id]);

  return isLoading ? (
    <div className="content-spinner">
      <div className="loader"></div>
    </div>
  ) : (
    <>
      <Navbar
        // aboutLink="/about"
        // contactLink="/contact"
        // about="About"
        // contact="Contact Us"
        // helpLink="/patient-sign-up"
        // help="Login/Signup as a Patient"
        doctorLink="/doctor-sign-up"
        doctor="Join as a Doctor"
      />
      <section className="mt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="d-flex mb-3">
                <button className="ghost_button" onClick={() => navigate(-1)}>
                  <img
                    src={Doc3}
                    alt="arrow-img"
                    className="img-fluid main-img9"
                  />
                </button>
                <div className="ms-lg-4 ps-lg-3 ps-2 mt-1">
                  {/* <h4 className="main-text mTc fw-bold dcotprdetaolhead">Doctor Profile</h4> */}
                  <h4 className="pro1 mTc">Doctor Profile</h4>
                </div>
              </div>
              <div className="d-flex flex-sm-row flex-column align-items-sm-center mt-5 mb-3">
                <div className="doctor_list_image">
                  <img
                    src={`${baseImageURL}${doctorDetail?.user?.profile_image}`}
                    alt="doctor-img"
                    className=""
                  />
                </div>
                <div className="mt-3 ms-3">
                  <h5 className="pro1 mTc">
                    {doctorDetail?.user?.first_name}
                    {doctorDetail?.user?.last_name}
                  </h5>
                  <p className="mt-lg-3  desktop-p24 paradoctordetail">
                    {doctorDetail?.specialist_in?.name}
                  </p>
                  <p className="paradoctordetail desktop-p24"></p>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-2 mb-5">
            <div className="col-lg-5 col-md-5 col-sm-12 mt-2">
              <div className="rounded border ps-2 pe-2 pt-2">
                <p className=" desktop-p25">
                  <svg
                    className="me-1"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9 1.5C7.4087 1.5 5.88258 2.13214 4.75736 3.25736C3.63214 4.38258 3 5.9087 3 7.5C3 9.82354 4.5118 12.0782 6.16946 13.8279C6.98296 14.6866 7.799 15.3896 8.41251 15.8781C8.64084 16.0599 8.84027 16.2114 9 16.3294C9.15973 16.2114 9.35916 16.0599 9.58749 15.8781C10.201 15.3896 11.017 14.6866 11.8305 13.8279C13.4882 12.0782 15 9.82354 15 7.5C15 5.9087 14.3679 4.38258 13.2426 3.25736C12.1174 2.13214 10.5913 1.5 9 1.5ZM9 17.25C8.58397 17.874 8.58379 17.8739 8.58357 17.8738L8.58143 17.8723L8.57643 17.869L8.55924 17.8574C8.54464 17.8474 8.52381 17.8332 8.49717 17.8147C8.4439 17.7778 8.36735 17.7241 8.27084 17.6544C8.07791 17.5151 7.80477 17.3117 7.47812 17.0516C6.826 16.5323 5.95454 15.7821 5.08054 14.8596C3.3632 13.0468 1.5 10.4265 1.5 7.5C1.5 5.51088 2.29018 3.60322 3.6967 2.1967C5.10322 0.790176 7.01088 0 9 0C10.9891 0 12.8968 0.790176 14.3033 2.1967C15.7098 3.60322 16.5 5.51088 16.5 7.5C16.5 10.4265 14.6368 13.0468 12.9195 14.8596C12.0455 15.7821 11.174 16.5323 10.5219 17.0516C10.1952 17.3117 9.92209 17.5151 9.72916 17.6544C9.63265 17.7241 9.5561 17.7778 9.50283 17.8147C9.47619 17.8332 9.45536 17.8474 9.44076 17.8574L9.42357 17.869L9.41857 17.8723L9.41699 17.8734C9.41678 17.8735 9.41603 17.874 9 17.25ZM9 17.25L9.41603 17.874C9.1641 18.042 8.83549 18.0417 8.58357 17.8738L9 17.25Z"
                      fill="#0E2647"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9 6C8.17157 6 7.5 6.67157 7.5 7.5C7.5 8.32843 8.17157 9 9 9C9.82843 9 10.5 8.32843 10.5 7.5C10.5 6.67157 9.82843 6 9 6ZM6 7.5C6 5.84315 7.34315 4.5 9 4.5C10.6569 4.5 12 5.84315 12 7.5C12 9.15685 10.6569 10.5 9 10.5C7.34315 10.5 6 9.15685 6 7.5Z"
                      fill="#0E2647"
                    />
                  </svg>
                  {doctorDetail?.address?.address1}
                </p>
                <p className=" desktop-p25 m-0 pb-2">
                  <svg
                    className="me-1"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_336_21971)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9Z"
                        fill="#0E2647"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.75 9C0.75 8.58579 1.08579 8.25 1.5 8.25H16.5C16.9142 8.25 17.25 8.58579 17.25 9C17.25 9.41421 16.9142 9.75 16.5 9.75H1.5C1.08579 9.75 0.75 9.41421 0.75 9Z"
                        fill="#0E2647"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9 0.75C9.21069 0.75 9.41166 0.83862 9.55376 0.994183C11.5523 3.18218 12.6881 6.02164 12.7498 8.98438C12.7501 8.99479 12.7501 9.00521 12.7498 9.01562C12.6881 11.9784 11.5523 14.8178 9.55376 17.0058C9.41166 17.1614 9.21069 17.25 9 17.25C8.78931 17.25 8.58834 17.1614 8.44624 17.0058C6.44767 14.8178 5.31188 11.9784 5.25016 9.01562C5.24995 9.00521 5.24995 8.99479 5.25016 8.98438C5.31188 6.02164 6.44767 3.18218 8.44624 0.994183C8.58834 0.83862 8.78931 0.75 9 0.75ZM6.75017 9C6.80158 11.3061 7.59469 13.5264 9 15.3369C10.4053 13.5264 11.1984 11.3061 11.2498 9C11.1984 6.69387 10.4053 4.47363 9 2.6631C7.59469 4.47363 6.80158 6.69387 6.75017 9Z"
                        fill="#0E2647"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_336_21971">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {doctorDetail?.website}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 mt-2">
              <div className="rounded border ps-2 pe-2 pt-2">
                <p
                  className="desktop-p25"
                  style={{ color: "var(--main-bg1-color)" }}
                >
                  <svg
                    className="me-3"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="8" height="8" rx="4" fill="#89D1B3" />
                  </svg>
                  Available Today
                </p>
                <p className="desktop-p25 m-0 pb-2">
                  <svg
                    className="me-1"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.0007 0.333984C3.76937 0.333984 0.333984 3.76937 0.333984 8.0007C0.333984 12.232 3.76937 15.6674 8.0007 15.6674C12.232 15.6674 15.6674 12.232 15.6674 8.0007C15.6674 3.76937 12.232 0.333984 8.0007 0.333984ZM8.0007 1.00075C11.8642 1.00075 15.0007 4.13716 15.0007 8.0007C15.0007 11.8642 11.8642 15.0007 8.0007 15.0007C4.13717 15.0007 1.00076 11.8642 1.00076 8.0007C1.00076 4.13716 4.13717 1.00075 8.0007 1.00075Z"
                      fill="black"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.66797 3.33333V8C7.66797 8.184 7.8173 8.33333 8.0013 8.33333H12.668C12.852 8.33333 13.0013 8.184 13.0013 8C13.0013 7.816 12.852 7.66667 12.668 7.66667H8.33464V3.33333C8.33464 3.14933 8.1853 3 8.0013 3C7.8173 3 7.66797 3.14933 7.66797 3.33333Z"
                      fill="black"
                    />
                  </svg>
                  {doctorDetail?.doctor_timing[0]?.opening_time} -{" "}
                  {doctorDetail?.doctor_timing[0]?.closing_time}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 mt-1">
              <Link to="/patient-portal/appointment-questions" className="text-decoration-none">
                <div class="d-grid gap-2 col-12 mx-auto">
                  <button
                    class="fw-bold rounded patient-button2 mt-md-3 m-2 mt-lg-4 p-2"
                    type="button"
                  >
                    Book Appointment
                  </button>
                </div>
              </Link>
            </div>
          </div>
          <div className="row mt-4 mb-5">
            <div className="col-lg-7 col-md-7 col-sm-12">
              <h5 className="fw-bold mTc">About us</h5>
              {/* <ReactReadMoreReadLess
                charLimit={100}
                readMoreText={"Read More"}
                readMoreStyle={{
                  color: "var(--main-button-color)",
                  cursor: "pointer",
                }}
                readLessText={"Read Less"}
                readLessStyle={{
                  color: "var(--main-button-color)",
                  cursor: "pointer",
                }}
              > */}
              {doctorDetail?.about_us}
              {/* </ReactReadMoreReadLess> */}
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12 mt-1"></div>
          </div>
          <h5 className="fw-bold mTc">Specializations</h5>
          <div className="row mt-4 mb-5">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="pt-2 detail-border rounded">
                <div className="d-flex ms-2">
                  <p className="desktop-p25">
                    <ParseSpecializationComponent
                      specialization={doctorDetail?.specialization}
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 mt-1"></div>
          </div>
          <h5 className="fw-bold mTc">Conditions Treated</h5>
          <div className="row mt-4 mb-5">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="pt-2 detail-border rounded">
                <div className="d-flex ms-2 flex-wrap w-100">
                  {doctorDetail?.diseases?.map((item, index) => (
                    <p
                      key={index}
                      className="desktop-p25"
                      style={{ paddingRight: "10px" }}
                    >
                      {item?.disease.name},
                      {index < doctorDetail.diseases.length - 1 && "  "}
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 mt-1"></div>
          </div>
        </div>
      </section>
      <ContactSection />
      <Footer />
    </>
  );
}
export default DoctorDetail;
