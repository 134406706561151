import React, { useState, useEffect } from "react";
import "../../../styles/dashboard.css";
import { Link, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Logo from "../../../assets/homepage/Logo.svg";
import Portal1 from "../../../assets/dashboard/Doctor7.svg";
import classNames from "classnames";

function AppointmentRouting() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 250);
  }, []);

  let location = useLocation();
  let activeLink = location.pathname;

  return (
    <>
      {isLoading ? (
        <div className="content-spinner">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row g-0">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div>
                <div className="container-fluid g-0">
                  <div className="bg-white me-3 pe-2">
                    <ul className="nav-tabs">
                      
                      {/* Apply the 'nav-tabs' class */}
                      <li className="mt-1">
                        <Link
                          to="/doctor-portal/appointment"
                          className="text-decoration-none"
                        >
                          <a
                            className={classNames("nav-style1 fw-bold", {
                              active:
                                activeLink ===
                                "/doctor-portal/appointment",
                            })}
                          >
                            Scheduled
                          </a>
                        </Link>
                      </li>
                      <li className="mt-1">
                        <Link
                          to="/doctor-portal/appointment/all-appointment"
                          className="text-decoration-none"
                        >
                          <a
                            className={classNames("nav-style1 fw-bold", {
                              active:
                                activeLink === "/doctor-portal/appointment/all-appointment",
                            })}
                          >
                            All Appointment
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
              <Outlet />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AppointmentRouting;
