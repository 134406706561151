import g5 from "../../assets/homepage-new/g5.webp";

export default function AboutHistory() {
  return (
    <>
      <div className="homepage-gallery-container about_page_gallery">
        <div className="container">
          <div className="container-wrapper">
            <div className="gallery-text-section">
              <div className="text">
                <span>Our History</span>
                <h1>Committed to Delivering Outstanding Dental Healthcare</h1>
                <p>
                  With over +120 years of combined experience and +72 partners,
                  our commitment to excellence has earned us +24 awards. Our
                  journey is marked by a dedication to seamless smiles, putting
                  you in control of your dental care journey.
                </p>
              </div>
              <div className="image">
                <div className="image-bg"></div>
                <img src={g5} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
