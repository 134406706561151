import React from "react";
import HomeNavbar from "./HomeNavbar";
import "../styles/homepageStyles/HomepageForDoctorsStyles.css";
import g2 from "../assets/homepage-new/g2.webp";
import d1 from "../assets/homepage-new/d1.webp";
import d2 from "../assets/homepage-new/d2.webp";
import d3 from "../assets/homepage-new/d3.webp";
import icon5 from "../assets/homepage-new/icon-5.webp";
import icon6 from "../assets/homepage-new/icon-6.webp";
import icon7 from "../assets/homepage-new/icon-7.webp";
import { Link } from "react-router-dom";
import { useApiContext } from "../contextapi/contextApi";

function HomepageForDoctors() {
  const { signInUserData } = useApiContext();

  return (
    <>
      <div className="homepage-for-doctors-container-outer">
        <div className="homepage-for-doctors-container">
          <div className="text">
            <h1>join our professional network as a doctor</h1>
            <p>
              {" "}
              Join our esteemed community of dental professionals and enhance
              your pratice. Discover unparalleled support and opportunities for
              growth as you become a vital part of our collaborative network.
            </p>
            <Link
              to={`${
                signInUserData && signInUserData?.user_type === "Patient"
                  ? "/doctor-sign-up"
                  : signInUserData && signInUserData?.user_type === "Doctor"
                  ? "/doctor-portal"
                  : "/doctor-sign-up"
              }`}
            >
              <button className="btn-large">
                {`${
                  signInUserData && signInUserData?.user_type === "Patient"
                    ? "Join As A Doctor"
                    : signInUserData && signInUserData?.user_type === "Doctor"
                    ? "Doctor Dashboard"
                    : "Join As A doctor"
                }`}
              </button>
            </Link>
          </div>
          <div className="images">
            <div className="image-container-01">
              <div className="image-wrapper">
                <img src={d3} alt="" />
              </div>
              <div className="image-wrapper">
                <img src={g2} alt="" />
              </div>
            </div>
            <div className="image-container-02">
              <div className="image-wrapper">
                <img src={d1} alt="" />
              </div>
              <div className="image-wrapper">
                <img src={d2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomepageForDoctors;
