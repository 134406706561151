import React from "react";
import HomeNavbar from "./HomeNavbar";
import "../styles/homepageStyles/HomepageIconsStyles.css";
import g2 from "../assets/homepage-new/g2.webp";
import d1 from "../assets/homepage-new/d1.webp";
import d2 from "../assets/homepage-new/d2.webp";
import d3 from "../assets/homepage-new/d3.webp";
import healthcare from '../assets/healtcare.svg'
import globe from '../assets/globe.svg'
import icon5 from "../assets/homepage-new/icon-5.webp";
import icon6 from "../assets/homepage-new/icon-6.webp";
import icon7 from "../assets/homepage-new/icon-7.webp";
import { Link } from "react-router-dom";
import { useApiContext } from "../contextapi/contextApi";
import { GoRocket } from "react-icons/go";

function HomepageIcons() {
  const { signInUserData } = useApiContext();

  return (
    <>
      <div className="homepage-icons-container">
        <div className="about-container">
          <div className="about-container-inner">
            <div className="item-container">
              <div className="logo">
                <img src={icon5} alt="" />
                {/* <LiaStethoscopeSolid /> */}
              </div>
              <div className="text">
                <h1>+25</h1>
                <h2>Years in IT Healthcare</h2>
              </div>
            </div>
            <div className="item-container">
              <div className="logo">
                {/* <img src={icon6} alt="" /> */}
                <GoRocket />
              </div>
              <div className="text">
                <h1>+14</h1>
                <h2>Years in Digitalization </h2>
              </div>
            </div>
            <div className="item-container">
              <div className="logo">
                <img src={globe} alt="" />
                {/* <BsGlobeEuropeAfrica /> */}
              </div>
              <div className="text">
                <h1>+7</h1>
                <h2>Countries</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomepageIcons;
