import React, { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useApiContext } from "../contextapi/contextApi";
import Validation from "../subcomponents/Validation";

function ContactUsForm() {
  const navigate = useNavigate(); // Initialize useNavigate
  const { instance } = useApiContext();

  const [agreed, setAgreed] = useState(false);
  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  function handleInput(event) {
    setErrors({});
    const newObj = { ...values, [event.target.name]: event.target.value };
    setValues(newObj);
  }

  const [values, setValues] = useState({
    first_name: "",
    email: "",
    subject: "",
    message: ""
  });
  const [errors, setErrors] = useState({});
  function handleInput(event) {
    setErrors({});
    const newObj = { ...values, [event.target.name]: event.target.value };
    setValues(newObj);
  }

  function handleValidation(event) {
    event.preventDefault();
    const tempErrors = Validation(values, agreed);

    setErrors(tempErrors);

    if (
      "email" in tempErrors ||
      "first_name" in tempErrors ||
      "subject" in tempErrors
    ) {
      return;
    } else {
      setIsLoading(true);
      instance
        .post("/contactusrequests", values)
        .then((response) => {
          setIsLoading(false);
          toast.success(
            " Your request has been submitted. Thank You for Contacting Us!"
          );
          setValues({
            first_name: "",
            email: "",
            subject: "",
            message: ""
          });
        })
        .catch((error) => {
          toast.error("Something went wrong. Pleas try again!");
          setIsLoading(false);
        });
    }
  }
  return (
    <>
      <div className="form-wrapper">
        <h1>CONTACT US</h1>
        <form onSubmit={handleValidation} className="">
          <div className="mt-2">
            <label for="exampleInputEmail1" className="form-label">
              Name
            </label>
            <div className="d-flex position-relative">
              <input
                type="text"
                placeholder="Name"
                name="first_name"
                className="form-input"
                aria-describedby="emailHelp"
                onChange={handleInput}
                value={values.first_name}
              />
            </div>
            {errors.first_name && (
              <p className="text-danger input-p">{errors.first_name}</p>
            )}
          </div>
          <div className="mt-2">
            <label for="exampleInputEmail1" className="form-label">
              Email
            </label>
            <div className="d-flex position-relative">
              <input
                type="text"
                placeholder="Email"
                name="email"
                className="form-input"
                aria-describedby="emailHelp"
                onChange={handleInput}
                value={values.email}
              />
            </div>
            {errors.email && (
              <p className="text-danger input-p">{errors.email}</p>
            )}
          </div>
          <div className="mt-2">
            <label for="exampleInputEmail1" className="form-label">
              Subject
            </label>
            <div className="d-flex position-relative">
              <input
                type="text"
                placeholder="Subject"
                name="subject"
                className="form-input"
                aria-describedby="emailHelp"
                onChange={handleInput}
                value={values.subject}
              />
            </div>
            {errors.subject && (
              <p className="text-danger input-p">{errors.subject}</p>
            )}
          </div>
          <div className="mt-2">
            <label for="exampleInputEmail1" className="form-label ">
              Message
            </label>
            <div className="d-flex position-relative">
              <textarea
                placeholder="Message"
                name="message"
                className="form-input larger"
                aria-describedby="emailHelp"
                onChange={handleInput}
                value={values.message}
              />
            </div>
          </div>

          <div className=" mt-3 d-grid gap-2 pb-4">
            <button
              style={{ textDecoration: "none" }}
              disabled={isLoading}
              type="submit"
              className="btn-small color-gradient"
            >
              {isLoading ? (
                <AiOutlineLoading3Quarters className="chat_spinner" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
export default ContactUsForm;
