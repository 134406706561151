import classnames from "classnames";
import { addDays, format, subDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCurrentDateInGermany } from "../../Utils/Utils";
import Portal17 from "../../assets/dashboard/dashboard-patient7.svg";
import Portal16 from "../../assets/dashboard/dashboard-patient8.svg";
import DefultImg from "../../assets/homepage/profile-img.png";
import { useApiContext } from "../../contextapi/contextApi";
import InviteModal from "../../components/inviteModal";

import defaultImage from "../../assets/homepage/profile-img.png";
import GuestList from "../GuestList";

const SubDashFirst = () => {
  const { signInUserData, instance, setTokenChannel, baseImageURL } =
    useApiContext();
  const [selectedDate, setSelectedDate] = useState(getCurrentDateInGermany());
  const [appointments, setAppointments] = useState(null);
  const [activeDate, setActiveDate] = useState(getCurrentDateInGermany());
  const [showModal, setShowModal] = useState(false);
  const [dateToday, setDateToday] = useState(getCurrentDateInGermany());
  const [disableScollLeftOnCalendar, setDisableScollLeftOnCalendar] =
    useState(true);
  const [refresh, setRefresh] = useState(false);

  const getWeekDates = (start) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      dates.push(addDays(start, i));
    }
    return dates;
  };

  const weekDates = getWeekDates(selectedDate);

  useEffect(() => {
    const fetchData = () => {
      fetchCheckboxOptions(activeDate);
      // fetchCheckboxOptions
    };

    // Run fetchData initially
    fetchData();

    // Set up an interval to run fetchData every half second
    const intervalId = setInterval(() => {
      setRefresh((prev) => !prev);
      fetchData();
    }, 10000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [activeDate]);

  const handleNextClick = () => {
    setSelectedDate(addDays(selectedDate, 7));
  };

  const handlePreviousClick = () => {
    if (
      !(format(weekDates[0], "yyyy-MM-dd") === format(dateToday, "yyyy-MM-dd"))
    ) {
      setSelectedDate(subDays(selectedDate, 7));
    }
  };

  useEffect(() => {
    if (
      format(weekDates[0], "yyyy-MM-dd") === format(dateToday, "yyyy-MM-dd")
    ) {
      setDisableScollLeftOnCalendar(true);
    } else {
      setDisableScollLeftOnCalendar(false);
    }
  }, [selectedDate]);

  const fetchCheckboxOptions = async (date) => {
    try {
      const formData = new FormData();
      formData.append("user_id", signInUserData?.user_id);
      formData.append("date", formatDate(date));
      const response = await instance.post(
        `/patient/dashboard-upcoming-appoinments`,
        formData
      );

      const sortedAppointments = response.data.data.sort((a, b, index) => {
        const timeA = a.appointment_date_time.start_time;
        const timeB = b.appointment_date_time.start_time;

        // Assuming the times are in the same day
        const date = new Date();
        const [hoursA, minutesA] = timeA.split(":");
        const [hoursB, minutesB] = timeB.split(":");

        date.setHours(
          (parseInt(hoursA, 10) % 12) + (timeA.includes("PM") ? 12 : 0)
        );
        date.setMinutes(parseInt(minutesA, 10));

        const dateA = new Date(date);

        date.setHours(
          (parseInt(hoursB, 10) % 12) + (timeB.includes("PM") ? 12 : 0)
        );
        date.setMinutes(parseInt(minutesB, 10));

        const dateB = new Date(date);

        console.log(`Appointment ${index}: `, {
          timeA: dateA,
          timeB: dateB,
        });

        return dateA - dateB;
      });

      setAppointments(sortedAppointments);
    } catch (error) {
      console.error("Error fetching checkbox options:", error);
    }
  };

  const formatDate = (date) => {
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate.getTime())) {
      const year = parsedDate.getFullYear();
      const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
      const day = String(parsedDate.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    } else {
      // Handle invalid date
      return "";
    }
  };

  // Function to format the selected date to "Month, Year"
  const formatMonthYear = (dateString) => {
    const options = { year: "numeric", month: "long" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <>
      <div>
        <h6 className="ps-3 fw-bold dashboard-h5">Upcoming Appointments</h6>
      </div>
      <div className="rounded border p-3 m-3">
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-between">
            {selectedDate && (
              <p className="fw-bold dashboard-p">
                {formatMonthYear(selectedDate)}
              </p>
            )}
          </div>
          {/* <p className="fw-light dashboard-p">
            <img src={Portal5} alt="" className="pe-1" width={22} />
            Filter
          </p> */}
        </div>
        <div className="mb-2 mt-2">
          <div className="row align-items-center">
            <div
              style={{ opacity: disableScollLeftOnCalendar ? 0.5 : 1 }}
              className="col-lg-1 col-md-2 col-sm-12 transition"
            >
              <button
                onClick={handlePreviousClick}
                className="bg-transparent"
                style={{ border: "1px soild var(--main-bg-color)" }}
              >
                <img src={Portal16} alt="patient-img" />
              </button>
            </div>
            <div className="col-lg-10 col-md-8 col-sm-12">
              <div className="date-list mt-2">
                {weekDates.map((date, index) => (
                  <div
                    key={date}
                    className={classnames(
                      "date-item",
                      "custom-cursor",
                      "rounded",
                      "p-2",
                      {
                        "active-date":
                          activeDate &&
                          format(date, "yyyy-MM-dd") ===
                            format(activeDate, "yyyy-MM-dd"),
                      }
                    )}
                    onClick={() => {
                      fetchCheckboxOptions(date);
                      setActiveDate(date);
                    }}
                  >
                    <div className="date">
                      {format(date, "dd")} <br />
                      {format(date, "EE")}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-1 col-md-2 col-sm-12 mt-1 mb-2 text-lg-end text-md-end">
              <button
                onClick={handleNextClick}
                className="bg-transparent"
                style={{ border: "1px soild var(--main-bg-color)" }}
              >
                <img src={Portal17} alt="patient-img" />
              </button>
            </div>
          </div>
        </div>
        {appointments &&
          appointments.map((item) => (
            <div className="container">
              <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-12">
                  <p className="dashboard-p1 pt-1">
                    {item?.appointment_date_time?.start_time}
                  </p>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <hr className="dash-hr" />
                  <div className="row rounded dashboard-main3">
                    <div className="col-lg-4 d-flex">
                      {item?.doctor?.user?.profile_image ? (
                        <img
                          className="rounded-circle mt-2 mb-2"
                          src={
                            `${baseImageURL}${item?.doctor?.user?.profile_image}` ||
                            DefultImg
                          }
                          alt="doctor-img"
                          style={{ width: "50px", height: "50px" }}
                        />
                      ) : (
                        <img
                          className="rounded-circle mt-2 mb-2"
                          src={`${defaultImage}` || DefultImg}
                          alt="doctor-img"
                          style={{ width: "50px", height: "50px" }}
                        />
                      )}
                      <div className="ms-3 mt-3">
                        <ul className="list-unstyled">
                          <li className="dashboard-p1 fw-bold">
                            {item?.doctor?.user?.first_name}
                            {item?.doctor?.user?.last_name}
                          </li>
                          <li className="dashboard-p1 fw-bold">
                            {item?.appointment_date_time?.start_time} -
                            {item?.appointment_date_time?.end_time}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="accepted_reschedule">
                        {item.status === "Pending" ? (
                          <button
                            className="rounded p-1 ps-4 pe-4 dashboard-button1 btn-Upc upcoming-button"
                            disabled
                          >
                            Pending
                          </button>
                        ) : item.status === "Accepted By User" ? (
                          <>
                            <button
                              className="rounded p-1 ps-4 pe-4 dashboard-button1 btn-Upc upcoming-button"
                              disabled
                            >
                              Upcoming
                            </button>
                            <button
                              disabled
                              title="This button will be enabled 5 minutes before the meeting."
                              className="rounded p-1 ps-4 pe-4 dashboard-button1 btn-Upc join-now-button"
                            >
                              Join Now
                            </button>
                          </>
                        ) : item.status === "On-going" ||
                          item?.status === "Join" ? (
                          <div className="accepted_reschedule">
                            <>
                              <button
                                className="rounded dashboard modal_toggler_button p-1"
                                type="button"
                                onClick={() => setShowModal(true)}
                              >
                                Invite user
                              </button>
                              <InviteModal
                                showModal={showModal}
                                setShowModal={setShowModal}
                                appointmentId={item.id}
                                setRefresh={setRefresh}
                              />
                            </>
                            <Link
                              to={`/live-meeting/${item.channel_name}/${item?.id}`}
                            >
                              <button className="rounded p-1 ps-4 pe-4 dashboard-button1 btn-Upc join-now-button">
                                Join Now
                              </button>
                            </Link>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <GuestList appointmentId={item.id} refresh={refresh} />
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12">
                  <p className="dashboard-p1 pt-4 pt-md-1 pt-lg-1">
                    {item?.appointment_date_time?.end_time}
                  </p>
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className="mt-5">
        <Link to="/patient-portal/my-doctors" className="text-decoration-none">
          <div className="d-grid gap-2 col-12 col-lg-6 col-md-6 mx-auto">
            <button
              className="patient-button2 btn-Upc rounded p-2"
              type="button"
            >
              Schedule Appointment
            </button>
          </div>
        </Link>
      </div>
    </>
  );
};

export default SubDashFirst;
