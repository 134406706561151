import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { checkImageUrl, validateFormData } from "../../Utils/Utils";
import Portal18 from "../../assets/dashboard/Upload.png";
import { useApiContext } from "../../contextapi/contextApi";

const defaultImage = require("../../assets/dashboard/box-frame1.jpg");

const roundedBorder = {
  padding: "5px",
  width: "100px",
};

function Profile1() {
  const navigate = useNavigate();
  // galobal sate //
  const {
    baseImageURL,
    signInUserData,
    instance,
    userDetail,
    setUserDetail,
    setSignInUserData,
  } = useApiContext();
  // ------------ //

  const [first_name, setFirst_name] = useState(signInUserData?.first_name);
  const [last_name, setLast_name] = useState(signInUserData?.last_name);
  const [about_us, setAbout_us] = useState(
    userDetail?.about_us || signInUserData?.about_us || ""
  );
  const [specialist_in, setSpecialist_in] = useState(
    userDetail?.specialist_in.id || signInUserData?.specialist_in.id
  );
  const [specialization, setSpecialization] = useState(
    userDetail?.specialization || signInUserData?.specialization
  );
  const [association, setAssociation] = useState(
    userDetail?.association || signInUserData?.association || ""
  );
  const [membership_num, setMembership_num] = useState(
    userDetail?.membership_num || signInUserData?.membership_num
  );
  const [insurance_preference, setInsurance_preference] = useState(
    userDetail?.insurance_preference || signInUserData?.insurance_preference
  );
  const [address1, setAddress1] = useState(
    userDetail?.address1 || signInUserData?.address1
  );
  const [address2, setAddress2] = useState(
    userDetail?.address2 || signInUserData?.address2
  );
  const [zipcode, setZipcode] = useState(
    userDetail?.zipcode || signInUserData?.zipcode
  );
  const [state, setState] = useState(
    userDetail?.state || signInUserData?.state
  );
  const [city, setCity] = useState(userDetail?.city || signInUserData?.city);
  const [profile_image, setProfileImage] = useState(defaultImage);
  const [website, setWebsite] = useState(
    userDetail?.website || signInUserData?.website || ""
  );
  const [clinicName, setClinicName] = useState(
    userDetail?.clinic_name || signInUserData?.clinic_name || ""
  );
  const [file, setFile] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [dieasesIds, setDieasesIds] = useState([]);

  const parseSpecialization = (inputArray) => {
    const specilizationArray = JSON.parse(inputArray);

    if (typeof specilizationArray === "string") {
      const parsedArray = specilizationArray.split(",");
      return parsedArray;
    }

    if (Array.isArray(specilizationArray) && specilizationArray.length === 0) {
      return [specilizationArray];
    }

    const parsedArray = specilizationArray.map((item) => {
      // Remove double quotes from the beginning and end of each string
      return item.replace(/^"|"$/g, "");
    });

    return parsedArray;
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [placeholder, setPlaceholder] = useState("Select");
  const [diseases, setDiseases] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const inputString = parseSpecialization(specialization);
  const [tags, setTags] = useState(inputString);

  // Error State Variables
  const [errorsArray, setErrorsArray] = useState({});
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    const tempImageUrl =
      userDetail?.profile_image !== null
        ? `${baseImageURL}${userDetail.profile_image}`
        : signInUserData?.profile_image !== null
        ? `${baseImageURL}${signInUserData.profile_image}`
        : defaultImage;

    checkImageUrl(tempImageUrl, function (isValid) {
      if (isValid) {
        setProfileImage(tempImageUrl);
      } else {
        setProfileImage(defaultImage);
      }
    });

    instance.get(`/doctor/edit/${signInUserData.user_id}`).then((response) => {
      if (response.data.statusCode === 200) {
        setDiseases(response.data.data.disease_list);

        const doctorDiseases = response?.data?.data?.doctor?.disease?.map(
          (item) => item?.disease_id
        );

        const selectedDiseases = response?.data?.data?.disease_list?.filter(
          (item) => doctorDiseases?.includes(item?.id)
        );

        const idArrray = selectedDiseases.map((item) => item.id);

        setSelectedOptions(selectedDiseases);
        setDieasesIds(idArrray);
      } else {
        toast.error("Something went wrong! Please try again.");
      }
    });
  }, []);

  // loader //
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 250);
  }, []);

  //  update doctor profile api call //

  function displayErrors(errors) {
    errors.forEach((error) => {
      toast.warn(error, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
  }

  const handleButtonClick = () => {
    const formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("about_us", about_us);
    formData.append("specialist_in", specialist_in);
    formData.append("specialization", tags);
    formData.append("association", association);
    formData.append("membership_num", membership_num);
    formData.append("insurance_preference", insurance_preference);
    formData.append("address1", address1);
    formData.append(
      "address2",
      address2 !== null && address2 !== "null" ? address2 : ""
    );
    formData.append("zipcode", zipcode);
    formData.append("state", state);
    formData.append("city", city);
    formData.append(
      "profile_image",
      file ? file : userDetail.profile_image || signInUserData.profile_image
    );
    formData.append("user_id", signInUserData.user_id);
    formData.append("diseases", dieasesIds);
    formData.append("clinic_name", clinicName);
    formData.append("website", website);
    setIsSaving(true);

    const { isValid, errors } = validateFormData(formData);

    setIsValid(isValid);
    setErrorsArray(errors);

    if (isValid) {
      console.log("Adress 2: ", typeof address2);
      instance
        .post(`/doctor/updateProfile`, formData, {
          headers: {
            "Content-Type": "multipart/-data",
          },
        })
        .then((response) => {
          setIsSaving(false);
          setUserDetail(response.data.data);
          // if (response.data.statusCode === 200) {
          setSignInUserData({
            ...response.data.data,
            first_name,
            last_name,
            profile_image,
          });

          Swal.fire({
            title: "Success",
            text: response.data.message,
            icon: "success",
          });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.message.diseases) {
              const diseasesErrors = error.response.data.message.diseases;
              displayErrors(diseasesErrors);
            }

            if (error.response.data.message.profile_image) {
              const profileImageErrors =
                error.response.data.message.profile_image;
              displayErrors(profileImageErrors);
            }

            if (error.response.data.message.specialist_in) {
              const specialistInErrors =
                error.response.data.message.specialist_in;
              displayErrors(specialistInErrors);
            }
            if (error.response.data.message.specialization) {
              displayErrors(error.response.data.message.specialization);
            }
            setIsSaving(false);
          } else {
            setIsSaving(false);
            toast.error("Somehting went wrong!");
          }
        });
    } else {
      setIsSaving(false);
    }
  };

  //   file update handler //

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setProfileImage(reader.result);
    };
    if (file) {
      setFile(file);
      reader.readAsDataURL(file);
    }
  };

  // dissese //
  const handleSelect = (selected) => {
    const newDiseaseIds = Object.keys(selected).map((key) => selected[key].id);

    const dataArray = [newDiseaseIds];

    console.log("Data Array: ", newDiseaseIds);

    setPlaceholder("");

    setDieasesIds(dataArray);

    // Append each item in the array to the FormData object
    dataArray.forEach((item, index) => {});

    setSelectedOptions(selected);
  };

  // set tags
  const handleChange = (tags) => {
    setTags(tags);
  };

  const handleInputChange1 = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      // Prevent the default behavior of the Enter key (form submission)
      event.preventDefault();
      // Add the new tag to the tags array
      setTags([...tags, inputValue.trim()]);
      setSpecialization([...tags, inputValue.trim()]);

      // Clear the input field
      setInputValue("");
    }
  };

  const handleTagRemove = (tagToRemove) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
  };

  return (
    <>
      {/* Same as */}
      {isLoading ? (
        <div className="content-spinner">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="container">
          <div className="row g-0">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className=" mb-4">
                <h4 className="fw-bold mTc pro-set-h4">Basic Information</h4>
              </div>
              <div className="d-flex">
                <div>
                  <div className="">
                    <img
                      src={profile_image}
                      alt="Profile"
                      className="rounded-box-image-frame rounded"
                      width="100"
                      height="100"
                    />
                  </div>
                </div>
                <div>
                  <label
                    className="rounded list-button
                      dashboard-p3 text-center col-lg-6 col-sm-12 mo7 p-2 mb-1 ms-4 mt-4"
                    htmlFor="upload-input"
                  >
                    <img src={Portal18} alt="" width={18} /> Upload Photo
                    <input
                      id="upload-input"
                      type="file"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                  </label>
                  <p className="fw-bold text-muted ms-4 dashboard-p3 main-text mTc">
                    Allowed JPG, PNG. Max size of 2MB
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-5 mt-5 pe-3">
            <h5 className="fw-bold mTc dashboard-h5">Personal Information</h5>
            <form novalidate>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label
                      for="exampleInputEmail1"
                      className="form-label fw-bold mTc mo12"
                    >
                      First Name *
                      {!isValid ? (
                        <span className="doctor_profile_errors">
                          {errorsArray?.first_name}
                        </span>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      placeholder="First Name"
                      className={`form-control me-2 mb-2 mt-2 register-input ${
                        !isValid && errorsArray?.first_name
                          ? "is-invalid"
                          : null
                      }`}
                      aria-describedby="emailHelp"
                      required
                      value={first_name}
                      onChange={(e) => setFirst_name(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label
                      htmlFor="input2"
                      for="exampleInputEmail1"
                      className="form-label fw-bold mTc mo12"
                    >
                      Last Name *{" "}
                      {!isValid ? (
                        <span className="doctor_profile_errors">
                          {errorsArray?.last_name}
                        </span>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      className={`form-control me-2 mb-2 mt-2 register-input ${
                        !isValid && errorsArray?.last_name ? "is-invalid" : null
                      }`}
                      id="input2"
                      name="input2"
                      aria-describedby="emailHelp"
                      required
                      value={last_name}
                      onChange={(e) => setLast_name(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-2">
                <label
                  for="exampleInputEmail1"
                  className="form-label fw-bold mTc mo12"
                >
                  Phone Number *
                </label>
                <div id="survey-form" className="mt-2">
                  <PhoneInput
                    country={"us"}
                    className="marginBottom"
                    value={signInUserData.phone_no}
                    inputProps={{
                      style: {
                        height: "35px",
                        width: "99%",
                      },
                    }}
                    disabled
                  />
                </div>
              </div>

              <div className="mt-3">
                <label
                  for="exampleInputEmail1"
                  className="form-label fw-bold mTc mo12"
                >
                  Email *
                </label>
                <div className="d-flex position-relative mt-2">
                  <input
                    type="email"
                    placeholder="Email"
                    className={`form-control me-2 mb-2 mt-2 register-input`}
                    aria-describedby="emailHelp"
                    value={signInUserData.email}
                    readOnly
                  />
                </div>
              </div>

              <div className="mt-2">
                <label
                  for="exampleInputEmail1"
                  className="form-label fw-bold mTc mo12"
                >
                  About Me *
                  {!isValid ? (
                    <span className="doctor_profile_errors">
                      {errorsArray?.about_us}
                    </span>
                  ) : null}
                </label>
                <div className="d-flex position-relative">
                  <input
                    type="text"
                    placeholder="Add a description about yourself"
                    className={`form-control me-2 mb-2 mt-2 register-input1 ${
                      !isValid && errorsArray?.about_us ? "is-invalid" : null
                    }`}
                    aria-describedby="emailHelp"
                    value={about_us}
                    onChange={(e) => setAbout_us(e.target.value)}
                  />
                </div>
              </div>
            </form>
          </div>

          <div className="mb-5 pe-3">
            <h5 className="fw-bold mTc dashboard-h5">
              Professional Information
            </h5>
            <form novalidate>
              <div className="mb-3 mt-3">
                <div className="row mt-3">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label
                      for="exampleInputEmail1"
                      className="form-label fw-bold mTc mo12"
                    >
                      Specialist In *
                    </label>
                    <select
                      className="form-select register-input mt-2 mTc"
                      aria-label="Default select example"
                      onChange={(e) => setSpecialist_in(e.target.value)}
                      value={specialist_in}
                    >
                      <option value="" selected disabled>
                        Select One
                      </option>
                      <option value="1">Dentist</option>
                      <option value="2">Orthodontist</option>
                      <option value="3">Oral & Maxillofacial Surgeon</option>
                    </select>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label
                      htmlFor="specialization"
                      for="exampleInputEmail1"
                      className="form-label fw-bold mTc mo7"
                    >
                      Specialization *{" "}
                      {!isValid ? (
                        <span className="doctor_profile_errors">
                          {errorsArray?.tags}
                        </span>
                      ) : null}
                    </label>
                    <div
                      className={
                        !isValid && errorsArray?.tags
                          ? "border border-danger rounded-1"
                          : null
                      }
                    >
                      <TagsInput
                        value={tags}
                        onChange={handleChange}
                        inputProps={{
                          placeholder: "Specialization",
                          style: roundedBorder, // Apply the rounded border style here
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-sm-12">
                    <label
                      for="exampleInputEmail1"
                      className="form-label fw-bold mTc mo12"
                    >
                      Specialization *
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      className="form-control mt-2 register-input"
                      aria-describedby="emailHelp"
                      required
                      value={specialization}
                      onChange={(e) => setSpecialization(e.target.value)}
                    />
                  </div> */}
                </div>

                <div className="row mt-3">
                  <div
                    className="col-lg-12 col-md-12 col-sm-12"
                    style={{ width: "100%" }}
                  >
                    <label
                      for="exampleInputEmail1"
                      className="form-label fw-bold mTc mo12"
                    >
                      Diseases List *
                      {!isValid ? (
                        <span className="doctor_profile_errors">
                          {errorsArray?.dieasesIds}
                        </span>
                      ) : null}
                    </label>
                    <div
                      className={
                        !isValid && errorsArray.dieasesIds
                          ? "border border-danger rounded-1"
                          : null
                      }
                    >
                      <Multiselect
                        options={diseases}
                        selectedValues={selectedOptions}
                        onSelect={handleSelect}
                        onRemove={handleSelect}
                        displayValue="name"
                        // value={signInUserData?.diseases || userDetail?.diseases}
                        placeholder={placeholder}
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label
                      for="exampleInputEmail1"
                      className="form-label fw-bold mTc mo12"
                    >
                      LNAR *
                    </label>
                    <input
                      type="number"
                      placeholder=""
                      className={`form-control me-2 mb-2 mt-2 register-input`}
                      aria-describedby="emailHelp"
                      value={userDetail?.lnar || signInUserData?.lnar}
                      readOnly
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label
                      for="exampleInputEmail1"
                      className="form-label fw-bold mTc mo12"
                    >
                      Association Name *{" "}
                      {!isValid ? (
                        <span className="doctor_profile_errors">
                          {errorsArray?.association}
                        </span>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      className={`form-control me-2 mb-2 mt-2 register-input ${
                        !isValid && errorsArray?.association
                          ? "is-invalid"
                          : null
                      }`}
                      aria-describedby="emailHelp"
                      required
                      value={association}
                      onChange={(e) => setAssociation(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label
                      for="exampleInputEmail1"
                      className="form-label fw-bold mTc mo12"
                    >
                      Membership Number *{" "}
                      {!isValid ? (
                        <span className="doctor_profile_errors">
                          {errorsArray?.membership_num}
                        </span>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      className={`form-control me-2 mb-2 mt-2 register-input ${
                        !isValid && errorsArray.membership_num
                          ? "is-invalid"
                          : null
                      }`}
                      aria-describedby="emailHelp"
                      required
                      value={membership_num}
                      onChange={(e) => setMembership_num(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label
                      htmlFor="insurance_preference"
                      for="exampleInputEmail1"
                      className="form-label me-2 mb-2 mt-2   fw-bold mTc mo7"
                    >
                      Insurance Preference*
                    </label>
                    <select
                      className="form-select register-input m-1 mTc"
                      aria-label="Default select example"
                      onChange={(e) => setInsurance_preference(e.target.value)}
                      value={insurance_preference}
                      placeholder="Select one"
                    >
                      <option value="Legally">Legally</option>
                      <option value="Privately">Privately</option>
                      <option value="Both">Both</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="mb-5">
            <h5 className="fw-bold mTc dashboard-h5">Clinic Information</h5>
            <form novalidate>
              <div className="mb-3">
                <div className="row mt-3">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label
                      htmlFor="Clinic Name"
                      // for="exampleInputEmail1"
                      className="form-label fw-bold mTc mo12"
                    >
                      Clinic Name*{" "}
                      {!isValid ? (
                        <span className="doctor_profile_errors">
                          {errorsArray?.clinicName}
                        </span>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      placeholder="Clinic Name"
                      className={`${
                        !isValid && errorsArray.clinicName ? "is-invalid" : null
                      } form-control mt-2 register-input`}
                      id="clinic_name"
                      name="clinic_name"
                      value={clinicName}
                      onChange={(e) => setClinicName(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label
                      htmlFor="websitek"
                      className="form-label fw-bold mTc mo12"
                    >
                      Website
                    </label>
                    <input
                      type="text"
                      placeholder="https://www"
                      className="form-control me-2 mb-2 mt-2 register-input"
                      id="website"
                      name="website"
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label
                      for="exampleInputEmail1"
                      className="form-label fw-bold mTc mo12"
                    >
                      Address 1 *{" "}
                      {!isValid ? (
                        <span className="doctor_profile_errors">
                          {errorsArray?.address1}
                        </span>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      className={`form-control me-2 mb-2 mt-2 register-input ${
                        !isValid && errorsArray?.address1 ? "is-invalid" : null
                      }`}
                      aria-describedby="emailHelp"
                      required
                      value={address1}
                      onChange={(e) => setAddress1(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label
                      for="exampleInputEmail1"
                      className="form-label fw-bold mTc mo12"
                    >
                      Address 2
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      className={`form-control me-2 mb-2 mt-2 register-input`}
                      aria-describedby="emailHelp"
                      required
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label
                      for="exampleInputEmail1"
                      className="form-label fw-bold mTc mo12"
                    >
                      City *{" "}
                      {!isValid ? (
                        <span className="doctor_profile_errors">
                          {errorsArray?.city}
                        </span>
                      ) : null}
                    </label>

                    <input
                      type="text"
                      placeholder=""
                      className={`form-control me-2 mb-2 mt-2 register-input ${
                        !isValid && errorsArray?.city ? "is-invalid" : null
                      }`}
                      aria-describedby="emailHelp"
                      required
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label
                      for="exampleInputEmail1"
                      className="form-label fw-bold mTc mo12"
                    >
                      State *{" "}
                      {!isValid ? (
                        <span className="doctor_profile_errors">
                          {errorsArray?.state}
                        </span>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      className={`form-control me-2 mb-2 mt-2 register-input ${
                        !isValid && errorsArray?.state ? "is-invalid" : null
                      }`}
                      aria-describedby="emailHelp"
                      required
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label
                      for="exampleInputEmail1"
                      className="form-label fw-bold mTc mo12"
                    >
                      Zipcode *{" "}
                      {!isValid ? (
                        <span className="doctor_profile_errors">
                          {errorsArray?.zipcode}
                        </span>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      className={`form-control me-2 mb-2 mt-2 register-input ${
                        !isValid && errorsArray?.zipcode ? "is-invalid" : null
                      }`}
                      aria-describedby="emailHelp"
                      required
                      value={zipcode}
                      onChange={(e) => setZipcode(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </form>

            <div className="d-grid col-6 gap-2 mt-5 pb-5">
              <button
                disabled={isLoading}
                className="register-button2 dashboard-p3 rounded"
                type="submit"
                onClick={handleButtonClick}
              >
                {isSaving ? (
                  <AiOutlineLoading3Quarters className="chat_spinner" />
                ) : (
                  "Save Changes"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Profile1;
