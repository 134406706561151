import { useEffect, useRef, useState } from "react";
import { ImSpinner8 } from "react-icons/im";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";

import Logo from "../../assets/register/Logo.svg";
import {
  AgoraChatContextProvider,
  AgoraVideoContextProvider,
  FocusedContextProvider,
  HostUserContextProvider,
  RefreshFocusedContextProvider,
  ToggleChatContextProvider,
} from "./components/AgoraContext";
import ChatFeed from "./components/ChatFeed";
import VideoFeed from "./components/VideoFeed";

import { toast } from "react-toastify";
import { getCurrentDateInGermany } from "../../Utils/Utils";
import { useApiContext } from "../../contextapi/contextApi";
import "./styles/LiveMeeting.css";
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default function LiveMeeting() {
  const { channelName, appointmentId } = useParams();
  const { instance, signInUserData, setGuestuser, guestUser } = useApiContext();
  const [permissionDenied, setPermissionDenied] = useState(false);
  const email = useRef("");
  const password = useRef("");
  const [isLoading, setIsLoading] = useState(false);
  const videoFeedRef = useRef(null);
  const navigate = useNavigate();
  const [invalidAppointmentDate, setInvalidAppointmentDate] = useState(false);
  const [appointmentTime, setAppointmentTime] = useState(null);
  const [appDetailLoading, setAppDetailLoading] = useState(true);

  async function getAppointment() {
    try {
      const { data } = await instance.get(
        `/doctor/get-appoinment-details/${appointmentId}`
      );

      const appointmentDetails = data.data[0].latest_appoinment;

      const appointmentDate = new Date(
        `${appointmentDetails.date} ${appointmentDetails.start_time}`
      );
      const currentDateInGermany = getCurrentDateInGermany();

      appointmentDate.setMinutes(appointmentDate.getMinutes() - 5);

      if (appointmentDate > currentDateInGermany) {
        console.log("Not Correct Time:", {
          appointmentDate,
          currentDateInGermany,
        });

        setInvalidAppointmentDate(true);
        const formattedDateTime = appointmentDate.toLocaleString(undefined, {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          timeZoneName: "short",
        });
        setAppointmentTime(formattedDateTime);
      } else {
        console.log("Correct Time:", {
          appointmentDate,
          currentDateInGermany,
        });
      }
      setAppDetailLoading(false);
    } catch (error) {
      toast.error("Something went wrong");
      setAppDetailLoading(false);
    }
  }

  useEffect(() => {
    getAppointment();
    instance.get(`/update-appointment-status?appoinment_id=${appointmentId}`);
  }, []);

  async function joinAsGuest(e) {
    e.preventDefault();

    if (!emailRegex.test(email.current)) {
      toast.error("Invalid email");
      return;
    }

    if (password.current === "") {
      toast.error("Password Feild cannot be empty.");
      return;
    }

    try {
      setIsLoading(true);
      const res = await instance.post("/guest/verify-guest-invite", {
        email: email.current,
        password: password.current,
        appointmentId,
      });

      setGuestuser(res.data.data);

      if (!res.data.data) {
        throw new Error("Incorrect password...");
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Invalid email or password.");
    }
  }

  const navigateBackRoute = () => {
    if (!signInUserData) return "/";

    return signInUserData?.user_type === "Doctor"
      ? "/doctor-portal"
      : "/patient-portal";
  };

  if (appDetailLoading) {
    return (
      <div className="video__feed__loader">
        <AiOutlineLoading3Quarters className="chat_spinner" />
      </div>
    );
  }

  if (invalidAppointmentDate) {
    return (
      <div className="invalid_appointment_date">
        <div className="permission__denied">
          <h3>To soon to Join</h3>
          <p>Meeting will start at {appointmentTime}.</p>
          <button onClick={() => navigate(navigateBackRoute())}>Go Back</button>
        </div>
      </div>
    );
  }

  if (!signInUserData && !guestUser) {
    return (
      <section className="join_as_guest">
        <div className="join_as_guest_container">
          <div className="join_as_guest_header">
            <img src={Logo} alt="Remote Dental Care" />
            <h3 className="title">Join as guest</h3>
            <p className="subtitle">
              You can use your email and password you recieve through email to
              join as guest.
            </p>
          </div>
          <div className="join_as_guest_body">
            <form onSubmit={joinAsGuest}>
              <label>
                Email:
                <input
                  type="email"
                  onChange={(e) => (email.current = e.target.value)}
                />
              </label>
              <label>
                Password:{" "}
                <input
                  type="text"
                  onChange={(e) => (password.current = e.target.value)}
                />{" "}
              </label>
              <button className="submit_invite" type="submit">
                {isLoading ? <ImSpinner8 className="rotate" /> : "Join"}
              </button>
            </form>
          </div>
        </div>
      </section>
    );
  }

  return (
    <ToggleChatContextProvider>
      <div className="live_meeting_container" ref={videoFeedRef}>
        {signInUserData && (
          <AgoraChatContextProvider>
            <ChatFeed appointmentId={appointmentId} />
          </AgoraChatContextProvider>
        )}
        {permissionDenied ? (
          <div className="permission__denied">
            <h3>Permission Denied</h3>
            <p>
              Please give access to camera and audio to continue to meeting.
            </p>
            <button onClick={() => window.location.reload()}>
              Start Meeting
            </button>
          </div>
        ) : (
          <AgoraVideoContextProvider>
            <HostUserContextProvider>
              <FocusedContextProvider>
                <RefreshFocusedContextProvider>
                  <VideoFeed
                    setPermissionDenied={setPermissionDenied}
                    channelName={channelName}
                    videoFeedRef={videoFeedRef}
                    appointmentId={appointmentId}
                  />
                </RefreshFocusedContextProvider>
              </FocusedContextProvider>
            </HostUserContextProvider>
          </AgoraVideoContextProvider>
        )}
      </div>
    </ToggleChatContextProvider>
  );
}
