import React from "react";
import Doc1 from "../../assets/homepage/Arrow5.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const SubDashFourth = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/patient-portal/patient-appointment-complete");
  };

  return    <>
  <section className="ms-2">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="d-flex mt-3 mb-3">
          <Link to="/patient-portal/question-01">
            <div>
              <img
                src={Doc1}
                alt="arrow-img"
                width={30}
                className="main-img"
             
              />
            </div>
            </Link>
            <div className="ps-2 pt-1">
              <h6 className="main-text mTc fw-bold">
                Schedule Appointment
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="mt-4 ms-2">
          <p className="mTc font-p2">What is the quality of pain?</p>

          <div className="d-flex mt-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Pointed
              </label>
            </div>
          </div>

          <div className="d-flex  mt-3">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Blunt
              </label>
            </div>
          </div>

          <div className="d-flex  mt-3">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Burning
              </label>
            </div>
          </div>

          <div className="d-flex  mt-3">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                I can't say exactly
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="mt-5 mb-5">
      <div class="d-grid gap-2 col-5 mx-auto">
        <button
          class="Button"
          type="button"
       onClick={handleClick}
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</>;
};

export default SubDashFourth;
