import { addDays, differenceInYears, parseISO, subDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import { IoIosSend } from "react-icons/io";
import React, { useState } from "react";

import Patient6 from "../../../assets/dashboard-patient/dashboard-patient5.svg";
import Patient5 from "../../../assets/dashboard-patient/dashboard-patient6.svg";
import { useApiContext } from "../../../contextapi/contextApi";
import Portal20 from "../../../assets/dashboard/Arrow1.png";
import FFF from "../../../assets/homepage/profile-img2.png";
import RescheduleModal from "./RescheduleModal";
import InviteModal from "../../inviteModal";

import "./AppointmentStyles.css";
import GuestList from "../../GuestList";

function formatDate1(dob) {
  const dobDate = parseISO(dob);
  const age = differenceInYears(new Date(), dobDate);
  return `${age} years`;
}

function AppointmentDetaild() {
  const { baseImageURL, appdetail, apquestions, instance, signInUserData } =
    useApiContext();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [activeDate, setActiveDate] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  console.log("Appointment Detail : ", appdetail);

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();
  const acceptInvite = async () => {
    const response = await instance.post("/doctor/accept-appoinment", {
      appoinment_id: appdetail.id,
      user_id: signInUserData.id,
    });
    if (response.data.statusCode === 200) {
      navigate("/doctor-portal/");
    }
  };

  const formatDate = (dateString) => {
    const inputDate = new Date(dateString);
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dayOfWeek = daysOfWeek[inputDate.getDay()];
    const month = months[inputDate.getMonth()];
    const dayOfMonth = inputDate.getDate();

    const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}`;
    return formattedDate;
  };

  const dob = appdetail?.data?.data[0]?.patient?.user.dob;
  const formattedDOB = formatDate1(dob);

  const handleNextClick = () => {
    setSelectedDate(addDays(selectedDate, 7));
  };

  const handlePreviousClick = () => {
    setSelectedDate(subDays(selectedDate, 7));
  };

  const getWeekDates = (start) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      dates.push(addDays(start, i));
    }
    return dates;
  };
  const weekDates = getWeekDates(selectedDate);

  return (
    <>
      <div className="new-content appointment_detail">
        <div className="container">
          <div className="row g-0">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="d-flex align-items-baseline">
                <div>
                  <button
                    onClick={() => navigate(-1)}
                    className="border-white border bg-white"
                  >
                    <img
                      src={Portal20}
                      alt="arrow3-img"
                      className="me-2"
                      width={30}
                    />
                  </button>
                </div>
                <h6 className="fw-bold mo dashboard-h5">Appointment Detail</h6>
              </div>

              <div className="row g-0 mt-4 mb-3">
                <div className="col-lg-6 col-md-5 col-sm-12 mt-2">
                  <div className="d-flex align-items-start">
                    <div>
                      <img
                        className="rounded"
                        src={`${baseImageURL}${appdetail?.data?.data[0]?.patient?.user.profile_image}`}
                        alt=""
                        style={{
                          width: "80px",
                          height: "80px",
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                        onError={(e) => {
                          e.target.src = FFF;
                        }}
                      />
                    </div>
                    <div>
                      <ul className="list-unstyled ms-4">
                        <li className="dashboard-p3 fw-bold">
                          {appdetail?.data?.data[0]?.patient?.user.first_name}
                          &nbsp;
                          {appdetail?.data?.data[0]?.patient?.user.last_name}
                        </li>
                        <li className="dashboard-p3">
                          Patient ID: &nbsp;
                          {appdetail?.data?.data[0]?.patient_id}
                        </li>
                        <li className="dashboard-p3">
                          {appdetail?.data?.data[0]?.patient?.user?.dob
                            ? formattedDOB
                            : null}
                          , &nbsp;
                          {appdetail?.data?.data[0]?.patient?.user.gender}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="rounded dashboard-main mt-3">
                    <div className="d-flex justify-content-between p-3">
                      <p className="main-text mTc fw-bold m-0 p-0">
                        <img
                          src={Patient5}
                          alt="patient-img"
                          className="me-2"
                        />
                        {formatDate(
                          appdetail?.data?.data[0]?.latest_appoinment?.date
                        )}
                      </p>
                      <p className="main-text mTc fw-bold m-0 p-0">
                        <img
                          src={Patient6}
                          alt="patient-img"
                          className="me-2"
                        />
                        {
                          appdetail?.data?.data[0]?.latest_appoinment
                            ?.start_time
                        }
                        <span className="mx-2">-</span>
                        {appdetail?.data?.data[0]?.latest_appoinment?.end_time}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-2"></div>
                <div className="col-lg-2 col-md-2 col-sm-12 mt-2">
                  <div className="d-grid gap-2 col-12 mx-auto">
                    {appdetail?.data?.data[0]?.status === "Pending" ? (
                      <button
                        onClick={acceptInvite}
                        className="card-button1 dashboard-p3 rounded pt-1 pb-1"
                        type="button"
                      >
                        <i className="fa fa-check me-2"></i>
                        Accept
                      </button>
                    ) : appdetail?.data?.data[0]?.status === "Cancel" ? (
                      <button
                        className="dashboard-p3 rounded pt-1 pb-1 text-danger border-0"
                        type="button"
                      >
                        <i className="fa fa-check me-2"></i>
                        Cancelled
                      </button>
                    ) : appdetail?.data?.data[0]?.status ===
                      "Accepted By User" ? (
                      <button
                        className="dashboard-p3 rounded pt-1 pb-1 text-info border-0"
                        type="button"
                      >
                        <i className="fa fa-check me-2"></i>
                        Upcoming
                      </button>
                    ) : (
                      <button
                        className="dashboard-p3 rounded pt-1 pb-1 text-success border-0"
                        type="button"
                      >
                        <i className="fa fa-check me-2"></i>
                        Completed
                      </button>
                    )}
                    {appdetail?.data?.data[0]?.status !== "Completed" &&
                      appdetail?.data?.data[0]?.status !== "Cancel" && (
                        <button
                          className="card-button dashboard-p3 rounded pt-1 pb-1"
                          type="button"
                          onClick={openModal}
                        >
                          {/* <i className="fa fa-calendar me-2"></i> */}
                          {appdetail?.data?.data[0]?.status === "Pending"
                            ? "Cancel"
                            : "Cancel"}
                        </button>
                      )}
                  </div>
                  {appdetail?.data?.data[0]?.status === "Accepted By User" ? (
                    <div className="d-grid gap-2 col-12 mx-auto mt-2">
                      <button
                        className="rounded modal_toggler_button p-1"
                        type="button"
                        onClick={() => setShowModal(true)}
                      >
                        Invite user <IoIosSend />
                      </button>
                      <InviteModal
                        appointmentId={appdetail?.data?.data[0].id}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        setRefresh={setRefresh}
                      />
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="rounded border diseases-diagnose-container">
                <div className="d-flex justify-content-between align-items-center p-2">
                  <div>
                    <p className="dashboard-p3 fw-bold p-0 m-0">Diagnose</p>
                  </div>
                  <div>
                    <ul className=" diseases-list-container">
                      {appdetail?.data?.data?.diagnoses.map((diagnose) => (
                        <li key={diagnose.id} className="dashboard-p3">
                          {diagnose.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <hr className="m-0 p-0" />
                <div className="d-flex justify-content-between align-items-center p-2">
                  <div>
                    <p className="dashboard-p3 fw-bold p-0 m-0">Disease</p>
                  </div>
                  <div>
                    <ul className="diseases-list-container">
                      {appdetail?.data?.data?.diseases.map((diseases) => (
                        <li key={diseases.id} className="dashboard-p3">
                          {diseases.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {appdetail?.data?.data["0"].status === "Cancel" &&
                appdetail?.data?.data["0"]?.canceled_by ? (
                  <>
                    <hr className="m-0 p-0" />

                    <div className="appointment_detail_cancelled_by border shadow m-0 p-0 gap-1">
                      <p className="p-2">
                        <strong>Cancelled By: </strong>
                        {`${appdetail?.data?.data["0"]?.canceled_by?.first_name} ${appdetail?.data?.data["0"]?.canceled_by?.last_name}`}
                      </p>
                      <p className="p-2">
                        <strong>Reason: </strong>{" "}
                        {appdetail?.data?.data["0"]?.cancellation_reason}
                      </p>
                    </div>
                  </>
                ) : null}
                {appdetail?.data?.data?.symptoms.length &&
                appdetail?.data?.data?.symptoms[0] !== "" ? (
                  <>
                    <hr className="m-0 p-0" />
                    <div className="d-flex justify-content-between align-items-center p-2">
                      <div>
                        <p className="dashboard-p3 fw-bold p-0 m-0">Symptoms</p>
                      </div>
                      <div>
                        <ul className="diseases-list-container">
                          {appdetail?.data?.data?.symptoms[0]
                            ?.split(",")
                            .map((symptom) => (
                              <li key={symptom} className="dashboard-p3">
                                {symptom}
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </>
                ) : null}
                <GuestList
                  appointmentId={appdetail?.data?.data[0]?.id}
                  refresh={refresh}
                />
              </div>
              <h6 className="fw-bold main-text pt-4 pb-2 mtc">
                Consultation Reasons
              </h6>
              <div className="rounded border bg-white mb-4 p-3 mt-2 pt-1">
                <div className="fw-bold main-text pt-2 pb-2 mtc">
                  {appdetail?.data?.data[0]?.reason?.name}
                </div>
                {appdetail?.data?.data?.questions &&
                appdetail?.data?.data?.questions?.length ? (
                  appdetail?.data?.data?.questions.map((values, index) => (
                    <div key={index} className="container mt-2">
                      <div className="row">
                        <div className="col-12">
                          <div
                            className="accordion mt-2 mb-2"
                            id="accordionExample"
                          >
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id={`heading-${index}`}
                              >
                                <button
                                  className="accordion-button bg-light text-dark p-2 ps-3"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapse-${index}`}
                                  aria-expanded="true"
                                  aria-controls={`collapse-${index}`}
                                >
                                  {values.question}
                                </button>
                              </h2>
                              <div
                                id={`collapse-${index}`}
                                className="accordion-collapse collapse"
                                aria-labelledby={`heading-${index}`}
                                data-bs-parent=".accordion"
                              >
                                <div
                                  className={`accordion-body p-2 ps-3 ${
                                    values?.answer_image ? "has_image" : ""
                                  }`}
                                  style={{ padding: "0px !Important" }}
                                >
                                  {values?.question_image ? (
                                    <div className="app_questions_image">
                                      <img
                                        src={`${baseImageURL}${values?.question_image}`}
                                        alt="tooth diagram"
                                      />
                                    </div>
                                  ) : null}
                                  {values?.answer_image ? (
                                    <img
                                      src={`${baseImageURL}${values?.answer_image}`}
                                      alt=""
                                    />
                                  ) : (
                                    <p>{values?.answer}</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No data available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* -------------Modal------------ */}
      <div
        className={`modal fade ${isModalOpen ? "show" : ""}`}
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <RescheduleModal closeModal={closeModal} />
      </div>
    </>
  );
}

export default AppointmentDetaild;
