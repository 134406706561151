import React from "react";
import Pat5 from "../../../assets/dashboard/folder.svg";

const Records = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row g-0 mt-2">
          <div className="col-lg-2 col-md-2 col-sm-12">
            <p className="fw-bold ps-3 dashboard-p3">July 3, 2023</p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12">
            <hr className="me-3" />
            <div className="container-fluid">
              <div className="row g-0 mt-3">
                <div className="col-lg-2 col-md-2 col-sm-12">
                  <div className="mt-2 m-1">
                    <div className="shadow-sm border rounded p-1 ps-2">
                      <img src={Pat5} alt="folder" width="30" />
                      <div className="lh-sm">
                        <p className="fw-bold pt-3 dashboard-p3">
                          X Ray Report
                        </p>
                        <p className="fw-bold text-muted dashboard-p3">
                          4 Files
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12">
                  <div className="mt-2 m-1">
                    <div className="shadow-sm border rounded p-1 ps-2">
                      <img src={Pat5} alt="folder" width="30" />
                      <div className="lh-sm">
                        <p className="fw-bold pt-3 dashboard-p3">
                          X Ray Report
                        </p>
                        <p className="fw-bold text-muted dashboard-p3">
                          4 Files
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12">
                  <div className="mt-2 m-1">
                    <div className="shadow-sm border rounded p-1 ps-2">
                      <img src={Pat5} alt="folder" width="30" />
                      <div className="lh-sm">
                        <p className="fw-bold pt-3 dashboard-p3">
                          X Ray Report
                        </p>
                        <p className="fw-bold text-muted dashboard-p3">
                          4 Files
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row g-0 mt-2">
          <div className="col-lg-2 col-md-2 col-sm-12">
            <p className="fw-bold ps-3 dashboard-p3">July 3, 2023</p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12">
            <hr className="me-3" />
            <div className="container-fluid">
              <div className="row g-0 mt-3">
                <div className="col-lg-2 col-md-2 col-sm-12">
                  <div className="mt-2 m-1">
                    <div className="shadow-sm border rounded p-1 ps-2">
                      <img src={Pat5} alt="folder" width="30" />
                      <div className="lh-sm">
                        <p className="fw-bold pt-3 dashboard-p3">
                          X Ray Report
                        </p>
                        <p className="fw-bold text-muted dashboard-p3">
                          4 Files
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12">
                  <div className="mt-2 m-1">
                    <div className="shadow-sm border rounded p-1 ps-2">
                      <img src={Pat5} alt="folder" width="30" />
                      <div className="lh-sm">
                        <p className="fw-bold pt-3 dashboard-p3">
                          X Ray Report
                        </p>
                        <p className="fw-bold text-muted dashboard-p3">
                          4 Files
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Records;
