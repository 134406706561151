import React from "react";
import "../styles/NewContactUsStyles.css";
import Contact from "../assets/homepage/hosp.jpg";
import Contact2 from "../assets/homepage/image 21.png";
import { useApiContext } from "../contextapi/contextApi";
import NewNavbar from "../components/HomeNavbar";
import { useEffect } from "react";
import ContactUsForm from "./ContactUsForm";
import phone from "../assets/contact-phone.png";
import email from "../assets/contact-email.png";

import ClientSlider from "../components/ClientSection";
import MoreButtons from "../components/HomepageMoreButtons";
import Footer from "../components/HomepageFooter";
import Icons from "../components/HomepageIcons";

function ContactUs() {
  const { signInUserData, userDetail } = useApiContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="contact-us-header">
        <NewNavbar />
        <div className="form-bg">
          <div className="overlay">
            <h1>PREFER TO DROP US A LINE FIRST? NO PROBLEM.</h1>
            <div className="form-wrapper-outer">
              <ContactUsForm />
            </div>
          </div>
        </div>
      </div>
      <div className="contact-us-info">
        <div className="contact-us-info-inner">
          <div className="email">
            <img src={email} alt="" />
            <h1>SUPPORT</h1>
            <h2>
              <a
                href="mailto:support@dental-telecare.com"
                class="text-black text-decoration-none"
              >
                support@dental-telecare.com
              </a>
            </h2>
            <h1>GENERAL QURIES</h1>
            <h2>
              <a
                href="mailto:info@dental-telecare.com"
                class="text-black text-decoration-none"
              >
                info@dental-telecare.com
              </a>
            </h2>
          </div>
          {/* <div className="phone">
            <img src={phone} alt="" />
            <h1>TOLL FREE</h1>
            <h2>111-765-345</h2>
            <h1>SUPPORT</h1>
            <h2>+72-57683-09878</h2>
          </div> */}
        </div>
      </div>
      <Icons />
      <ClientSlider />
      <MoreButtons />
      <Footer />
    </>
  );
}

export default ContactUs;
