import React, { useEffect, useState } from "react";
import Portal9 from "../../assets/dashboard/clock2.svg";
import Portal10 from "../../assets/dashboard/doctor9.svg";
import Portal11 from "../../assets/dashboard/Reschedule-Icon.svg";
import Portal12 from "../../assets/dashboard/click.svg";
import FFF from "../../assets/homepage/profile-img2.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import DropdownButton from "../../subcomponents/Dropdown";
import Portal19 from "../../assets/dashboard/doctor10.svg";
import Portal20 from "../../assets/dashboard/Arrow1.png";
import { useLocation } from "react-router-dom";
import Req from "../../assets/dashboard/req.png";
import { useApiContext } from "../../contextapi/contextApi";
import { async } from "q";
import Patient5 from "../../assets/dashboard-patient/dashboard-patient6.svg";
import Patient6 from "../../assets/dashboard-patient/dashboard-patient5.svg";
import defultimag from "../../assets/homepage/profile-img.png";
import { format, differenceInYears, parseISO } from "date-fns";
import RescheduleModal from "./appointment/RescheduleModal";
import "./Request1Styles.css";
import CancelAppointmentModal from "../CancelAppointmentModal";

function formatDate1(dob) {
  // Parse the date string into a Date object
  const dobDate = parseISO(dob);

  // Calculate the age
  const age = differenceInYears(new Date(), dobDate);

  return `${age} years`;
}

function Request1() {
  const { baseImageURL, signInUserData, instance, setSchduleRequestid } =
    useApiContext();
  const [requests, setRequests] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const [apdetail, setApDetail] = useState([]);
  const [extra, setExtra] = useState();
  const [reasonName, setReasonName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false);
  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    instance
      .get(`/doctor/new-appoinments-requests/${signInUserData.user_id}`)
      .then((response) => {
        if (response.data.statusCode === 200) {
          setRequests(response?.data?.data);
          setIsLoading(false);
          if (response?.data?.data?.length === 0) {
            setShowNoDataMessage(true);
          }
        } else {
          console.log("Api is not working");
        }
      });
  }, [refresh]);

  const handleAppointmnetDetail = async (id) => {
    try {
      const response = await instance.get(
        `doctor/get-appoinment-details/${id}`
      );
      setSchduleRequestid(id);
      const extraData = response?.data?.data;
      setReasonName(extraData[0].reason.name);
      setExtra(extraData);

      const patientData = response?.data?.data?.questions;
      if (patientData) {
        setApDetail(patientData);
      }
    } catch (err) {
      setApDetail([]);
    }
  };

  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const handleLogoutTabClick = () => {
    setShowLogoutModal(true);
  };
  const handleLogoutConfirm = () => {
    window.location.href = "/";
  };
  const handleLogoutModalClose = () => {
    setShowLogoutModal(false);
  };
  let location = useLocation();
  let activeLink = location.pathname;
  // console.log("location : ", location);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  // ----------------------------------------- //
  const [showOriginalContent, setShowOriginalContent] = useState(true);
  const [patient, setPatient] = useState(null);
  const handleAcceptClick = (item) => {
    setPatient(null);
    setPatient(item);
    setShowOriginalContent(false);
  };

  const navigate = useNavigate();

  const acceptInvite = async () => {
    const response = await instance.post("/doctor/accept-appoinment", {
      appoinment_id: patient.id,
      user_id: signInUserData.id,
    });
    if (response.data.statusCode === 200) {
      navigate("/doctor-portal");
    }
  };

  const handleBackClick = () => {
    setShowOriginalContent(true);
  };

  const formatDate = (dateString) => {
    const inputDate = new Date(dateString); // Replace this with your input date

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dayOfWeek = daysOfWeek[inputDate.getDay()];
    const month = months[inputDate.getMonth()];
    const dayOfMonth = inputDate.getDate();

    const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}`;
    return formattedDate;
  };

  const dob = patient?.patient?.user.dob;
  const formattedDOB = formatDate1(dob);

  const renderContent = () => {
    if (showOriginalContent) {
      return (
        <>
          {isLoading ? (
            <div className="content-spinner">
              <div className="loader"></div>
            </div>
          ) : (
            <div>
              {showNoDataMessage ? (
                <div className="no-data-message text-center">
                  <img
                    src={Req}
                    alt=""
                    className="img-fluid mt-5"
                    width={500}
                  />
                  <h3 className="mt-4">No New Request.</h3>
                  <p className="mt-4">Please checkback later.</p>
                </div>
              ) : (
                <div className="main-title">
                  <div className="container-fluid">
                    <div className="row">
                      {requests &&
                        requests.map((item) => (
                          <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                            <div>
                              <div className="card">
                                <div className="card-box1 pt-3 ps-2 pe-2 d-flex justify-content-between align-items-center">
                                  <div>
                                    <p className="text-white dashboard-th">
                                      {formatDate(item?.latest_appoinment.date)}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="text-white dashboard-th">
                                      <img
                                        src={Portal9}
                                        className="pe-2 "
                                        alt=""
                                      />
                                      {item?.latest_appoinment.start_time}
                                    </p>
                                  </div>
                                </div>
                                <div className="ps-2 pe-2">
                                  <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                      <div className="d-flex pt-2">
                                        <img
                                          className="schdule rounded-circle mt-1"
                                          src={
                                            `${baseImageURL}${item?.patient?.user?.profile_image}` ||
                                            defultimag
                                          }
                                          alt=""
                                          style={{
                                            objectFit: "cover",
                                            objectPosition: "center",
                                          }}
                                        />
                                        <p className="ps-2 pt-3 dashboard-p2 fw-bold dashboard-th">
                                          {item.patient.user.first_name +
                                            " " +
                                            item.patient.user.last_name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="d-flex row justify-content-between pt-3">
                                    <div className="col-4 mTc fw-bold dashboard-th">
                                      Appointment Reason
                                    </div>
                                    <div className="col-8  mTc fw-bold dashboard-th d-flex justify-content-end">
                                      {item?.reason?.name}
                                      &nbsp;
                                    </div>
                                  </div> */}

                                  <div className="d-flex row justify-content-between pt-2">
                                    <div className="col-4 mTc fw-bold dashboard-th">
                                      Diagnose
                                    </div>
                                    <div className="col-8  mTc dashboard-th new_appoint_disease">
                                      {item?.appoinment_details?.diagnoses?.map(
                                        (diagnose, index) =>
                                          index ===
                                          item?.appoinment_details?.diagnoses
                                            ?.length -
                                            1 ? (
                                            <span key={diagnose}>
                                              {diagnose.name}
                                            </span>
                                          ) : (
                                            <span key={diagnose}>
                                              {diagnose.name}, &nbsp;
                                            </span>
                                          )
                                      )}
                                    </div>
                                  </div>

                                  <div className="d-flex row justify-content-between pb-2">
                                    <div className="col-4 mTc fw-bold dashboard-th">
                                      Disease
                                    </div>
                                    <div className="col-8  mTc dashboard-th new_appoint_disease">
                                      {item?.appoinment_details?.diseases?.map(
                                        (disease, index) =>
                                          index ===
                                          item?.appoinment_details?.diseases
                                            ?.length -
                                            1 ? (
                                            <span key={disease}>
                                              {disease.name}
                                            </span>
                                          ) : (
                                            <span key={disease}>
                                              {disease.name}, &nbsp;
                                            </span>
                                          )
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="border-top bg-white">
                                  <div className="row m-1">
                                    {/* <div className="col-lg-6 col-md-6 col-sm-12">
                                      <div className="m-1">
                                        <div className="d-grid gap-2 col-12 mx-auto ">
                                          <button
                                            className="btn card-button dashboard-p3 rounded fw-bold"
                                            type="button"
                                          >
                                            <i className="fa fa-calendar me-2"></i>
                                            Reschedule
                                          </button>
                                        </div>
                                      </div>
                                    </div> */}
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                      <dispatchEvent>
                                        <div className="m-1">
                                          <div className="d-grid gap-2 col-12 mx-auto">
                                            <button
                                              className="btn card-button1 dashboard-p3 rounded fw-bold"
                                              type="button"
                                              onClick={() => {
                                                handleAcceptClick(item);
                                                handleAppointmnetDetail(
                                                  item.id
                                                );
                                              }}
                                            >
                                              <i className="fa fa-eye me-2"></i>
                                              View Detail
                                            </button>
                                          </div>
                                        </div>
                                      </dispatchEvent>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      );
    } else {
      return (
        <>
          {isLoading ? (
            <div className="content-spinner">
              <div className="loader"></div>
            </div>
          ) : (
            <div>
              {showNoDataMessage ? (
                <div className="no-data-message">
                  <p>No data available</p>
                </div>
              ) : (
                <div className="new-content">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="d-flex align-items-baseline">
                          <div>
                            <Link to="/doctor-portal/new-request">
                              <button
                                onClick={handleBackClick}
                                className="border-white border bg-white"
                              >
                                <img
                                  src={Portal20}
                                  alt="arrow3-img"
                                  className="me-2"
                                  width={30}
                                />
                              </button>
                            </Link>
                          </div>
                          <h6 className="fw-bold mo dashboard-h5">
                            Appointment Invite
                          </h6>
                        </div>
                        <div className="row mt-4 mb-3 g-0">
                          <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                            <div className="d-flex align-items-start">
                              <div>
                                <img
                                  className="rounded"
                                  src={
                                    `${baseImageURL}${patient?.patient?.user?.profile_image}` ||
                                    defultimag
                                  }
                                  alt=""
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    objectFit: "cover",
                                    objectPosition: "center",
                                  }}
                                  onError={(e) => {
                                    e.target.src = FFF;
                                  }}
                                />
                              </div>
                              <div>
                                <ul className="list-unstyled ms-4">
                                  <li className="dashboard-p3 fw-bold">
                                    {patient?.patient?.user?.first_name}&nbsp;
                                    {patient?.patient?.user?.last_name}
                                  </li>
                                  <li className="dashboard-p3">
                                    Patient ID: &nbsp;
                                    {patient?.patient_id}
                                  </li>
                                  <li className="dashboard-p3">
                                    {formattedDOB}, &nbsp;
                                    {patient?.patient?.user.gender}
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="rounded dashboard-main mt-3">
                              <div className="d-flex justify-content-between p-3">
                                <p className="main-text mTc fw-bold m-0 p-0">
                                  <img
                                    src={Patient5}
                                    alt="patient-img"
                                    className="me-2"
                                  />
                                  {formatDate(patient?.latest_appoinment?.date)}
                                </p>
                                <p className="main-text mTc fw-bold m-0 p-0">
                                  <img
                                    src={Patient6}
                                    alt="patient-img"
                                    className="me-2"
                                  />
                                  {patient?.latest_appoinment?.start_time} -{" "}
                                  {patient?.latest_appoinment?.end_time}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12 mt-2"></div>
                          <div className="col-lg-1 col-md-1 col-sm-12 mt-2"></div>
                          <div className="col-lg-2 col-md-2 col-sm-12 mt-2">
                            <div className="d-grid gap-2 col-12 mx-auto">
                              <button
                                onClick={acceptInvite}
                                className="card-button1 dashboard-p3 rounded pt-1 pb-1"
                                type="button"
                              >
                                <i className="fa fa-check me-2"></i>
                                Accept
                              </button>
                              <button
                                className="card-button dashboard-p3 rounded pt-1 pb-1"
                                disabled={!extra}
                                type="button"
                                onClick={() => setShowModal(true)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="rounded border diseases-diagnose-container">
                          <div className="d-flex justify-content-between align-items-center p-2">
                            <div>
                              <p className="dashboard-p3 fw-bold p-0 m-0">
                                Diagnose
                              </p>
                            </div>
                            <div>
                              <ul className="diseases-list-container">
                                {extra?.diagnoses.map((diagnoses) => (
                                  <li key={diagnoses.id}>{diagnoses.name}</li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          <hr className="m-0 p-0" />
                          <div className="d-flex justify-content-between align-items-center p-2">
                            <div>
                              <p className="dashboard-p3 fw-bold p-0 m-0">
                                Disease
                              </p>
                            </div>
                            <div>
                              <ul className="diseases-list-container">
                                {extra?.diseases.map((diseases) => (
                                  <li key={diseases.id}>{diseases.name}</li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          {extra?.symptoms && extra?.symptoms[0] !== "" ? (
                            <>
                              <hr className="m-0 p-0" />
                              <div className="d-flex justify-content-between align-items-center p-2">
                                <div>
                                  <p className="dashboard-p3 fw-bold p-0 m-0">
                                    Symptoms
                                  </p>
                                </div>
                                <div>
                                  <ul className="diseases-list-container">
                                    {extra.symptoms[0]
                                      ?.split(",")
                                      .map((symptom) => (
                                        <li
                                          key={symptom}
                                          className="dashboard-p3"
                                        >
                                          {symptom}
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>

                        <h6 className="fw-bold main-text pt-4 pb-2 mtc">
                          Consultation Reasons
                        </h6>
                        <div className="rounded border bg-white p-3 mt-2 mb-4 pt-1">
                          <div className="fw-bold main-text pt-2 pb-2 mtc">
                            {reasonName}
                          </div>
                          {apdetail && apdetail?.length > 0 ? (
                            apdetail.map((values, index) => (
                              <div key={index} className="container mt-2">
                                <div className="row">
                                  <div className="col-12">
                                    <div
                                      className="accordion mt-2 mb-2"
                                      id="accordionExample"
                                    >
                                      <div className="accordion-item">
                                        <h2
                                          className="accordion-header"
                                          id={`heading-${index}`}
                                        >
                                          <button
                                            className="accordion-button bg-light text-dark p-2 ps-3"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse-${index}`}
                                            aria-expanded="true"
                                            aria-controls={`collapse-${index}`}
                                          >
                                            {values.question}
                                          </button>
                                        </h2>
                                        <div
                                          id={`collapse-${index}`}
                                          className="accordion-collapse collapse"
                                          aria-labelledby={`heading-${index}`}
                                          data-bs-parent=".accordion"
                                        >
                                          <div
                                            className={`accordion-body p-2 ps-3 ${
                                              values?.answer_image
                                                ? "has_image"
                                                : ""
                                            }`}
                                            style={{
                                              padding: "0px !Important",
                                            }}
                                          >
                                            {values?.question_image ? (
                                              <div className="app_questions_image">
                                                <img
                                                  src={`${baseImageURL}${values?.question_image}`}
                                                  alt=""
                                                />
                                              </div>
                                            ) : null}
                                            {values?.answer_image ? (
                                              <img
                                                src={`${baseImageURL}${values?.answer_image}`}
                                                alt=""
                                              />
                                            ) : (
                                              <p>{values?.answer}</p>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <p>No data available</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* -------------------- Cancel Appointment Modal -------------- */}
          <CancelAppointmentModal
            showModal={showModal}
            setShowModal={setShowModal}
            doctorId={signInUserData.doctor.id}
            appointmentId={extra && extra[0] && extra[0].id}
            setRefresh={() => setRefresh((prev) => !prev)}
          />

          {/* -------------Modal------------ */}
          <div
            className={`modal fade ${isModalOpen ? "show" : ""}`}
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <RescheduleModal closeModal={closeModal} />
          </div>
        </>
      );
    }
  };
  return <>{renderContent()}</>;
}
export default Request1;
