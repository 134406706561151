import React from "react";
import HomeNavbar from "./HomeNavbar";
import "../styles/homepageStyles/HomepageHowToStyles.css";
import g6 from "../assets/homepage-new/g6.webp";
import g1 from "../assets/homepage-new/g1.webp";
import s1 from "../assets/homepage-new/s1.webp";
import s2 from "../assets/homepage-new/s2.webp";
import s3 from "../assets/homepage-new/s3.webp";
import arrow from "../assets/homepage-new/arrow.webp";
import { Link, useNavigate } from "react-router-dom";
import { useApiContext } from "../contextapi/contextApi";
import { useState, useEffect } from "react";

function HomepageHowTo(props) {
  const { signInUserData, instance, setAppointmentType } = useApiContext();
  const [hasDoctors, setHasDoctors] = useState(false);
  const navigate = useNavigate();

  const getPatientDoctors = async () => {
    try {
      const response = await instance.get(
        `/patient/get-doctors-list/${signInUserData.user_id}`
      );
      if (response) {
        if (response.data.data.length === 0) {
          setHasDoctors(false);
        } else {
          setHasDoctors(true);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getPatientDoctors();
  }, []);

  return (
    <>
      <div className="homepage-how-to-container">
        <div className="top-container">
          <section className="section-01">
            <h1 className="up">we're committed to deliver </h1>
            <h1>outstanding dental healthcare </h1>
            <div className="images-gallery">
              <div className="image-wrapper">
                <img src={g1} alt="" />
              </div>
              <div className="image-wrapper">
                <img src={g6} alt="" />
              </div>
            </div>
          </section>
        </div>
        <div className="container">
          <div className="container-wrapper">
            <h1 className="sub">find your dentist and </h1>
            <h1>join video call in three steps</h1>
            <section className="section-02">
              <div className="text">
                <span>1</span>
                <h1>Fill out anamnesis form</h1>
                <p>
                  Fill out anamesis. AI will help you to find the right doctor,
                  select a doctor, make appointment and videocall with doctor.
                </p>
              </div>
              <div className="image">
                <img className="screen" src={s1} alt="" />
                <img className="arrow" src={arrow} alt="" />
              </div>
            </section>
            <section className="section-03">
              <div className="image">
                <img className="arrow" src={arrow} alt="" />
                <img className="screen" src={s2} alt="" />
              </div>
              <div className="text">
                <span>2</span>

                <h1>Looking for the best dentist according to your diagnose</h1>
                <p>
                  Discover the top-notch dentist based on your specific
                  diagnosis.
                </p>
              </div>
            </section>
            <section className="section-04">
              <div className="text">
                <span>3</span>

                <h1>
                  Choose the time to book appointment as per your convenience
                </h1>
                <p>
                  Select a convenient time for your appointment, putting you in
                  control of your dental care journey.
                </p>
              </div>
              <div className="image">
                <img className="screen" src={s3} alt="" />
              </div>
            </section>

            <section className="section-05">
              <div className="text">
                <h1 className="up">Empower your smile:</h1>
                <h1>Book your remote dental consultaion today</h1>
              </div>
              <div className="button-container">
                <button
                  onClick={() => {
                    const url = `${
                      signInUserData && signInUserData?.user_type === "Patient"
                        ? hasDoctors
                          ? "/patient-portal"
                          : "/patient-portal/appointment-question"
                        : signInUserData &&
                          signInUserData?.user_type === "Doctor"
                        ? "/doctor-portal"
                        : "/patient-sign-up"
                    }`;
                    if (url.includes("appointment")) {
                      setAppointmentType("new");
                    }
                    navigate(url);
                  }}
                  className="btn-large gradient-image"
                >
                  {`${
                    signInUserData && signInUserData?.user_type === "Patient"
                      ? "Book An Appointment"
                      : signInUserData && signInUserData?.user_type === "Doctor"
                      ? "Doctor Dashboard"
                      : "Book An Appointment"
                  }`}
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomepageHowTo;
