import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import NewPatientSignup from "./appmodule/patient/NewPatientSignup";
import PatientSecond from "./appmodule/patient/PatientSecond";
import PatientThird from "./appmodule/patient/PatientThird";
import About from "./pages/About";
import Contact from "./pages/ContactUs";
import DataPrivacy from "./pages/DataPrivacy";
import HomePage from "./pages/HomePage";

import NewDoctorSignup from "./appmodule/doctor/NewDoctorSignup";
import Check from "./appmodule/signin/Check";
import NewSignIn from "./appmodule/signin/NewSignin";
import DoctorAppointment from "./components/DoctorAppointment";
import DoctorDetail from "./components/DoctorDetail";
import DoctorList from "./components/DoctorList";
import Availability1 from "./components/doctorportal/Availability1";
import Dashboard1 from "./components/doctorportal/Dashboard1";
import DoctorProfile from "./components/doctorportal/DoctorProfile";
import DoctorRoutes from "./components/doctorportal/DoctorRoutes";
import Patient1 from "./components/doctorportal/Patient1";
import PatientDetail from "./components/doctorportal/PatientDetail";
import Profile1 from "./components/doctorportal/Profile1";
import Request1 from "./components/doctorportal/Request1";
import Schedule1 from "./components/doctorportal/Schedule1";
import AllAppointment from "./components/doctorportal/appointment/AllAppointment";
import AppointmentRouting from "./components/doctorportal/appointment/AppointmentRouting";
import Scheduled from "./components/doctorportal/appointment/Scheduled";
import DoctorRoutes1 from "./components/doctorportal/doctordetail/DoctorRoutes1";
import PatientRoutes from "./components/doctorportal/pateintdetail/PatientRoutes";
import Api from "./subcomponents/Api";
// import Information from "./components/doctorportal/pateintdetail/Information";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { ErrorBoundary } from "react-error-boundary";
import Preview from "./components/Preview";
import PrivateRoute from "./components/PrivateRoute";
import Chat from "./components/chat/Chat";
import DoctorSubscription from "./components/doctorportal/DoctorSubscription";
import AppointmentDetaild from "./components/doctorportal/appointment/AppointmentDetaild";
import RescheduleModal from "./components/doctorportal/appointment/RescheduleModal";
import History1 from "./components/doctorportal/doctordetail/Hstory";
import Records1 from "./components/doctorportal/doctordetail/Records";
import History from "./components/doctorportal/pateintdetail/History";
import LiveMeeting from "./components/livemeeting/LiveMeeting";
import MyDevice from "./components/patientportal/MyDevice";
import MyDoctors from "./components/patientportal/MyDoctor";
import PatientPortalRoutes from "./components/patientportal/PatientPortalRoutes";
import Profile3 from "./components/patientportal/Profile3";
import SubDashFifth from "./components/patientportal/SubDashFifth";
import SubDashFirst from "./components/patientportal/SubDashFirst";
import SubDashFourth from "./components/patientportal/SubDashFourth";
import SubDashSecond from "./components/patientportal/SubDashSecond";
import SubDashThird from "./components/patientportal/SubDashThird";
import History4 from "./components/patientportal/appointment/History4";
import PatientAppointment from "./components/patientportal/appointment/PatientAppointment";
import PatientRoutesAppointment from "./components/patientportal/appointment/PatientRoutesAppoinment";
import { useApiContext } from "./contextapi/contextApi";
import AppointmentQuestions from "./pages/AppointmentQuestions.jsx";
import GPTQuestions from "./pages/AppointmentQuestions.jsx/GPTQuestions.jsx";
import ErrorPage from "./pages/ErrorPage";
import Imprint from "./pages/Imprint.jsx";
import NotFound from "./pages/NotFound";
import InsideQuestions from "./pages/homepages/InsideQuestions";
import ScheduleAppointmentFirst from "./pages/homepages/ScheduleAppointmentFirst";
import Calendar from "./pages/Calendar.jsx";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const { signInUserData } = useApiContext();

  const options = {
    position: "top center",
    timeout: 5000,
    offset: "30px",
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <AlertProvider template={AlertTemplate} {...options}>
        <div>
          {isLoading ? (
            <div className="content-spinner">
              <div className="loader"></div>
            </div>
          ) : (
            <div>
              <BrowserRouter>
                <Routes>
                  {/* doctor portal  */}
                  <Route
                    path="/doctor-portal"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <PrivateRoute type="Doctor">
                          <DoctorRoutes />
                        </PrivateRoute>
                      </ErrorBoundary>
                    }
                  >
                    <Route
                      index
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Doctor">
                            <Dashboard1 />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />

                    <Route
                      path="appointment"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Doctor">
                            <AppointmentRouting />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    >
                      <Route
                        index
                        element={
                          <ErrorBoundary FallbackComponent={ErrorPage}>
                            <PrivateRoute type="Doctor">
                              <Scheduled />
                            </PrivateRoute>
                          </ErrorBoundary>
                        }
                      />
                      <Route
                        path="all-appointment"
                        element={
                          <ErrorBoundary FallbackComponent={ErrorPage}>
                            <PrivateRoute type="Doctor">
                              <AllAppointment />
                            </PrivateRoute>
                          </ErrorBoundary>
                        }
                      />
                    </Route>

                    <Route
                      path="reshduled"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Doctor">
                            <RescheduleModal />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="new-request"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Doctor">
                            <Request1 />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="appointment-detail"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Doctor">
                            <AppointmentDetaild />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />

                    <Route
                      path="my-patients"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Doctor">
                            <Patient1 />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="schedule-timing"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Doctor">
                            <Schedule1 />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />

                    <Route
                      path="availability"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Doctor">
                            <Availability1 />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />

                    <Route
                      path="messages"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Doctor">
                            <Chat />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />

                    <Route
                      path="calendar"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Doctor">
                            <Calendar />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />

                    {/* <Route
                      path="order-list"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute  type='Doctor'>
                            <OrderList />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />

                    <Route
                      path="invoice-list"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute  type='Doctor'>
                            <InvoiceList />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    /> */}
                    {/* 
                    <Route
                      path="/doctor-portal/buy-products"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute  type='Doctor'>
                            <BuyProducts />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    ></Route> */}
                    {/* 
                    <Route
                      path="/doctor-portal/checkout-product"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute  type='Doctor'>
                            <CheckoutProduct />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    ></Route> */}

                    <Route
                      path="profile-setting"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Doctor">
                            <Profile1 />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                  </Route>
                  <Route
                    path="/doctor-personal-detail"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <PrivateRoute>
                          <DoctorRoutes1 />
                        </PrivateRoute>
                      </ErrorBoundary>
                    }
                  >
                    <Route
                      index
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute>
                            <History1 />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="records"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute>
                            <Records1 />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                  </Route>
                  <Route
                    path="/doctor-detail"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <PrivateRoute>
                          <DoctorDetail />
                        </PrivateRoute>
                      </ErrorBoundary>
                    }
                  />

                  <Route
                    path="/patient-detail"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <PrivateRoute type="Doctor">
                          <PatientRoutes />
                        </PrivateRoute>
                      </ErrorBoundary>
                    }
                  >
                    <Route
                      index
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Doctor">
                            <History />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                  </Route>
                  {/* {signInUserData?.user_type == "Doctor" && (
                )} 
                  {/* doctor portal  */}
                  {/* patient portal  */}
                  <Route
                    path="/patient-profile-picture"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <PrivateRoute type="Patient">
                          <PatientSecond />
                        </PrivateRoute>
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/patient-insured"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <PrivateRoute type="Patient">
                          <PatientThird />
                        </PrivateRoute>
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/patient-portal"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <PrivateRoute type="Patient">
                          <PatientPortalRoutes />
                        </PrivateRoute>
                      </ErrorBoundary>
                    }
                  >
                    <Route
                      index
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Patient">
                            <SubDashFirst />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="/patient-portal/appointment-slot"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Patient">
                            <SubDashSecond />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="/patient-portal/my-doctors/:doctorId"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Patient">
                            <Calendar />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="/patient-portal/question-01"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Patient">
                            <SubDashThird />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="/patient-portal/question-02"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Patient">
                            <SubDashFourth />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="/patient-portal/patient-appointment-complete"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Patient">
                            <SubDashFifth />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="my-device"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Patient">
                            <MyDevice />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="appointment"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Patient">
                            <PatientRoutesAppointment />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    >
                      <Route
                        index
                        element={
                          <ErrorBoundary FallbackComponent={ErrorPage}>
                            <PrivateRoute type="Patient">
                              <PatientAppointment />
                            </PrivateRoute>
                          </ErrorBoundary>
                        }
                      />
                      <Route
                        path="completed"
                        element={
                          <ErrorBoundary FallbackComponent={ErrorPage}>
                            <PrivateRoute type="Patient">
                              <History4 />
                            </PrivateRoute>
                          </ErrorBoundary>
                        }
                      />
                    </Route>
                    <Route
                      path="my-doctors"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Patient">
                            <MyDoctors />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="messages"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Patient">
                            <Chat />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="profile-setting"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Patient">
                            <Profile3 />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="appointment-questions"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <AppointmentQuestions />
                        </ErrorBoundary>
                      }
                    />
                    {/* CHAT GPT QUESTIONS */}

                    <Route path="gpt-questions" element={<GPTQuestions />} />

                    {/* CHAT GPT QUESTIONS */}
                    <Route
                      path="inside-question"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Patient">
                            <InsideQuestions />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="schedule-appointment-question"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Patient">
                            <ScheduleAppointmentFirst />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="doctor-list"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Patient">
                            <DoctorList />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="doctor-appointment"
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <PrivateRoute type="Patient">
                            <DoctorAppointment />
                          </PrivateRoute>
                        </ErrorBoundary>
                      }
                    />
                  </Route>

                  <Route
                    path="/patient-detail"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <PrivateRoute type="Patient">
                          <PatientDetail />
                        </PrivateRoute>
                      </ErrorBoundary>
                    }
                  />
                  {/* patient portal  */}
                  {/* website */}
                  <Route
                    path="/"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <HomePage />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/api"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <Api />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/login"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <NewSignIn />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/check-in"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <Check />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/doctor-sign-up"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <NewDoctorSignup />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/patient-sign-up"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <NewPatientSignup />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/doctor-profile"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <DoctorProfile />
                      </ErrorBoundary>
                    }
                  />
                  {/* <Route
                    path="/doctor-waiting"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <DoctorWaiting />
                      </ErrorBoundary>
                    }
                  /> */}
                  <Route
                    path="/subscription-wating"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <DoctorSubscription />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/contact"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <Contact />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/about"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <About />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/dataprivacy"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <DataPrivacy />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/preview"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <Preview />
                      </ErrorBoundary>
                    }
                  />

                  <Route path="/imprint" element={<Imprint />} />

                  {/* website */}
                  {/* live session */}
                  {/* NEED TO PROTECT THIS ROUTE IN THE END */}
                  <Route
                    path="/live-meeting/:channelName/:appointmentId"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <LiveMeeting />
                      </ErrorBoundary>
                    }
                  />

                  {/* live session */}
                  <Route
                    path="*"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <NotFound />
                      </ErrorBoundary>
                    }
                  />
                </Routes>

                {/* NOT FOUND PAGE */}
              </BrowserRouter>
            </div>
          )}
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </AlertProvider>
    </>
  );
}

export default App;
