import React, { useEffect, useState } from "react";
import Desktop from "../../subcomponents/Desktop";
import { useApiContext } from "../../contextapi/contextApi";
import { useNavigate, Link } from "react-router-dom";

import noDoctor from "../../assets/no-doctors.svg";

const MyDoctors = () => {
  const { signInUserData, instance, setAppointmentType } = useApiContext();
  const [patientDoctors, setPatientDoctors] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getPatientDoctors();
  }, []);

  const getPatientDoctors = async () => {
    try {
      const response = await instance.get(
        `/patient/get-doctors-list/${signInUserData.user_id}`
      );
      if (response) setPatientDoctors(response.data.data);

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching checkbox options:", error);
      setIsLoading(false);
    }
  };

  console.log("doctor list: ", patientDoctors.length);

  return isLoading ? (
    <div className="content-spinner">
      <div className="loader"></div>
    </div>
  ) : (
    <>
      {patientDoctors.length === 0 ? (
        <section className="mt-1">
          {console.log("There is no doctor")}
          <div className="no-data-message text-center">
            <img src={noDoctor} alt="no doctors" />
            <h3 className="mt-4">No Available Doctors</h3>
            <button
              onClick={() => {
                setAppointmentType("new");
                navigate("/patient-portal/appointment-questions");
              }}
              className="btn btn-primary mt-4"
            >
              Find a doctor
            </button>
          </div>
        </section>
      ) : (
        patientDoctors.map((doctor) => (
          <Desktop doctor={doctor} path="inside" />
        ))
      )}
    </>
  );
};

export default MyDoctors;
