import React, { useState } from "react";
import "../../styles/dashboard.css";
import { Link, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Logo from "../../assets/homepage/Logo.svg";
import className from "classnames";
import Logout from "./Logout";
import IMO from "../../assets/dashboard/mobile-icon.png";
import { useApiContext } from "../../contextapi/contextApi";
import DefultImg from "../../assets/homepage/profile-img.png";
import Breadcrumbs from "../breadCrumbs";
import DoctorWaiting from "./DoctorWaiting";
import { checkImageUrl } from "../../Utils/Utils";

function DoctorRoutes() {
  const {
    baseImageURL,
    signInUserData,
    newAppointmentRequest,
    userDetail,
    isVerified,
    newMessages,
  } = useApiContext();

  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [activeTab, setActiveTab] = useState("/doctor-portal");
  const [dashboardName, setDashboardName] = useState("Dashboard");
  const [imageurl, setImageurl] = useState(DefultImg);

  const handleTabChange = (tabPath, tabName) => {
    setActiveTab(tabPath);
    setDashboardName(tabName);
  };

  const handleLogoutTabClick = () => {
    setShowLogoutModal(true);
  };

  const handleLogoutConfirm = () => {
    localStorage.removeItem("signInUserData");
    localStorage.removeItem("user-profile");
    localStorage.removeItem("answer_ids");
    localStorage.removeItem("agoraToken");
    localStorage.removeItem("checkproduct-detail");
    window.location.href = "/";
  };

  const handleLogoutModalClose = () => {
    setShowLogoutModal(false);
  };

  let location = useLocation();
  let activeLink = location.pathname;

  const tempImageUrl =
    userDetail?.profile_image !== "null"
      ? `${baseImageURL}${userDetail.profile_image}`
      : signInUserData?.profile_image !== "null"
      ? `${baseImageURL}${signInUserData.profile_image}`
      : DefultImg;

  checkImageUrl(tempImageUrl, function (isValid) {
    if (isValid) {
      setImageurl(tempImageUrl);
    } else {
      setImageurl(DefultImg);
    }
  });

  if (isVerified) {
    console.log("Doctor is verfied");
  } else {
    console.log("Doctor is not verirfied.");
  }

  if (!isVerified) return <DoctorWaiting />;

  return (
    <>
      <div className="overflow-hidden">
        <section className="shadow-sm mono">
          <div className="container-fluid">
            <div className="row g-0 p-2 ps-4 pe-4">
              <div className="col-lg-5 col-md-5 col-sm-12">
                <div>
                  <Link to="/">
                    <img src={Logo} alt="logo-img" className="img-fluid" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="d-flex justify-content-between align-items-baseline mt-2 ms-lg-5">
                  <h5 className="fw-bold mTc main-text48">{dashboardName}</h5>
                  <button
                    class="btn rounded d-block d-sm-none"
                    type="button"
                    style={{ backgroundColor: "rgba(4, 156, 213, 1)" }}
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <i class="fa fa-bars text-white"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container-fluid">
          <div className="row g-0">
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="collapse dont-collapse-sm" id="collapseExample">
                <div className="rounded border text-center p-2 m-3">
                  <div>
                    <img
                      src={imageurl}
                      className="rounded-circle doctorprofilemain"
                      alt=""
                    />
                  </div>
                  <h5 className="fw-bold pt-3">
                    {signInUserData && signInUserData?.first_name}&nbsp;
                    {signInUserData && signInUserData?.last_name}
                  </h5>

                  <p className="dashboard-p3">
                    {signInUserData && signInUserData?.specialist_in.name}
                  </p>
                </div>
                <div className="bg-white rounded border ps-2 me-3 pe-2 ms-3 mono2">
                  <ul id="" role="" className=" list-unstyled">
                    <li className="mt-4">
                      <Link
                        to="/doctor-portal"
                        className="text-decoration-none"
                      >
                        <a
                          className={className("nav-style", {
                            "active-css": activeLink === "/doctor-portal",
                          })}
                          onClick={() =>
                            handleTabChange("/doctor-portal", "Dashboard")
                          }
                        >
                          Dashboard
                        </a>
                      </Link>
                    </li>
                    <li className="mt-4">
                      <Link
                        to="/doctor-portal/appointment"
                        className="text-decoration-none"
                      >
                        <a
                          className={className("nav-style", {
                            "active-css":
                              activeLink == "/doctor-portal/appointment" ||
                              activeLink ==
                                "/doctor-portal/appointment/all-appointment" ||
                              activeLink == "/doctor-portal/appointment-detail",
                          })}
                          onClick={() =>
                            handleTabChange("/doctor-portal", "Appointment")
                          }
                        >
                          Appointment
                        </a>
                      </Link>
                    </li>
                    <li className="mt-4">
                      <Link
                        to="/doctor-portal/new-request"
                        className="text-decoration-none"
                      >
                        <a
                          className={className(
                            "nav-style d-flex justify-content-between align-items-center",
                            {
                              "active-css":
                                activeLink == "/doctor-portal/new-request",
                            }
                          )}
                          onClick={() =>
                            handleTabChange("/doctor-portal", "New Request")
                          }
                        >
                          New Request{" "}
                          {newAppointmentRequest ? (
                            <span className="new_appointment_notification">
                              {newAppointmentRequest}
                            </span>
                          ) : null}
                          <span class="badge rounded-circle badge-doctor-routes">
                            {/* 1 */}
                          </span>
                        </a>
                      </Link>
                    </li>
                    <li className="mt-4">
                      <Link
                        to="/doctor-portal/my-patients"
                        className="text-decoration-none"
                      >
                        <a
                          className={className("nav-style", {
                            "active-css":
                              activeLink == "/doctor-portal/my-patients",
                          })}
                          onClick={() =>
                            handleTabChange("/doctor-portal", "My Patients")
                          }
                        >
                          My Patients
                        </a>
                      </Link>
                    </li>
                    {/* <li className="mt-4">
                    <Link
                      to="/doctor-portal/schedule-timing"
                      className="text-decoration-none"
                    >
                      <a
                        className={className("nav-style", {
                          "active-css":
                            activeLink == "/doctor-portal/schedule-timing",
                        })}
                        onClick={() =>
                          handleTabChange("/doctor-portal", "Schedule Timing")
                        }
                      >
                        Schedule Timing
                      </a>
                    </Link>
                  </li> */}

                    <li className="mt-4">
                      <Link
                        to="/doctor-portal/messages"
                        className="text-decoration-none"
                      >
                        <a
                          className={className(
                            "nav-style d-flex align-items-center justify-content-between",
                            {
                              "active-css":
                                activeLink == "/doctor-portal/messages",
                            }
                          )}
                          onClick={() =>
                            handleTabChange("/doctor-portal", "Messages")
                          }
                        >
                          Messages{" "}
                          {newMessages > 0 ? (
                            <span className="new_appointment_notification">
                              {newMessages}
                            </span>
                          ) : null}
                        </a>
                      </Link>
                    </li>
                    <li className="mt-4">
                      <Link
                        to="/doctor-portal/availability"
                        className="text-decoration-none"
                      >
                        <a
                          className={className("nav-style", {
                            "active-css":
                              activeLink == "/doctor-portal/availability",
                          })}
                          onClick={() =>
                            handleTabChange("/doctor-portal", "Availability")
                          }
                        >
                          Availability
                        </a>
                      </Link>
                    </li>

                    {/* <li className="mt-4">
                      <Link
                        to="/doctor-portal/order-list"
                        className="text-decoration-none"
                      >
                        <a
                          className={className("nav-style", {
                            "active-css":
                              activeLink == "/doctor-portal/order-list"
                          })}
                          onClick={() =>
                            handleTabChange("/doctor-portal", "Order History")
                          }
                        >
                          Order History
                        </a>
                      </Link>
                    </li> */}

                    {/* <li className="mt-4">
                      <Link
                        to="/doctor-portal/invoice-list"
                        className="text-decoration-none"
                      >
                        <a
                          className={className("nav-style", {
                            "active-css":
                              activeLink == "/doctor-portal/invoice-list"
                          })}
                          onClick={() =>
                            handleTabChange("/doctor-portal", "Invoices")
                          }
                        >
                          Invoices
                        </a>
                      </Link>
                    </li> */}

                    {/* <li className="mt-4">
                      <Link
                        to="/doctor-portal/buy-products"
                        className="text-decoration-none"
                      >
                        <a
                          className={className("nav-style", {
                            "active-css":
                              activeLink == "/doctor-portal/buy-products" ||
                              activeLink == "/doctor-portal/checkout-product"
                          })}
                          onClick={() =>
                            handleTabChange("/doctor-portal", "Buy Products")
                          }
                        >
                          Buy Products
                        </a>
                      </Link>
                    </li> */}

                    <li className="mt-4">
                      <Link
                        to="/doctor-portal/profile-setting"
                        className="text-decoration-none"
                      >
                        <a
                          className={className("nav-style", {
                            "active-css":
                              activeLink == "/doctor-portal/profile-setting",
                          })}
                          onClick={() =>
                            handleTabChange("/doctor-portal", "Profile Setting")
                          }
                        >
                          Profile Setting
                        </a>
                      </Link>
                    </li>
                    <li className="mt-4">
                      <Link
                        to="/doctor-portal/calendar"
                        className="text-decoration-none"
                      >
                        <a
                          className={className("nav-style", {
                            "active-css":
                              activeLink == "/doctor-portal/calendar",
                          })}
                          onClick={() =>
                            handleTabChange("/doctor-portal", "Calendar")
                          }
                        >
                          Calendar
                        </a>
                      </Link>
                    </li>
                    <li className="mt-4" style={{ cursor: "pointer" }}>
                      <a className="nav-style" onClick={handleLogoutTabClick}>
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <Logout
              showModal={showLogoutModal}
              onClose={handleLogoutModalClose}
              onConfirm={handleLogoutConfirm}
            />
            <div className="col-lg-9 col-md-8 col-sm-12 mt-3 mono3">
              <div className="me-3">
                <div className="breadcrumb-wrapper-doctor">
                  <Breadcrumbs />
                </div>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoctorRoutes;
