import { useRef, useState, useEffect } from "react";
import { ImSpinner8 } from "react-icons/im";
import { IoCloseOutline } from "react-icons/io5";
import _, { filter } from "lodash";
import { toast } from "react-toastify";

import { useApiContext } from "../contextapi/contextApi";
import useEscapeKey from "../hooks/useEscapekey";
import defaultImage from "../assets/homepage/profile-img.png";

import "../styles/inviteModal.css";
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default function PatientReferralModal({
  showModal,
  setShowModal,
  patient,
  setRefresh,
}) {
  const { instance, signInUserData, baseImageURL } = useApiContext();
  const [isLoading, setIsLoading] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [doctorList, setdoctorList] = useState();
  const [filteredDoctorList, setFilteredDoctorList] = useState();
  const modalRef = useRef();

  const closeModal = () => setShowModal(false);

  useEscapeKey(closeModal);

  async function getDoctorList() {
    try {
      const { data } = await instance.post("/patient/doctors-list", {
        user_id: patient.id,
        reason_id: 1,
        diagnosis: "Check Up",
      });

      const filteredData = data.data.filter(
        (item) => item.id !== signInUserData.doctor.id
      );

      setdoctorList(filteredData);
      setFilteredDoctorList(filteredData);
    } catch (error) {
      toast.error("Something went wrong while fetching doctors.");
    }
  }

  async function sendReferral(doctor_id) {
    setIsLoading(doctor_id);

    try {
      const { data } = await instance.post("/doctor/invite-patient", {
        doctor_id,
        first_name: patient.first_name,
        last_name: patient.last_name,
        email: patient.email,
        invited_by: signInUserData.doctor.id,
      });
      setRefresh((prev) => !prev);
      toast.success(
        `${patient.first_name} ${patient.last_name} reffered successfully.`,
        { theme: "light" }
      );
      setIsLoading(null);
      closeModal();
    } catch (error) {
      setIsLoading(null);
      toast.error(`Oops! something went wrong.`);
    }
  }

  useEffect(() => {
    getDoctorList();
  }, []);

  const handleFilter = _.debounce(() => {
    const filtered = _.filter(doctorList, (item) => {
      const combinedString =
        item?.user?.first_name.toLowerCase() +
        " " +
        item?.user?.last_name.toLowerCase();

        console.log("Combined string", combinedString)

      return combinedString.includes(searchQuery.toLocaleLowerCase());
    });

    console.log("filtered ", filtered);

    filtered.length
      ? setFilteredDoctorList(filtered)
      : setFilteredDoctorList(doctorList);
  }, 0);

  function handleSearchQueryChange(e) {
    setSearchQuery(e.target.value);
    if (e.target.value === "") {
      setFilteredDoctorList(doctorList);
      return;
    }
    handleFilter();
  }

  return (
    <section
      className={`invite_modal_wrapper ${!showModal && "hide_invite_modal"}`}
    >
      <div
        className="invite_modal_container patient_refer_modal"
        ref={modalRef}
      >
        <div className="invite_modal_header">
          <h3>Refer Patient</h3>
          <IoCloseOutline className="close" onClick={closeModal} />
        </div>
        <div className="invite_modal_subheader">
          {patient?.profile_image ? (
            <img
              src={`${baseImageURL}${patient?.profile_image}`}
              alt={`${patient?.first_name}${patient?.last_name}`}
            />
          ) : (
            <img
              src={defaultImage}
              alt={`${patient?.first_name}${patient?.last_name}`}
            />
          )}
          <div>
            <h3>{`${patient?.first_name}${patient?.last_name}`}</h3>
          </div>
        </div>
        <div className="invite_modal_body">
          <label>
            Name:
            <input
              type="search"
              value={searchQuery}
              onChange={(e) => handleSearchQueryChange(e)}
              placeholder="Enter atleast 3 characters for searching..."
              required
            />
          </label>
          <div className="reffer_modal_doctor_list">
            {filteredDoctorList?.map((item) => (
              <div key={item.id} className="modal_doctor_card">
                {item?.user?.profile_image ? (
                  <img
                    src={baseImageURL + item?.user?.profile_image}
                    alt={`${item.user.first_name + item.user.last_name}`}
                  />
                ) : (
                  <img
                    src={defaultImage}
                    alt={`${patient?.first_name}${patient?.last_name}`}
                  />
                )}
                <div>
                  <h5>{`${item.user.first_name + " " + item.user.last_name}`}</h5>
                  <button
                    className="invite_patient_button"
                    disabled={isLoading === item.id}
                    onClick={() => sendReferral(item.id)}
                  >
                    {isLoading === item.id ? (
                      <ImSpinner8 className="rotate" />
                    ) : (
                      "Refer"
                    )}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="invite_modal_footer">
          <button onClick={closeModal} className="close_invite">
            Close
          </button>
        </div>
      </div>
    </section>
  );
}
