import classnames from "classnames";
import { addDays, format, subDays } from "date-fns";
import React, { useEffect, useState } from "react";
import "react-clock/dist/Clock.css";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import "react-time-picker/dist/TimePicker.css";
import { toast } from "react-toastify";
import { getCurrentDateInGermany, parseSpecialization } from "../Utils/Utils";
import Portal17 from "../assets/dashboard/dashboard-patient7.svg";
import Portal16 from "../assets/dashboard/dashboard-patient8.svg";
import Doc3 from "../assets/homepage/arrow2.png";
import { useApiContext } from "../contextapi/contextApi";
import Desktop from "../subcomponents/Desktop";

function DoctorAppointment({ doctor }) {
  const {
    signInUserData,
    instance,
    apiDoctorData,
    gptQuestions,
    setGptQuestions,
    appointReason,
  } = useApiContext();

  const [phone, setPhone] = useState("");
  const [date, setDate] = useState(getCurrentDateInGermany());
  const [time, setTime] = useState("");
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(getCurrentDateInGermany());
  const [timeSlots, setTimeSlots] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [activeSlot, setActiveSlot] = useState("");
  const [activeDate, setActiveDate] = useState(getCurrentDateInGermany());
  const [dateToday, setDateToday] = useState(getCurrentDateInGermany());
  const [isLoading, setIsLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Next");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [disableScollLeftOnCalendar, setDisableScollLeftOnCalendar] =
    useState(true);

  const doctorSpecialization = parseSpecialization(
    apiDoctorData.specialization
  );

  const handleNextClick = () => {
    setSelectedDate(addDays(selectedDate, 7));
  };

  const handlePreviousClick = () => {
    if (
      !(format(weekDates[0], "yyyy-MM-dd") === format(dateToday, "yyyy-MM-dd"))
    ) {
      setSelectedDate(subDays(selectedDate, 7));
    }
  };

  useEffect(() => {
    if (
      format(weekDates[0], "yyyy-MM-dd") === format(dateToday, "yyyy-MM-dd")
    ) {
      setDisableScollLeftOnCalendar(true);
    } else {
      setDisableScollLeftOnCalendar(false);
    }
  }, [selectedDate]);

  const getWeekDates = (start) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      dates.push(addDays(start, i));
    }
    return dates;
  };
  const weekDates = getWeekDates(selectedDate);

  useEffect(() => {
    // setTimeout(() => {
    getTimeSlots(activeDate);

    // }, 2000);
  }, []);

  // getTimeSlots();

  const getTimeSlots = async (date) => {

    console.log("Date in getTime Slots", date)
    // console.log(date);
    setActiveDate(date);
    // if (date !== "undefined") {
    // const date = new Date();
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Get the day of the week (0-6, where 0 is Sunday)
    const dayOfWeek = date.getDay();

    // Get the day name based on the day of the week
    const dayName = dayNames[dayOfWeek];

    try {
      const response = await instance.post("patient/get-doctor-timings", {
        selected_date: format(date, "yyyy-MM-dd"),
        doctor_id: apiDoctorData.id,
      });

      const { data } = await instance.post("patient/get-available-slots", {
        doctor_id: apiDoctorData.id,
        patient_id: signInUserData.id,
        date: format(date, "yyyy-MM-dd"),
        doctor_timings: response.data.data,
      });

      console.log("Data: ", data);

      setTimeSlots(data.data.timing_slots || []);
    } catch (error) {
      toast.error("Error fetching doctor timings:");
    }
  };

  const bookAppointment = async () => {
    setIsLoading(true);
    setButtonText("Processing");
    try {
      const response = await instance.post("/create-appoinment", {
        doctor_id: apiDoctorData.id,
        appoint_time: time,
        appoint_date: activeDate,
        patient_id: signInUserData.user_id,
        ...gptQuestions,
      });

      setGptQuestions(null);

      if (response.data.statusCode === 201) {
        navigate("/patient-portal");
        setIsButtonDisabled(true); // Disable the button on success
      }
    } catch (error) {
      toast.warn(error.response.data.message);
    } finally {
      setIsLoading(false);
      setButtonText("Next");
    }
  };

  const handleSlot = (slot) => {
    setActiveSlot(slot);
    setTime(slot);
  };

  if (!signInUserData) {
    <div className="content-spinner">
      <div className="loader"></div>
    </div>;
  }
  const SingleProfile = (doctor) => {
    navigate("/doctor-detail");
  };

  console.log("Doctor Appointment Component.");

  return (
    <>
      <section className="pt-3">
        <div className="container mt-4">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="d-flex mt-4 mb-3">
                <button className="ghost_button" onClick={() => navigate(-1)}>
                  <img
                    src={Doc3}
                    alt="arrow-img"
                    className="img-fluid main-img9"
                  />
                </button>
                <div className="ms-lg-4 ps-lg-3 ps-2 mt-1">
                  {/* <h4 className="main-text mTc fw-bold dcotprdetaolhead">Doctor Profile</h4> */}
                  <h4 className="pro1 mTc">Doctor Profile</h4>
                </div>
              </div>
              <Desktop doctor={apiDoctorData} showButton={false} />
            </div>
          </div>
        </div>

        <div className="container doctor-appointment mt-5 ps-5 pe-5 rounded pt-4 mb-5">
          <div className="mb-2 mt-2">
            <div className="row align-items-center">
              <div
                style={{ opacity: disableScollLeftOnCalendar ? 0.5 : 1 }}
                className="col-lg-1 col-md-2 col-sm-12 transition"
              >
                <button
                  onClick={handlePreviousClick}
                  className="bg-transparent"
                  style={{ border: "1px soild var(--main-bg-color)" }}
                >
                  <img src={Portal16} alt="patient-img" />
                </button>
              </div>
              <div className="col-lg-10 col-md-8 col-sm-12">
                <div className="date-list mt-2">
                  {weekDates.map((date, index) => (
                    <div
                      key={index}
                      className={classnames(
                        "date-item",
                        "custom-cursor",
                        "rounded",
                        "p-2",
                        {
                          "active-date":
                            activeDate &&
                            format(date, "yyyy-MM-dd") ===
                              format(activeDate, "yyyy-MM-dd"),
                        }
                      )}
                      onClick={() => {
                        getTimeSlots(date);
                        setActiveDate(date);
                      }}
                    >
                      <div className="date">
                        {format(date, "dd")} <br />
                        {format(date, "EE")}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-lg-1 col-md-2 col-sm-12 text-end">
                <button
                  onClick={handleNextClick}
                  className="bg-transparent"
                  style={{ border: "1px soild var(--main-bg-color)" }}
                >
                  <img src={Portal17} alt="patient-img" />
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-5 mb-3">
            <h6 className="mTc fw-bold">Available Slots</h6>

            {timeSlots.length !== 0 ? (
              timeSlots.map((slot) => (
                <div className="col-lg-3 col-md-4 col-sm-12 mt-3" key={slot}>
                  <div class="d-grid gap-2 col-12 mx-auto">
                    <button
                      onClick={() => handleSlot(slot)}
                      className={`rounded border p-2 ${
                        slot === activeSlot ? "active-slot" : ""
                      }`}
                      type="button"
                    >
                      {slot}
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <h3 className="text-center">No Slots Available</h3>
            )}
          </div>
          {timeSlots.length !== 0 ? (
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="d-flex justify-content-center mt-5">
                  <div class="d-grid gap-2 col-12 col-md-4 mt-5 mb-5">
                    <button
                      onClick={bookAppointment}
                      className="fw-bold rounded patient-button2 p-2"
                      type="button"
                      disabled={isLoading || isButtonDisabled}
                    >
                      {isLoading ? "Processing..." : buttonText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </section>
    </>
  );
}
export default DoctorAppointment;
