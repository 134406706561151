import {
  addMinutes,
  eachMinuteOfInterval,
  format,
  isAfter,
  isBefore,
  isEqual,
  isSameMinute,
  parse,
  subMinutes,
} from "date-fns";
import { isArray } from "lodash";
import React, { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import Select from "react-select";
import "react-select-search/style.css";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useApiContext } from "../../contextapi/contextApi";
import "../../styles/availability.css";

// Function to convert time to Date object
const timeToDate = (time) => parse(time, "HH:mm", new Date());
const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const removeTimeRange = (openingTime, closingTime, interval, options) => {
  const startTime = timeToDate(openingTime);
  const endTime = timeToDate(closingTime);

  let intervalArray = eachMinuteOfInterval(
    {
      start: startTime,
      end: endTime,
    },
    { step: interval }
  );

  if (
    !isSameMinute(intervalArray[0], timeToDate(options[0].item)) &&
    options.some((item) =>
      isSameMinute(
        timeToDate(item.item),
        subMinutes(intervalArray[0], interval)
      )
    )
  ) {
    // Remove the first element from intervalArray
    intervalArray.shift();
  }

  if (
    !isSameMinute(
      intervalArray[intervalArray.length - 1],
      timeToDate(options[options.length - 1])
    ) &&
    options.some((item) =>
      isSameMinute(
        timeToDate(item.item),
        addMinutes(intervalArray[intervalArray.length - 1], interval)
      )
    )
  ) {
    // Remove the last element from intervalArray
    intervalArray.pop();
  }

  const filteredOptions = options.filter((option) => {
    const currentTime = timeToDate(option.item);
    return !intervalArray.some((intervalTime) =>
      isSameMinute(intervalTime, currentTime)
    );
  });

  return filteredOptions;
};

const addTimeRange = (openingTime, closingTime, interval) => {
  const startTime = timeToDate(openingTime);
  const endTime = timeToDate(closingTime);

  let intervalArray = eachMinuteOfInterval(
    {
      start: startTime,
      end: endTime,
    },
    { step: interval }
  );

  const stringIntervalArray = intervalArray.map((entry) =>
    format(entry, "HH:mm")
  );

  return stringIntervalArray;
};

function generateTimeArray(interval) {
  if (![15, 30, 60].includes(interval)) {
    throw new Error("Invalid interval. Please provide 15, 30, or 60.");
  }

  const timeArray = [];
  const hoursInDay = 24;
  const minutesInHour = 60;

  for (let hour = 0; hour < hoursInDay; hour++) {
    for (let minute = 0; minute < minutesInHour; minute += interval) {
      const formattedHour = hour.toString().padStart(2, "0");
      const formattedMinute = minute.toString().padStart(2, "0");
      const timeString = `${formattedHour}:${formattedMinute}`;

      timeArray.push({ item: timeString });
    }
  }

  const formatedOptionsObject = {};

  daysOfWeek.forEach((item) => {
    formatedOptionsObject[item] = [timeArray];
  });

  return formatedOptionsObject;
}

const AVAILABILITES_DURATION = [
  { value: 15, item: "15 min" },
  { value: 30, item: "30 min" },
  { value: 60, item: "1 hour" },
];

function Availability1() {
  const { signInUserData, instance } = useApiContext(useApiContext);
  const [options, setOptions] = useState([]); // Store the avialbiltiy options
  const [isLoading, setIsLoading] = useState(false);
  const [timingErrors, setTimingErrors] = useState({}); // Show Error when invalid time is selected
  const [availabityDuration, setAvailabityDuration] = useState(15); // Will store the avialbility duration [15 min, 30 min, 1 hour]
  const [availabilities, setAvailabilities] = useState(
    daysOfWeek.reduce((acc, day) => {
      acc[day] = {
        availabilityCount: 1,
        isOpen: false,
        openingTime: null,
        closingTime: null,
        slotDuration: availabityDuration,
      };
      return acc;
    }, {})
  ); // Will Store the Availablity Payload
  const [initialStateLoading, setInitialStateLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(true);
  const [inValidError, setInValidError] = useState({});
  const [inValidOperation, setInValidOperation] = useState({});

  // DATA FETCHING FUNCTIONS
  const fetchTimeOptions = async (rawData) => {
    // const formattedOptions = response.data.map((item) => ({
    //   item,
    // }));

    // daysOfWeek.forEach((item) => {
    //   formatedOptionsObject[item] = [formattedOptions];
    // });

    if (rawData === null) {
      const slotDuration = 15;
      const formattedOptionsObject = generateTimeArray(slotDuration);
      setAvailabityDuration(slotDuration);
      setOptions(formattedOptionsObject);
      return;
    }

    const slotDuration = Number(rawData["Monday"]?.slotDuration) || 15;

    const formatedOptionsObject = generateTimeArray(slotDuration);

    for (const day in rawData) {
      if (rawData.hasOwnProperty(day)) {
        const availability_count = rawData[day].availabilityCount;

        if (availability_count !== 1)
          Array.from({ length: availability_count }).forEach((item, index) => {
            if (index !== 0) {
              const previous_options_array =
                formatedOptionsObject[day][index - 1];

              formatedOptionsObject[day][index] = removeTimeRange(
                rawData[day].openingTime[index - 1],
                rawData[day].closingTime[index - 1],
                availabityDuration,
                previous_options_array
              );
            }
          });
      }
    }

    setAvailabityDuration(slotDuration);
    setOptions(formatedOptionsObject);
  };

  const fetchUserAvailabilities = async () => {
    instance
      .get(`/doctor/get-timings/${signInUserData.user_id}`)
      .then(async (response) => {
        if (response.data.statusCode === 200) {
          if (response.data.data) {
            if (
              isArray(response.data.data) &&
              response.data.data.length === 0
            ) {
              fetchTimeOptions(null);
              return;
            } else {
              const rawData = response.data.data;

              for (const day in rawData) {
                if (rawData.hasOwnProperty(day)) {
                  // Get the array of timings for the current day
                  const dayTimings = rawData[day];

                  // Add the availabilityCount attribute to each timing object
                  dayTimings.availabilityCount =
                    dayTimings.closingTime.length === 0
                      ? 1
                      : dayTimings.closingTime.length;

                  if (dayTimings.closingTime.length === 0)
                    dayTimings.closingTime = null;
                  if (dayTimings.openingTime.length === 0)
                    dayTimings.openingTime = null;
                }
              }

              fetchTimeOptions(rawData);
              setAvailabilities(rawData);
            }
          } else {
            console.warn("User availability data is not available.");
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching user availabilities:", error);
      });
  };

  const fetchData = async () => {
    await fetchUserAvailabilities();
    setInitialStateLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  //  Whenever Avialbity Update this useEffect check in any inavlid time has been selected or not
  useEffect(() => {
    const validateTimings = () => {
      const errors = {};

      Object.keys(availabilities)?.forEach((day) => {
        const { openingTime, closingTime } = availabilities[day];

        if (openingTime && closingTime) {
          if (openingTime && closingTime)
            openingTime.forEach((item, index) => {
              const closing_time = timeToDate(closingTime[index]);
              const opening_time = timeToDate(item);

              if (
                isAfter(opening_time, closing_time) ||
                isEqual(opening_time, closing_time)
              ) {
                // Opening time is after or equal to closing time
                errors[day] = errors[day] || [];
                errors[day][index] = true;
              } else {
                // Opening time is before closing time
                errors[day] = errors[day] || [];
                errors[day][index] = false;
              }
            });
        }
      });

      return errors;
    };

    setTimingErrors(validateTimings());
  }, [availabilities]);

  const toggleAvailability = (day) => {
    if (availabilities[day]?.isOpen) {
      setAvailabilities({
        ...availabilities,
        [day]: {
          availabilityCount: 1,
          isOpen: false,
          openingTime: null,
          closingTime: null,
          slotDuration: availabityDuration,
        },
      });
    } else {
      setAvailabilities({
        ...availabilities,
        [day]: {
          availabilityCount: 1,
          isOpen: true,
          openingTime: null,
          closingTime: null,
          slotDuration: availabityDuration,
        },
      });
    }
  };

  const addNewAvailability = (day) => {
    const currentIndex = availabilities[day].availabilityCount - 1;
    const activeOptions = options[day][currentIndex];

    let hasTrueValue = false;

    if (
      timingErrors.hasOwnProperty(day) &&
      Array.isArray(timingErrors[day]) &&
      timingErrors[day].length > 0
    ) {
      hasTrueValue = timingErrors[day].some((value) => value === true);
    }

    if (
      options[day][currentIndex][0].item ===
        availabilities[day].openingTime[currentIndex] &&
      options[day][currentIndex][options[day][currentIndex].length - 1].item ===
        availabilities[day].closingTime[currentIndex]
    ) {
      toast.info("All options has been selected!", {
        className: "toast_class",
      });
      return;
    }
    if (hasTrueValue) {
      toast.warn("Please resolve error first.");
      return;
    }

    // Check if user has first filled previus input fields
    // If not then thrwo an return the control
    if (
      !availabilities[day].openingTime ||
      !availabilities[day].closingTime ||
      !availabilities[day].openingTime[currentIndex] ||
      !availabilities[day].closingTime[currentIndex]
    ) {
      toast.warn(
        "Please fill out previous availbility first. Before createing new one."
      );
      return;
    }

    options[day][currentIndex + 1] = removeTimeRange(
      availabilities[day].openingTime[currentIndex],
      availabilities[day].closingTime[currentIndex],
      availabityDuration,
      activeOptions
    );

    availabilities[day].availabilityCount++;
    setRefreshData((prev) => !prev);
  };

  const deleteAvailability = (day, index) => {
    const openingTime = availabilities[day].openingTime[index];
    const closingTime = availabilities[day].closingTime[index];

    if (index === availabilities[day].availabilityCount - 1) {
      if (openingTime) {
        availabilities[day].openingTime.pop();
      }
      if (closingTime) {
        availabilities[day].closingTime.pop();
      }
      options[day].pop();
    } else {
      const timingsToAdd = addTimeRange(
        openingTime,
        closingTime,
        availabityDuration
      );

      options[day] = options[day].map((item) => {
        let uniqueArray = new Set([...item, timingsToAdd]);
        return Array.from(uniqueArray);
      });

      availabilities[day].openingTime.splice(index, 1);

      availabilities[day].closingTime.splice(index, 1);
    }

    availabilities[day].availabilityCount--;
    setRefreshData((prev) => !prev);
  };

  const handleOpeningTimeChange = (option, day, index = 0) => {
    const selectedTime = option ? option.item : null;

    let returnEarly = false;

    const updatedOpeningTime =
      availabilities[day].openingTime !== null
        ? [...availabilities[day].openingTime]
        : [];

    updatedOpeningTime[index] = selectedTime;

    if (availabilities[day].availabilityCount - 1 !== index) {
      setInValidOperation((prev) => ({ ...prev, [day]: true }));
      return;
    } else setInValidOperation((prev) => ({ ...prev, [day]: false }));

    const { openingTime, closingTime } = availabilities[day];
    if (closingTime && closingTime[index]) {
      const errors = {};
      openingTime.forEach((item, i) => {
        if (i === openingTime.length - 1) return;

        if (!isBefore(timeToDate(openingTime[index]), timeToDate(item))) return;

        if (
          isBefore(timeToDate(selectedTime), timeToDate(item)) &&
          (isBefore(
            timeToDate(closingTime[index]),
            timeToDate(closingTime[i])
          ) ||
            isEqual(timeToDate(closingTime[index]), timeToDate(closingTime[i])))
        ) {
          errors[day] = errors[day] || [];
          errors[day][index] = false;
        } else {
          errors[day] = errors[day] || [];
          errors[day][index] = true;
          returnEarly = true;
        }
      });

      setInValidError(errors);
    }

    if (returnEarly) return;

    setAvailabilities({
      ...availabilities,
      [day]: {
        ...availabilities[day],
        openingTime: updatedOpeningTime,
      },
    });
  };

  const handleClosingTimeChange = (option, day, index = 0) => {
    const selectedTime = option ? option.item : null;

    let earlyReturn = false;

    if (availabilities[day].availabilityCount - 1 !== index) {
      setInValidOperation((prev) => ({ ...prev, [day]: true }));
      return;
    } else setInValidOperation((prev) => ({ ...prev, [day]: false }));

    const updatedClosingTime =
      availabilities[day].closingTime !== null
        ? [...availabilities[day].closingTime]
        : [];

    updatedClosingTime[index] = selectedTime;

    const { openingTime, closingTime } = availabilities[day];
    if (openingTime && openingTime[index]) {
      const errors = {};
      openingTime.forEach((item, i) => {
        if (i === openingTime.length - 1) return;

        if (!isBefore(timeToDate(openingTime[index]), timeToDate(item))) return;
        if (
          isBefore(timeToDate(openingTime[index]), timeToDate(item)) &&
          (isBefore(timeToDate(selectedTime), timeToDate(item)) ||
            isEqual(timeToDate(selectedTime), timeToDate(item)))
        ) {
          errors[day] = errors[day] || [];
          errors[day][index] = false;
        } else {
          errors[day] = errors[day] || [];
          errors[day][index] = true;
          earlyReturn = true;
        }
      });
      setInValidError(errors);
    }

    if (earlyReturn) return;

    setAvailabilities({
      ...availabilities,
      [day]: {
        ...availabilities[day],
        closingTime: updatedClosingTime,
      },
    });
  };

  const handleSlotDurationChange = (day, duration) => {
    setAvailabilities({
      ...availabilities,
      [day]: {
        ...availabilities[day],
        slotDuration: duration,
      },
    });
  };

  const addAvailabilities = async () => {
    // Checking if there are any errors
    const isEmpty = Object.keys(timingErrors).length === 0;
    let hasTrueValue = false;

    for (const day in timingErrors) {
      if (timingErrors.hasOwnProperty(day)) {
        if (
          Array.isArray(timingErrors[day]) &&
          timingErrors[day].some((value) => value === true)
        ) {
          hasTrueValue = true;
          break;
        }
      }
    }

    if (isEmpty || hasTrueValue) {
      toast.error("Please resolve any errors before saving availablities.");
      return;
    }

    try {
      setIsLoading(true);
      const response = await instance.post("/doctor/add-timings", {
        user_id: signInUserData.user_id,
        Allavailabilities: availabilities,
      });
      setIsLoading(false);
      Swal.fire({
        title: "Success",
        text: response.data.message,
        icon: "success",
      });
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Please try again.");
    }
  };

  const changeAvailabilityDuration = async (interval) => {
    setOptions(generateTimeArray(interval));
    setAvailabityDuration(interval);
    setAvailabilities(
      daysOfWeek.reduce((acc, day) => {
        acc[day] = {
          availabilityCount: 1,
          isOpen: false,
          openingTime: null,
          closingTime: null,
          slotDuration: interval,
        };
        return acc;
      }, {})
    );
  };

  const defaultOpeningTimes = {};
  const defaultClosingTimes = {};

  // Iterate through each day and set the default values for opening and closing times
  daysOfWeek.forEach((day) => {
    const openingTime = availabilities[day]?.openingTime;
    defaultOpeningTimes[day] = openingTime ? { item: openingTime } : null;

    const closingTime = availabilities[day]?.closingTime;
    defaultClosingTimes[day] = closingTime ? { item: closingTime } : null;
  });

  if (initialStateLoading)
    return (
      <div className="content-spinner">
        <div className="loader"></div>
      </div>
    );

  return (
    <>
      <div className="availability_slots_header mb-4">
        <h4 className="fw-bold mTc pro-set-h4">Manage Working Hours</h4>
        <label>
          <span>Select Appointment Duration</span>
          <select
            value={availabityDuration}
            onChange={(e) => changeAvailabilityDuration(Number(e.target.value))}
          >
            {AVAILABILITES_DURATION.map((item) => (
              <option value={item.value}>{item.item}</option>
            ))}
          </select>
        </label>
      </div>
      {daysOfWeek.map((day) => (
        <div key={day} className="availbality_slots_container">
          <div className="availbality_slots_switch">
            <div className="pt-1 d-flex">
              <div>
                <label className="switch1">
                  <input
                    type="checkbox"
                    onClick={() => toggleAvailability(day)}
                    checked={availabilities[day]?.isOpen}
                  />
                  <span className="slider1 round"></span>
                </label>
              </div>
              <div className="text-center">
                <p
                  className={`fw-bold dashboard-p3 ps-2 ${
                    availabilities[day]?.isOpen ? "mo" : "text-secondary"
                  }`}
                >
                  {day}
                </p>
              </div>
            </div>
          </div>
          <div className="availbality_slots_select" key={refreshData}>
            {availabilities[day]?.isOpen && (
              <>
                {Array(availabilities[day].availabilityCount)
                  .fill(0)
                  .map((item, index) => (
                    <>
                      <strong className="availability_index">
                        {index + 1} Duration
                      </strong>
                      <div className="select_input_wrapper">
                        <Select
                          options={options[day][index]}
                          getOptionLabel={(option) => option.item}
                          value={{
                            item:
                              availabilities[day].openingTime !== null
                                ? availabilities[day].openingTime[index]
                                : null,
                          }}
                          onChange={(option) =>
                            handleOpeningTimeChange(option, day, index)
                          }
                          isClearable={false}
                        />
                      </div>
                      <div className="availabilit_to">
                        <div className="text-center pt-2">
                          <h6 className="fw-bold mo">To</h6>
                        </div>
                      </div>
                      <div className="select_input_wrapper">
                        <Select
                          options={options[day][index]}
                          getOptionLabel={(option) => option.item}
                          value={{
                            item:
                              availabilities[day].closingTime !== null
                                ? availabilities[day].closingTime[index]
                                : null,
                          }}
                          onChange={(option) =>
                            handleClosingTimeChange(option, day, index)
                          }
                          isClearable={false}
                        />
                        {timingErrors[day] && timingErrors[day][index] ? (
                          <span className="availabitlity_select_error">
                            Closing time must be greater than opening time
                          </span>
                        ) : null}
                      </div>
                      {index !== 0 ? (
                        <button
                          onClick={() => deleteAvailability(day, index)}
                          className="delete_availability"
                        >
                          <MdOutlineDelete />
                        </button>
                      ) : (
                        <span></span>
                      )}
                    </>
                  ))}

                <div className="add_new_availability">
                  {inValidError[day] &&
                  inValidError[day].some((error) => !!error) ? (
                    <p className="availability_error_message">
                      <div>
                        Oops! It seems the time slot you've selected contains
                        that duration is already selected. Please choose another
                        available time to avoid conflicts.
                      </div>
                      <button
                        onClick={() => setInValidError({})}
                        className="close_error"
                      >
                        Close
                      </button>
                    </p>
                  ) : null}
                  {inValidOperation[day] && inValidOperation[day] === true ? (
                    <p className="availability_error_message">
                      <div>
                        Hold on! Modifying this slot could impact upcoming
                        slots. To avoid inconsistencies, kindly delete any
                        subsequent slots before making changes here.
                      </div>
                      <button
                        onClick={() => setInValidOperation({})}
                        className="close_error"
                      >
                        Close
                      </button>
                    </p>
                  ) : null}
                  <button onClick={() => addNewAvailability(day)}>
                    Add New Availability
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      ))}
      <div className="mt-3 mb-5 d-flex w-100 justify-content-center align-items-center">
        <button
          disabled={isLoading}
          className="btn register-button2 dashboard-p3 rounded w-50"
          onClick={addAvailabilities}
        >
          {isLoading ? (
            <AiOutlineLoading3Quarters className="chat_spinner" />
          ) : (
            "Save Availabities"
          )}
        </button>
      </div>
    </>
  );
}

export default Availability1;
