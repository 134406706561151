import React, { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useApiContext } from "../../contextapi/contextApi";
import "../../styles/dashboard.css";
import Validation from "../../subcomponents/Validation";

function PatientSignUp() {
  const [maxDate, setMaxDate] = useState(getCurrentDate());
  const { instance } = useApiContext();
  const [agreed, setAgreed] = useState(false);
  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [showEmailSent, setShowEmailSent] = useState(true);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  function handleInput(event) {
    setErrors({});
    const newObj = { ...values, [event.target.name]: event.target.value };
    setValues(newObj);
  }

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  }

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    dob: "",
    gender: "Male",
    phone_no: "",
    insurance: "Privately",
    password: ""
  });
  const [errors, setErrors] = useState({});
  function handleInput(event) {
    setErrors({});
    const newObj = { ...values, [event.target.name]: event.target.value };
    setValues(newObj);
  }

  function handleValidation(event) {
    event.preventDefault();
    const tempErrors = Validation(values, agreed);

    setErrors(tempErrors);
    console.log(tempErrors);

    if (
      "email" in tempErrors ||
      "password" in tempErrors ||
      "dob" in tempErrors ||
      "last_name" in tempErrors ||
      "phone_no" in tempErrors ||
      "first_name" in tempErrors
    ) {
      // console.log("error occured");
    } else {
      setIsLoading(true);
      instance
        .post("/patient/register", values)
        .then((response) => {
          setIsLoading(false);

          console.log("Success:", response.data.data);
          if (response.data.statusCode === 201) {
            // setSignInUserData(response.data.data);
            // navigate("/patient-portal/schedule-appointment-question");
            toast.success(
              " Thank You for Signing Up! A verification email has been sent to your email address."
            );
            setValues({
              first_name: "",
              last_name: "",
              email: "",
              dob: "",
              gender: "Male",
              phone_no: "",
              insurance: "Privately",
              password: ""
            });
          } else {
            toast.error("Something went wrong. Please try again!");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 403) {
            // Change 'statusCode' to 'status'
            const responseData = error.response.data.message;
            const errorMessagesContainer =
              document.getElementById("error-messages");
            errorMessagesContainer.innerHTML = "";
            Object.keys(responseData).forEach((key) => {
              const errorMessage = responseData[key][0];
              switch (key) {
                case "email":
                  if (errorMessage === "The email has already been taken.") {
                    displayErrorMessage(
                      "The email is already taken. Please use a different email."
                    );
                  }
                  break;
                case "phone_no":
                  if (errorMessage === "The phone no has already been taken.") {
                    displayErrorMessage(
                      "The phone number is already exist. Please use a different phone number."
                    );
                  }
                  break;
              }
            });

            function displayErrorMessage(message) {
              const errorMessageElement = document.createElement("div");
              errorMessageElement.className = "error-message";
              errorMessageElement.textContent = message;
              errorMessagesContainer.appendChild(errorMessageElement);
            }
            setIsLoading(false);
          } else {
            toast.error("Something went wrong. Pleas try again!");
            setIsLoading(false);
          }
        });
    }
  }
  return (
    <>
      {!showEmailSent && (
        <div className="email-sent-notification-container">
          <div className="email-sent-notification-container-inner">
            email sent
          </div>
        </div>
      )}
      <div className="form-wrapper">
        <h1>JOIN AS A PATIENT</h1>
        <form onSubmit={handleValidation} className="">
          <div>
            <label for="exampleInputEmail1" className="form-label">
              Name
            </label>
            <div className="row">
              <div className="col-6">
                <input
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  className="form-input"
                  aria-describedby="emailHelp"
                  onChange={handleInput}
                  value={values.first_name}
                />
                {errors.first_name && (
                  <p className="text-danger input-p">{errors.first_name}</p>
                )}
              </div>
              <div className="col-6">
                <input
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  className="form-input"
                  aria-describedby="emailHelp"
                  onChange={handleInput}
                  value={values.last_name}
                />
                {errors.last_name && (
                  <p className="text-danger ps-4 input-p p-0">
                    {errors.last_name}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="mt-2">
            <label for="exampleInputEmail1" className="form-label">
              Email
            </label>
            <div className="d-flex position-relative">
              <input
                type="email"
                placeholder="Email"
                name="email"
                className="form-input"
                aria-describedby="emailHelp"
                onChange={handleInput}
                value={values.email}
              />
            </div>
            {errors.email && (
              <p className="text-danger input-p">{errors.email}</p>
            )}
          </div>
          <div className="mt-3">
            <label htmlFor="phoneInput" className="form-label">
              Phone Number
            </label>
            <div id="survey-form">
              <PhoneInput
                country={"de"}
                className="form-input phone-input"
                value={values.phone_no}
                onChange={(newPhone) => {
                  setPhone(newPhone); // Update the phone state
                  handleInput({
                    target: { name: "phone_no", value: newPhone }
                  }); // Update the values.phone field
                }}
                inputProps={{
                  style: {
                    height: "35px",
                    width: "99%"
                  }
                }}
              />
              <div>
                {errors.phone_no && (
                  <p className="text-danger input-p">{errors.phone_no}</p>
                )}
              </div>
            </div>
          </div>

          <div className="row mt-1">
            <div className="mt-2 col-6">
              <label for="exampleInputEmail1" className="form-label">
                Date of Birth
              </label>
              <div className="flex position-relative">
                <input
                  type="date"
                  placeholder="Select your date of birth"
                  name="dob"
                  className="form-input"
                  aria-describedby="emailHelp"
                  onChange={handleInput}
                  max={maxDate}
                  value={values.dob}
                />
                {errors.dob && (
                  <p className="text-danger input-p">{errors.dob}</p>
                )}
              </div>
            </div>
            <div className="mt-2 col-6">
              <label for="exampleInputEmail1" className="form-label">
                Gender
              </label>
              <div className="custom-select55">
                <select
                  id="gender"
                  name="gender"
                  className="form-input gender"
                  onChange={handleInput}
                  value={values.gender}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
          </div>

          {/* <div className="mt-3">
                <label
                  for="exampleInputEmail1"
                  className="form-label fw-bold mTc"
                >
                  Password
                </label>
                <div className="d-flex position-relative">
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    required
                    className="form-control register-input"
                    aria-describedby="emailHelp"
                    onChange={handleInput}
                  />
                </div>
                {/* {errors.password && (
                  <p className="text-danger input-p">{errors.password}</p>
                )} 

                <p className="text-danger input-p mt-2" id="error-messages"></p>
              </div> */}

          <div className="row mt-3">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <label for="exampleInputEmail1" className="form-label">
                Insurance Type
              </label>
              <div className="d-flex">
                <select
                  onChange={handleInput}
                  name="insurance"
                  className="form-input"
                  aria-label="Default select example"
                  value={values.insurance}
                >
                  <option value="Privately">Privately</option>
                  <option value="Legally">Legally</option>
                  {/* <option value="Both">Both</option> */}
                </select>
              </div>
            </div>
          </div>

          <div className="mt-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <div className="d-flex position-relative">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                id="password"
                className="form-input"
                aria-describedby="emailHelp"
                value={values.password}
                onChange={(e) => {
                  handleInput(e);
                  setPassword(e.target.value);
                }}
              />
              <span
                className="position-absolute end-0 top-50 translate-middle-y"
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? (
                  <i
                    className="fa fa-eye me-3"
                    style={{ color: "var(--main-button-color)" }}
                  ></i>
                ) : (
                  <i
                    className="fa fa-eye-slash me-3"
                    style={{ color: "var(--main-button-color)" }}
                  ></i>
                )}
              </span>
            </div>
            {errors.password && (
              <p className="text-danger input-p">{errors.password}</p>
            )}
            <p className="text-danger input-p mt-4" id="error-messages"></p>
          </div>

          <div className=" mt-3 d-grid gap-2 pb-4">
            {/* <button
                        className="register-button2 rounded"
                        type="submit"
                      >
                        Sign Up
                      </button> */}
            {/* <input
              style={{ textDecoration: "none" }}
              type="submit"
              className="btn-small color-gradient"
              value="Join As A Patient"
            /> */}
            <button
              style={{ textDecoration: "none" }}
              disabled={isLoading}
              type="submit"
              className="btn-small color-gradient"
            >
              {isLoading ? (
                <AiOutlineLoading3Quarters className="chat_spinner" />
              ) : (
                "Join As A Patient"
              )}
            </button>
          </div>
        </form>
        <div className="buttons-container">
          <span>Already a member?</span>
          <Link to="/login">
            <button>Login To Patient Area</button>
          </Link>
        </div>
      </div>
    </>
  );
}
export default PatientSignUp;
