import React from "react";
import Logo from "../../assets/register/Logo.svg";
import Button from "../../subcomponents/Button";

function RegisterNavbar() {
  return (
    <>
      {/* <section className="register-navbar">
      <div className="container">
        <div className="row g-0">
          <div className="col-lg-2 col-md-2 col-sm-12 pb-3 pt-3">
            <div className="text-lg-end text-center">
              <img src={Logo} alt="logo-img" />
            </div>
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12"></div>
          <div className="col-lg-3 col-md-3 col-sm-12  pb-3 pt-3">
            <div className="d-flex justify-content-center align-items-baseline sm-mt-3">
              <h6 className="fw-bold dashboard-p3 me-3 mTc">Help</h6>

              <Button name="Join as a Doctor" />
            </div>
          </div>
        </div>
      </div>
    </section> */}

      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container col-lg-12 ">
          <div className="col-lg-3">
            <a className="navbar-brand ms-1" href="/">
              <img src={Logo} alt="logo-img" />
            </a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="d-flex">
            <div className="collapse navbar-collapse col-lg-9" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li style={{ display: "flex", justifyContent: "center", alignItems: "center" }}> <h6 className="fw-bold dashboard-p3" >Help</h6></li>
                <li className="nav-item ms-lg-3">
                  <Button name="Join as a Doctor" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>

  );
}

export default RegisterNavbar;
