import { useRef, useState } from "react";
import { ImSpinner8 } from "react-icons/im";
import { IoCloseOutline } from "react-icons/io5";
import { toast } from "react-toastify";

import { useApiContext } from "../contextapi/contextApi";
import useEscapeKey from "../hooks/useEscapekey";

import "../styles/inviteModal.css";
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default function InviteModal({
  showModal,
  setShowModal,
  appointmentId,
  setRefresh
}) {
  const { instance, signInUserData } = useApiContext();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const modalRef = useRef();

  const closeModal = () => setShowModal(false);

  useEscapeKey(closeModal);

  async function inviteByEmail() {
    if (name === "") {
      toast.error("Name Feild cannot be empty.");
      return;
    }

    if (!emailRegex.test(email)) {
      toast.error("Invalid email");
      return;
    }
    setIsLoading(true);

    try {
      const { data } = await instance.post("/guest/send-guest-invite", {
        name,
        email,
        appointmentId,
        user_id: signInUserData.user_id,
      });
      setRefresh(prev => !prev)
      toast.success(`Invite sent to ${name} successfully.`, { theme: "light" });
      setIsLoading(false);
      closeModal();
    } catch (error) {
      setIsLoading(false);
      toast.error(`Oops! something went wrong.`);
    }
  }

  return (
    <section
      className={`invite_modal_wrapper ${!showModal && "hide_invite_modal"}`}
    >
      <div className="invite_modal_container" ref={modalRef}>
        <div className="invite_modal_header">
          <h3>Invite meeting</h3>
          <IoCloseOutline className="close" onClick={closeModal} />
        </div>
        <div className="invite_modal_body">
          <label>
            Name:
            <input
              type="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter name..."
              required
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email..."
              required
            />
          </label>
        </div>
        <div className="invite_modal_footer">
          <button onClick={closeModal} className="close_invite">
            Close
          </button>
          <button
            className="submit_invite"
            disabled={isLoading}
            onClick={() => inviteByEmail()}
          >
            {isLoading ? <ImSpinner8 className="rotate" /> : "Invite"}
          </button>
        </div>
      </div>
    </section>
  );
}
