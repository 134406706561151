import React from "react";
import "../../../styles/dashboard.css";
import { Link, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import classNames from "classnames"; // Update the import

function PatientRoutesAppointment() {
  let location = useLocation();
  let activeLink = location.pathname;

  return (
    <>
     <div className="me-3 pe-2">
              <ul className="nav-tabs">
                {/* Apply the 'nav-tabs' class */}
                <li className="mt-1">
                  <Link
                    to="/patient-portal/appointment"
                    className="text-decoration-none"
                  >
                    <a
                      className={classNames("nav-style1 fw-bold", {
                        active: activeLink === "/patient-portal/appointment",
                      })}
                    >
                      All Appointment
                    </a>
                  </Link>
                </li>
                <li className="mt-1">
                  <Link
                    to="/patient-portal/appointment/completed"
                    className="text-decoration-none"
                  >
                    <a
                      className={classNames("nav-style1 fw-bold", {
                        active:
                          activeLink === "/patient-portal/appointment/completed",
                      })}
                    >
                      Completed
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="row g-0">
          <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
            <Outlet />
          </div>
        </div>
    </>
  );
}

export default PatientRoutesAppointment;
