import React from "react";
import { Link } from "react-router-dom";
import d1 from "../../assets/homepage-new/d1.webp";
import d2 from "../../assets/homepage-new/d2.webp";
import d3 from "../../assets/homepage-new/d3.webp";
import g2 from "../../assets/homepage-new/g2.webp";
import headerImage3 from "../../assets/about/header_image_3.jpg";
import headerImage2 from '../../assets/about/about_gallery_1.jpg'
import headerImage4 from '../../assets/about/header_image_4.jpg';
import { useApiContext } from "../../contextapi/contextApi";
import "../../styles/homepageStyles/HomepageForDoctorsStyles.css";

function HomepageForDoctors() {
  const { signInUserData } = useApiContext();

  return (
    <>
      <div className="homepage-for-doctors-container-outer about_page_header">
        <div className="homepage-for-doctors-container">
          <div className="text">
            <h1>
              Revolutionizing Dental Care: Your Virtual Connection to Smiles
            </h1>
            <h3>Empowering Smiles, Anytime, Anywhere</h3>
            <p>
              Your virtual gateway to dental excellence – connecting you with
              experienced dentists for remote consultations, 24/7.
            </p>
          </div>
          <div className="images">
            <div className="image-container-01">
              <div className="image-wrapper">
                <img src={d2} alt="" />
              </div>
              <div className="image-wrapper">
                <img src={headerImage3} alt="" />
              </div>
            </div>
            <div className="image-container-02">
              <div className="image-wrapper">
                <img src={headerImage2} alt="" />
              </div>
              <div className="image-wrapper">
                <img src={headerImage4} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomepageForDoctors;
