import React from "react";
import HomeNavbar from "./HomeNavbar";
import "../styles/homepageStyles/HomepageAppStyles.css";
import s1 from "../assets/homepage-new/s1.webp";
import s2 from "../assets/homepage-new/s2.webp";
import s3 from "../assets/homepage-new/s3.webp";
import store1 from "../assets/homepage-new/store-1.webp";
import store2 from "../assets/homepage-new/store-2.webp";

function HomepageHeader(props) {
  return (
    <>
      <div className="homepage-app-container">
        <div className="container">
          <div className="text">
            <h1>seamless smiles on-the-go</h1>
            <h2>download our remote dental care app today!</h2>
          </div>
          <div className="screens-container">
            <div className="image-wrapper-1">
              <img src={s1} alt="" />
            </div>
            <div className="image-wrapper-2">
              <img src={s2} alt="" />
            </div>
            <div className="image-wrapper-3">
              <img src={s3} alt="" />
            </div>
          </div>
          <div className="app-store">
            <div className="image-wrapper-4">
              <img src={store1} alt="" />
            </div>
            <div className="image-wrapper-4">
              <img src={store2} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomepageHeader;
