import React, { useEffect, useState } from "react";
import Portal7 from "../../../assets/dashboard/Rectangle1.png";
import Portal8 from "../../../assets/dashboard/Eye1.svg";
import Apo from "../../../assets/dashboard/apo.png";
import { useApiContext } from "../../../contextapi/contextApi";
import defultimag from "../../../assets/homepage/profile-img.png";
import { Link } from "react-router-dom";
import AppointmentDetaild from "./AppointmentDetaild";

function AllAppointment() {
  const [appointments, setAppointments] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const {
    baseImageURL,
    signInUserData,
    instance,
    setAppDetail,
    setApQuestions,
  } = useApiContext();

  useEffect(() => {
    getAllDoctorAppointments();
  }, []);

  const getAllDoctorAppointments = async () => {
    try {
      const response = await instance.get(
        `/doctor/appoinment-list/${signInUserData.user_id}`
      );
      console.log(response.data.data, "appontment list doctor");
      setAppointments(response.data.data);
      setIsLoading(false);

      if (response.data.data.length === 0) {
        setShowNoDataMessage(true);
      }
    } catch (error) {
      console.error("Error fetching checkbox options:", error);
    }
  };

  const getAllAppointmentsDetail = async (id) => {
    try {
      const response = await instance.get(
        `/doctor/get-appoinment-details/${id}`
      );
      setAppDetail(response);
      const patientData = response?.data?.data?.questions;
      if (patientData) {
        setApQuestions(patientData);
      }
    } catch (error) {
      console.error("Error fetching checkbox options:", error);
    }
  };

  const formatDate = (dateString) => {
    const inputDate = new Date(dateString); // Replace this with your input date

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    const dayOfWeek = daysOfWeek[inputDate.getDay()];
    const month = months[inputDate.getMonth()];
    const dayOfMonth = inputDate.getDate();

    const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}`;
    return formattedDate;
  };

  return (
    <>
      <div className="container-fluid">
        {isLoading ? (
          <div className="content-spinner">
            <div className="loader"></div>
          </div>
        ) : (
          <div>
            {showNoDataMessage ? (
              <div className="no-data-message text-center">
                <img src={Apo} alt="" className="img-fluid mt-5" width={500} />
              </div>
            ) : (
              <div className="container-fluid">
                <div className="row mb-3 g-0">
                  <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end">
                    <div>
                      <input
                        type="date"
                        id="birthday"
                        name="birthday"
                        className="main-input55  rounded border border-muted"
                        placeholder="Select your birthday"
                      />
                    </div>
                  </div>
                </div>

                <table class="table table-white border table-hover">
                  <thead>
                    <tr>
                      <th scope="col" className="invoice-th">
                        Patient Name
                      </th>
                      <th scope="col" className="invoice-th">
                        Scheduled Time
                      </th>

                      <th scope="col" className="invoice-th">
                        Status
                      </th>
                      <th scope="col" className="invoice-th">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {appointments &&
                      appointments?.map((item) => (
                        <tr className="align-middle">
                          <th scope="row" className="invoice-th">
                            <div className="d-flex pt-2">
                              <div>
                                <img
                                  src={
                                    `${baseImageURL}${item.patient.user.profile_image}` ||
                                    defultimag
                                  }
                                  alt=""
                                  className="rounded-circle border"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    objectFit: "cover",
                                    objectPosition: "center"
                                  }}
                                />
                              </div>
                              <div className="d-flex ms-2">
                                <ul className="list-unstyled">
                                  <li className="fw-bold dashboard-th">
                                    {item?.patient?.user?.first_name}&nbsp;
                                    {item?.patient?.user?.last_name}
                                  </li>
                                  <li className="fw-light  dashboard-th">
                                    PT{item.patient.user.id}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </th>
                          <td className="invoice-li">
                            <ul className="list-unstyled m-0 p-0">
                              <li className="dashboard-th">
                                {formatDate(item?.created_at)}
                              </li>
                              <li className="dashboard-th">
                                {item?.latest_appoinment?.start_time} -{" "}
                                {item?.latest_appoinment?.end_time}
                              </li>
                            </ul>
                          </td>

                          <td className="invoice-li">
                            <ul className="list-unstyled">
                              <li className="dashboard-th">
                                {item.status === "Accepted By User" ? (
                                  <p className="upcoming">Upcoming</p>
                                ) : item.status === "Pending" ? (
                                  <p className="pendingupcoming">Pending</p>
                                ) : item.status === "Cancel" ? (
                                  <p
                                    style={{
                                      color: "red",
                                      fontFamily: "Montserrat"
                                    }}
                                  >
                                    Cancelled
                                  </p>
                                ) : item.status === "Completed" ? (
                                  <p className="completed">Completed</p>
                                ) : null}
                              </li>
                            </ul>
                          </td>

                          <td className="invoice-li">
                            <Link to="/doctor-portal/appointment-detail">
                              <img
                                src={Portal8}
                                onClick={() =>
                                  getAllAppointmentsDetail(item.id)
                                }
                                alt="eye-img"
                              />
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default AllAppointment;
