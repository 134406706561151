import React from "react";
import { useEffect, useState } from "react";
import Logo from "../assets/homepage/Logo.svg";
import { Link, useNavigate } from "react-router-dom";
import Facebook from "../assets/homepage-new/facebook.svg";
import Instagram from "../assets/homepage-new/instagram.svg";
import Linkedin from "../assets/homepage-new/linkedin.svg";
import email from "../assets/homepage-new/email.svg";
import phone from "../assets/homepage-new/phone.svg";
import "../styles/homepageStyles/HomeNavbarStyles.css";
import { FaBars } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { IoMdArrowDropdown, IoIosMail, IoMdGlobe } from "react-icons/io";

import { useApiContext } from "../contextapi/contextApi";

function HomeNavbar({ imprint = true }) {
  const { signInUserData, userDetail, baseImageURL, setSignInUserData } =
    useApiContext();
  const [isMenuHidden, setIsMenuHidden] = useState(true);
  const [showLogoutContainer, setShowLogoutContainer] = useState(false);

  const [isWidthSmall, setIsWidthSmall] = useState(window.innerWidth < 768);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("signInUserData");
    localStorage.removeItem("user-profile");
    localStorage.removeItem("answer_ids");
    setSignInUserData(null);
    navigate("/");
  };
  useEffect(() => {
    const handleResize = () => {
      setIsWidthSmall(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]); // Add window.innerWidth to the dependency array

  const handleIconClick = () => {
    setIsMenuHidden(!isMenuHidden);
  };
  const location = useLocation();

  useEffect(() => {
    const allLinks = document.getElementById("site-links-highlight");
    if (allLinks) {
      const linkArray = allLinks.children;
      if (location.pathname == "/") {
        linkArray[0].classList.add("border-highlight");
      } else if (location.pathname == "/about") {
        linkArray[1].classList.add("border-highlight");
      } else if (location.pathname == "/contact") {
        linkArray[2].classList.add("border-highlight");
      }
    }
  }, []);

  return (
    <>
      {isWidthSmall ? (
        <div className="homepage-navbar-container-small">
          <div className="logo-container">
            <Link className="navbar-brand ms-1" to="/">
              <img src={Logo} alt="logo-img" />
            </Link>
          </div>
          <div className="buttons-container">
            <FaBars className="menu-icon" onClick={handleIconClick} />
          </div>
          <div className={`menu-small ${isMenuHidden ? "hide" : ""}`}>
            <div className="nav-buttons-container">
              <Link to="/">
                <button>Home</button>
              </Link>
              <Link to="/about">
                <button>About</button>
              </Link>
              <Link to="/contact">
                <button>Contact</button>
              </Link>
            </div>
            <div className="join-buttons-container">
              <Link
                to={`${
                  signInUserData && signInUserData?.user_type === "Patient"
                    ? "/patient-portal"
                    : "/patient-sign-up"
                }`}
              >
                <button className="btn-small color-p1">Patient Area</button>
              </Link>
              <Link
                to={`${
                  signInUserData && signInUserData?.user_type === "Doctor"
                    ? "/doctor-portal"
                    : "/doctor-sign-up"
                }`}
              >
                <button className="btn-small color-p2">Doctor Area</button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="homepage-navbar-container">
          <div className="logo-container">
            <Link className="navbar-brand ms-1" to="/">
              <img src={Logo} alt="logo-img" />
            </Link>
          </div>
          <div className="navbar-wrapper">
            <div className="over-the-nav">
              <span className="email">
                {" "}
                <span>
                  {" "}
                  <img
                    className="email-phone-logo"
                    src={email}
                    alt="facebook-logo"
                  />
                </span>
                <a
                  href="mailto:info@dental-telecare.com"
                  class="text-white text-decoration-none"
                >
                  info@dental-telecare.com
                </a>
              </span>
              {/* <span className="phone">
                <span>
                  <img
                    className="email-phone-logo"
                    src={phone}
                    alt="facebook-logo"
                  />
                </span>{" "}
                123456789
              </span> */}
              {imprint ? (
                <div className="social-container">
                  <a href="mailto:contact@bc-conulting-llc.com">
                    <span>
                      <IoIosMail />
                    </span>
                  </a>
                  <a
                    href="http://www.bc-consulting-llc.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      <IoMdGlobe />
                    </span>
                  </a>
                </div>
              ) : (
                <div className="social-container">
                  <div>
                    <img
                      className="social-logo"
                      src={Facebook}
                      alt="facebook-logo"
                    />
                  </div>
                  <div>
                    <img
                      className="social-logo"
                      src={Instagram}
                      alt="facebook-logo"
                    />
                  </div>
                  <div>
                    <img
                      className="social-logo"
                      src={Linkedin}
                      alt="facebook-logo"
                    />
                  </div>
                </div>
              )}

              <div className="username">
                {signInUserData ? (
                  <div className="profile-container">
                    <img
                      src={`${baseImageURL}${signInUserData?.profile_image}`}
                      alt="Profile"
                      onClick={() => setShowLogoutContainer((e) => !e)}
                    />
                    <span onClick={() => setShowLogoutContainer((e) => !e)}>
                      {signInUserData?.first_name}
                    </span>
                    <IoMdArrowDropdown
                      onClick={() => setShowLogoutContainer((e) => !e)}
                      className="icon"
                    />
                    {showLogoutContainer && (
                      <div className="logout-button-container">
                        <Link
                          to={`${
                            signInUserData &&
                            signInUserData?.user_type === "Patient"
                              ? "/patient-portal/profile-setting"
                              : "/doctor-portal/profile-setting"
                          }`}
                        >
                          <button className="">Profile</button>
                        </Link>
                        <button className="" onClick={handleLogout}>
                          Logout
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="navbar">
              <div className="navbar-inner">
                <div id="site-links-highlight" className="site-links">
                  <Link to="/">
                    <div>Home</div>
                  </Link>
                  <Link to="/about">
                    <div>About</div>
                  </Link>
                  <Link to="/contact">
                    <div>Contact</div>
                  </Link>
                </div>
                <div className="login-buttons">
                  <Link
                    to={`${
                      signInUserData && signInUserData?.user_type === "Patient"
                        ? "/patient-portal"
                        : "/patient-sign-up"
                    }`}
                  >
                    <button className="btn-small color-p1">Patient Area</button>
                  </Link>
                  <Link
                    to={`${
                      signInUserData && signInUserData?.user_type === "Doctor"
                        ? "/doctor-portal"
                        : "/doctor-sign-up"
                    }`}
                  >
                    <button className="btn-small color-p2">Doctor Area</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HomeNavbar;
