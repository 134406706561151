import { useEffect } from "react";
import Navbar from "../../components/HomeNavbar";
import NewDoctorSignupForm from "./NewDoctorSignupForm";
import "./NewDoctorSignupStyles.css";

import ClientSlider from "../../components/ClientSection";
import Footer from "../../components/HomepageFooter";
import Icons from "../../components/HomepageIcons";
import MoreButtons from "../../components/HomepageMoreButtons";

import { Link } from "react-router-dom";

function DoctorSignUp() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <>
      <div className="doctor-signup-header">
        <Navbar />
        <div className="form-bg">
          <div className="overlay">
            <div className="form-wrapper-outer">
              <NewDoctorSignupForm />
            </div>
          </div>
        </div>
      </div>
      <div className="doctor-signup-about">
        <div className="container">
          <h1>EVEN MORE SUCCESS FOR YOUR PRACTICE</h1>
          <h2>FOR ALL DENTAL SERVICE PROVIDER</h2>
          <p>
            With our software for patients and appointment management, you can
            manage your medical practice more efficiently, relieve your practice
            team and attract new patients.
          </p>
          <div className="buttons-container">
            <span>Already a member?</span>
            <Link to="/login">
              <button className="btn-small color-gradient">
                Login To Doctor Area
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Icons />
      <ClientSlider />
      <MoreButtons />
      <Footer />
    </>
  );
}
export default DoctorSignUp;
