import { addDays, format, isSameDay, subDays } from "date-fns";
import { useEffect, useState } from "react";
import { ImSpinner8 } from "react-icons/im";
import { IoCaretBackSharp, IoCaretForward } from "react-icons/io5";
import { formatMonthAndDay, getCurrentDateInGermany } from "../Utils/Utils";

import { toast } from "react-toastify";
import { useApiContext } from "../contextapi/contextApi";
import "../styles/calendar.css";
import { useNavigate, useParams } from "react-router-dom";
import useDoctorCalendarStore from "../store/useDoctorCalendarStore";
import defaultImage from "../assets/homepage/profile-img.png";
import { Link } from "react-router-dom";
import { MdCancel } from "react-icons/md";
import { IoIosCheckmarkCircle } from "react-icons/io";

// Function to create an array of dates in week using active date
const getWeekDates = (start) => {
  const weekDates = [];

  for (let i = 0; i < 7; i++) {
    weekDates.push(addDays(start, i));
  }

  return weekDates;
};

const todayDate = getCurrentDateInGermany();

function mapStatus(inputStatus) {
  const statusMapping = {
    Available: "vacant",
    Blocked: "blocked",
    Booked: "booked",
    "Booking Requested": "requested",
    "On Going": "ongoing",
    Expired: "expired",
    Completed: "completed",
  };

  const outputStatus = statusMapping[inputStatus] || inputStatus.toLowerCase();

  return outputStatus;
}

function linkMap(status) {
  const linkObject = {
    Booked: "/doctor-portal/appointment",
    "Booking Requested": "/doctor-portal/new-request",
    "On Going": "/doctor-portal",
    Completed: "/doctor-portal/appointment/all-appointment",
  };

  return linkObject[status];
}

export default function Calendar() {
  const [activeDate, setCurrentActiveDate] = useState(
    getCurrentDateInGermany()
  ); // Inital or Very First Date in every week render
  const [currentDate, setCurrentDate] = useState(getCurrentDateInGermany()); // Currentt Selected date by user
  const [weekDates, setWeekDates] = useState(getWeekDates(activeDate)); // Array that store current visible date at moment
  const { instance, signInUserData, baseImageURL } = useApiContext();
  const [slotsData, setSlotsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [checkedItems, setCheckedItems] = useState([]);
  const [isBlocking, setIsBlocking] = useState(false);
  const [isUnBlocking, setIsUnBlocking] = useState(false);
  const [revalidate, setRevalidate] = useState(false);
  const [isDoc] = useState(signInUserData.user_type === "Doctor");
  const [doctorId, setDoctorId] = useState(null);
  const { doctorId: doctorUserId } = useParams();
  const navigate = useNavigate();
  const [doctor, setDoctor] = useState({});

  async function getDoctorSlotsByDate(doctor_id) {
    try {
      setIsLoading(true);
      if (!doctor_id && !signInUserData?.doctor?.id) {
        navigate("/patient-portal/my-doctors");
      }
      const { data } = await instance.post(
        "/doctor/get-doctor-timings-by-date",
        {
          doctor_id: doctor_id ? doctor_id : signInUserData.doctor.id,
          selected_date: format(currentDate, "yyyy-MM-dd"),
        }
      );
      setSlotsData(data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  async function getDoctorById() {
    try {
      setIsLoading(true);
      const { data } = await instance.get(`/user-verification/${doctorUserId}`);
      const docId = data.data.doctor.id;
      setDoctor(data.data);
      setDoctorId(docId);
      await getDoctorSlotsByDate(docId);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getDoctorById();
  }, []);

  useEffect(() => {
    if (signInUserData.user_type === "Doctor") {
      getDoctorSlotsByDate();
    } else if (doctorId) {
      getDoctorSlotsByDate(doctorId);
    }
  }, [currentDate, revalidate]);

  const handleCheckboxChange = (value) => {
    const isChecked = checkedItems.includes(value);

    if (isChecked) {
      setCheckedItems(checkedItems.filter((item) => item !== value));
    } else {
      setCheckedItems([...checkedItems, value]);
    }
  };

  // Function to handle next date button click
  const handleNextDate = () => {
    const newActiveDate = addDays(activeDate, 7);
    setWeekDates(getWeekDates(newActiveDate));
    setCurrentActiveDate(newActiveDate);
  };

  // Function to handle previous date button click
  const handlePrevDate = () => {
    const newActiveDate = subDays(activeDate, 7);
    setWeekDates(getWeekDates(newActiveDate));
    setCurrentActiveDate(newActiveDate);
  };

  // Function to Block Slots
  async function blockSlots() {
    try {
      setIsBlocking(true);

      // Check if all of the selected elemnet are blocked
      // If not show a toast error
      const blockedTimeSlots = slotsData
        .filter((slot) => slot.status === "Available")
        .map((slot) => slot.time_slot);
      if (
        !checkedItems.every((timeSlot) => blockedTimeSlots.includes(timeSlot))
      ) {
        toast.error("Please select only available slots.");
        setIsBlocking(false);
        return;
      }

      await instance.post("/doctor/block-doctor-timing-slots", {
        doctor_id: signInUserData.doctor.id,
        date: format(currentDate, "yyyy-MM-dd"),
        slots: checkedItems,
      });
      setCheckedItems([]);
      toast.success("Slots Blocked successfully");
      setRevalidate((prev) => !prev);
      setIsBlocking(false);
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
      setIsBlocking(false);
    }
  }

  async function unBlockSlots() {
    try {
      setIsUnBlocking(true);

      // Check if all of the selected elemnet are blocked
      // If not show a toast error
      const blockedTimeSlots = slotsData
        .filter((slot) => slot.status === "Blocked")
        .map((slot) => slot.time_slot);
      if (
        !checkedItems.every((timeSlot) => blockedTimeSlots.includes(timeSlot))
      ) {
        toast.error("Please select only blocked slots.");
        setIsUnBlocking(false);
        return;
      }

      await instance.post("/doctor/unblock-doctor-timing-slots", {
        doctor_id: signInUserData.doctor.id,
        date: format(activeDate, "yyyy-MM-dd"),
        slots: checkedItems,
      });
      setCheckedItems([]);
      toast.success("Slots unblocked successfully");
      setRevalidate((prev) => !prev);
      setIsUnBlocking(false);
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
      setIsUnBlocking(false);
    }
  }

  return (
    <section className="cal_section">
      <div
        className={`cal_section_title ${
          doctorUserId ? "cal_section_for_patient" : ""
        }`}
      >
        {doctorUserId ? (
          <div>
            {doctor?.user?.profile_image ? (
              <img
                src={`${baseImageURL}${doctor?.profile_image}`}
                alt={`${doctor?.first_name} ${doctor?.last_name}`}
              />
            ) : (
              <img src={defaultImage} alt="doctor-image" />
            )}
            <h2>{`${doctor?.first_name} ${doctor?.last_name}`}</h2>
          </div>
        ) : null}
        <h2>
          {formatMonthAndDay(activeDate, "mon") +
            " " +
            activeDate.getFullYear()}
        </h2>
      </div>
      <div className="cal_section_header">
        <button
          onClick={() => handlePrevDate()}
          className={`border cal_nav ${
            isSameDay(todayDate, activeDate) ? "disabled" : ""
          }`}
          disabled={isSameDay(todayDate, activeDate)}
        >
          <IoCaretBackSharp />
        </button>
        <div>
          {weekDates.map((item, index) => (
            <button
              key={item.getDate()}
              onClick={() => setCurrentDate(addDays(activeDate, index))}
              className={`border ${
                isSameDay(currentDate, addDays(activeDate, index))
                  ? "active"
                  : ""
              }`}
            >
              <p>
                <span className="cal_day">
                  {formatMonthAndDay(item, "day")}
                </span>{" "}
                <span className="cal_date">{item.getDate()}</span>{" "}
              </p>
            </button>
          ))}
        </div>
        <button onClick={() => handleNextDate()} className="border cal_nav">
          <IoCaretForward />
        </button>
      </div>
      {isDoc && slotsData?.length !== 0 ? (
        <div className="cal_section_filter">
          <button className="cal_section_unblock_btn" onClick={unBlockSlots}>
            {isUnBlocking ? <ImSpinner8 className="rotate" /> : "UnBlock"}
          </button>
          <button className="cal_section_block_btn" onClick={blockSlots}>
            {isBlocking ? <ImSpinner8 className="rotate" /> : "Block"}
          </button>
        </div>
      ) : null}

      {isLoading ? (
        <div className="calendar_body_loader">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="cal_section_body">
          {!slotsData || slotsData?.length === 0 ? (
            <div className="no_slots">No Slots Available</div>
          ) : (
            slotsData?.map((slot) => {
              let status;

              if (typeof slot.status === "string") {
                status = slot.status;
              } else {
                status = slot.status.status;
              }

              return (
                <label
                  key={slot.time_slot}
                  className={`cal_slot_wrapper ${mapStatus(status)}`}
                >
                  {status === "Expired" && (
                    <MdCancel className="cal_slot_icons expired_icons" />
                    )}
                  {status === "Completed" && (
                    <IoIosCheckmarkCircle className="cal_slot_icons" />
                  )}
                  <div className="cal_slot_box">
                    <h3>{status}</h3>
                    <p>{slot.time_slot}</p>

                    {isDoc &&
                    slot?.status?.appoinment &&
                    slot?.status?.appoinment?.length !== 0
                      ? slot?.status?.appoinment?.map((singleAppointment) => (
                          <div key={singleAppointment.id}>
                            <Link
                              to={linkMap(status)}
                              className="ghost_link appoitnment_in_calendar_wrapper"
                            >
                              {singleAppointment?.patient?.user
                                .profile_image ? (
                                <img
                                  src={`${baseImageURL}${singleAppointment?.patient?.user.profile_image}`}
                                  alt={`${singleAppointment?.patient?.user?.first_name}${singleAppointment?.patient?.user?.last_name}`}
                                />
                              ) : (
                                <img
                                  src={defaultImage}
                                  alt={`${singleAppointment?.patient?.user?.first_name}${singleAppointment?.patient?.user?.last_name}`}
                                />
                              )}
                              <h5>
                                {singleAppointment?.patient?.user?.first_name +
                                  " " +
                                  singleAppointment?.patient?.user?.last_name}
                              </h5>
                            </Link>
                          </div>
                        ))
                      : null}
                  </div>
                  <input
                    className={`clas_slot_checkbox`}
                    disabled={
                      !isDoc ||
                      (slot.status !== "Available" && slot.status !== "Blocked")
                    }
                    type="checkbox"
                    checked={checkedItems.includes(slot.time_slot)}
                    value={slot.time_slot}
                    onChange={() => handleCheckboxChange(slot.time_slot)}
                  />
                </label>
              );
            })
          )}
        </div>
      )}
    </section>
  );
}
