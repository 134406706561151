import { useEffect, useRef, useState } from "react";

import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import PhotoCameraFrontIcon from "@mui/icons-material/PhotoCameraFront";
import { useApiContext } from "../../../contextapi/contextApi";
import {
  useFocusedContext,
  useHostUserContext,
  useRefreshFocused,
} from "./AgoraContext";
import { RenderUserInformation } from "./SingleVideoCard";

export default function VideoFocused({ user }) {
  const focusedVideoRef = useRef(null);
  const { setFocusedUser } = useFocusedContext();
  const [userData, setUserData] = useState(null);
  const [guestData, setGuestData] = useState(null);
  const { isVideoMuted } = useHostUserContext();
  const { instance, baseImageURL, signInUserData, guestUser } = useApiContext();
  const { togglefocused } = useRefreshFocused();

  const enterFullScreen = () => {
    if (focusedVideoRef.current) {
      const videoElement = focusedVideoRef.current.querySelector("video");

      if (videoElement) {
        if (videoElement.requestFullscreen) {
          videoElement.requestFullscreen();
        } else if (videoElement.mozRequestFullScreen) {
          videoElement.mozRequestFullScreen();
        } else if (videoElement.webkitRequestFullscreen) {
          videoElement.webkitRequestFullscreen();
        } else if (videoElement.msRequestFullscreen) {
          videoElement.msRequestFullscreen();
        }
      }
    }
  };

  useEffect(() => {
    const getUserById = async () => {
      try {
        let response;

        response = await instance.get(`/user-verification/${user?.uid}`);

        const data = response.data.data;

        setUserData(data);
      } catch (error) {
        try {
          const response = await instance.get(
            `/guest/get-single-guest-details/${user?.uid}`
          );
          console.log("Response From appointment guest: ", response);

          const data = response.data.data;
          if (!data?.length) setUserData("device");

          setGuestData(data[0]);
        } catch (error) {
          setUserData("device");
        }
      }
    };

    getUserById();
  }, []);

  useEffect(() => {
    console.log("user Effect ran and this is the focused user: ", user);

    if (user?.video && user?.videoTrack) {
      focusedVideoRef.current.innerHTML = null;

      user.videoTrack.play(focusedVideoRef.current);
    }
  }, [user, togglefocused]);

  return (
    <div className="vc_focused">
      {userData === "device" ? (
        <span className="single_video_container_user_title">
          <PhotoCameraFrontIcon />
        </span>
      ) : (
        RenderUserInformation(userData, baseImageURL, guestData)
      )}
      <button className="close_vc_focused" onClick={() => setFocusedUser(null)}>
        Close
      </button>
      <div
        className={`vc_focused_video ${
          user?.client && isVideoMuted ? "d-none" : ""
        }`}
        ref={focusedVideoRef}
      ></div>
      {!user?.client ? (
        <>
          <div className="svc_mic">
            <span className="svc_control_button">
              {user?.audio ? <MicIcon /> : <MicOffIcon />}
            </span>
          </div>
          <div className="svc_volume" onClick={enterFullScreen}>
            <span className="svc_control_button">
              <AspectRatioIcon />
            </span>
          </div>
        </>
      ) : null}
    </div>
  );
}
