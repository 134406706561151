import { useEffect, useRef, useState } from "react";
import { useFocusedContext, useHostUserContext } from "./AgoraContext";

import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { FaUserCircle } from "react-icons/fa";

import PhotoCameraFrontIcon from "@mui/icons-material/PhotoCameraFront";

import { useApiContext } from "../../../contextapi/contextApi";

export default function SingleVideoCard({
  user,
  toggleRemoteAudio,
  appointmentId,
}) {
  const videoRef = useRef();
  const { setFocusedUser, focusedUser } = useFocusedContext();
  const [userData, setUserData] = useState(null);
  const [guestData, setGuestData] = useState(null);
  const [muteAudio, setMuteAudio] = useState(false);
  const { baseImageURL, instance } = useApiContext();
  const { isVideoMuted } = useHostUserContext();

  useEffect(() => {
    const getUserById = async () => {
      try {
        let response;

        response = await instance.get(`/user-verification/${user?.uid}`);

        const data = response.data.data;

        setUserData(data);
      } catch (error) {
        try {
          console.log("user in single video: ", user);

          const response = await instance.get(
            `/guest/get-single-guest-details/${user.uid}`
          );

          const data = response.data.data;
          if (!data?.length) setUserData("device");

          setGuestData(data[0]);
        } catch (error) {
          setUserData("device");
        }
      }
    };

    getUserById();
  }, []);

  console.log("User Data: ", userData);

  useEffect(() => {
    if (user.video && user.videoTrack && focusedUser?.uid !== user?.uid) {
      videoRef.current.innerHTML = null;

      user.videoTrack.play(videoRef.current);
    }
  }, [user, focusedUser]);

  return (
    <div className="svc_wrapper">
      <div
        onClick={() => setFocusedUser(user)}
        className={`svc_container ${
          user?.client && isVideoMuted ? "d-none" : ""
        }`}
        ref={videoRef}
      ></div>
      {userData === "device" ? (
        <span className="single_video_container_user_title">
          <PhotoCameraFrontIcon />
        </span>
      ) : (
        RenderUserInformation(userData, baseImageURL, guestData)
      )}
      {!user?.client ? (
        <>
          <div className="svc_volume">
            <button
              onClick={() => {
                toggleRemoteAudio(user, muteAudio);
                setMuteAudio((prev) => !prev);
              }}
              className="svc_control_button"
            >
              {user.audio ? (
                !muteAudio ? (
                  <VolumeUpIcon />
                ) : (
                  <VolumeOffIcon />
                )
              ) : (
                <VolumeOffIcon />
              )}
            </button>
          </div>
          <div className="svc_mic">
            <span className="svc_control_button">
              {user?.audio ? <MicIcon /> : <MicOffIcon />}
            </span>
          </div>
        </>
      ) : null}
    </div>
  );
}

export function RenderUserInformation(userData, baseImageURL, guestData) {
  if (!userData && !guestData) return null;

  if (guestData)
    return (
      <>
        <h3 className="single_video_container_user_title">
          {guestData.name}
          (Guest)
        </h3>
        <FaUserCircle className="svc_profile_image" />
      </>
    );

  return (
    <>
      <h3 className="single_video_container_user_title">
        {`${userData?.first_name} ${userData?.last_name}`}
      </h3>
      <img
        className="svc_profile_image"
        src={`${baseImageURL}${userData?.profile_image}`}
        alt={userData?.first_name}
      />
    </>
  );
}
