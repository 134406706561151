import React from 'react';

const Logout = ({ showModal, onClose, onConfirm }) => {
  return (
    <div className={`modal ${showModal ? "show" : ""}`} tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content"  style={{marginTop: "-26%"}}>
          <div className="modal-header">
            <h5 className="modal-title fw-bold mTc">Log Out</h5>
            {/* <button type="button" className="close p-2 border border-muted ps-3 pe-3" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button> */}
          </div>
          <div className="modal-body mTc">
            Are you sure you want to log out?
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary dashboard-p3" onClick={onClose}>Cancel</button>
            <button type="button" className="btn logout-btn dashboard-p3" onClick={onConfirm}>Log Out</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
