import classnames from "classnames";
import { addDays, format, subDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getCurrentDateInGermany } from "../../Utils/Utils";
import Portal2 from "../../assets/dashboard/Icon1.svg";
import Portal3 from "../../assets/dashboard/Icon2.svg";
import Portal4 from "../../assets/dashboard/Icon3.svg";
import Portal17 from "../../assets/dashboard/dashboard-patient7.svg";
import Portal16 from "../../assets/dashboard/dashboard-patient8.svg";
import DefultImg from "../../assets/homepage/profile-img.png";
import InviteModal from "../../components/inviteModal";
import { useApiContext } from "../../contextapi/contextApi";
import "./doctorPortalStyles.css";
import GuestList from "../GuestList";

function Dashboard1() {
  const {
    baseImageURL,
    signInUserData,
    instance,
    setTokenChannel,
    newAppointmentRequest,
    setNewAppointmentRequest,
  } = useApiContext();
  const [selectedDate, setSelectedDate] = useState(getCurrentDateInGermany());
  const [appointments, setAppointments] = useState([]);
  const [numberList, setNumberList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeDate, setActiveDate] = useState(getCurrentDateInGermany());
  const [dateToday, setDateToday] = useState(getCurrentDateInGermany());
  const [refreshVar, setRefreshVar] = useState(0);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [disableScollLeftOnCalendar, setDisableScollLeftOnCalendar] =
    useState(true);
  const [refresh, setRefresh] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Increment the counter to trigger a re-render
      setRefreshVar((prevCounter) => prevCounter + 1);
      setRefresh(prev => !prev);
    }, 10000); // 10000 milliseconds = 10 seconds

    return () => clearInterval(intervalId); // Clear the interval if the component unmounts
  }, [refreshVar]); // Include counter in the dependency array

  useEffect(() => {
    const handleBackButton = (e) => {
      e.preventDefault();
      // Add your custom logic here to handle the back button press.
      window.history.pushState("/doctor-portal"); // Navigate to the desired route.
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  useEffect(() => {
    instance
      .get(`/doctor/new-appoinments-requests/${signInUserData.user_id}`)
      .then((response) => {
        if (response.data.statusCode === 200) {
          setNewAppointmentRequest(response?.data?.data?.length);

          if (response?.data?.data?.length === 0) {
            setShowNoDataMessage(true);
          }
        } else {
          toast.error("Something went wrong. Please Refresh and try again.");
        }
      });
    fetchUpcomingAppointments(activeDate);
  }, [refreshVar]);

  console.log("Upcoming Appointments", appointments);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 250);
  }, []);

  const handleNavigateNewRequests = () => {
    navigate("/doctor-portal/new-request");
  };

  const handleNextClick = () => {
    setSelectedDate(addDays(selectedDate, 7));
  };

  const handlePreviousClick = () => {
    if (
      !(format(weekDates[0], "yyyy-MM-dd") === format(dateToday, "yyyy-MM-dd"))
    ) {
      setSelectedDate(subDays(selectedDate, 7));
    }
  };

  useEffect(() => {
    if (
      format(weekDates[0], "yyyy-MM-dd") === format(dateToday, "yyyy-MM-dd")
    ) {
      setDisableScollLeftOnCalendar(true);
    } else {
      setDisableScollLeftOnCalendar(false);
    }
  }, [selectedDate]);

  useEffect(() => {
    fetchDashboardLists();
    fetchUpcomingAppointments(activeDate);
  }, []);

  const fetchUpcomingAppointments = async (date) => {
    try {
      const formData = new FormData();
      formData.append("user_id", signInUserData?.user_id);
      formData.append("date", formatDate(date));
      const response = await instance.post(
        `/doctor/upcoming-appoinments`,
        formData
      );

      const sortedAppointments = response.data.data.sort((a, b, index) => {
        const timeA = a.latest_appoinment.start_time;
        const timeB = b.latest_appoinment.start_time;

        // Assuming the times are in the same day
        const date = new Date();
        const [hoursA, minutesA] = timeA.split(":");
        const [hoursB, minutesB] = timeB.split(":");

        date.setHours(
          (parseInt(hoursA, 10) % 12) + (timeA.includes("PM") ? 12 : 0)
        );
        date.setMinutes(parseInt(minutesA, 10));

        const dateA = new Date(date);

        date.setHours(
          (parseInt(hoursB, 10) % 12) + (timeB.includes("PM") ? 12 : 0)
        );
        date.setMinutes(parseInt(minutesB, 10));

        const dateB = new Date(date);

        console.log(`Appointment ${index}: `, {
          timeA: dateA,
          timeB: dateB,
        });

        return dateA - dateB;
      });

      setAppointments(response.data.data);
    } catch (error) {
      console.error("Error fetching checkbox options:", error);
    }
  };

  const fetchDashboardLists = async () => {
    try {
      const response = await instance.get(
        `/doctor/dashboard/${signInUserData?.user_id}`
      );
      if (response) {
        setNumberList(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching checkbox options:", error);
    }
  };

  // token api //
  const tokenGenerator = async (appointmentId) => {
    try {
      localStorage.setItem("appdid", appointmentId);
      const response = await instance
        .post("/create-appoinment-meeting-link", {
          appoinment_id: appointmentId,
          user_type: signInUserData.user_type,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            setTokenChannel(response?.data?.data);
          }
        });
    } catch (error) {
      console.error("Error fetching token and channel name:", error);
    }
  };
  // ----------//

  const getWeekDates = (start) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      dates.push(addDays(start, i));
    }
    return dates;
  };

  const weekDates = getWeekDates(selectedDate);

  const formatDate = (date) => {
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate.getTime())) {
      const year = parsedDate.getFullYear();
      const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
      const day = String(parsedDate.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    } else {
      // Handle invalid date
      return "";
    }
  };
  // Function to format the selected date to "Month, Year"
  const formatMonthYear = (dateString) => {
    const options = { year: "numeric", month: "long" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <>
      {isLoading ? (
        <div className="content-spinner">
          <div className="loader"></div>
        </div>
      ) : (
        <div>
          <div className="rounded border p-3 mb-3 new-request-doctor-dashboard-card">
            <div className="fw-bold">
              <span>{newAppointmentRequest}</span> Appointment Request
              {newAppointmentRequest === 1 || newAppointmentRequest === 0
                ? ""
                : "s"}
            </div>
            <div className=" gap-2 col-12  ml-4">
              {newAppointmentRequest > 0 && (
                <button
                  className="btn card-button1 dashboard-p3 rounded fw-bold"
                  type="button"
                  onClick={() => {
                    handleNavigateNewRequests();
                    // handleAppointmnetDetail(item.id);
                  }}
                >
                  <i className="fa fa-eye me-2"></i>
                  View All
                </button>
              )}
            </div>
          </div>
          <div className="rounded border p-2 p-lg-3 mb-3">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="d-flex dashboard-main2 pt-1 align-items-start">
                  <img src={Portal2} className="dashboard-icon-img" />

                  <div className="ms-3">
                    <ul className="list-unstyled">
                      <li className="pt-1 portal-p-1">Total Patient</li>
                      <li className="fw-bold portal-p-2">
                        {numberList && numberList.total_patients}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="d-flex dashboard-main2 pt-1 align-items-start">
                  <img src={Portal3} className="dashboard-icon-img" />

                  <div className="ms-3">
                    <ul className="list-unstyled">
                      {/* Fix to be done */}
                      <li className="pt-1 portal-p-1">Today's Appointments</li>
                      <li className="fw-bold portal-p-2">
                        {numberList && numberList.todays_patients}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="d-flex pt-1 align-items-start">
                  <img src={Portal4} className="dashboard-icon-img" />
                  <div className="ms-3">
                    <ul className="list-unstyled">
                      <li className="pt-1 portal-p-1">Total Appointments</li>
                      <li className="fw-bold portal-p-2">
                        {numberList && numberList.total_appoinments}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <h6 className="fw-bold dashboard-h5">Upcoming Appointments</h6>
          </div>
          <div className="rounded border p-3 mt-3">
            <div className="d-flex justify-content-between">
              <div className="d-flex justify-content-between">
                {selectedDate && (
                  <p className="fw-bold dashboard-p">
                    {formatMonthYear(selectedDate)}
                  </p>
                )}
              </div>
              {/* <p className="fw-light dashboard-p">
                <img src={Portal5} alt="" className="pe-1" width={22} />
                Filter
              </p> */}
            </div>
            <div className="mb-2 mt-2">
              <div className="row align-items-center ">
                <div
                  style={{ opacity: disableScollLeftOnCalendar ? 0.5 : 1 }}
                  className="col-lg-1 col-md-2 col-sm-12 transition"
                >
                  <button
                    onClick={handlePreviousClick}
                    className="bg-transparent"
                    style={{ border: "1px soild var(--main-bg-color)" }}
                  >
                    <img src={Portal16} alt="patient-img" />
                  </button>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-4">
                  <div className="date-list">
                    {weekDates.map((date, index) => (
                      <div
                        key={index}
                        className={classnames(
                          "date-item",
                          "custom-cursor",
                          "rounded",
                          "p-2",
                          {
                            "active-date":
                              activeDate &&
                              format(date, "yyyy-MM-dd") ===
                                format(activeDate, "yyyy-MM-dd"),
                          }
                        )}
                        onClick={() => {
                          fetchUpcomingAppointments(date);
                          setActiveDate(date);
                        }}
                      >
                        <div className="date">
                          {format(date, "dd")} <br />
                          {format(date, "EE")}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-lg-1 col-md-1 col-sm-4 mt-1 mb-2 text-lg-end text-md-end">
                  <button
                    onClick={handleNextClick}
                    className="bg-transparent"
                    style={{ border: "1px soild var(--main-bg-color)" }}
                  >
                    <img src={Portal17} alt="patient-img" />
                  </button>
                </div>
              </div>
            </div>

            {appointments &&
              appointments.map((item) => (
                <div className="container">
                  <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-12">
                      <p className="dashboard-p1 pt-1">
                        {item.latest_appoinment.start_time}
                      </p>
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-12">
                      <hr className="dash-hr" />
                      <div className="row rounded dashboard-main3">
                        <div className="col-lg-4 d-flex">
                          <img
                            src={`${baseImageURL}${
                              item?.patient?.user?.profile_image || DefultImg
                            }`}
                            className="rounded-circle"
                            alt=""
                            style={{
                              width: "50px",
                              height: "50px",
                              marginTop: "9.2px",
                              marginBottom: "5px",
                            }}
                          />
                          <div className="ms-3 mt-2">
                            <ul className="list-unstyled">
                              <li className="dashboard-p1 fw-bold mt-1">
                                {item.patient.user.first_name +
                                  " " +
                                  item.patient.user.last_name}
                              </li>
                              <li className="dashboard-p1">
                                {item.latest_appoinment.start_time}
                                {""} {item.latest_appoinment.end_time}
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-lg-8">
                          <div className="text-end pt-3 pb-2">
                            {item.status === "Accepted By User" ||
                            item.status === "ReSchedule" ? (
                              <div className="accepted_reschedule">
                                <button
                                  className="rounded p-1 ps-4 pe-4 dashboard-button1 btn-Upc upcoming-button"
                                  disabled
                                >
                                  Upcoming
                                </button>
                                <button
                                  disabled
                                  title="This button will be enabled 5 minutes before the meeting."
                                  className="rounded p-1 ps-4 pe-4 dashboard-button1 btn-Upc join-now-button"
                                >
                                  Join Now
                                </button>
                              </div>
                            ) : (
                              <div className="accepted_reschedule">
                                <>
                                  <button
                                    className="rounded dashboard modal_toggler_button p-1"
                                    type="button"
                                    onClick={() => setShowModal(true)}
                                  >
                                    Invite user
                                  </button>
                                  <InviteModal
                                    showModal={showModal}
                                    setShowModal={setShowModal}
                                    appointmentId={item.id}
                                    setRefresh={setRefresh}
                                  />
                                </>
                                <Link
                                  to={`/live-meeting/${item.channel_name}/${item?.id}`}
                                >
                                  <button className="rounded p-1 ps-4 pe-4 dashboard-button1 btn-Upc join-now-button">
                                    Join Now
                                  </button>
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                        <GuestList appointmentId={item.id} refresh={refresh} />
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12">
                      <p className="dashboard-p1 pt-4 pt-md-1 pt-lg-1">
                        {item.latest_appoinment.end_time}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            <div></div>
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard1;
