import { useNavigate } from "react-router-dom";
import "./AppointmentModal.css";
import { IoClose } from "react-icons/io5";
import { useApiContext } from "../contextapi/contextApi";

export default function AppointmentModal({
  show,
  setShowApppointmentModal,
  username,
}) {
  const { setAppointmentType } = useApiContext();
  const navigate = useNavigate();
  return show ? (
    <div className="box welcome__modal">
      <div className="modal-container" id="m1-o">
        <div className="modal">
          <button
            className="modal__btn close__btn"
            onClick={() => setShowApppointmentModal(false)}
          >
            <IoClose />
          </button>
          <h1 className="modal__title">
            <span>🌟</span> Welcome {username}! <span>🌟</span>
          </h1>
          <p className="modal__text">
            Our team of experienced dentists is here to provide top-notch care
            tailored to your needs. Booking an appointment is quick and easy.
            Simply click the "Make an Appointment" button below, and let us take
            care of the rest.
          </p>
          <button
            className="modal__btn"
            onClick={() => {
              setShowApppointmentModal(false);
              setAppointmentType("new");
              navigate("/patient-portal/appointment-questions");
            }}
          >
            Make an Appointment
          </button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
