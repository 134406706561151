import { Link, useLocation } from "react-router-dom";
import { useApiContext } from "../../contextapi/contextApi";

export default function AboutCallToActions() {
  const { signInUserData } = useApiContext();
  const location = useLocation();

  const handelScroll = (e) => {
    if (
      e.target.innerText === "Join As A Patient" &&
      location.pathname === "/patient-sign-up"
    ) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }

    if (
      e.target.innerText === "Join As A Doctor" &&
      location.pathname === "/doctor-sign-up"
    ) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="about_call_to_action">
      <div className="about_cta_inner">
        <h1>
          Book your remote dental consultation today and join our professional
          network
        </h1>
        <div className="about_cta_buttons_container">
          <Link
            to={`${
              signInUserData && signInUserData?.user_type === "Patient"
                ? "/patient-portal"
                : "/patient-sign-up"
            }`}
          >
            <button className="btn-large" onClick={(e) => handelScroll(e)}>
              {" "}
              Join As A Patient
            </button>
          </Link>
          <Link
            to={`${
              signInUserData && signInUserData?.user_type === "Doctor"
                ? "/doctor-portal"
                : "/doctor-sign-up"
            }`}
          >
            <button className="btn-large" onClick={(e) => handelScroll(e)}>
              {" "}
              Join As A Doctor
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
