import { createContext, useContext, useEffect, useState } from "react";
import AC from "agora-chat";
import AgoraRTC from "agora-rtc-sdk-ng";

const AgoraChatContext = createContext(null);
const AgoraVideoContext = createContext(null);
const FocusedVideoContext = createContext(null);
const ToggleChatContext = createContext(null);
const HostUserContext = createContext(null);
const RefreshFocusedContext = createContext(null);

export function AgoraChatContextProvider({ children }) {
  const [appKey, setAppkey] = useState("71999694#1166282");
  const [conn, setConn] = useState(null);

  useEffect(() => {
    const connection = new AC.connection({
      appKey,
    });

    setConn(connection);
  }, []);

  return (
    <AgoraChatContext.Provider value={{ conn }}>
      {children}
    </AgoraChatContext.Provider>
  );
}

export function AgoraVideoContextProvider({ children }) {
  const client = AgoraRTC.createClient({
    mode: "rtc",
    codec: "vp8",
  });
  const deviceClient = AgoraRTC.createClient({
    mode: "rtc",
    codec: "vp8",
  });

  return (
    <AgoraVideoContext.Provider value={{ client, deviceClient }}>
      {children}
    </AgoraVideoContext.Provider>
  );
}

export function FocusedContextProvider({ children }) {
  const [focusedUser, setFocusedUser] = useState(null);

  function isFocusedUser(user) {
    if (!user || !focusedUser) return false;
    return focusedUser?.uid === user?.uid ? true : false;
  }

  return (
    <FocusedVideoContext.Provider
      value={{ focusedUser, setFocusedUser, isFocusedUser }}
    >
      {children}
    </FocusedVideoContext.Provider>
  );
}

export function ToggleChatContextProvider({ children }) {
  const [showChat, setShowChat] = useState(false);

  return (
    <ToggleChatContext.Provider value={{ showChat, setShowChat }}>
      {children}
    </ToggleChatContext.Provider>
  );
}

export function HostUserContextProvider({ children }) {
  const [isVideoMuted, setIsVideoMuted] = useState(false);

  return (
    <HostUserContext.Provider value={{ isVideoMuted, setIsVideoMuted }}>
      {children}
    </HostUserContext.Provider>
  );
}

export function RefreshFocusedContextProvider({ children }) {
  const [togglefocused, setTogglefocused] = useState(false);

  return (
    <RefreshFocusedContext.Provider value={{ togglefocused, setTogglefocused }}>
      {children}
    </RefreshFocusedContext.Provider>
  );
}

export const useFocusedContext = () => {
  return useContext(FocusedVideoContext);
};

export const useAgoraVideoContext = () => {
  return useContext(AgoraVideoContext);
};

export const useAgoraChatContext = () => {
  return useContext(AgoraChatContext);
};

export const useToggleChatContext = () => {
  return useContext(ToggleChatContext);
};

export const useHostUserContext = () => {
  return useContext(HostUserContext);
};

export const useRefreshFocused = () => {
  return useContext(RefreshFocusedContext);
};
