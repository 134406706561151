import React, { useState } from "react";
import axios from "axios";

const Api = () => {
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    password: "",
    gender: "",
    phone_no: "",
    email: "",
  });

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    password: "",
    gender: "",
    phone_no: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!state.first_name) {
      newErrors.first_name = "First name is required";
      valid = false;
    } else {
      newErrors.first_name = "";
    }

    if (!state.last_name) {
      newErrors.last_name = "Last name is required";
      valid = false;
    } else {
      newErrors.last_name = "";
    }

    if (!state.email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(state.email)) {
      newErrors.email = "Invalid email format";
      valid = false;
    } else {
      newErrors.email = "";
    }

    if (!state.gender) {
      newErrors.gender = "Gender is required";
      valid = false;
    } else {
      newErrors.gender = "";
    }

    if (!state.phone_no) {
      newErrors.phone_no = "Phone number is required";
      valid = false;
    } else if (!/^\d+$/.test(state.phone_no)) {
      newErrors.phone_no = "Invalid phone number";
      valid = false;
    } else {
      newErrors.phone_no = "";
    }

    if (!state.password) {
      newErrors.password = "Password is required";
      valid = false;
    } else if (state.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
      valid = false;
    } else {
      newErrors.password = "";
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const userData = {
        first_name: state.first_name,
        last_name: state.last_name,
        phone_no: state.phone_no,
        password: state.password,
        gender: state.gender,
        email: state.email,
        doctor_id: 2,
      };

      axios
        .post("http://192.168.0.13/register-specialist", userData)
        .then((response) => {
          console.log("OK");
        })
        .catch((error) => {
          console.error(error.response.data);
        });
    }
  };

  return (
    <div>
      <h1>Register or Create new account</h1>
      <hr />
      <form onSubmit={handleSubmit}>
        <label htmlFor="first_name">
          First Name
          <input
            type="text"
            name="first_name"
            value={state.first_name}
            onChange={handleChange}
          />
          {errors.first_name && (
            <span className="error">{errors.first_name}</span>
          )}
        </label>
        <br /> <br />
        <label htmlFor="last_name">
          Last Name
          <input
            type="text"
            name="last_name"
            value={state.last_name}
            onChange={handleChange}
          />
          {errors.last_name && (
            <span className="error">{errors.last_name}</span>
          )}
        </label>
        <br /> <br />
        <label htmlFor="email">
          Email
          <input
            type="email"
            name="email"
            value={state.email}
            onChange={handleChange}
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </label>
        <br /> <br />
        <label htmlFor="gender">
          Gender
          <select
            className="form-select"
            name="gender"
            aria-label="Default select example"
            value={state.gender}
            onChange={handleChange}
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
          {errors.gender && <span className="error">{errors.gender}</span>}
        </label>
        <br /> <br />
        <label htmlFor="phone_no">
          Phone Number
          <input
            type="number"
            name="phone_no"
            value={state.phone_no}
            onChange={handleChange}
          />
          {errors.phone_no && <span className="error">{errors.phone_no}</span>}
        </label>
        <br /> <br />
        <label htmlFor="password">
          Password
          <input
            type="password"
            name="password"
            value={state.password}
            onChange={handleChange}
          />
          {errors.password && <span className="error">{errors.password}</span>}
        </label>
        <button type="submit">Register</button>
      </form>
    </div>
  );
};

export default Api;
