import AC from "agora-chat";
import { useEffect, useRef, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaImage } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";
import { RiAttachment2, RiSendPlane2Fill } from "react-icons/ri";
import { toast } from "react-toastify";

import { timeAgo } from "../../../Utils/Utils";
import { useApiContext } from "../../../contextapi/contextApi";
import "../styles/ChatFeed.css";
import { useAgoraChatContext, useToggleChatContext } from "./AgoraContext";
import ChatHeader from "./ChatHeader";

export default function ChatFeed({
  appointmentId,
}) {
  const { instance, signInUserData } = useApiContext();
  const { conn } = useAgoraChatContext();
  const { showChat, setShowChat } = useToggleChatContext();

  const [messages, setMessages] = useState([]);
  const [textMessage, setTextMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [refreshMessages, setRefreshMessages] = useState(false);
  const chatBodyRef = useRef(null);
  const fileRef = useRef(null);
  const imageRef = useRef(null);

  // CONVERT THIS VARIABLE TO DYNAMIC VARIABLE OBTAINED FROM DATABASE ITSELF
  const [peerId, setPeerId] = useState();
  const [token, setToken] = useState("");
  const [connected, setConnected] = useState(false);

  // USE STATE FOR STORING LOADING STATES
  const [textLoading, setTextLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);

  // ADD EVENT HANDLERS FOR NOTIFICATIONS
  useEffect(() => {
    initialize();

    conn?.addEventHandler("connection&message", {
      onConnected: () => {
        setIsLoading(false);
        setConnected(true);
        toast.done("Successfully Logged in");
      },
      onDisconnected: () => {
        setIsLoading(false);
        setConnected(false);
        toast.done("logged out...");
      },
      onTextMessage: (message) => {
        setIsLoading(false);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            from: message?.from,
            msg: message?.msg,
          },
        ]);
        setRefreshMessages((prev) => !prev);
      },
      onTokenWillExpire: (params) => {
        // toast.info("Meeting is about to end.");
      },
      onTokenExpired: (params) => {
        toast.info("Token has Ended.");
      },
      onError: (error) => {
        setIsLoading(false);
        toast.error("Oops! Something went wrong!");
      },
      onImageMessage: (message) => {
        setMessages((prevMessages) => setMessages([...prevMessages, message]));
        setRefreshMessages((prev) => !prev);
      },
      onFileMessage: function (message) {
        setMessages((prevMessages) => setMessages([...prevMessages, message]));
        setRefreshMessages((prev) => !prev);
      },
    });
    scrollToBottom();
  }, [conn]);

  useEffect(() => {
    if (connected && peerId) {
      conn
        .getHistoryMessages({
          targetId: peerId.toString(),
          pageSize: 50,
          cursor: -1,
          chatType: "singleChat",
          searchDirection: "up",
        })
        .then((res) => {
          setMessages(res?.messages?.reverse());
        })
        .catch((error) => {
          toast.error("Error in fetching previous messages!");
        });
    }
  }, [connected, refreshMessages]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // FUNCTION TO INITALIZE CHAT PROCESS
  const initialize = async () => {
    try {
      const appoinment = await instance.get(
        `/doctor/get-appoinment-details/${appointmentId}`
      );

      if (appoinment?.data?.data["0"]) {
        if (signInUserData?.user_type === "Patient") {
          const doctor = await getDoctorById(
            appoinment?.data?.data["0"]?.doctor_id
          );

          setPeerId(doctor?.user_id);
        } else {
          setPeerId(appoinment?.data?.data["0"]?.patient?.user_id);
        }
      } else {
        toast.error("Something went wrong please try again. 160");
      }

      const res = await instance.get(
        `agora/generate-chat-user-token?user_id=${signInUserData?.user_id}`
      );
      setToken(res?.data?.data);
      handleLogin(res?.data?.data, signInUserData?.user_id);
    } catch (error) {
      // Uncomment this toast error if required.....
      // toast.error("Something went wrong. Please refresh & try again.");
    }
  };

  // FUNCTION TO LOGIN IN USER TO THE CHAT
  const handleLogin = (token, id) => {
    setIsLoading(true);


    console.log("Trying to connect to chat server...")

    conn
      .open({
        user: id.toString(),
        agoraToken: token.toString(),
      })
      .then(() => {
        console.log("Connected to chat Server");
        setRefreshMessages((prev) => !prev);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong while connecting to chat server");
        setIsLoading(false);
      });
  };

  // FUNCTION TO SEND TEXT MESSAGE TO THE PEER
  const handleSendPeerMessage = () => {
    if (textMessage.length > 0) {
      const options = {
        chatType: "singleChat",
        type: "txt",
        to: peerId.toString(),
        msg: textMessage,
      };

      const messageToSend = AC.message.create(options);

      if (conn) {
        setTextLoading(true);
        conn
          .send(messageToSend)
          .then((res) => {
            setTextMessage("");
            setTimeout(() => setRefreshMessages((prev) => !prev), 1000);
            setTextLoading(false);
          })
          .catch((error) => {
            toast.error("Unable to send message");
            console.log("Error: ", error);
            setTextLoading(false);
          });
      } else {
        toast.error("Something went wrong. 209");
      }
    }
  };

  // FUNCTION TO SEND IMAGES
  const sendImages = () => {
    if (
      !(
        imageRef?.current &&
        imageRef?.current?.files &&
        imageRef?.current?.files?.length > 0
      )
    ) {
      toast.warn("No image to send.");
      return;
    }

    const file = AC.utils.getFileUrl(imageRef.current);

    // Reset the input feild
    fileRef.current.value = null;

    if (file) {
      setImageLoading(true);
      var allowType = {
        jpg: true,
        gif: true,
        png: true,
        bmp: true,
      };

      if (file?.filetype?.toLowerCase() in allowType) {
        const options = {
          chatType: "singleChat",
          type: "img",
          to: peerId.toString(),
          file: file,
          ext: {
            file_length: file.data.size,
          },
          onFileUploadError: () => toast.error("Unable to upload file."),

          onFileUploadProgress: (e) => console.log("Porgress", e),

          onFileUploadComplete: () => toast.done("File upload complete."),
        };

        try {
          const messageToSend = AC.message.create(options);
          conn.send(messageToSend).then((res) => {
            setImageLoading(false);
            setTimeout(() => setRefreshMessages((prev) => !prev), 1000);
          });
        } catch (error) {
          setImageLoading(false);
          toast.error("Could not send Image");
        }
      } else {
        setImageLoading(false);
        toast.error("Invalid File type");
      }
    } else {
      toast.error("Please select a file.");
    }
  };

  // FUNCTION TO SEND FILES
  const sendFiles = () => {
    if (
      !(
        fileRef?.current &&
        fileRef?.current?.files &&
        fileRef?.current?.files?.length > 0
      )
    ) {
      toast.warn("No File to send.");
      return;
    }

    // Reset the input feild
    const file = AC.utils.getFileUrl(fileRef.current);

    fileRef.current.value = null;

    if (file) {
      setFileLoading(true);
      const allowTypes = {
        zip: true,
        txt: true,
        doc: true,
        docx: true,
        pdf: true,
      };

      if (file?.filetype?.toLowerCase() in allowTypes) {
        const option = {
          type: "file",
          file: file,
          to: peerId.toString(),
          chatType: "singleChat",
          ext: { file_length: file.data.size },

          onFileUploadError: () => toast.error("On load upload file error."),

          onFileUploadProgress: (e) => console.log("Progress:", e),

          onFileUploadComplete: () => toast.done("File upload complete."),
        };

        const msg = AC.message.create(option);

        conn
          .send(msg)
          .then((res) => {
            setFileLoading(false);
            setTimeout(() => setRefreshMessages((prev) => !prev), 1000);
          })
          .catch((e) => {
            setFileLoading(false);
            toast.error("Error! File's not sent.");
          });
      } else {
        setFileLoading(false);
        toast.error("Invalid Format");
      }
    } else {
      toast.error("Please select a file");
    }
  };

  function handleSubmit(e) {
    e.preventDefault();
    handleSendPeerMessage();
  }

  // Function will scroll to very bottom of the chat body
  // Whenever a new message is recieved....
  const scrollToBottom = () => {
    const container = chatBodyRef.current;

    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  const getDoctorById = async (id) => {
    try {
      const respone = await instance.get(
        `/doctor/get-user-by-doctorid?doctor_id=${id}`
      );

      const data = respone?.data?.data;

      return data;
    } catch (error) {
      toast.error("some error occured");
    }
  };

  return (
    <section className={`cf_container ${showChat ? "show" : null}`}>
      <div className="cf_heading">
        <ChatHeader isLoading={isLoading} peerId={peerId} />
      </div>
      <div ref={chatBodyRef} className="cf_body">
        {messages?.map((message, index) => (
          <>
            <div
              key={message?.id + index}
              className={`${
                message?.from === peerId.toString() ? "" : "active"
              }`}
            >
              {message?.type === "img" ? (
                <img src={message?.url} alt="image" width={200} height={100} />
              ) : message?.type === "file" ? (
                <a href={message?.url} download>
                  <MdOutlineFileDownload />
                  {message?.filename ? (
                    <>{message?.filename.slice(0, 20)}...</>
                  ) : (
                    "Download File"
                  )}
                </a>
              ) : (
                <p>{message?.msg}</p>
              )}
              <span className="chat_time_stamp">{timeAgo(message?.time)}</span>
            </div>
          </>
        ))}
      </div>
      <div className="cf_input">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={textMessage}
            onChange={(e) => setTextMessage(e.target.value)}
            placeholder="Enter your message"
          />
          <label title="Select only one file to send.">
            {imageLoading ? (
              <AiOutlineLoading3Quarters className="chat_spinner" />
            ) : (
              <FaImage />
            )}
            <input
              disabled={imageLoading}
              type="file"
              ref={imageRef}
              onChange={sendImages}
            />
          </label>
          <label title="Select only one file to send.">
            {fileLoading ? (
              <AiOutlineLoading3Quarters className="chat_spinner" />
            ) : (
              <RiAttachment2 />
            )}
            <input
              disabled={fileLoading}
              type="file"
              ref={fileRef}
              onChange={sendFiles}
            />
          </label>
          <button type="submit" disabled={textLoading}>
            {textLoading ? (
              <AiOutlineLoading3Quarters className="chat_spinner" />
            ) : (
              <RiSendPlane2Fill />
            )}
          </button>
        </form>
      </div>
    </section>
  );
}
