import React from "react";
import "../styles/homepageStyles/HomepageGalleryStyles.css";
import g1 from "../assets/homepage-new/g1.webp";
import g2 from "../assets/homepage-new/g2.webp";
import g3 from "../assets/homepage-new/g3.webp";
import g4 from "../assets/homepage-new/g4.webp";
import g5 from "../assets/homepage-new/g5.webp";
import g7 from "../assets/homepage-new/g7.webp";
import { Link, useNavigate } from "react-router-dom";
import { useApiContext } from "../contextapi/contextApi";
import { useState, useEffect } from "react";

function HomepageGallery(props) {
  const { signInUserData, instance, setAppointmentType } = useApiContext();
  const [hasDoctors, setHasDoctors] = useState(false);
  const navigate = useNavigate();

  const getPatientDoctors = async () => {
    try {
      const response = await instance.get(
        `/patient/get-doctors-list/${signInUserData.user_id}`
      );
      if (response) {
        if (response.data.data.length === 0) {
          setHasDoctors(false);
        } else {
          setHasDoctors(true);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getPatientDoctors();
  }, []);

  return (
    <>
      <div className="homepage-gallery-container">
        <div className="container">
          <div className="container-wrapper">
            <h1>
              Ensuring Every Appointment feels like a continuation, Not A
              restart.
            </h1>
            <div className="images-container">
              <div className="image-wrapper-1">
                <img className="image-01" src={g1} alt="" />
              </div>
              <div className="image-wrapper-2">
                <img src={g2} alt="" />
              </div>
              <div className="image-wrapper-3">
                <img src={g3} alt="" />
              </div>
            </div>
            <div className="gallery-text-section">
              <div className="text">
                <h1>Consistent care always here</h1>
                <p>
                  Expert continuity in your care with us. Your appointment will
                  be with the same dedicated professional, ensuring a seamless
                  and personalized experience.
                </p>
              </div>
              <div className="image">
                <div className="image-bg"></div>
                <img src={g5} alt="" />
              </div>
            </div>
            <div className="gallery-text-section center">
              <div className="image">
                <div className="image-bg"></div>
                <img src={g7} alt="" />
              </div>
              <div className="text right">
                <h1>Cutting-edge dental expertise</h1>
                <p>
                  With advanced training in comprehensive treatments, we provide
                  a wide array of modern solutions for both restorative and
                  cosmetic dentistry needs.
                </p>
              </div>
            </div>
            <div className="gallery-text-section">
              <div className="text less">
                <h1>Your time matters</h1>
                <p>
                  We respect your schedule. Timeliness is our commitment,
                  ensuring punctuality without sacrificing the personalized
                  attention you deserve.
                </p>
              </div>
              <div className="image">
                <div className="image-bg"></div>
                <img src={g4} alt="" />
              </div>
            </div>
            <div className="button-container">
              <button
                onClick={() => {
                  const url = `${
                    signInUserData && signInUserData?.user_type === "Patient"
                      ? hasDoctors
                        ? "/patient-portal"
                        : "/patient-portal/appointment-question"
                      : signInUserData && signInUserData?.user_type === "Doctor"
                      ? "/doctor-portal"
                      : "/patient-sign-up"
                  }`;
                  if (url.includes("appointment")) {
                    setAppointmentType("new");
                  }
                  navigate(url);
                }}
                className="btn-large gradient-image"
              >
                {`${
                  signInUserData && signInUserData?.user_type === "Patient"
                    ? "Book An Appointment"
                    : signInUserData && signInUserData?.user_type === "Doctor"
                    ? "Doctor Dashboard"
                    : "Book An Appointment"
                }`}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomepageGallery;
