import { differenceInYears, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Req from "../../assets/dashboard/pat.png";
import defultimag from "../../assets/homepage/profile-img.png";
import { useApiContext } from "../../contextapi/contextApi";
import InvitePatientModal from "../InvitePatientModal";
import PatientReferralModal from "../PatientReferralModal";

function formatDate(dob) {
  // Parse the date string into a Date object
  const dobDate = parseISO(dob);

  const age = differenceInYears(new Date(), dobDate);

  return `${age} years`;
}

function Patient1() {
  const { signInUserData, instance } = useApiContext();
  const [myPatienst, setMyPatienst] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const [showPatientInviteModal, setshowPatientInviteModal] = useState(false);

  useEffect(() => {
    patientsList();
  }, []);

  // Scheduled appointment list  //
  const patientsList = async () => {
    try {
      const response = await instance.get(
        `/doctor/patients-list/${signInUserData.user_id}`
      );
      setMyPatienst(response.data.data);
      setIsLoading(false);

      if (
        response.data.data.statusCode === 404 ||
        response.data.data.length === 0
      ) {
        setShowNoDataMessage(true);
      }
    } catch (err) {
      setIsLoading(false);
      setMyPatienst(null);
      toast.error("Something went wrong.");
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="content-spinner">
          <div className="loader"></div>
        </div>
      ) : (
        <div>
          {showNoDataMessage ? (
            <div className="no-data-message">
              <img src={Req} alt="" className="img-fluid" width={500} />
              <h3 className="mt-4">No Patient Found</h3>
              <p className="mt-4">
                Patient who had previously consulted with you will be shown
                here.
              </p>
              <button
                className="invite_patient_button"
                onClick={() => setshowPatientInviteModal(true)}
              >
                Invite Patient
              </button>
              <InvitePatientModal
                showModal={showPatientInviteModal}
                setShowModal={setshowPatientInviteModal}
                setRefresh={() => {}}
              />
            </div>
          ) : (
            <div className="container-fluid">
              <div className="my_patient_list_header">
                <button
                  className="invite_patient_button"
                  onClick={() => setshowPatientInviteModal(true)}
                >
                  Invite Patient
                </button>
                <InvitePatientModal
                  showModal={showPatientInviteModal}
                  setShowModal={setshowPatientInviteModal}
                  setRefresh={() => {}}
                />
              </div>
              <div className="row">
                {myPatienst?.map((item) => (
                  <div key={item.id} className="col-lg-4 col-md-4 col-sm-12">
                    <MyPatientCard item={item} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Patient1;

export const MyPatientCard = ({ item }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {
    baseImageURL,
    signInUserData,
    setPatientDetail,
    instance,
    setHistory,
  } = useApiContext();
  const [showModal, setShowModal] = useState(false); // show reffereal model

  console.log("patient Card details: ", { item });

  // patinet appointment detail //

  const handlePatientDetail = async (id) => {
    try {
      setIsLoading(true);
      const response = await instance.get(`/doctor/patients-details/${id}`);
      const patientData = response?.data?.data;
      setPatientDetail(patientData);

      const response2 = await instance.get(
        `/doctor/consultancy-history/${id}/${signInUserData?.doctor?.id}`
      );
      const patientDatas = response2?.data?.data;
      setHistory(patientDatas);

      setIsLoading(false);
      navigate("/patient-detail");
    } catch (err) {
      setIsLoading(false);
      setPatientDetail(null);
      setHistory(null);
    }
  };

  return (
    <div className="card shadow-sm">
      <div className="text-center pt-4">
        <img
          src={
            `${baseImageURL}${item.patient.user.profile_image}` || defultimag
          }
          alt=""
          className="rounded-circle mt-1"
          style={{
            width: "84px",
            height: "84px",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </div>
      <div className="text-center">
        <ul className="list-unstyled lh-lg">
          <li className="fw-bold dashboard-p3">
            {item?.patient?.user?.first_name}&nbsp;
            {item?.patient?.user?.last_name}
          </li>
          {/* <li className="text-muted dashboard-p3">
        Patient ID: {item.id}
      </li> */}
          {/* <li className="text-muted dashboard-p3">
        Pappelallee 18, Geisa, 36416
      </li> */}
        </ul>
        <hr className="ms-3 me-3 mt-4" />
        {item?.patient?.user?.phone_no && item?.patient?.user?.gender && (
          <div className="pati-block ms-3 me-3">
            <ul className="list-unstyled text-start">
              <li className="text-muted dashboard-p3">Phone</li>
              <li className="text-muted dashboard-p3">Age</li>
            </ul>
            <ul className="list-unstyled text-end">
              <li className="fw-bold dashboard-p3">
                +{item?.patient?.user?.phone_no}
              </li>
              <li className="fw-bold dashboard-p3">
                {formatDate(item.patient.user.dob)}, &nbsp;
                {item?.patient?.user?.gender}
              </li>
            </ul>
          </div>
        )}

        <div className="d-grid gap-2 col-12 mx-auto">
          <button
            className="rounded dashboard-p3 box-button34 pt-1 pb-1 mb-2 ms-3 me-3"
            type="button"
            onClick={() => handlePatientDetail(item?.patient_id)}
          >
            {isLoading ? (
              <AiOutlineLoading3Quarters className="chat_spinner" />
            ) : (
              "View Detail"
            )}
          </button>
          <button
            className="invite_patient_button mb-2 ms-3 me-3"
            onClick={() => setShowModal(true)}
          >
            Refer to Doctor
          </button>
        </div>
      </div>
      <PatientReferralModal
        showModal={showModal}
        setShowModal={setShowModal}
        setRefresh={() => {}}
        patient={item?.patient?.user}
      />
    </div>
  );
};
