import { parseSpecialization } from "../../Utils/Utils";

export default function ParseSpecializationComponent({ specialization }) {
  const doctorSpecialization = parseSpecialization(specialization);
  return doctorSpecialization?.map((item, index) => {
    if (doctorSpecialization.length - 1 === index) {
      return <>{item}</>;
    }
    return <>{item}, </>;
  });
}
