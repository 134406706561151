import Navbar from "./Navbar";
import { useEffect } from "react";
import { IoIosLogOut } from "react-icons/io";
import emailImage from "../assets/register/Verification Email.png";
import "../styles/Subsciption.css";
import { useApiContext } from "../contextapi/contextApi";

export default function UnverfiedPatient() {
  const { instance, setSignInUserData, signInUserData, setUserDetail } =
    useApiContext();
  const Logout = () => {
    localStorage.removeItem("signInUserData");
    localStorage.removeItem("user-profile");
    localStorage.removeItem("answer_ids");
    window.location.href = "/";
  };

  useEffect(() => {
    const doctorDetails = async () => {
      try {
        const response = await instance.get(
          `/user-verification/${signInUserData.user_id}`
        );
        if (response) {
          if (response.data.statusCode == 200) {
            setSignInUserData(response.data.data);
            setUserDetail(response.data.data);
            if (response.data.data.is_email_verified) {
              if (response.data.data.is_email_verified !== 0) {
                setSignInUserData(response.data.data);
                window.location.href = "/patient-portal";
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching checkbox options:", error);
      }
    };
    doctorDetails();
  }, []);

  return (
    <>
      <Navbar doctor="Home" doctorLink="/" />
      <div className="container-fluid">
        <div className="row g-0">
          <div className="col-lg-9 col-md-9 col-sm-12 mt-4 under_processing">
            <div>
              <img src={emailImage} alt="Profile under_review" />
              <p>
                Kindly validate your email address to access your account. A
                verification email has been dispatched to your registered email
                account for confirmation.
              </p>
            </div>
            <button
              onClick={() => Logout()}
              className="btn btn-primary d-inline-block align-items-center gap-2"
            >
              <IoIosLogOut /> Logout
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
