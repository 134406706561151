import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/homepage/Logo.svg";
import Button from "../subcomponents/Button";
import "../styles/homepage.css";
import { useApiContext } from "../contextapi/contextApi";

function Navbar(props) {
  const { apiResponseData, setApiResponseData } = useApiContext();
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container col-lg-12">
          <div className="col-lg-3">
            <Link className="navbar-brand ms-1" to="/">
              <img src={Logo} alt="logo-img" />
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse col-lg-9"
            id="navbarSupportedContent"
          >
            <div className="col-lg-6">
              <ul className="navbar-nav mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link
                    className="nav-link dashboard-p3 active fw-bold"
                    id="navbar-a"
                    aria-current="page"
                    to={props.aboutLink}
                  >
                    {props.about}
                  </Link>
                </li>
                <li className="nav-item ms-xl-2">
                  <Link
                    className="nav-link ms-3 dashboard-p3 fw-bold"
                    id="navbar-a"
                    to={props.contactLink}
                  >
                    {props.contact}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 justify-content-end">
                <li className="nav-item ms-xl-2">
                  <Link
                    className="nav-link ms-3 dashboard-p3 fw-bold"
                    id="navbar-a"
                    to={props.helpLink}
                  >
                    {props.help}
                  </Link>
                </li>
                <li className="nav-item ms-lg-3">
                  <Link to={props.doctorLink}>
                    <button
                      className={`main-button dashboard-p3 rounded p-2 ps-4 pe-4 ${props.disp}`}
                      type="submit"
                    >
                      {props.doctor}
                    </button>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
export default Navbar;