import { AiOutlineLoading3Quarters } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useApiContext } from "../../../contextapi/contextApi";
import Portal8 from "../../../assets/dashboard/Eye1.svg";

const History = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { history } = useApiContext();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 250);
  }, []);

  useEffect(() => {
    console.log("History: ", history);
  }, [history]);

  return (
    <>
      {isLoading ? (
        <div className="content-spinner">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="p-1 me-4">
          <table class="table table-white border table-hover mt-4">
            <thead>
              <tr>
                <th scope="col" className="invoice-th">
                  Date & Time
                </th>
                <th scope="col" colSpan={2} className="invoice-th">
                  Reason
                </th>

                <th scope="col" className="invoice-th">
                  Status
                </th>
                <th scope="col" className="invoice-th">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {history?.map((item) => (
                <tr className="align-middle">
                  <PatientDetailCard item={item} />
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default History;

export const PatientDetailCard = ({ item }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setAppDetail, instance } = useApiContext();
  const navigate = useNavigate();

  const handleClick = async (id) => {
    try {
      setIsLoading(true);
      const response = await instance.get(
        `/doctor/get-appoinment-details/${id}`
      );
      setAppDetail(response);

      setIsLoading(false);
      navigate("/doctor-portal/appointment-detail");
    } catch (error) {
      setIsLoading(false);
      toast.error("Oops! Something went wrong");
    }
  };

  return (
    <>
      <td scope="row" className="invoice-th">
        <div className="d-flex ms-2">
          {item?.date} <br />
          {item?.start_time} - {item?.end_time}
        </div>
      </td>
      <td className="invoice-li" colSpan={2}>
        {item?.reason?.name}
      </td>

      <td className="invoice-li">
        <span class="badge bg-success">{item?.status}</span>
      </td>

      <td className="invoice-li">
        <button
          style={{ textDecoration: "none" }}
          disabled={isLoading}
          onClick={() => handleClick(item?.id)}
          className="ghost_button"
        >
          {isLoading ? (
            <AiOutlineLoading3Quarters className="chat_spinner" />
          ) : (
            <img src={Portal8} alt="eye-img" />
          )}
        </button>
      </td>
    </>
  );
};
