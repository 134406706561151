import React from "react";
import { useNavigate } from "react-router-dom";

import OoopsError from "../assets/Ooops Error occured.mp4";
import "../styles/NotFound.css";
import { useApiContext } from "../contextapi/contextApi";

export default function ErrorPage(props) {
  const { signInUserData } = useApiContext();
  const navigate = useNavigate();
  const { error, resetErrorBoundary } = props;

  function handleClick() {
    resetErrorBoundary();

    if (signInUserData) {
      signInUserData?.user_type === "Doctor"
        ? navigate("/doctor-portal")
        : navigate("/patient-portal");
    } else {
      navigate("/");
    }
  }

  return (
    <div className="not_found">
      <video controls={false} autoPlay={true} loop muted>
        <source src={OoopsError} type="video/mp4" />
        Oops! Something went wrong.
      </video>
      <button onClick={handleClick} className="btn">
        Return to previous page
      </button>
    </div>
  );
}
