import { useState, useEffect } from "react";
import HomeNavbar from "./HomeNavbar";
import "../styles/homepageStyles/HomepageHeaderStyles.css";
import camera from "../assets/homepage-new/camera.webp";
import { Link, useNavigate } from "react-router-dom";
import { useApiContext } from "../contextapi/contextApi";

function HomepageHeader(props) {
  const { signInUserData, instance, setAppointmentType } = useApiContext();
  const [hasDoctors, setHasDoctors] = useState(false);
  const navigate = useNavigate();

  const getPatientDoctors = async () => {
    try {
      const response = await instance.get(
        `/patient/get-doctors-list/${signInUserData.user_id}`
      );

      console.log("Response: ", response);

      if (response) {
        if (response.data.data.length === 0) {
          setHasDoctors(false);
        } else {
          setHasDoctors(true);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getPatientDoctors();
  }, []);

  return (
    <>
      <div className="homepage-header-container">
        <HomeNavbar />
        <div className="header-content">
          <div className="container">
            <div className="text">
              <h1>Find a dentist for your problem 24/7 via video call</h1>
              <h2>Your Reliable Remote Dentistry Hub</h2>
              <p>
                Connect with Dentists Around You and Book Online Consultations
                form the Comfort of Your Home
              </p>

              <button
                onClick={() => {
                  const url = `${
                    signInUserData && signInUserData?.user_type === "Patient"
                      ? hasDoctors
                        ? "/patient-portal"
                        : "/patient-portal/appointment-question"
                      : signInUserData && signInUserData?.user_type === "Doctor"
                      ? "/doctor-portal"
                      : "/patient-sign-up"
                  }`;
                  if (url.includes("appointment")) {
                    setAppointmentType("new");
                  }
                  navigate(url);
                }}
                className="btn-large gradient-image"
              >
                {`${
                  signInUserData && signInUserData?.user_type === "Patient"
                    ? "Book An Appointment"
                    : signInUserData && signInUserData?.user_type === "Doctor"
                    ? "Doctor Dashboard"
                    : "Book An Appointment"
                }`}
              </button>
            </div>
            <div className="image">
              <img src={camera} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomepageHeader;
