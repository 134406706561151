import { useNavigate } from "react-router-dom";

import notFound from "../assets/NotFound.mp4";
import "../styles/NotFound.css";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="not_found">
      <video controls={false} autoPlay={true} loop muted>
        <source src={notFound} type="video/mp4" />
        404 page not Found
      </video>
      <button onClick={() => navigate(-1)} className="btn">
        Go Back
      </button>
    </div>
  );
}
