import React from "react";
import "../styles/homepageStyles/HomepageFooterStyles.css";
import facebook from "../assets/homepage-new/facebook.svg";
import linkedin from "../assets/homepage-new/linkedin.svg";
import instagram from "../assets/homepage-new/instagram.svg";
import logo from "../assets/homepage-new/Logo.svg";
import { Link } from "react-router-dom";
import { IoIosMail } from "react-icons/io";
import { IoMdGlobe } from "react-icons/io";

function HomepageFooter({ imprint = true }) {
  return (
    <>
      <div className="homepage-footer-container">
        <div className="container">
          <div className="container-wrapper">
            <div className="logo-social">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
              {imprint ? (
                <div className="social">
                  <a href="mailto:contact@bc-conulting-llc.com">
                    <span>
                      <IoIosMail />
                    </span>
                  </a>
                  <a
                    href="http://www.bc-consulting-llc.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      <IoMdGlobe />
                    </span>
                  </a>
                </div>
              ) : (
                <div className="social">
                  <img src={facebook} alt="" />
                  <img src={instagram} alt="" />
                  <img src={linkedin} alt="" />
                </div>
              )}
            </div>
            <div className="nav">
              <Link to="/about">
                <button>About Us</button>
              </Link>
              <Link to="/contact">
                <button>Contact Us</button>
              </Link>
            </div>
            <div className="copyright">
              <span>Copyright &copy; Biotech & Capital Consulting LLC.</span>
              <div>
                <Link to="/imprint">
                  <span className="imprint-dataprivacy">Imprint</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomepageFooter;
