import React, { useState } from "react";
import { Link } from "react-router-dom";
import FFF from "../../../assets/homepage/profile-img2.png";
import Sch from "../../../assets/dashboard/apo.png";
import Doctor3 from "../../../assets/dashboard-patient/dashboard-patient4.png";
import Patient5 from "../../../assets/dashboard-patient/dashboard-patient6.svg";
import Patient6 from "../../../assets/dashboard-patient/dashboard-patient5.svg";
import Doc1 from "../../../assets/homepage/Arrow5.svg";
import DropdownButton from "../../../subcomponents/Dropdown";
import { useApiContext } from "../../../contextapi/contextApi";
import { useEffect } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { addDays, subDays, format } from "date-fns";
import "./Reschedule.css";
import { useNavigate } from "react-router-dom";
// import Portal16 from "../../../assets/dashboard/dashboard-patient8.svg";
// import Portal17 from "../../../assets/dashboard/dashboard-patient7.svg";
import Appointment from "../../Appointment";
import NoAppointment from "../../../assets/no-appointment.jpg";
import ParseSpecializationComponent from "../../UtilComponent/ParseSpecializationComponent";
import { toast } from "react-toastify";
import GuestList from "../../GuestList";

function PatientAppointment() {
  const [showNextContent, setShowNextContent] = useState(false);
  const [allAppointment, setAllAppointment] = useState([]);
  const {
    baseImageURL,
    signInUserData,
    instance,
    setApQuestions,
    apquestions,
    setAppointmentType,
  } = useApiContext();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [appointmentReason, setAppointmentReason] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [patintdetail, setPatinetDetail] = useState([]);
  const [historyRefresh, sethistoryRefresh] = useState(true);

  const navigate = useNavigate();

  const handleNextClick = () => {
    setShowNextContent(true);
  };

  const handleBackClick = () => {
    setShowNextContent(false);
  };

  const formatDate = (dateString) => {
    const inputDate = new Date(dateString); // Replace this with your input date

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dayOfWeek = daysOfWeek[inputDate.getDay()];
    const month = months[inputDate.getMonth()];
    const dayOfMonth = inputDate.getDate();

    const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}`;
    return formattedDate;
  };

  const handleViewDetailClick = async (id) => {
    try {
      const response = await instance.get(
        `patient/get-appoinment-details/${id}`
      );
      setPatinetDetail(response?.data?.data[0]);
      const patientData = response?.data?.data?.questions;
      const patientReason = response?.data?.data[0]?.reason;
      if (patientReason) {
        setAppointmentReason(patientReason.name);
      }
      if (patientData) {
        setApQuestions(patientData);
      }
    } catch (err) {
      setPatinetDetail(null);
    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 250);
  // }, []);

  useEffect(() => {
    allHistory();
  }, [historyRefresh]);

  // all appointment list patient portal //
  const allHistory = async () => {
    try {
      const response = await instance.get(
        `patient/get-completed-appoinment-list/${signInUserData.user_id}`
      );
      setAllAppointment(response.data.data);
      setIsLoading(false);
    } catch (err) {
      setAllAppointment([]);
      setIsLoading(false);
    }
  };

  // all appointment list patient portal //
  // const allAapointment = async () => {
  //   try {
  //     const response = await instance.get(
  //       `patient/get-appoinments-list/${signInUserData.user_id}`
  //     );
  //     setAllAppointment(response.data.data);
  //     console.log("Appointmenst fetched", response.data.data)
  //     setIsLoading(false);
  //   } catch (err) {
  //     setAllAppointment([]);
  //     setIsLoading(false);
  //   }
  // };

  const handlePreviousClick = () => {
    setSelectedDate(subDays(selectedDate, 7));
  };

  const getWeekDates = (start) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      dates.push(addDays(start, i));
    }
    return dates;
  };

  const CancelAppointment = async (app_id) => {
    instance
      .post(`/doctor/cancel-appoinment/`, {
        appoinment_id: app_id,
      })
      .then((response) => {
        console.log(response);
        if (response.data.statusCode === 200) {
          toast.success("Cancelled Successfully !");
          sethistoryRefresh((prev) => !prev);
          setShowNextContent(false);
        }
      });
  };

  console.log("Patient Detail: ", patintdetail);

  return (
    <>
      {isLoading ? (
        <div className="content-spinner">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          {showNextContent ? (
            <>
              <section className="ms-2">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="d-flex mt-3 mb-3">
                        <div>
                          <img
                            src={Doc1}
                            alt="arrow-img"
                            width={30}
                            className="main-img"
                            onClick={handleBackClick}
                          />
                        </div>
                        <div className="ps-2 pt-1">
                          <h6 className="main-text mTc fw-bold">
                            Appointment Detail
                          </h6>
                        </div>
                      </div>
                      <div className="rounded dashboard-main mt-3"></div>
                    </div>
                  </div>
                </div>
              </section>

              <div className="mt-3">
                <div className="rounded border text-center p-4 mb-3 me-3 shadow">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="d-lg-flex">
                          <img
                            className="rounded"
                            src={`${baseImageURL}${patintdetail?.doctor?.user?.profile_image}`}
                            alt="doctor-img"
                            style={{ width: "80px", height: "80px" }}
                            onError={(e) => {
                              e.target.src = FFF;
                            }}
                          />
                          <div>
                            <ul className="list-unstyled text-lg-start pt-2 ms-3 d-flex align-items-start flex-column justify-content-start">
                              <li className="fw-bold main-text">
                                {patintdetail?.doctor?.user?.first_name}
                                {patintdetail?.doctor?.user?.last_name}
                              </li>
                              <li className="main-text">
                                {patintdetail?.doctor?.specialist_in?.name}
                              </li>
                              <li className="main-text">
                                {patintdetail?.doctor?.specialization ? (
                                  <ParseSpecializationComponent
                                    specialization={
                                      patintdetail?.doctor?.specialization
                                    }
                                  />
                                ) : null}
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="rounded dashboard-main mt-3">
                          <div className="d-flex justify-content-between p-3">
                            <p className="main-text mTc fw-bold m-0 p-0">
                              <img
                                src={Patient5}
                                alt="patient-img"
                                className="me-2"
                              />
                              {formatDate(
                                Array.isArray(
                                  patintdetail?.appointment_date_times
                                ) &&
                                  patintdetail.appointment_date_times[0]?.date
                              )}
                            </p>
                            <p className="main-text mTc fw-bold m-0 p-0">
                              <img
                                src={Patient6}
                                alt="patient-img"
                                className="me-2"
                              />
                              {Array.isArray(
                                patintdetail?.appointment_date_times
                              ) &&
                                patintdetail.appointment_date_times[0]
                                  ?.start_time}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12"></div>
                      <div className="col-lg-3 col-md-3 col-sm-12 d-flex d-lg-block d-md-block">
                        {patintdetail.status !== "Cancel" &&
                        patintdetail.status !== "Completed" ? (
                          <div className="d-grid gap-2 col-5 col-lg-10 col-md-10 m-2">
                            <button
                              onClick={() => CancelAppointment(patintdetail.id)}
                              className="fw-bold rounded patient-button1 main-text p-1"
                              type="button"
                            >
                              Cancel
                            </button>
                          </div>
                        ) : null}
                        <div className="d-grid gap-2 col-5 col-lg-10 col-md-10 m-2">
                          <td className="invoice-li">
                            <ul className="list-unstyled">
                              <li className="dashboard-th p-1 fw-bold main-text ">
                                {patintdetail.status === "Accepted By User" ? (
                                  <p className="upcoming">Upcoming</p>
                                ) : patintdetail.status === "Pending" ? (
                                  <p className="pendingupcoming">Pending</p>
                                ) : patintdetail.status === "Cancel" ? (
                                  <p
                                    style={{
                                      color: "red",
                                      fontFamily: "Montserrat",
                                    }}
                                  >
                                    Cancelled
                                  </p>
                                ) : patintdetail.status === "Completed" ? (
                                  <p className="completed">Completed</p>
                                ) : null}
                              </li>
                            </ul>
                          </td>
                        </div>
                      </div>
                      <div className="container-fluid mt-3">
                        <GuestList appointmentId={patintdetail.id} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h6 className="fw-bold main-text pt-2 pb-2 mtc">
                Consultation Reasons
              </h6>
              <div className="rounded border bg-white me-3 p-3 mt-2">
                <div class="fw-bold main-text pt-2 pb-2 mtc">
                  {appointmentReason}
                </div>
                {apquestions && apquestions?.length > 0 ? (
                  apquestions.map((values, index) => (
                    <div key={index} className="container mt-2">
                      <div className="row">
                        <div className="col-12">
                          <div
                            className="accordion mt-2 mb-2"
                            id="accordionExample"
                          >
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id={`heading-${index}`}
                              >
                                <button
                                  className="accordion-button bg-light text-dark p-2 ps-3"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapse-${index}`}
                                  aria-expanded="true"
                                  aria-controls={`collapse-${index}`}
                                >
                                  {values.question}
                                </button>
                              </h2>
                              <div
                                id={`collapse-${index}`}
                                className="accordion-collapse collapse"
                                aria-labelledby={`heading-${index}`}
                                data-bs-parent=".accordion"
                              >
                                <div
                                  className={`accordion-body p-2 ps-3 ${
                                    values?.answer_image ? "has_image" : ""
                                  }`}
                                  style={{ padding: "0px !Important" }}
                                >
                                  {values?.question_image ? (
                                    <div className="app_questions_image">
                                      <img
                                        src={`${baseImageURL}${values?.question_image}`}
                                        alt=""
                                      />
                                    </div>
                                  ) : null}
                                  {values?.answer_image ? (
                                    <img
                                      src={`${baseImageURL}${values?.answer_image}`}
                                      alt=""
                                    />
                                  ) : (
                                    <p>{values?.answer}</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No data available</p>
                )}
              </div>
            </>
          ) : (
            <div className="mt-3">
              {allAppointment.length === 0 ? (
                <div className="no-data-message text-center">
                  <img src={NoAppointment} alt="No Appointment" />
                  <h3 className="mt-4">You have no appointment</h3>
                  <button
                    onClick={() => {
                      setAppointmentType("new");
                      navigate("/patient-portal/appointment-questions");
                    }}
                    className="btn btn-primary mt-4"
                  >
                    Schedule an Appointment
                  </button>
                </div>
              ) : (
                allAppointment?.map((item) => (
                  <div
                    key={item.id}
                    className="rounded border text-center p-4 mb-3 me-3 shadow"
                  >
                    <div className="container-fluid">
                      <div className="row d-flex justify-content-between">
                        <div className="col-lg-6 col-md-6 col-sm-12 flex-fill">
                          <div className="d-lg-flex">
                            <img
                              className="rounded"
                              src={`${baseImageURL}${item?.doctor?.user?.profile_image}`}
                              alt="doctor-img"
                              style={{ width: "80px", height: "80px" }}
                              onError={(e) => {
                                e.target.src = FFF;
                              }}
                            />
                            <div>
                              <ul className="list-unstyled text-lg-start pt-2 ms-3 d-flex align-items-start flex-column justify-content-start">
                                <li className="fw-bold main-text">
                                  {item?.doctor?.user?.first_name}
                                  {item?.doctor?.user?.last_name}
                                </li>
                                <li className="main-text">
                                  {item?.doctor?.specialist_in?.name}
                                </li>
                                <li className="main-text">
                                  <ParseSpecializationComponent
                                    specialization={
                                      item?.doctor?.specialization
                                    }
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="rounded dashboard-main d-flex justify-content-between container-fluid mt-3">
                            <div className="d-flex justify-content-between gap-2 p-3">
                              <p className="main-text mTc fw-bold m-0 p-0">
                                <img
                                  src={Patient5}
                                  alt="patient-img"
                                  className="me-2"
                                />
                                {formatDate(
                                  item?.appointment_date_times[0]?.date
                                )}
                              </p>
                              <p className="main-text mTc fw-bold m-0 p-0">
                                <img
                                  src={Patient6}
                                  alt="patient-img"
                                  className="me-2"
                                />
                                {item?.appointment_date_times[0]?.start_time}
                                {""} {item?.appointment_date_times[0]?.end_time}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between p-3 float-end">
                              {item?.reason?.name}
                            </div>
                          </div>
                          <div className="container-fluid mt-3">
                            <GuestList appointmentId={item.id} />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 d-flex d-lg-block d-md-block">
                          <div className="d-grid gap-2 col-5 col-lg-10 col-md-10 m-2">
                            <button
                              className="fw-bold rounded patient-button2 main-text1 p-1"
                              type="button"
                              onClick={() =>
                                handleViewDetailClick(item.id) +
                                handleNextClick()
                              }
                            >
                              View Detail
                            </button>
                          </div>

                          <div className="d-grid gap-2 col-5 col-lg-10 col-md-10 m-2">
                            <td className="invoice-li">
                              <ul className="list-unstyled">
                                <li className="dashboard-th p-1 fw-bold main-text ">
                                  {item.status === "Accepted By User" ? (
                                    <p className="upcoming">Upcoming</p>
                                  ) : item.status === "Pending" ? (
                                    <p className="pendingupcoming">Pending</p>
                                  ) : item.status === "Cancel" ? (
                                    <p
                                      style={{
                                        color: "red",
                                        fontFamily: "Montserrat",
                                      }}
                                    >
                                      Cancelled
                                    </p>
                                  ) : item.status === "Completed" ? (
                                    <p className="completed">Completed</p>
                                  ) : null}
                                </li>
                              </ul>
                            </td>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default PatientAppointment;
