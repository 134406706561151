import { useEffect, useState } from "react";
import { FaCalendar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { parseSpecialization } from "../Utils/Utils";
import locationicon from "../assets/location-icon.svg";
import websiteicon from "../assets/website-icon.svg";
import { useApiContext } from "../contextapi/contextApi";
import "../styles/dashboard.css";
import "./DesktopStyles.css";

import defaultImage from "../assets/homepage/profile-img.png";

function Desktop({ doctor, path, showButton = true }) {
  const [allDisease, setAllDisease] = useState([]);

  useEffect(() => {
    const getAllDiseases = () => {
      const diseaseArray = doctor?.diseases;
      if (diseaseArray) {
        return diseaseArray.map((e) => e.disease.name);
      }
      return [];
    };

    setAllDisease(getAllDiseases());
  }, [doctor]); // Run this effect when the 'doctor' prop changes

  const [showAboutContainer, setShowAboutContainer] = useState(false);

  const { setApiDoctorData, baseImageURL, setChatPeerId, setAppointmentType } =
    useApiContext();
  const doctorSpecialization = parseSpecialization(doctor.specialization);

  const navigate = useNavigate();

  const handleClickHeading = () => {
    setShowAboutContainer((e) => !e);
  };

  const handleClick = (doctor) => {
    if (path == "inside") {
      setAppointmentType("inside");
      navigate("/patient-portal/appointment-questions");
      setApiDoctorData(doctor);
    } else if (path == "doc-app") {
      navigate("/patient-portal/doctor-appointment");
      setApiDoctorData(doctor);
    }
  };

  function isCurrentDayPresent(doctorAvailability) {
    const currentDay = new Date().toLocaleString("en-us", { weekday: "long" });

    const isAvailable = doctorAvailability.some(
      (availability) =>
        availability.day.toLowerCase() === currentDay.toLowerCase()
    );

    return isAvailable;
  }

  const SingleProfile = (doctor) => {
    setApiDoctorData(doctor);
    navigate("/doctor-detail");
  };

  return (
    <>
      <section className="mt-1">
        <div className="container">
          <div className="row g-0">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="mb-3">
                <div className="desktop-box1 shadow-sm border rounded p-3">
                  <div className="container">
                    <div className="row g-0">
                      <div className="col-lg-9 col-md-9 col-sm-12">
                        <div className="patient-box1 desktop-doctor-info-container">
                          <div className="ms-3 doctor_list_image ">
                            {doctor?.user?.profile_image ? (
                              <img
                                src={`${baseImageURL}${doctor?.user?.profile_image}`}
                                alt="doctor-img"
                              />
                            ) : (
                              <img src={defaultImage} alt="doctor-image" />
                            )}
                          </div>
                          <div className="d-block ps-3 doctor-text-container">
                            <div className="doctor-name">
                              <h5 className="fw-bold">
                                {doctor?.user?.first_name +
                                  " " +
                                  doctor?.user?.last_name}
                              </h5>
                              <div>
                                {isCurrentDayPresent(doctor?.doctor_timing) ? (
                                  <span className="availability">
                                    Available
                                  </span>
                                ) : (
                                  <span className="availability not-available">
                                    Not Available
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="desktop-li main-text sm-mt-3">
                              Specializes in:{" "}
                              <span>{doctor?.specialist_in?.name}</span>
                            </div>
                            <ul className="list-unstyled">
                              <li className="desktop-li main-text">
                                {doctorSpecialization.map((item, index) => {
                                  if (
                                    doctorSpecialization.length - 1 ===
                                    index
                                  ) {
                                    return <>{item}</>;
                                  }
                                  return <>{item}, </>;
                                })}
                              </li>
                              <li className="desktop-li main-text sm-mt-3">
                                {/* {doctor.specialist_in.name} */}
                              </li>
                            </ul>
                            <p className="desktop-li main-text">
                              <img src={locationicon} alt="" />
                              {doctor?.address?.address1},
                              {doctor?.address?.address2},
                              {doctor?.address?.state}
                            </p>
                            {doctor?.website ? (
                              <p className="desktop-li main-text">
                                <img src={websiteicon} alt="" />

                                {doctor?.website}
                              </p>
                            ) : null}
                            {/* <div class="d-grid gap-2 col-5 col-lg-10 col-md-10 m-2">
                              <button
                                class="fw-bold rounded patient-button2 main-text1 p-1 px-4"
                                type="button"
                                onClick={() => handleClick(doctor)}
                                style={{
                                  width: "fit-content"
                                }}
                              >
                                Book Appointment
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-3 col-sm-12 d-flex d-lg-block d-md-block book-appointment-button-container">
                        {showButton ? (
                          <div class="d-grid gap-2 col-5 col-lg-10 col-md-10 pt-sm-4 col-12">
                            <button
                              class="fw-bold rounded fint_a_doctor Button p-1"
                              type="button"
                              onClick={() => handleClick(doctor)}
                            >
                              Book Appointment
                            </button>
                            <button
                              onClick={() => {
                                navigate(
                                  `/patient-portal/my-doctors/${doctor?.user_id}`
                                );
                              }}
                              className="fw-bold rounded fint_a_doctor Button p-1 d-flex align-items-center justify-content-center gap-2"
                            >
                              <FaCalendar /> Doctor Calendar
                            </button>
                          </div>
                        ) : null}

                        {/* <Link to={link} className="text-decoration-none"> */}
                        {/* <div class="d-grid gap-2 col-5 col-lg-10 col-md-10 m-2">
                          <button
                            onClick={() => SingleProfile(doctor)}
                            class="fw-bold rounded patient-button1 main-text p-1"
                            type="button"
                          >
                            View Profile
                          </button>
                        </div> */}
                        {/* </Link> */}
                      </div>
                    </div>

                    <div className="desktop-accordian-container">
                      <div class="accordion" id={`accordion${doctor?.user_id}`}>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id={`headingOne${doctor?.user_id}`}
                          >
                            <button
                              class="accordion-button collapsed fw-bold"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapseOne${doctor?.user_id}`}
                              aria-expanded="false"
                              aria-controls={`collapseOne${doctor?.user_id}`}
                            >
                              About
                            </button>
                          </h2>
                          <div
                            id={`collapseOne${doctor?.user_id}`}
                            class="accordion-collapse collapse"
                            aria-labelledby={`headingOne${doctor?.user_id}`}
                            data-bs-parent={`#accordion${doctor?.user_id}`}
                          >
                            <div class="accordion-body">{doctor?.about_us}</div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id={`headingTwo${doctor?.user_id}`}
                          >
                            <button
                              class="accordion-button collapsed fw-bold"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapseTwo${doctor?.user_id}`}
                              aria-expanded="false"
                              aria-controls={`collapseTwo${doctor?.user_id}`}
                            >
                              Conditions Treated
                            </button>
                          </h2>
                          <div
                            id={`collapseTwo${doctor?.user_id}`}
                            class="accordion-collapse collapse"
                            aria-labelledby={`headingTwo${doctor?.user_id}`}
                            data-bs-parent={`#accordion${doctor?.user_id}`}
                          >
                            <div class="accordion-body diseases-tabs-container">
                              {allDisease.map((disease, index) => (
                                <span className="capitalized" key={index}>
                                  {disease}
                                  {/* {index !== allDisease.length - 1 && ", "} */}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Desktop;

function OpeningClosingTime({ doctorAvailability }) {
  const currentDay = new Date().toLocaleString("en-us", { weekday: "long" });

  // This is will return the avilabiltiy of current day
  const todayAvailabity = doctorAvailability.filter(
    (availability) =>
      availability.day.toLowerCase() === currentDay.toLocaleLowerCase()
  );

  if (todayAvailabity.length === 0) return <></>;
  else
    return (
      <>
        {todayAvailabity[0]?.opening_time} - {todayAvailabity[0]?.closing_time}
      </>
    );
}
