import { useRef, useState } from "react";
import { ImSpinner8 } from "react-icons/im";
import { IoCloseOutline } from "react-icons/io5";
import { toast } from "react-toastify";

import { useApiContext } from "../contextapi/contextApi";
import useEscapeKey from "../hooks/useEscapekey";
import _ from "lodash";

import "../styles/inviteModal.css";
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default function InvitePatientModal({
  showModal,
  setShowModal,
  setRefresh,
}) {
  const { instance, signInUserData } = useApiContext();
  const [isLoading, setIsLoading] = useState(false);
  const [firstname, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const modalRef = useRef();

  const closeModal = () => setShowModal(false);

  useEscapeKey(closeModal);

  async function inviteByEmail() {
    let earlyReturn = false;

    if (firstname === "") {
      earlyReturn = true;
      setErrors((prev) => ({ ...prev, firstName: "First name is required." }));
    }

    if (lastName === "") {
      earlyReturn = true;
      setErrors((prev) => ({ ...prev, lastName: "Last name is required." }));
    }

    if (email === "") {
      earlyReturn = true;
      setErrors((prev) => ({ ...prev, email: "Email is required." }));
    } else if (!emailRegex.test(email)) {
      earlyReturn = true;
      setErrors((prev) => ({ ...prev, email: "Invalid email format!" }));
    }

    if (earlyReturn) return;

    setIsLoading(true);

    try {
      const { data } = await instance.post("/doctor/invite-patient", {
        first_name: firstname,
        last_name: lastName,
        email,
        doctor_id: signInUserData.doctor.id,
      });
      setRefresh((prev) => !prev);
      toast.success(`Invite sent to ${firstname} successfully.`, {
        theme: "light",
      });
      setIsLoading(false);
      closeModal();
    } catch (error) {
      setIsLoading(false);
      error?.response?.status === 403
        ? toast.error("Patient already exists.")
        : toast.error("Oops! Something went wrong.");
    }
  }

  return (
    <section
      className={`invite_modal_wrapper ${!showModal && "hide_invite_modal"}`}
    >
      <div className="invite_modal_container" ref={modalRef}>
        <div className="invite_modal_header">
          <h3>Invite new patient</h3>
          <IoCloseOutline className="close" onClick={closeModal} />
        </div>
        <div className="invite_modal_body">
          <label>
            First name:
            <input
              type="text"
              value={firstname}
              onChange={(e) => {
                setFirstName(e.target.value);
                setErrors((prev) => _.omit(prev, "firstName"));
              }}
              placeholder="Enter first name..."
              required
            />
            {errors?.firstName && (
              <span className="error_message">{errors?.firstName}</span>
            )}
          </label>
          <label>
            Last name:
            <input
              type="text"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                setErrors((prev) => _.omit(prev, "lastName"));
              }}
              placeholder="Enter last name..."
              required
            />
            {errors?.lastName && (
              <span className="error_message">{errors?.lastName}</span>
            )}
          </label>
          <label>
            Email:
            <input
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrors((prev) => _.omit(prev, ["email"]));
              }}
              placeholder="Enter email..."
              required
            />
            {errors?.email && (
              <span className="error_message">{errors?.email}</span>
            )}
          </label>
        </div>
        <div className="invite_modal_footer">
          <button onClick={closeModal} className="close_invite">
            Close
          </button>
          <button
            className="submit_invite"
            disabled={isLoading}
            onClick={() => inviteByEmail()}
          >
            {isLoading ? <ImSpinner8 className="rotate" /> : "Invite"}
          </button>
        </div>
      </div>
    </section>
  );
}
