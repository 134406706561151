import React, { useState, useEffect } from "react";
import "../../../styles/dashboard.css";
import { Link, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import classNames from "classnames"; // Update the import
import Logo from "../../../assets/dashboard/Logo.svg";
import { useApiContext } from "../../../contextapi/contextApi";
import { format, differenceInYears, parseISO } from "date-fns";
import defultimag from "../../../assets/homepage/profile-img.png";
import Portal20 from "../../../assets/dashboard/Arrow1.png";

function formatDate(dob) {
  // Parse the date string into a Date object
  const dobDate = parseISO(dob);

  // Format the date as "dd MMM yyyy"
  // const formattedDate = format(dobDate, 'yyyy');

  // Calculate the age
  const age = differenceInYears(new Date(), dobDate);

  return `${age} years`;
}

function AppointmentRouting() {
  let location = useLocation();
  let activeLink = location.pathname;
  const { baseImageURL, patientDetail, setPatientDetail } = useApiContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 250);
  }, []);

  // const formatDate = (dateString) => {
  //   const inputDate = new Date(dateString); // Replace this with your input date

  //   const daysOfWeek = [
  //     "Sunday",
  //     "Monday",
  //     "Tuesday",
  //     "Wednesday",
  //     "Thursday",
  //     "Friday",
  //     "Saturday",
  //   ];
  //   const months = [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ];

  //   const dayOfWeek = daysOfWeek[inputDate.getDay()];
  //   const month = months[inputDate.getMonth()];
  //   const dayOfMonth = inputDate.getDate();

  //   const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}`;
  //   return formattedDate;
  // };

  return (
    <>
      {isLoading && !patientDetail ? (
        <div className="content-spinner">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <section className="shadow-sm">
            <div className="container-fluid">
              <div className="row g-0 p-2 ps-4 pe-4">
                <div className="col-lg-5 col-md-5 col-sm-12">
                  <div>
                    <img src={Logo} alt="logo-img" className="img-fluid" />
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12">
                  <div className="mt-2 ms-lg-5">
                    <h5 className="fw-bold mTc main-text">Patient Details</h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="container-fluid">
            <div className="row g-0">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="rounded border p-2 m-4 pb-3">
                  <div className=" text-center m-3">
                    <img
                      src={
                        `${baseImageURL}${patientDetail[0]?.user?.profile_image}` ||
                        defultimag
                      }
                      className="rounded-circle mt-1"
                      style={{ width: "84px", height: "84px" }}
                      alt=""
                    />
                    <h5 className="fw-bold pt-3">
                      {patientDetail[0]?.user?.first_name}
                      {patientDetail[0]?.user?.last_name}
                    </h5>
                    <p className="dashboard-p3 text-muted">
                      Phone: {patientDetail[0]?.user?.phone_no}
                    </p>
                    <p className="dashboard-p3 text-muted">
                      Age: {formatDate(patientDetail[0]?.user?.dob)}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-9 col-md-9 col-sm-12">
                <div className="mt-4 ps-4">
                  <Link to="/doctor-portal/my-patients">
                    <button className="border-white border bg-white">
                      <img
                        src={Portal20}
                        alt="arrow3-img"
                        className="me-2"
                        width={30}
                      />
                    </button>
                  </Link>
                </div>

                <div className="container-fluid mt-2 ps-4">
                  <div className="row g-0">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div>
                        <div className="container-fluid g-0">
                          <div className="bg-white me-3 pe-2">
                            <ul className="nav-tabs">
                              <li className="mt-1">
                                <Link
                                  to="/patient-detail"
                                  className="text-decoration-none"
                                >
                                  <a
                                    className={classNames("nav-style", {
                                      active: activeLink === "/patient-detail",
                                    })}
                                  >
                                    Consult History
                                  </a>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-1">
                      <Outlet />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default AppointmentRouting;
