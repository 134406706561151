import React from "react";
import { useLocation, Link } from "react-router-dom";
import "../styles/dashboard.css";

function Breadcrumbs() {
  const location = useLocation();
  const pathParts = location.pathname.split("/").filter((part) => part !== "");

  function capitalizeFirstLetter(str) {
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  }

  // Generate the breadcrumbs
  const breadcrumbs = pathParts.map((part, index) => ({
    path: `/${pathParts.slice(0, index + 1).join("/")}`,
    label: capitalizeFirstLetter(part.replace(/-/g, " "))
  }));

  return (
    <div className="breadcrumb-container">
      {breadcrumbs.map((breadcrumb, index) => (
        <Link to={breadcrumb.path}>
          {breadcrumb.label}
          <span>&gt;</span>
        </Link>
      ))}
    </div>
  );
}

export default Breadcrumbs;
