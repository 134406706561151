import React from "react";
import "../styles/homepageStyles/HomepageMoreButtonsStyles.css";
import { Link, useLocation } from "react-router-dom";
import { useApiContext } from "../contextapi/contextApi";

function HomepageMoreButtons() {
  const { signInUserData } = useApiContext();
  const location = useLocation();

  const handelScroll = (e) => {
    if (
      e.target.innerText === "Join As A Patient" &&
      location.pathname === "/patient-sign-up"
    ) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }

    if (
      e.target.innerText === "Join As A Doctor" &&
      location.pathname === "/doctor-sign-up"
    ) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  };
  return (
    <>
      <div className="homepage-more-buttons-container">
        <div className="container">
          <div className="container-wrapper">
            <h1>Ready for Dental care you can absolutely trust?</h1>
            <div className="button-container">
              <Link
                to={`${
                  signInUserData && signInUserData?.user_type === "Patient"
                    ? "/patient-portal"
                    : "/patient-sign-up"
                }`}
              >
                <button className="btn-large" onClick={(e) => handelScroll(e)}>
                  {" "}
                  Join As A Patient
                </button>
              </Link>
              <Link
                to={`${
                  signInUserData && signInUserData?.user_type === "Doctor"
                    ? "/doctor-portal"
                    : "/doctor-sign-up"
                }`}
              >
                <button className="btn-large" onClick={(e) => handelScroll(e)}>
                  {" "}
                  Join As A Doctor
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomepageMoreButtons;
