import AgoraRTC from "agora-rtc-sdk-ng";
import { useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosSend } from "react-icons/io";
import { toast } from "react-toastify";

import "../styles/VideoFeed.css";
import {
  useAgoraVideoContext,
  useFocusedContext,
  useRefreshFocused,
} from "./AgoraContext";
import SingleVideoCard from "./SingleVideoCard";
import VideoControls from "./VideoControls";
import VideoFocused from "./VideoFocused";

import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/register/Logo.svg";
import { useApiContext } from "../../../contextapi/contextApi";
import InviteModal from "../../inviteModal";

const appId = "a0633ba975c2436aa51b2c61fe968e6c";

export default function VideoFeed({
  channelName,
  setPermissionDenied,
  videoFeedRef,
  appointmentId,
}) {
  const [remoteUsers, setRemoteUsers] = useState([]);
  const [isJoined, setIsJoined] = useState(false);
  const [isJoining, setisJoining] = useState(false);
  const [agoraToken, setAgoraToken] = useState(null);
  const [deviceConnected, setDeviceConnected] = useState(false);
  const [cameraOptions, setCameraOptions] = useState([]);
  const [initialCameraLabel, setInitialCameraLabel] = useState(""); // State to store the label of camera used by client itself
  const [deviceCameraLabel, setDeviceCameraLabel] = useState("");
  const { focusedUser, setFocusedUser, isFocusedUser } = useFocusedContext();
  const { setTogglefocused } = useRefreshFocused();
  const { client, deviceClient } = useAgoraVideoContext();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { guestUser } = useApiContext();

  const localTracksRef = useRef({
    audioTrack: null,
    videoTrack: null,
  });
  const externalVideoRef = useRef({
    videoTrack: null,
  });

  const videoSliderRef = useRef(null);
  const { signInUserData, instance } = useApiContext();

  const updateCameraOptions = async () => {
    try {
      const cameras = await AgoraRTC.getCameras();
      setCameraOptions(cameras);

      let externalCameraRemoved = true;

      cameras.forEach((camera) => {
        if (deviceCameraLabel === camera.label) {
          externalCameraRemoved = false;
        }
      });

      if (externalCameraRemoved && deviceCameraLabel) {
        await removeDeviceClient();
        setDeviceCameraLabel("");
      }
    } catch (error) {
      toast.error("Could not get external camera.");
    }
  };

  const hanldeUserJoined = useCallback(async (user) => {
    setRemoteUsers((prevUsers) => {
      const userExists = prevUsers.some((u) => u.uid === user.uid);

      if (!userExists) {
        return [
          ...prevUsers,
          {
            uid: user.uid,
            audio: false,
            video: false,
            client: false,
            audioTrack: null,
            videoTrack: null,
          },
        ];
      } else {
        return prevUsers;
      }
    });
  }, []);

  const handleUserPublished = async (user, mediaType) => {
    await client.subscribe(user, mediaType);

    if (mediaType === "video") {
      const remoteVideoTrack = user.videoTrack;

      setRemoteUsers((prevUsers) => {
        const updatedUsers = prevUsers.map((prevUser) => {
          if (prevUser.uid === user.uid) {
            return {
              ...prevUser,
              audio: user.hasAudio,
              video: user.hasVideo,
              audioTrack: user?.audioTrack,
              videoTrack: remoteVideoTrack,
            };
          }
          return prevUser;
        });

        return updatedUsers;
      });
    }

    if (mediaType === "audio") {
      if (user.audioTrack) user.audioTrack.play();

      setRemoteUsers((prevUsers) => {
        return prevUsers.map((User) => {
          if (User.uid === user.uid) {
            return {
              ...User,
              audio: user?.hasAudio,
              audioTrack: user?.audioTrack,
            };
          }
          return User;
        });
      });
    }

    if (isFocusedUser(user)) {
      setTogglefocused((prev) => !prev);
    }
  };

  const handleUserUnpublished = async (user, type) => {
    if (type === "audio") {
      if (user.audioTrack) user.audioTrack.stop();

      setRemoteUsers((prevUsers) => {
        return prevUsers?.map((User) => {
          if (User.uid === user.uid) {
            return { ...User, audio: !User.audio, audioTrack: null };
          }
          return User;
        });
      });
    }

    if (type === "video") {
      setRemoteUsers((prevUsers) => {
        return prevUsers?.map((User) => {
          if (User.uid === user.uid) {
            return { ...User, video: !User.video };
          }
          return User;
        });
      });
    }

    if (isFocusedUser(user)) {
      setTogglefocused((prev) => !prev);
    }
  };

  const navigateBackRoute = () => {
    if (!signInUserData) return "/";

    return signInUserData?.user_type === "Doctor"
      ? "/doctor-portal"
      : "/patient-portal";
  };

  const leaveChannel = async () => {
    await client.leave();
    await deviceClient.leave();
    await localTracksRef.current.audioTrack.close();
    await localTracksRef.current.videoTrack.close();
    await externalVideoRef?.current?.videoTrack?.close();
    setIsJoined(false);
    const newRoute = navigateBackRoute();
    navigate(newRoute);
  };

  const toggleRemoteAudio = (user, muteVideo) => {
    if (!user.uid) return;

    const newUser = remoteUsers.find((u) => u.uid === user.uid);

    if (newUser) {
      !muteVideo ? newUser?.audioTrack?.stop() : newUser?.audioTrack?.play();
    } else return;
  };

  useEffect(() => {
    // if (focusedUser?.host) {
    const newUser = remoteUsers.filter((u) => u?.uid === focusedUser?.uid);
    setFocusedUser(newUser[0]);
    // }
  }, [remoteUsers]);

  const getAgoraToken = async () => {
    try {
      const response = await instance.get(
        `/agora/getToken?channel_name=${channelName}&user_id=1&user_role=1&appoinment_id=${appointmentId}`
      );

      const token = response?.data?.data?.token;

      if (token) {
        return token;
      }
    } catch (error) {
      toast.error("Error in accesing token");
    }
  };

  const createDeviceClient = async (
    showNotification = false,
    initalCameraId,
    token,
    cameraId
  ) => {
    const cameras = await AgoraRTC.getCameras();
    let deviceUID;

    if (cameras.length > 1) {
      try {
        if (cameraId) {
          externalVideoRef.current.videoTrack =
            await AgoraRTC.createCameraVideoTrack({
              cameraId: cameraId,
            });

          cameras.forEach((camera) => {
            if (camera.deviceId === cameraId) {
              setDeviceCameraLabel(camera.label);
            }
          });
        } else {
          const externalCameras = cameras?.map((singleCamera) => {
            if (
              !singleCamera.label.includes("Integrated") &&
              !singleCamera.label.includes("Built-in") &&
              !singleCamera.label.includes("facing") &&
              singleCamera.label !== initalCameraId
            ) {
              return singleCamera;
            }
          });

          externalVideoRef.current.videoTrack =
            await AgoraRTC.createCameraVideoTrack({
              cameraId: externalCameras[0].deviceId,
            });

          setDeviceCameraLabel(externalCameras[0].label);
        }
        deviceUID = await deviceClient.join(appId, channelName, token, null);
        setDeviceConnected(true);

        // Create and store the external video track

        setRemoteUsers((prevUsers) => [
          ...prevUsers,
          {
            uid: deviceUID,
            audio: false,
            video: true,
            client: true,
            device: true,
            videoTrack: externalVideoRef.current.videoTrack,
            audioTrack: null,
          },
        ]);

        await deviceClient.publish(externalVideoRef.current.videoTrack);
      } catch (error) {
        toast.error("External device not accesssible!");
      }
    } else {
      if (showNotification) toast.error("External device not found!");
    }
  };

  const removeDeviceClient = async () => {
    deviceClient.leave();
    externalVideoRef.current?.videoTrack.close();
    setDeviceConnected(false);
    const deviceUser = remoteUsers.filter((u) => u?.device);
    if (deviceUser && deviceUser?.length > 0)
      if (isFocusedUser(deviceUser[0])) setFocusedUser(null);
    setRemoteUsers((prev) => prev.filter((U) => !U.device));
  };

  const toggleDeviceUser = async (cameraId) => {
    const cameras = await AgoraRTC.getCameras();

    if (cameras.length > 1) {
      if (deviceConnected) {
        await removeDeviceClient();
        toast.done("External Device Removed!");
      } else {
        await createDeviceClient(
          true,
          initialCameraLabel,
          agoraToken,
          cameraId
        );
        toast.done("External Device Added!");
      }
    } else {
      if (deviceConnected) {
        await removeDeviceClient();
        toast.done("External Device Removed!");
      } else {
        toast.error("External Device not found!");
      }
    }
  };

  console.log("Remote users: ", remoteUsers);

  useEffect(() => {
    const init = async () => {
      // GET ALL THE CAMERA DEVICES AND SET THE CAMERAS OPTIONS ARRAY
      const cameras = await AgoraRTC.getCameras();
      setCameraOptions(cameras);

      try {
        localTracksRef.current.videoTrack =
          await AgoraRTC.createCameraVideoTrack();
      } catch (errors) {
        setPermissionDenied(true);
        return;
      }

      try {
        localTracksRef.current.audioTrack =
          await AgoraRTC.createMicrophoneAudioTrack();
      } catch (errors) {
        setPermissionDenied(true);
        return;
      }

      const token = await getAgoraToken();

      setAgoraToken(token);

      // CHECK IF USER IS TRYING TO JOIN AGAIN
      const existinguser = remoteUsers?.some(
        (U) => U?.uid === signInUserData?.id
      );

      if (existinguser) {
        toast.error("Conflict! User already in meeting.");
        return;
      }

      setisJoining(true);

      console.log("Credentials while joining: ", {
        appId,
        channelName,
        token,
        id: signInUserData?.user_id || guestUser.id,
      });

      // Initialize the orignal user client
      await client.join(
        appId,
        channelName,
        token,
        signInUserData?.user_id || guestUser.id
      );

      setRemoteUsers((prevUsers) => [
        ...prevUsers,
        {
          uid: signInUserData?.user_id || guestUser.id,
          audio: true,
          video: true,
          client: true,
          host: true,
          videoTrack: localTracksRef.current.videoTrack,
          audioTrack: localTracksRef.current.audioTrack,
        },
      ]);

      // Creating device client and publishing it
      if (signInUserData) {
        setInitialCameraLabel(localTracksRef.current.videoTrack._deviceName);
        await createDeviceClient(
          false,
          localTracksRef.current.videoTrack._deviceName,
          token
        );
      }

      // Publish the local tracks for both clients
      await client.publish(localTracksRef.current.audioTrack);
      await client.publish(localTracksRef.current.videoTrack);

      console.log("Reached at this point....");

      setisJoining(false);
      setIsJoined(true);
      toast.info("Joined room successfully.");
    };

    try {
      if (!isJoining && !isJoined) {
        init();
      }
    } catch (error) {
      toast.error("Error during initialization");
    }

    try {
      client.on("user-joined", hanldeUserJoined);
      client.on("user-published", handleUserPublished);
      client.on("user-unpublished", handleUserUnpublished);
      client.on("user-left", (user) => {
        setRemoteUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
        if (isFocusedUser(user)) setFocusedUser(null);
      });
      client.on("token-privilege-will-expire", () => {
        toast.warn("Meeting is about to end!");
        console.log("Token privilage will expire");
      });
      client.on("token-privilege-did-expire", () => {
        leaveChannel();
        console.log("Token privilage did expire");
      });
      client.on("stream-fallback", (something) => {
        console.log("Stream fallback: ", something);
        toast.warn("Stream Fallback");
      });
      client.on("stream-type-changed", (something) => {
        console.log("stream-type-changed: ", something);
        toast.warn("Stream Fallback");
      });
    } catch (error) {
      console.error("Error in adding events");
    }

    navigator.mediaDevices.addEventListener(
      "devicechange",
      updateCameraOptions
    );

    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        updateCameraOptions
      );
      client.removeAllListeners();
    };
  }, [client, deviceClient]);

  if (isJoining) {
    return (
      <div className="video__feed__loader">
        <AiOutlineLoading3Quarters className="chat_spinner" />
      </div>
    );
  } else if (isJoined) {
    return (
      <section
        className={`${guestUser ? "vc_component_guest" : "vc_component"} ${
          focusedUser ? "vc_component_focused" : ""
        }`}
      >
        <div className="vc_heading">
          <img src={Logo} alt="Remote Medical Care" />
          {signInUserData ? (
            <>
              <button
                className="rounded modal_toggler_button p-1"
                type="button"
                onClick={() => setShowModal(true)}
              >
                Invite user <IoIosSend />
              </button>
              <InviteModal
                appointmentId={appointmentId}
                showModal={showModal}
                setShowModal={setShowModal}
                setRefresh={() => {}}
              />
            </>
          ) : null}
        </div>
        {focusedUser && <VideoFocused user={focusedUser} />}
        <div
          className={`vc_feed_parent ${focusedUser ? "vc_feed_focused" : ""}`}
        >
          <div className="vc_feed" ref={videoSliderRef}>
            {remoteUsers.length > 0 &&
              remoteUsers.map((user) => (
                <SingleVideoCard
                  toggleRemoteAudio={toggleRemoteAudio}
                  key={user.uid}
                  user={user}
                  appointmentId={appointmentId}
                />
              ))}
          </div>
        </div>
        <div className="vc_controls">
          <VideoControls
            client={client}
            setRemoteUsers={setRemoteUsers}
            localTracksRef={localTracksRef}
            setIsJoined={setIsJoined}
            videoFeedRef={videoFeedRef}
            toggleDeviceUser={toggleDeviceUser}
            deviceConnected={deviceConnected}
            externalVideoRef={externalVideoRef}
            cameraOptions={cameraOptions}
            initialCameraLabel={initialCameraLabel}
          />
        </div>
      </section>
    );
  }
}
