import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useApiContext } from "../../contextapi/contextApi";
import "./scheduleappointment.css";

function ScheduleAppointmentFirst() {
  const { baseImageURL, instance, setDoctorsList, signInUserData } =
    useApiContext();
  const [checkboxOptions, setCheckboxOptions] = useState([]); // First list of checkbox are shown here
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState([]);
  const [postAnswersArray, setPostAnswersArray] = useState([]);
  const [questionsList, setQuestionList] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentSubQuestionIndex, setCurrentSubQuestionIndex] = useState(0);
  const [answeredQuestionIds, setAnsweredQuestionIds] = useState([]);
  const [subQuestionsArray, setSubQuestionsArray] = useState([]);
  const [displayingSubQuestions, setDisplayingSubQuestions] = useState(false);
  const [selectedMainAnswer, setSelectedMainAnswer] = useState(null); // Track the selected main answer
  const [reasonId, setReasonId] = useState(null); // Track the selected main answer
  const [textValue, setTextValue] = useState({
    question_id: null,
    answer: null,
  }); // Track the selected main answer
  const [lastQuestion, setLastQuestion] = useState(false); // Track the selected main answer

  const navigate = useNavigate();

  useEffect(() => {
    fetchCheckboxOptions();
  }, []);

  const fetchCheckboxOptions = async () => {
    try {
      const response = await instance.get("/patient/reasons-list");
      setCheckboxOptions(response.data.data);
    } catch (error) {
      console.error("Error fetching checkbox options:", error);
    }
  };

  const handleCheckboxClick = (option) => {
    if (selectedCheckbox === option) {
      setSelectedCheckbox(null);
    } else {
      setSelectedCheckbox(option);
    }
  };

  const handleAnswerClick = (question, questionId) => {
    if (typeof question === "string") {
      setLastQuestion(true);
      setTextValue({
        question_id: questionId,
        answer: question,
      });
      return;
    }

    if (question?.sub_questions?.length > 0) {
      setDisplayingSubQuestions(true);
    }

    if (!answeredQuestionIds.includes(question.id)) {
      setAnsweredQuestionIds((prevIds) => [...prevIds, question.id]);
    }
    if (selectedAnswer.includes(question.id)) {
      setSelectedAnswer((prevSelected) =>
        prevSelected.filter((id) => id !== question.id)
      );
    } else {
      setSelectedAnswer((prevSelected) => [...prevSelected, question.id]);
    }
  };

  const handleSubmit = async () => {
    if (selectedCheckbox) {
      setReasonId(selectedCheckbox.id);
      try {
        const response = await instance.get(
          `/patient/questions-list/${selectedCheckbox.id}`
        );
        console.log("Response: ", response.data.data);
        setQuestionList(response.data.data);
        setCheckboxOptions(null);
      } catch (error) {
        toast.error("Something went wrong! Please try again.");
      }
    } else {
      toast.error("Please select a checkbox before continuing.");
    }
  };

  const allQuestionsAnswered = () => {
    return questionsList.every((question) =>
      selectedAnswer.includes(question.id)
    );
  };

  const handleNextQuestion = async () => {
    if (lastQuestion) {
      console.log("This function was called....");
      const response = await instance.post("/patient/doctors-list", {
        answers_array: answeredQuestionIds,
        answers_id: textValue,
        reason_id: reasonId,
      });

      localStorage.setItem("answer_ids", JSON.stringify(answeredQuestionIds));
      localStorage.setItem("answers_id", JSON.stringify(textValue));

      if (response.data.statusCode === 200) {
        console.log("Doctor List form backend: ", response.data);
        setDoctorsList(response.data);
        navigate("/patient-portal/doctor-list");
      }
    }

    setDisplayingSubQuestions(false);

    if (selectedAnswer.length > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      console.log(answeredQuestionIds);

      setSelectedAnswer([]); // Reset selected answer for the next question

      if (currentQuestionIndex === questionsList.length - 1) {
        if (allQuestionsAnswered()) {
          try {
          } catch (error) {
            console.error("Error hitting the API");
          }
        } else {
          // console.log(answeredQuestionIds);

          localStorage.setItem(
            "answer_ids",
            JSON.stringify(answeredQuestionIds)
          );
          localStorage.setItem("answers_id", JSON.stringify(textValue));

          const response = await instance.post("/patient/doctors-list", {
            answers_array: answeredQuestionIds,
            answers_id: null,
            reason_id: reasonId,
          });
          if (response.data.statusCode === 200) {
            console.log("Doctor List form backend: ", response.data);
            setDoctorsList(response.data);
            navigate("/patient-portal/doctor-list");
          }
        }
      }
    } else {
      toast.warning("Please answer before continuing...");
    }
  };

  return (
    <>
      <nav className="shadow-bottom shadow bg-white pt-2 pb-2 appointment_questions">
        <div className="appointment_questions_heading">
          <h3 className="fw-bold mTc">Perfect Match Health Assessment</h3>
          <span>
            Answer a Few Questions to Discover the
            Specialist Tailored Just for You
          </span>
        </div>
        {checkboxOptions && (
          <div className="container appointment_questions_wrapper">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="appointment_wrapper_inner">
                  <p className="mTc font-p2">
                    Please state the reason for your consultation
                  </p>

                  {checkboxOptions?.map((option) => (
                    <div className="d-flex" key={option?.id}>
                      <div
                        className={`circle-checkbox ${
                          selectedCheckbox === option ? "checked" : ""
                        }`}
                        onClick={() => handleCheckboxClick(option)}
                      >
                        <div className="checkbox-inner"></div>
                      </div>
                      <p className="ps-2 font-p1">{option.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div class="d-grid gap-2 col-5 mx-auto">
              <button class="Button" type="button" onClick={handleSubmit}>
                Continue
              </button>
            </div>
          </div>
        )}

        {questionsList &&
          !displayingSubQuestions &&
          questionsList.length > currentQuestionIndex && (
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="mt-4">
                    <p className="mTc font-p2">
                      {questionsList[currentQuestionIndex].question_answer}
                    </p>

                    {questionsList[currentQuestionIndex].is_multi_selection ===
                    0 ? (
                      // Render radio buttons
                      questionsList[currentQuestionIndex].answers.map(
                        (answer) => (
                          <div className="d-flex" key={answer.id}>
                            <div
                              className={`circle-checkbox ${
                                selectedAnswer.includes(answer.id)
                                  ? "checked"
                                  : ""
                              }`}
                              onClick={() => handleAnswerClick(answer)}
                            >
                              <div className="checkbox-inner"></div>
                            </div>
                            <p className="ps-2 font-p1">
                              {answer.question_answer}
                            </p>
                          </div>
                        )
                      )
                    ) : questionsList[currentQuestionIndex]
                        .is_multi_selection === 1 ? (
                      // Render checkboxes
                      questionsList[currentQuestionIndex].answers.map(
                        (answer) => (
                          <div className="d-flex" key={answer.id}>
                            <div
                              className={`circle-checkbox ${
                                selectedAnswer.includes(answer.id)
                                  ? "checked"
                                  : ""
                              }`}
                              onClick={() => handleAnswerClick(answer)}
                            >
                              <div className="checkbox-inner"></div>
                            </div>
                            <p className="ps-2 font-p1">
                              {answer.question_answer}
                            </p>
                          </div>
                        )
                      )
                    ) : questionsList[currentQuestionIndex]
                        .is_multi_selection === 3 ? (
                      // Render input text field
                      <input
                        type="text"
                        name="textInput"
                        id="textInput"
                        onChange={(e) =>
                          handleAnswerClick(
                            e.target.value,
                            questionsList[currentQuestionIndex].id
                          )
                        }
                        className="form-control"
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="d-grid gap-2 col-5 mx-auto">
                <button
                  className="Button"
                  type="button"
                  onClick={handleNextQuestion}
                >
                  Continue
                </button>
              </div>
            </div>
          )}

        {/* Check if there are sub-questions */}
        {questionsList &&
          displayingSubQuestions &&
          questionsList.length > currentQuestionIndex && (
            <div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="mt-4"></div>
                    {/* Render sub-questions and their answers */}
                    {questionsList[currentQuestionIndex].answers.map((answer) =>
                      // Check if the selected answer is "Yes" to render sub-questions
                      selectedAnswer.includes(answer.id) &&
                      answer.sub_questions.length > 0 ? (
                        <div
                          className="mt-4 d-flex my-4 flex-column"
                          key={answer.id}
                        >
                          <p className="font-p2">
                            {answer.sub_questions[0].question_answer}
                          </p>
                          <div className="mt-5 d-flex justify-content-center align-items-center">
                            {answer.sub_questions[0]?.question_image && (
                              <img
                                src={`${baseImageURL}${answer.sub_questions[0]?.question_image}`}
                                alt=""
                                style={{ width: "300px" }}
                              />
                            )}
                          </div>

                          {answer.sub_questions.map((subQuestion) => (
                            <div
                              className="d-flex justify-content-between align-items-center"
                              key={subQuestion.id}
                            >
                              {subQuestion.answers.map((subAnswer) => (
                                <div className="d-flex" key={subAnswer.id}>
                                  <div
                                    className={`circle-checkbox ${
                                      selectedAnswer.includes(subAnswer.id)
                                        ? "checked"
                                        : ""
                                    }`}
                                    onClick={() => handleAnswerClick(subAnswer)}
                                  >
                                    <div className="checkbox-inner"></div>
                                  </div>
                                  <p className="ps-2 font-p1">
                                    {subAnswer.question_answer}
                                  </p>
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      ) : null
                    )}
                    <div className="d-grid gap-2 col-5 mx-auto">
                      <button
                        className="Button"
                        type="button"
                        onClick={handleNextQuestion}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </nav>
    </>
  );
}

export default ScheduleAppointmentFirst;
