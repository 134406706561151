import React, { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import Portal18 from "../../assets/dashboard/Upload.png";
import { useApiContext } from "../../contextapi/contextApi";
import Validation from "../../subcomponents/Validation";

const defaultImage = require("../../assets/dashboard/box-frame1.jpg");

const Profile3 = () => {
  const { baseImageURL, signInUserData, setSignInUserData, instance } =
    useApiContext();
  const [maxDate, setMaxDate] = useState(getCurrentDate());
  const [errors, setErrors] = useState(null);
  const [image, setImage] = useState(
    `${baseImageURL}${signInUserData?.profile_image}` || defaultImage
  );
  const [firstName, setFirstName] = useState(signInUserData.first_name);
  const [lastName, setLastName] = useState(signInUserData.last_name);
  const [phone, setPhone] = useState(signInUserData.phone_no);
  const [dob, setdob] = useState(signInUserData.dob);
  const [gender, setGender] = useState(signInUserData.gender);
  const [insurance, setInsurance] = useState(signInUserData?.insurance_preference || "");
  const [file, setFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function handleValidation(event) {
    event.preventDefault();
    const updateData = {
      first_name: firstName,
      last_name: lastName,
      phone_no: phone,
      dob: dob,
    };
    const errors = Validation(updateData, true);

    setErrors(errors);

    const formData = new FormData();
    formData.append("profile_image", file);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("insurance_preference", insurance);
    formData.append("gender", gender);
    formData.append("user_id", signInUserData?.user_id);
    formData.append("phone_no", phone);
    formData.append("dob", dob);

    if (
      !errors.phone_no &&
      !errors.first_name &&
      !errors.last_name &&
      !errors.dob
    ) {
      setIsLoading(true);
      instance
        .post("/patient/update-profile", formData, {
          headers: {
            "Content-Type": "multipart/-data", // Important for file uploads
          },
        })
        .then((response) => {
          setIsLoading(false);
          setSignInUserData(response.data.data.user);
          toast.success("Data update successfully.");
        })
        .catch((error) => {
          setIsLoading(false);
          alert(error);
        });
    }
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
    };
    if (file) {
      setFile(file);
      reader.readAsDataURL(file);
    }
  };

  // ---------Usman-----------
  function handleInsuranceChange(event) {
    const selectedValue = event.target.value;
    setInsurance(selectedValue);
    localStorage.setItem("selectedInsurance", selectedValue);
  }

  function handleGenderChange(event) {
    const selectedValue = event.target.value;
    setGender(selectedValue);
  }

  // Page load ke samay localStorage se stored value ko retrieve karein
  useEffect(() => {
    const storedInsurance = localStorage.getItem("selectedInsurance");
    if (storedInsurance) {
      setInsurance(storedInsurance);
    }
  }, []);

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className=" mb-4">
              <h4 className="fw-bold mTc pro-set-h4">Basic Information</h4>
            </div>
            <div className="d-flex">
              <div>
                {image && (
                  <div className="">
                    <img
                      src={image}
                      alt="Profile"
                      className="rounded-box-image-frame rounded"
                      width="100"
                      height="100"
                      style={{ objectFit: "cover", objectPosition: "center" }}
                    />
                  </div>
                )}
              </div>
              <div>
                <label
                  className="rounded list-button
                      dashboard-p3 text-center col-lg-6 col-sm-12 mo7 p-2 mb-1 ms-4 mt-4"
                  htmlFor="upload-input"
                >
                  <img src={Portal18} alt="" width={18} /> Upload Photo
                  <input
                    id="upload-input"
                    type="file"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                </label>
                <p className="fw-bold text-muted ms-4 dashboard-p3 main-text mTc">
                  Allowed JPG, PNG. Max size of 2MB
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-5 mt-3">
          <h5 className="fw-bold mTc dashboard-h5">Personal Information</h5>
          <form onSubmit={handleValidation}>
            <div className="mt-4">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label
                    for="exampleInputEmail1"
                    className="form-label fw-bold mTc mo12"
                  >
                    First Name *
                  </label>
                  <input
                    type="text"
                    placeholder="First Name"
                    className="form-control me-2 mb-2 mt-2 register-input"
                    aria-describedby="emailHelp"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setErrors(null);
                    }}
                    value={firstName}
                    required
                  />
                  {errors && errors.first_name && (
                    <p className="text-danger input-p">{errors.first_name}</p>
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label
                    htmlFor="input2"
                    for="exampleInputEmail1"
                    className="form-label fw-bold mTc mo12"
                  >
                    Last Name *
                  </label>
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="form-control mt-2 register-input"
                    id="input2"
                    name="input2"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setErrors(null);
                    }}
                    aria-describedby="emailHelp"
                    required
                  />
                  {errors && errors.last_name && (
                    <p className="text-danger input-p">{errors.last_name}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-2">
              <label
                for="exampleInputEmail1"
                className="form-label fw-bold mTc mo12"
              >
                Phone Number
              </label>
              <div id="survey-form" className="mt-2">
                <PhoneInput
                  country={"de"}
                  className="marginBottom"
                  value={phone}
                  onChange={(newPhone) => {
                    setPhone(newPhone);
                  }}
                  inputProps={{
                    style: {
                      height: "35px",
                      width: "99%",
                    },
                  }}
                />
              </div>
            </div>

            <div className="mt-3">
              <label
                for="exampleInputEmail1"
                className="form-label fw-bold mTc mo12"
              >
                Email
              </label>
              <div className="d-flex position-relative mt-2">
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control me-2 mb-2 register-input"
                  aria-describedby="emailHelp"
                  value={signInUserData?.email}
                  readOnly
                />
              </div>
            </div>

            <div className="mt-3">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label
                    for="exampleInputEmail1"
                    className="form-label fw-bold mTc mo12"
                  >
                    Date of Birth
                  </label>
                  <div className="flex position-relative">
                    <input
                      type="date"
                      placeholder="Select your date of birth"
                      name="dob"
                      className="custom-date-input55 mt-2"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        setdob(e.target.value);
                        setErrors(null);
                      }}
                      value={dob}
                      max={maxDate}
                    />
                    {errors && errors.dob && (
                      <p className="text-danger input-p">{errors.dob}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label
                    htmlFor="input2"
                    for="exampleInputEmail1"
                    className="form-label fw-bold mTc mo12"
                  >
                    Gender
                  </label>
                  <select
                    onChange={handleGenderChange}
                    value={gender}
                    className="form-select register-input mTc mo12"
                    aria-label="Default select example"
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <label
                  for="exampleInputEmail1"
                  className="form-label fw-bold mTc mo12"
                >
                  Insurance Type
                </label>
                <div className="d-flex">
                  {/* <select
                    onChange={(e) => setInsurance(e.target.value)}
                    className="form-select register-input mTc mo12"
                    aria-label="Default select example"
                  >
                    <option value="Privately">Privately</option>
                    <option value="Legally">Legally</option>
                    <option value="Both">Both</option>
                  </select> */}
                  <select
                    onChange={handleInsuranceChange}
                    value={insurance}
                    className="form-select register-input mTc mo12"
                    aria-label="Default select example"
                  >
                    <option value="Privately">Privately</option>
                    <option value="Legally">Legally</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="d-grid gap-2 col-6 mt-5">
              <button
                disabled={isLoading}
                class="register-button2 dashboard-p3 p-2 rounded"
                type="submit"
              >
                {isLoading ? (
                  <AiOutlineLoading3Quarters className="chat_spinner" />
                ) : (
                  "Save Changes"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Profile3;
