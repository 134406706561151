import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";

export const ApiContext = createContext();

const baseImageURL =
  "https://stagingbackend.dental-telecare.com/root/storage/app/public/";

export const instance = axios.create({
  baseURL: "https://stagingbackend.dental-telecare.com/api",
});

instance.defaults.headers.post["Content-Type"] =
  "application/json;charset=utf-8";
instance.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export function ApiProvider({ children }) {
  const [apiResponseData, setApiResponseData] = useState(null);
  const [apiDoctorData, setApiDoctorData] = useState(null);

  const [tokenChannel, setTokenChannel] = useState(() => {
    const agoraToken = localStorage.getItem("agoraToken");
    return agoraToken;
  });

  const [signInUserData, setSignInUserData] = useState(() => {
    const savedUserData = localStorage.getItem("signInUserData");
    return savedUserData;
  });

  const [userDetail, setUserDetail] = useState(() => {
    const userdataa = localStorage.getItem("user-profile");
    return userdataa;
  });

  const [chekoutproduct, setCheckOutProduct] = useState(() => {
    const checkoutdetail = localStorage.getItem("checkproduct-detail");
    return checkoutdetail;
  });

  const [doctorsList, setDoctorsList] = useState([]);
  const [patientDetail, setPatientDetail] = useState(null);
  const [numberList, setNumberList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [appdetail, setAppDetail] = useState(null);
  const [apquestions, setApQuestions] = useState(null);
  const [history, setHistory] = useState(null);
  const [invoiceDetail, setInvoiceDetail] = useState(null);
  const [schdulerequestid, setSchduleRequestid] = useState(null);
  const [newAppointmentRequest, setNewAppointmentRequest] = useState(0);
  const [isVerified, setIsVerified] = useState(true);
  const [chatPeerId, setChatPeerId] = useState(null);
  const [newMessages, setNewMessages] = useState(null);
  const [appointmentQuestionsArray, setAppointmentQuestionsArray] = useState(
    []
  );
  const [appointQuestionsInput, setAppointQuestionsInput] = useState(null);
  const [appointmentType, setAppointmentType] = useState();
  const [gptQuestions, setGptQuestions] = useState([]);
  const [appointReason, setAppointReason] = useState();
  const [reasonName, setreasonName] = useState();
  const [guestUser, setGuestuser] = useState();

  useEffect(() => {
    if (chekoutproduct) {
      const parsedUserDataae = JSON.parse(chekoutproduct);
      setCheckOutProduct(parsedUserDataae);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    // Save userData to localStorage whenever it changes
    if (signInUserData) {
      localStorage.setItem("signInUserData", JSON.stringify(signInUserData));
    } else {
      localStorage.removeItem("signInUserData");
    }

    if (userDetail) {
      localStorage.setItem("user-profile", JSON.stringify(userDetail));
    } else {
      localStorage.removeItem("user-profile");
    }

    if (tokenChannel) {
      localStorage.setItem("agoraToken", JSON.stringify(tokenChannel));
      setTimeout(() => {
        localStorage.removeItem("agoraToken");
      }, 30 * 60 * 1000);
    } else {
      localStorage.removeItem("agoraToken");
    }
  }, [signInUserData, userDetail, tokenChannel]);

  useEffect(() => {
    if (userDetail) {
      const parsedUserDataa = JSON.parse(userDetail);
      setUserDetail(parsedUserDataa);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (tokenChannel) {
      const parsedUserDataae = JSON.parse(tokenChannel);
      setTokenChannel(parsedUserDataae);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (signInUserData) {
      const parsedUserData = JSON.parse(signInUserData);
      setSignInUserData(parsedUserData);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="content-spinner">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <ApiContext.Provider
      value={{
        guestUser,
        setGuestuser,
        reasonName,
        setreasonName,
        appointReason,
        setAppointReason,
        appointmentType,
        setAppointmentType,
        gptQuestions,
        setGptQuestions,
        appointmentQuestionsArray,
        setAppointmentQuestionsArray,
        appointQuestionsInput,
        setAppointQuestionsInput,
        chatPeerId,
        setChatPeerId,
        isVerified,
        setIsVerified,
        newAppointmentRequest,
        setNewAppointmentRequest,
        baseImageURL,
        schdulerequestid,
        setSchduleRequestid,
        invoiceDetail,
        setInvoiceDetail,
        setCheckOutProduct,
        chekoutproduct,
        history,
        setHistory,
        apquestions,
        setApQuestions,
        appdetail,
        setAppDetail,
        tokenChannel,
        setTokenChannel,
        userDetail,
        setUserDetail,
        numberList,
        setPatientDetail,
        patientDetail,
        instance,
        signInUserData,
        setSignInUserData,
        apiDoctorData,
        setApiDoctorData,
        setSignInUserData,
        signInUserData,
        doctorsList,
        setDoctorsList,
        newMessages,
        setNewMessages,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}
export function useApiContext() {
  return useContext(ApiContext);
}
