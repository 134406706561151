import React, { useState } from "react";
import "../../../styles/dashboard.css";
import { Link, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import classNames from "classnames"; // Update the import
import Logo from "../../../assets/dashboard/Logo.svg";
import Portal1 from "../../../assets/dashboard/Doctor7.svg";
import { useApiContext } from "../../../contextapi/contextApi";

function DoctorRoutes1() {
  const { apiDoctorData} = useApiContext();
  let location = useLocation();
  let activeLink = location.pathname;

  return (
    <>
      <section className="shadow-sm">
        <div className="container-fluid">
          <div className="row g-0 p-2 ps-4 pe-4">
            <div className="col-lg-5 col-md-5 col-sm-12">
              <div>
                <img src={Logo} alt="logo-img" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12">
              <div className="mt-2 ms-lg-5">
                <h5 className="fw-bold mTc main-text">Doctor Details</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid">
        <div className="row g-0">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="rounded border p-2 m-4 pb-5">
              <div className=" text-center m-3">
                <img src={Portal1} className="doctor10-img" alt="doctor-img" />
                <h5 className="fw-bold pt-3">{apiDoctorData.user.first_name +' '+ apiDoctorData.user.last_name}</h5>
                <p className="dashboard-p3 text-muted">
                Dentist, Oral and Maxillofacial...
                </p>
              </div>
              <hr />
              <div className="card shadow-sm p-2">
                <div className="d-flex justify-content-between p-2">
                  <p className=" patient-p patient-p-color fw-bold  dashboard-p3">
                    Online Consultation
                  </p>
                  <a href="#" className=" patient-p text-danger  dashboard-p3">
                    Reschedule
                  </a>
                </div>
                <div className="ps-2 pe-2 pb-2">
                  <p className="patient-p text-muted  dashboard-p3">
                    Monday 12 July, 2023
                  </p>
                  <p className="patient-p text-muted  dashboard-p3">
                    9:45 pm - 10:00 pm
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-9 col-md-9 col-sm-12">
            <div className="container-fluid mt-2 ps-4">
              <div className="row g-0">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div>
                    <div className="container-fluid g-0">
                      <div className="bg-white me-3 pe-2">
                        <ul className="nav-tabs">
                          <li className="mt-1">
                            <Link
                              to="/doctor-personal-detail"
                              className="text-decoration-none"
                            >
                              <a
                                className={classNames("nav-style", {
                                  active:
                                    activeLink === "/doctor-personal-detail",
                                })}
                              >
                                Consult History
                              </a>
                            </Link>
                          </li>
                          <li className="mt-1">
                            <Link
                              to="/doctor-personal-detail/records"
                              className="text-decoration-none"
                            >
                              <a
                                className={classNames("nav-style", {
                                  active:
                                    activeLink ===
                                    "/doctor-personal-detail/records",
                                })}
                              >
                                Medical Records
                              </a>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoctorRoutes1;
