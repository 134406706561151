import { useRef, useState } from "react";
import { ImSpinner8 } from "react-icons/im";
import { IoCloseOutline } from "react-icons/io5";
import { toast } from "react-toastify";

import { useApiContext } from "../contextapi/contextApi";
import useEscapeKey from "../hooks/useEscapekey";

import "../styles/inviteModal.css";

export default function CancelAppointmentModal({
  showModal,
  setShowModal,
  appointmentId,
  setRefresh,
}) {
  const { instance, signInUserData } = useApiContext();
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState(null);
  const modalRef = useRef();

  const closeModal = () => setShowModal(false);

  useEscapeKey(closeModal);

  async function cancelAppointment() {
    setIsLoading(true);

    if (reason === "") {
      setErrors("Reason cannot be empty.");
      setIsLoading(false);
      return;
    }

    try {
      const { data } = await instance.post("/doctor/cancel-appoinment", {
        user_id: signInUserData.user_id,
        appoinment_id: appointmentId,
        reason,
      });
      toast.success(`Appointment Cancelled Successfully.`, { theme: "light" });
      setIsLoading(false);
      setRefresh();
      closeModal();
    } catch (error) {
      setIsLoading(false);
      toast.error(`Oops! something went wrong.`);
    }
  }

  return (
    <section
      className={`invite_modal_wrapper ${!showModal && "hide_invite_modal"}`}
    >
      <div className="invite_modal_container" ref={modalRef}>
        <div className="invite_modal_header">
          <h3>Cancel meeting</h3>
          <IoCloseOutline className="close" onClick={closeModal} />
        </div>
        <div className="invite_modal_body">
          <label>
            Reason:
            <input
              type="name"
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
                setErrors(null);
              }}
              placeholder="Enter reason..."
              required
            />
            {errors && <span className="error_message">{errors}</span>}
          </label>
        </div>
        <div className="invite_modal_footer">
          <button onClick={closeModal} className="close_invite">
            Close
          </button>
          <button
            className="submit_invite"
            disabled={isLoading}
            onClick={() => cancelAppointment()}
          >
            {isLoading ? (
              <ImSpinner8 className="rotate" />
            ) : (
              "Cancel Appointment"
            )}
          </button>
        </div>
      </div>
    </section>
  );
}
