import React from "react";
import { useApiContext } from "../contextapi/contextApi";
import "../styles/dashboard.css";
import Desktop from "../subcomponents/Desktop";

function DoctorList() {
  const { doctorsList } = useApiContext();

  return (
    <>
      <section className="pt-4 mt-3">
        <div className="container">
          <div className="mt-2 mb-4">
            <h3 className="main-text mTc ms-2">
              {doctorsList?.data?.length} Dentists available
            </h3>
          </div>
          <div className="mb-5">
            {doctorsList &&
              doctorsList?.data?.map((doctor, path) => (
                <Desktop path="doc-app" doctor={doctor} key={doctor.id} />
              ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default DoctorList;
