import React, { useState } from "react";
import Navbar from "./Navbar";
import Link from "@material-ui/core/Link";
import Review from "../assets/homepage/review.png";

const defaultImage = require("../assets/homepage/profile-img.png");

function Preview() {
  const [file, setFile] = useState("");

  const [profile_image, setProfileImage] = useState(defaultImage);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setProfileImage(reader.result);
    };
    if (file) {
      setFile(file);
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <Navbar help="Doctor's Dashboard" disp="d-none" />
      <div className="container-fluid">
        <div className="row g-0">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="rounded border text-center p-2 m-3">
              {profile_image && (
                <div>
                  <img
                    src={profile_image}
                    alt="Profile"
                    className="rounded-circle"
                    width="100"
                    height="100"
                  />
                </div>
              )}
              <label
                className="rounded list-button dashboard-p3 col-6 p-1 mb-1 mt-4"
                htmlFor="upload-input"
              >
                Upload
                <input
                  id="upload-input"
                  type="file"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
              </label>
            </div>
            <div className="rounded border text-center p-2 m-3">
              <div
                className="nav dim-content flex-column mb-5 pb-5 nav-pills"
                id="v-tabs"
                role="tablist"
                aria-orientation="vertical"
                aria-disabled
              >
                <a
                  className="nav-link mt-2 mb-2"
                  id="tab1-tab"
                  data-bs-toggle="pill"
                  href="#tab1"
                  role="tab"
                  aria-controls="tab1"
                  aria-selected="true"
                >
                  Dashboard
                </a>
                <a
                  className="nav-link  mt-4 mb-4"
                  id="tab2-tab"
                  data-bs-toggle="pill"
                  href="#tab2"
                  role="tab"
                  aria-controls="tab2"
                  aria-selected="false"
                >
                  Appointments
                </a>
                <a
                  className="nav-link mt-2 mb-2"
                  id="tab3-tab"
                  data-bs-toggle="pill"
                  href="#tab3"
                  role="tab"
                  aria-controls="tab3"
                  aria-selected="true"
                >
                  My Doctors
                </a>

                <a
                  className="nav-link  mt-4 mb-4"
                  id="tab6-tab"
                  data-bs-toggle="pill"
                  href="#tab6"
                  role="tab"
                  aria-controls="tab6"
                  aria-selected="false"
                >
                  Messages
                </a>
                <a
                  className="nav-link mt-2 mb-2"
                  id="tab7-tab"
                  data-bs-toggle="pill"
                  href="#tab7"
                  role="tab"
                  aria-controls="tab7"
                  aria-selected="true"
                >
                  Profile Settings
                </a>
                <a
                  className="nav-link  mt-4 mb-5"
                  id="tab8-tab"
                  data-bs-toggle="pill"
                  href="#tab8"
                  role="tab"
                  aria-controls="tab8"
                  aria-selected="false"
                >
                  Log Out
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-12">
            <div className="rounded border p-1 mt-3">
              <div className="ms-3">
                <h6 className="fw-bold pt-2 mTc main-text">
                  Profile In Review
                </h6>

                <p className=" w-75 mTc mo9">
                  Your Profile is in review, the admin will review the data
                  you’ve provide and approve your request. Make sure that the
                  added requirements are correct
                </p>
                <p className=" w-75 mTc mo9"></p>
                <Link className="text-decoration-none">
                  <p className="previe-btn"> Update Profile</p>
                </Link>
              </div>
            </div>

            <div className="text-center mt-2">
              <div>
                <img
                  src={Review}
                  alt="review-img"
                  className="img-fluid"
                  style={{ width: "20%" }}
                />
              </div>
              <div>
                <h5 className="fw-bold pt-3 mTc main-text">
                  Profile Is In Review
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Preview;
