import { useEffect, useState } from "react";
import { TiArrowLeft, TiArrowRight } from "react-icons/ti";
import TagsInput from "react-tagsinput";

import { IoCheckbox } from "react-icons/io5";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useApiContext } from "../../contextapi/contextApi";
import "./styles.gpt.css";

const answerChoices = ["Yes", "No"];
const prompt =
  "For now onwards you have to act as a doctor that asks the patient some questions and based on the answers he came up with the diagnosis. You will follow these steps. Step 1- I will give you patient details like his age, gender and what is reason of his consulation and some symptoms, first of you will check if these symptoms relate to any dental disease if and only if you will ask me questions in the form of yes or no that could help to do diagnosis, if they don't relate to any dental disease simply return FALSE. Step 2 - Based on all answers give me the final diagnosis name from this list (Karies, Reversible Pulpitis, Irreversible Pulpitis, Pulpaschaden Durch Trauma, Apikale Parodontitis, Apikaler Abszess, Gingivitis, Akute Parodontitis, Chronsiche Parodontitis, Perikoronitis, Broken Tooth, Temporomandibular Joint, Tooth broken off, Malocclusion, Veneers, Aligners, Inflammation). Only Choose a Diagnosis from this list.  Digonsis  can be more than one. But only give me names(from the list i have provided) of those without any description. Step 3 :  Ask maximum ten questions to make a decision. But if there's a question whose answer can decide immediately the disease give final diagnosis and do not ask further questions. Step 4  When you reach to a diagnosis then in your reponse first word should be: DIAGNOSIS. Understand this. Never ask all the questions at once. I repeat never ask all questions at once. Always ask one question and then after getting its reply ask the second one and so on. Also never write a single other word when you are asking a question. Reply me with question no one for provided symptoms.";

function getAge(dobString) {
  const dateOfBirth = new Date(dobString);

  const today = new Date();

  const ageInMilliSeconds = today.getTime() - dateOfBirth.getTime();

  const ageInYears = Math.floor(
    ageInMilliSeconds / (365.25 * 24 * 60 * 60 * 1000)
  );

  return ageInYears;
}

function removeDots(word) {
  return word.replaceAll(".", "");
}

const noSymptomsReasons = ["discomfort or pain in the temporomandibular joint"];

export default function GPTQuestions() {
  const [symptoms, setSymptoms] = useState([]);
  const {
    instance,
    setGptQuestions,
    signInUserData,
    appointmentQuestionsArray,
    setDoctorsList,
    appointmentType,
    appointReason,
    reasonName,
  } = useApiContext();
  const [status, setStatus] = useState("symptoms");
  const [aiQuestion, setAiQuestion] = useState("");
  const [conversation, setConversation] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [questionAnswer, setQuestionAnswer] = useState([]);
  const [isNoSymptomsReason, setIsNoSymptomsReason] = useState(
    noSymptomsReasons.includes(reasonName[0].name.toLowerCase())
  );

  const navigate = useNavigate();

  // Pain in tooth
  // Dicomfort and pain in temp

  useEffect(() => {
    if (status === "ai_questions" && aiQuestion) {
      setConversation([
        ...conversation,
        {
          role: "assistant",
          content: aiQuestion,
        },
      ]);
    }
  }, [status, aiQuestion]);

  useEffect(() => {
    if (isNoSymptomsReason) {
      getGPTQuestion();
    }
  }, []);

  //  Create Differenct GPT prompt based on different reasons
  const createPrompt = (reason_name) => {
    console.log("Reason Name: ", reason_name);
    switch (reason_name) {
      case "Discomfort or pain in the temporomandibular joint":
        return `I am ${getAge(signInUserData.dob)} years old ${
          signInUserData.gender
        }, reason for appointment is ${reasonName[0]?.name} at ${
          appointmentQuestionsArray[0].answer
        } region (according to teeth diagram).`;

      case "Pain in one or more teeth":
        return `I am ${getAge(signInUserData.dob)} years old ${
          signInUserData.gender
        }, reason for appointment is ${reasonName[0]?.name} at ${
          appointmentQuestionsArray[0].answer
        } region (according to teeth diagram). and I have following symptoms: ${symptoms.join(
          ","
        )}.`;

      default:
        return `I am ${getAge(signInUserData.dob)} years old ${
          signInUserData.gender
        }, reason for appointment is ${
          reasonName[0]?.name
        } and I have following symptoms: ${symptoms.join(",")}.`;
    }
  };

  async function getGPTQuestion(paramconversation = [], paramQuestionAnswer) {
    setisLoading(true);
    try {
      if (symptoms.length === 0 && !isNoSymptomsReason) {
        toast.warn("Please Enter Atleast 1 symptom.");
        setisLoading(false);
        return;
      }

      console.log("Gpt Question: ", reasonName, appointmentQuestionsArray);

      const { data } = await instance.post(`/gpt/ask-gpt`, {
        conversation: [
          {
            role: "system",
            content: prompt,
          },
          {
            role: "user",
            content: createPrompt(reasonName[0].name),
          },
          ...paramconversation,
        ],
      });

      if (data.data.choices[0]?.message.content.startsWith("DIAGNOSIS:")) {
        setStatus("completed");
        const conversationPayload = {
          conversation: [...appointmentQuestionsArray, ...paramQuestionAnswer],
          symptoms,
          diagnosis: removeDots(
            data.data.choices[0]?.message.content.replace("DIAGNOSIS: ", "")
          ),
          user_id: signInUserData?.user_id,
          reason_id: appointReason,
        };

        setGptQuestions(conversationPayload);
        await getDoctorList(conversationPayload);
        setisLoading(false);

        return;
      }

      if (data.data.choices[0]?.message.content === "FALSE") {
        toast.error("Invalid Symptoms");
        setStatus("invalid_symptoms");
        setisLoading(false);
        return;
      }

      const newAiQuestion = data.data.choices[0]?.message.content || "";
      setAiQuestion(newAiQuestion);
      setSelectedAnswer("");

      if (status === "symptoms") setStatus("ai_questions");
      setisLoading(false);
    } catch (error) {
      console.log("Error: ", error);
      toast.error("Something went wrong!");
      setisLoading(false);
    }
  }

  async function getDoctorList(conversationPayload) {
    const { data } = await instance.post(
      "/patient/doctors-list",
      conversationPayload
    );

    setDoctorsList(data);

    if (appointmentType === "inside") {
      navigate("/patient-portal/doctor-appointment");
    } else {
      navigate("/patient-portal/doctor-list");
    }
  }

  function handleAnswerSelection() {
    setConversation([
      ...conversation,
      { role: "user", content: selectedAnswer },
    ]);

    // Save answer and question for backend
    setQuestionAnswer([
      ...questionAnswer,
      { question: aiQuestion, answer: selectedAnswer },
    ]);

    getGPTQuestion(
      [...conversation, { role: "user", content: selectedAnswer }],
      [...questionAnswer, { question: aiQuestion, answer: selectedAnswer }]
    );
  }

  function renderer() {
    if (status === "symptoms") {
      return (
        <>
          <TagsInput
            value={symptoms}
            onChange={setSymptoms}
            name="symptoms"
            renderInput={(props) => {
              let { onChange, value, addTag, ...other } = props;
              return (
                <input
                  type="text"
                  className="react-tagsinput-input"
                  onChange={onChange}
                  value={value}
                  {...other}
                  placeholder="Enter symptoms.."
                />
              );
            }}
          />
          <button onClick={() => getGPTQuestion([])}>
            Next <TiArrowRight />
          </button>
        </>
      );
    } else if (status === "ai_questions") {
      return (
        <div className="questions_container">
          <h5>{aiQuestion}</h5>
          {answerChoices.map((choice) => {
            const isSelected = selectedAnswer === choice;

            return (
              <div
                key={choice}
                className={`single_reason ${isSelected ? "selected" : ""}`}
                onClick={() => setSelectedAnswer(choice)}
              >
                {isSelected ? (
                  <IoCheckbox />
                ) : (
                  <MdOutlineCheckBoxOutlineBlank />
                )}
                {choice}
              </div>
            );
          })}
          <button
            disabled={selectedAnswer === ""}
            title={selectedAnswer === "" && "Please select an answer first."}
            onClick={handleAnswerSelection}
          >
            Next <TiArrowRight />
          </button>
        </div>
      );
    } else if (status === "completed") {
      return (
        <div className="final_diagnosis">
          <h5>DIAGNOSIS IS COMPLETED</h5>
          <p>Navigating to Doctor list.</p>
          <div className="content-spinner">
            <div className="loader"></div>
          </div>
        </div>
      );
    } else if (status === "invalid_symptoms") {
      return (
        <div className="final_diagnosis">
          <h3 className="text-center text-danger">Invalid Symptoms!</h3>
          <p className="text-center text-primary">
            Enter symptoms related to dental diseases.
            <br />
            Please visit a General Physician.
          </p>
          <button
            onClick={() => navigate("/patient-portal/appointment-questions")}
          >
            <TiArrowLeft /> Try Again
          </button>
        </div>
      );
    }
  }

  return (
    <>
      <section className="appointment_questionare gpt_quesion_section">
        <div className="reason_question_wrapper">
          <h2>Talk to Remote Medical Care AI Medical Assistant</h2>
          <p style={{ color: "black" }}>
            Engage in a seamless conversation with Remote Medical Care AI ,
            describing your symptoms for swift and accurate initial health
            evaluations.
          </p>
          {isLoading ? (
            <div className="content-spinner">
              <div className="loader"></div>
            </div>
          ) : (
            <div className="gpt_questionare">{renderer()}</div>
          )}
        </div>
      </section>
    </>
  );
}
