import "../../styles/homepageStyles/HomepageGalleryStyles.css";
import g1 from "../../assets/homepage-new/g1.webp";
import g2 from "../../assets/homepage-new/g2.webp";
import g3 from "../../assets/homepage-new/g3.webp";
import g4 from "../../assets/homepage-new/g4.webp";
import g5 from "../../assets/homepage-new/g5.webp";
import g7 from "../../assets/homepage-new/g7.webp";
import a_g_1 from "../../assets/about/about_gallery_1.jpg";
import a_g_2 from "../../assets/about/about_gallery_2.jpg";
import a_g_3 from "../../assets/about/about_gallery_3.jpg";

function AboutPageGallery() {
  return (
    <>
      <div className="homepage-gallery-container about_page_gallery">
        <div className="container">
          <div className="container-wrapper">
            <h1>
              Beyond Appointments: Crafting a Legacy of Outstanding Dental
              Healthcare
            </h1>
            <p>
              Welcome to our virtual dentistry hub, where finding a dentist and
              securing a video consultation is as easy as a few clicks. We're
              dedicated to providing a seamless and personalized dental
              experience, ensuring your oral health is prioritized from the
              comfort of your home.
            </p>
            <div className="images-container">
              <div className="image-wrapper-1">
                <img className="image-01" src={a_g_1} alt="" />
              </div>
              <div className="image-wrapper-2">
                <img src={a_g_2} alt="" />
              </div>
              <div className="image-wrapper-3">
                <img src={g3} alt="" />
              </div>
            </div>
            <div className="gallery-text-section">
              <div className="text">
                <span>What we Do</span>
                <h1>Your Reliable Remote Dentistry Hub</h1>
                <p>
                  Welcome to our virtual dentistry hub, where finding a dentist
                  and securing a video consultation is as easy as a few clicks.
                  We're dedicated to providing a seamless and personalized
                  dental experience, ensuring your oral health is prioritized
                  from the comfort of your home.
                </p>
              </div>
              <div className="image">
                <div className="image-bg"></div>
                <img src={a_g_3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutPageGallery;
