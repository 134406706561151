import React from "react";
import Elipse1 from "../assets/homepage/Ellipse1.svg";
import Appointment1 from "../assets/homepage/appointnme-flow.svg";
import "../styles/homepage.css";

function Appointment() {
  return (
    <>
      <section>
        <div className="container mt-5 mb-5">
          <div className="row g-0">
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4 mb-4">
              <div className="pt-4">
                <p className="appo-p">How We Work</p>
                <h3 className="appo-h3">
                  We’re Committed to deliver outstanding Dental Healthcare
                </h3>
                <h5 className="appo-h5 pt-4 pb-3 fw-bold">
                  Book appointment in three easy steps!
                </h5>
                <ul className="list-unstyled appo-ul">
                  <li className="fw-bold appo-li">
                    <img src={Elipse1} alt="elipse-img" className="pe-2" />
                    Fill the questionnaire, help us get the accurate diagnose
                    for you
                  </li>
                  <li className="fw-bold appo-li">
                    <img src={Elipse1} alt="elipse-img" className="pe-2" />
                    Look for the best doctor according to your Diagnose
                  </li>
                  <li className="fw-bold appo-li">
                    <img src={Elipse1} alt="elipse-img" className="pe-2" />
                    Choose the time to book appointment as per your convenience.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div>
                <img
                  src={Appointment1}
                  alt="appointment-img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Appointment;
